/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useTheme } from "react-jss";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header";
import CustomTypography from "../../components/CustomTypography";
import Form from "../../components/ContactUs/Form";
import SuccessModal from "../../components/Modal/SuccessModal";
import LoadingModal from "../../components/Modal/LoadingModal";
import api from "../../services/api";
import useStyles from "./styles";

const ContactUs = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [successModalIsOpen, setSuccessModalIsOpen] = useState(false);
  const [isSubmiting, setisSubmiting] = useState(false);
  const { t } = useTranslation();

  const handleCloseSuccessModal = () => {
    setSuccessModalIsOpen(false);
  };

  const handleFormSubmit = async (
    { name, email, subject, messageBody },
    { resetForm }
  ) => {
    setisSubmiting(true);
    try {
      const { data } = await api.post("/contact/send-message", {
        name,
        email,
        subject,
        message: messageBody,
      });
      if (data.message === "Message sent successfully!") {
        setSuccessModalIsOpen(true);
        resetForm({ values: "" });
      }
      setisSubmiting(false);
    } catch (message) {
      setisSubmiting(false);
    }
  };

  return (
    <div className={classes.container}>
      <SuccessModal
        isOpen={successModalIsOpen}
        onRequestClose={handleCloseSuccessModal}
      >
        <CustomTypography color={theme.textSecondary}>
          {t("contactUs.messageSendedSuccess")}
        </CustomTypography>
      </SuccessModal>
      <LoadingModal isLoading={isSubmiting} />
      <Header />
      <div className={classes.content}>
        <div className={classes.formWrapper}>
          <div className={classes.form}>
            <div className={classes.title}>
              <CustomTypography variant="h3">
                {t("contactUs.title")}
              </CustomTypography>
            </div>
            <div>
              <Form onSubmit={handleFormSubmit} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
