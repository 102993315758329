import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => ({
  menuItem: {
    "&:focus": {
      backgroundColor: theme.primary,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.secondary,
      },
    },
    "&:hover": {
      backgroundColor: `${theme.backgroundHover} !important`,
    },
  },
}));

export default useStyles;
