import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "react-jss";
import Divider from "@material-ui/core/Divider";
import CustomTypography from "../../../CustomTypography";
import CustomLink from "../../../CustomLink";
import useStyles from "./styles";

const SectionRow = ({
  title,
  imageCell,
  subtitle,
  stats,
  linkTo,
  placeholder,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <>
      <div className={classes.sectionGrid}>
        <div className={classes.imageWrapper}>{imageCell}</div>
        <div>
          <div className={classes.titleWrapper}>
            {linkTo && (
              <CustomLink to={linkTo}>
                <CustomTypography variant={title ? "searchResultTitle" : "tag"}>
                  {title || placeholder}
                </CustomTypography>
              </CustomLink>
            )}
            {!linkTo && (
              <CustomTypography variant={title ? "searchResultTitle" : "tag"}>
                {title || placeholder}
              </CustomTypography>
            )}
          </div>
          <div>
            {title && (
              <CustomTypography variant="tag">{subtitle}</CustomTypography>
            )}
          </div>
        </div>
        <div className={classes.statsColumn}>
          <div>
            <CustomTypography variant="searchResultTitle">
              {stats}
            </CustomTypography>
          </div>
        </div>
      </div>

      <div className={`divider ${classes.divider}`}>
        <Divider />
      </div>
    </>
  );
};

SectionRow.propTypes = {
  title: PropTypes.string,
  imageCell: PropTypes.node,
  subtitle: PropTypes.shape(),
  stats: PropTypes.string,
  linkTo: PropTypes.string,
  placeholder: PropTypes.string,
};

SectionRow.defaultProps = {
  title: undefined,
  imageCell: undefined,
  subtitle: undefined,
  stats: undefined,
  linkTo: undefined,
  placeholder: undefined,
};

export default SectionRow;
