import { createUseStyles } from "react-jss";
/* import { breakpoints } from "../../../constants/sizes"; */

const useStyles = createUseStyles((theme) => ({
  modalSection: {
    marginBottom: 20,
  },
  title: {},
  titleLine: {
    marginTop: 8,
    marginBottom: 10,
    backgroundColor: theme.stroke,
    height: 1,
  },
}));

export default useStyles;
