import React, { useEffect } from "react";
import { useTheme } from "react-jss";
import { useDispatch } from "react-redux";
import { fetchStatistics } from "../../redux/cooperative/cooperativeActions";
import Header from "../../components/Cooperatives/Header";
import Footer from "../../components/V2/Footer";
import useStyles from "./styles";
import Result from "./Result";

function Castanha() {
  const theme = useTheme();
  const classes = useStyles(theme);
  const dispatch = useDispatch();

  const dispatchFetchStatistics = () => {
    dispatch(fetchStatistics());
  };

  useEffect(() => {
    dispatchFetchStatistics();
  }, []);

  return (
    <div className={classes.container}>
      <Header />
      <Result />
      <Footer />
    </div>
  );
}

export default Castanha;
