import React from "react";
import { useTheme } from "react-jss";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import CustomTypography from "../../../CustomTypography";
import { getStrapiMedia } from "../../../../services/hcms/media";
import ArticleInfo from "../../ArticleInfo";
import Markdown from "../../../Markdown";
import HighlightSkeleton from "../../../Skeleton/HighlightSkeleton";
import NextLink from "../../../NextLink";
import useStyles from "./styles";

/**
 * This component represents an Article in Highlight.
 */
const Highlight = ({ article, columnist }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { t } = useTranslation();

  return (
    <div className={classes.wrapper}>
      <CustomTypography style={{ marginBottom: 16 }} variant="h3">
        {t("agency.highlight.title")}
      </CustomTypography>

      {!article ? (
        <HighlightSkeleton />
      ) : (
        <div>
          <NextLink
            className={classes.link}
            to={`agencia/artigo/${article.slug}`}
          >
            <img
              alt="highlightImg"
              src={getStrapiMedia(article.thumbnail)}
              className={classes.highlightImg}
            />
          </NextLink>

          {columnist && (
            <div style={{ marginTop: 16 }}>
              <ArticleInfo
                columnist={columnist}
                publishedAt={article.published}
              />
            </div>
          )}

          <NextLink
            style={{ margin: "16px 0px 16px 0px" }}
            className={classes.link}
            to={`agencia/artigo/${article.slug}`}
          >
            <CustomTypography variant="h3">{article.title}</CustomTypography>
          </NextLink>

          <CustomTypography
            color={theme.textSecondary}
            style={{ marginBottom: 16 }}
            className={classes.bodyPreview}
            variant="paragraph"
          >
            <Markdown isBody={false} hasCustomRender>
              {article.body}
            </Markdown>
          </CustomTypography>

          <NextLink
            style={{ marginBottom: 16 }}
            className={classes.link}
            to={`agencia/artigo/${article.slug}`}
          >
            <CustomTypography
              color={theme.primary1}
              weight="bold"
              variant="body"
            >
              {t("agency.highlight.keepReading")}
            </CustomTypography>
          </NextLink>
        </div>
      )}
    </div>
  );
};

Highlight.propTypes = {
  article: PropTypes.shape(),
  columnist: PropTypes.shape(),
};

Highlight.defaultProps = {
  article: undefined,
  columnist: undefined,
};
export default Highlight;
