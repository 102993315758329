import { createUseStyles } from "react-jss";
import { breakpoints, defaultContentContainer } from "../../constants/sizes";

const useStyles = createUseStyles((theme) => ({
  footer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    objectFit: "cover",
    paddingLeft: defaultContentContainer.paddingLeft,
    paddingRight: defaultContentContainer.paddingLeft,

    [breakpoints.md]: {
      padding: defaultContentContainer.paddingSm,
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  footerBackground: {
    width: "100%",
    position: "sticky",
    zIndex: 6,
    padding: "20px 0px 20px 0px",
    backgroundColor: theme.background1,
    display: "flex",
    [breakpoints.md]: {
      padding: defaultContentContainer.paddingSm,
    },
  },
  footerImage: {
    justifySelf: "center",
    alignSelf: "center",
    userSelect: "none",
  },
  verticalLine: {
    height: 48,
    width: 2,
    backgroundColor: theme.stroke,
    [breakpoints.md]: {
      display: "none",
    },
  },
  sn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    maxWidth: 180,
    [breakpoints.md]: {
      marginTop: 10,
      width: "100%",
      justifyContent: "center",
    },
  },
  snIcons: {
    display: "flex",
    justifyContent: "space-between",
    [breakpoints.md]: {
      width: "100%",
      justifyContent: "flex-start",
      columnGap: 30,
    },
  },
  icon: {
    "& path": {
      fill: theme.primary2,
    },
  },
  titleSn: {
    marginBottom: 15,
  },
  instagram: {
    marginTop: -5,
  },
  bottomButtons: {
    display: "flex",
    columnGap: 10,
    height: "100%",
    alignItems: "center",
    [breakpoints.md]: {
      flexDirection: "column",
      alignItems: "flex-start",
      rowGap: 10,
      marginBottom: 10,
    },
  },
}));

export default useStyles;
