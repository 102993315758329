import React from "react";
import { useTheme } from "react-jss";
import { Link } from "react-router-dom";
import YouTube from "react-youtube";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { getYoutubeId } from "../../../services/hcms/media";
import CustomTypography from "../../CustomTypography";
import Markdown from "../../Markdown";
import useStyles from "./styles";
import HighlightSkeleton from "../../Skeleton/HighlightSkeleton";

/**
 * This component represents an Video in Highlight.
 */
export default function VideoInfo({ data, miniThumb }) {
  VideoInfo.propTypes = {
    data: PropTypes.shape(),
    miniThumb: PropTypes.bool,
  };

  VideoInfo.defaultProps = {
    miniThumb: false,
    data: undefined,
  };

  const theme = useTheme();
  const classes = useStyles({ theme, miniThumb });
  const { t } = useTranslation();

  return !data ? (
    <HighlightSkeleton />
  ) : (
    <div className={classes.wrapper}>
      <YouTube
        className={classes.videoThumb}
        videoId={getYoutubeId(data.link)}
      />

      <Link
        className={classes.link}
        to={{ pathname: data.link }}
        target="_blank"
      >
        <CustomTypography
          className={classes.titlePreview}
          style={{ fontSize: miniThumb ? 20 : 24 }}
          variant="h3"
        >
          {data.title}
        </CustomTypography>
      </Link>

      <CustomTypography
        color={theme.textSecondary}
        className={classes.bodyPreview}
        style={{ fontSize: miniThumb ? 16 : 20 }}
        variant="paragraph"
      >
        <Markdown>{data.body}</Markdown>
      </CustomTypography>

      <div className={classes.dateWrapper}>
        <CustomTypography
          variant="body"
          weight="regular"
          style={{ fontSize: 16 }}
          color={theme.textSecondary}
        >
          {t("agency.article.publishedAtDate", {
            date: new Date(data.date),
          })}
        </CustomTypography>
      </div>
    </div>
  );
}
