import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";

export default function SkeletonTypography({ loading, variant, ...props }) {
  return (
    <div>
      <Typography component="div" key={variant} variant={variant} {...props}>
        {loading ? <Skeleton /> : variant}
      </Typography>
    </div>
  );
}

SkeletonTypography.propTypes = {
  loading: PropTypes.bool,
  variant: PropTypes.string,
};

SkeletonTypography.defaultProps = {
  loading: true,
  variant: "",
};
