import React from "react";
import PropTypes from "prop-types";
import DownArrowFilled from "../../Icons/DownArrowFilled";

const ArrowThumbComponent = ({ children, ...rest }) => (
  <span {...rest}>
    {children}
    <span className="arrowWrapper">
      <DownArrowFilled />
    </span>
  </span>
);

ArrowThumbComponent.propTypes = {
  children: PropTypes.node,
};

ArrowThumbComponent.defaultProps = {
  children: undefined,
};

export default ArrowThumbComponent;
