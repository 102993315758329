import * as React from "react";

function SvgMenu(props) {
  return (
    <svg
      width={32}
      height={32}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect opacity={0.4} width={32} height={32} rx={4} fill="#00585A" />
      <path
        d="M8 10a1 1 0 011-1h14a1 1 0 110 2H9a1 1 0 01-1-1zM8 22a1 1 0 011-1h14a1 1 0 010 2H9a1 1 0 01-1-1zM15 15a1 1 0 000 2h8a1 1 0 000-2h-8z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgMenu;
