import { createUseStyles } from "react-jss";
// import { breakpoints } from "../../../../constants/sizes";

const useStyles = createUseStyles(() => ({
  root: {
    padding: 15,
  },
  ResultArea: {},
  resultContent: {
    marginBottom: 8,
    display: "flex",
    flexWrap: "wrap",
    rowGap: 5,
  },

  info: {
    display: "flex",
    marginTop: 10,
    marginBottom: 5,
  },
  date: {
    textTransform: "capitalize",
  },
  productionsWrapper: {
    display: "grid",
    gridTemplateColumns: "auto auto",
    gridColumnGap: 20,
  },
  instituteInfoWrapper: {
    width: "100%",
  },
}));

export default useStyles;
