import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
import MobileMenu from "./MobileMenu";
import HeaderButtons from "../HeaderButtons";
import CustomButton from "../../CustomButton";
import useStyles from "./styles";
// import AuthContext from "../../contexts/auth";

/**
 * This component returns the main Header.
 */
const Header = ({ searchbar }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const location = useLocation();
  const [selectedButton, setSelectedButton] = useState({
    landing: false,
    agency: false,
    multimidia: false,
  });
  // const { isAuthenticated } = useContext(AuthContext);

  const classes = useStyles({
    theme,
  });

  /* const handleSignOut = () => {
    signOut();
  }; */

  /**
   * This userEffect set the selected header button according by pathname.
   */
  useEffect(() => {
    if (location.pathname === "/") {
      setSelectedButton({ landing: true });
    } else if (location.pathname === "/agencia/multimidia") {
      setSelectedButton({ multimidia: true, agency: true });
    } else if (location.pathname.includes("/agencia")) {
      setSelectedButton({ agency: true });
    } else if (location.pathname.includes("/buscador")) {
      setSelectedButton({});
    } else if (location.pathname.includes("/busca")) {
      setSelectedButton({ agency: true });
    } else if (location.pathname.includes("/contato")) {
      setSelectedButton({ contactUs: true });
    } else if (location.pathname.includes("/cooperativas")) {
      setSelectedButton({ cooperatives: true });
    } else if (location.pathname.includes("/missao")) {
      setSelectedButton({ duty: true });
    }
  }, [location]);

  return (
    <div className={classNames([classes.header, "header-root"])}>
      <div className={classes.headerItens}>
        <HeaderButtons selectedButton={selectedButton} />

        <div className={classes.actionsWrapper}>
          {searchbar && (
            <div className="header-search-bar-wrapper">{searchbar}</div>
          )}
          <div
            className={classNames([
              classes.headerMobile,
              "header-mobile-menu-wrapper",
            ])}
          >
            <MobileMenu selectedMenuButton={selectedButton} />
          </div>
          <div className={classes.buttonWrapper}>
            {/* <div className={classes.signInButton}>
              <CustomButton
                backgroundColor={theme.background0}
                color={theme.primary0}
                href="/login"
              >
                {t("forms.signIn")}
              </CustomButton>
        </div> */}
            <div className={classes.signUpButtonWrapper}>
              <CustomButton
                color={theme.background0}
                href="/cadastro"
                className={classes.authButton}
              >
                {t("forms.signUp")}
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  searchbar: PropTypes.node,
};

Header.defaultProps = {
  searchbar: null,
};

export default Header;
