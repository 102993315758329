import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => ({
  container: {
    marginTop: 30,
  },
  statesListWrapper: {
    marginTop: 25,
    border: `1px solid ${theme.stroke}`,
    borderRadius: 4,
    "& .list-item": {
      paddingLeft: 20,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      height: 60,
    },
    "& .list-item:nth-child(2n)": {
      backgroundColor: theme.background1,
    },
  },
  itemContent: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  iconWrapper: {
    display: "flex",
    alignItems: "center",
    height: "100%",
  },
}));

export default useStyles;
