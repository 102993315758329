import * as React from "react";

function LinkClip(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_3463_12219)">
        <path
          d="M9.23002 11.5113L7.05535 13.686C6.42397 14.3005 5.57603 14.6417 4.69498 14.6358C3.81394 14.6299 2.97065 14.2773 2.34756 13.6544C1.72446 13.0315 1.37168 12.1883 1.36553 11.3073C1.35937 10.4262 1.70035 9.57819 2.31468 8.94663L4.48935 6.76997C4.61436 6.64488 4.68455 6.47525 4.68449 6.2984C4.68442 6.12155 4.61411 5.95198 4.48902 5.82697C4.36392 5.70197 4.1943 5.63177 4.01745 5.63184C3.8406 5.6319 3.67102 5.70221 3.54602 5.8273L1.37202 8.00397C0.493529 8.8829 0.000175859 10.0748 0.00048843 11.3175C0.000801 12.5602 0.494754 13.7518 1.37368 14.6303C2.25261 15.5088 3.44452 16.0021 4.6872 16.0018C5.92988 16.0015 7.12154 15.5076 8.00002 14.6286L10.1747 12.454C10.2961 12.3282 10.3633 12.1598 10.3618 11.985C10.3603 11.8102 10.2902 11.643 10.1666 11.5194C10.043 11.3958 9.87576 11.3257 9.70096 11.3242C9.52616 11.3227 9.35776 11.3899 9.23202 11.5113H9.23002Z"
          fill="#00A5A8"
        />
        <path
          d="M14.6292 1.37402C14.1954 0.937225 13.6793 0.590868 13.1106 0.355013C12.542 0.119159 11.9322 -0.0015043 11.3166 1.64272e-05V1.64272e-05C10.7013 -0.00161806 10.0918 0.118735 9.52329 0.354117C8.9548 0.589499 8.43862 0.935239 8.00457 1.37135L5.82657 3.54668C5.70148 3.67168 5.63117 3.84126 5.6311 4.01811C5.63104 4.19496 5.70123 4.36458 5.82624 4.48968C5.95124 4.61477 6.12082 4.68508 6.29767 4.68515C6.47452 4.68521 6.64415 4.61502 6.76924 4.49001L8.94591 2.31535C9.25628 2.00311 9.6255 1.75555 10.0322 1.58699C10.4389 1.41843 10.875 1.33222 11.3152 1.33335V1.33335C11.9781 1.33357 12.626 1.5303 13.177 1.89868C13.7281 2.26706 14.1575 2.79054 14.4111 3.40296C14.6647 4.01537 14.731 4.68923 14.6017 5.33933C14.4724 5.98943 14.1532 6.5866 13.6846 7.05534L11.5099 9.23001C11.3848 9.3551 11.3145 9.52476 11.3145 9.70167C11.3145 9.87858 11.3848 10.0482 11.5099 10.1733C11.635 10.2984 11.8047 10.3687 11.9816 10.3687C12.1585 10.3687 12.3282 10.2984 12.4532 10.1733L14.6279 8.00001C15.5053 7.12074 15.9981 5.92941 15.9984 4.68729C15.9986 3.44516 15.5063 2.25364 14.6292 1.37402V1.37402Z"
          fill="#00A5A8"
        />
        <path
          d="M9.52872 5.52869L5.52872 9.52869C5.46505 9.59019 5.41426 9.66375 5.37932 9.74509C5.34438 9.82643 5.32599 9.91391 5.32522 10.0024C5.32445 10.0909 5.34132 10.1787 5.37484 10.2607C5.40836 10.3426 5.45786 10.417 5.52046 10.4796C5.58305 10.5422 5.65749 10.5917 5.73942 10.6252C5.82135 10.6588 5.90914 10.6756 5.99765 10.6749C6.08617 10.6741 6.17365 10.6557 6.25499 10.6208C6.33633 10.5858 6.40989 10.535 6.47139 10.4714L10.4714 6.47136C10.5928 6.34563 10.66 6.17722 10.6585 6.00243C10.657 5.82763 10.5869 5.66042 10.4633 5.53681C10.3397 5.41321 10.1725 5.3431 9.99765 5.34158C9.82286 5.34006 9.65446 5.40726 9.52872 5.52869Z"
          fill="#00A5A8"
        />
      </g>
      <defs>
        <clipPath id="clip0_3463_12219">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default LinkClip;
