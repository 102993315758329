import { createUseStyles } from "react-jss";
import { breakpoints } from "../../../../constants/sizes";

const useStyles = createUseStyles((/* theme */) => ({
  articlesWrapper: {
    margin: "40px 0px 40px 0px",
  },
  titleWrapper: {
    display: "flex",
    width: "100%",
    flexFlow: "row wrap",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
  },
  link: {
    display: "flex",
    textDecoration: "none",
    "&:hover": {},
  },
  moreArticles: {
    marginTop: 24,
    display: "grid",
    width: "100%",
    marginBottom: 60,

    gridTemplateColumns: "minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)",
    gridColumnGap: 32,
    gridRowGap: 40,
    alignItems: "stretch",

    [breakpoints.smd]: {
      gridColumnGap: 16,
    },

    [breakpoints.sm]: {
      gridTemplateColumns: "repeat(auto-fill, 100%)",
      marginTop: 16,
      display: "grid",
    },
  },
  moreArticle: {
    height: "auto",
    [breakpoints.sm]: {
      width: "100%",
    },
  },
}));

export default useStyles;
