import React from "react";
import Grid from "@material-ui/core/Grid";
import HighlightSkeleton from "../HighlightSkeleton";

export default function ContentListSkeleton() {
  return (
    <Grid container spacing={2} style={{ marginBottom: 20 }}>
      <Grid item sm={4} xs={12}>
        <HighlightSkeleton />
      </Grid>
      <Grid item sm={4} xs={12}>
        <HighlightSkeleton />
      </Grid>
      <Grid item sm={4} xs={12}>
        <HighlightSkeleton />
      </Grid>
    </Grid>
  );
}
