import api from "../../services/api";
import {
  FETCH_RESEARCHER_REQUEST,
  FETCH_RESEARCHER_SUCCESS,
  FETCH_RESEARCHER_FAILURE,
  FETCH_RESEARCHER_STATS_REQUEST,
  FETCH_RESEARCHER_STATS_SUCCESS,
  FETCH_RESEARCHER_STATS_FAILURE,
  FETCH_RESEARCHER_PROJECTS_REQUEST,
  FETCH_RESEARCHER_PROJECTS_SUCCESS,
  FETCH_RESEARCHER_PROJECTS_FAILURE,
  FETCH_RESEARCHER_PUBLICATIONS_REQUEST,
  FETCH_RESEARCHER_PUBLICATIONS_SUCCESS,
  FETCH_RESEARCHER_PUBLICATIONS_FAILURE,
  SET_STATS_START_DATE,
  SET_STATS_END_DATE,
  RESET_STATS_FILTERS,
  SET_DATE_PERIOD,
} from "./researcherTypes";

export const fetchResearcherRequest = () => ({
  type: FETCH_RESEARCHER_REQUEST,
});

export const fetchResearcherSuccess = (researcher) => ({
  type: FETCH_RESEARCHER_SUCCESS,
  payload: researcher,
});

export const fetchResearcherFailure = (error) => ({
  type: FETCH_RESEARCHER_FAILURE,
  payload: error,
});

export const fetchStatsRequest = () => ({
  type: FETCH_RESEARCHER_STATS_REQUEST,
});

export const fetchStatsSuccess = (response) => ({
  type: FETCH_RESEARCHER_STATS_SUCCESS,
  payload: response.statistics,
});

export const fetchStatsFailure = (error) => ({
  type: FETCH_RESEARCHER_STATS_FAILURE,
  payload: error,
});

export const fetchProjectsRequest = () => ({
  type: FETCH_RESEARCHER_PROJECTS_REQUEST,
});

export const fetchProjectsSuccess = (response) => ({
  type: FETCH_RESEARCHER_PROJECTS_SUCCESS,
  payload: response,
});

export const fetchProjectsFailure = (error) => ({
  type: FETCH_RESEARCHER_PROJECTS_FAILURE,
  payload: error,
});

export const fetchPublicationsRequest = () => ({
  type: FETCH_RESEARCHER_PUBLICATIONS_REQUEST,
});

export const fetchPublicationsSuccess = (response) => ({
  type: FETCH_RESEARCHER_PUBLICATIONS_SUCCESS,
  payload: response,
});

export const fetchPublicationsFailure = (error) => ({
  type: FETCH_RESEARCHER_PUBLICATIONS_FAILURE,
  payload: error,
});

export const resetStatsFilters = () => ({
  type: RESET_STATS_FILTERS,
});

/**
 * Fetch researcher from API
 * @param {*} id
 * @returns
 */
export const fetchResearcher = (id) => (dispatch) => {
  dispatch(fetchResearcherRequest());

  const url = `researchers/${id}`;

  api
    .get(url)
    .then((response) => {
      const results = response.data;
      dispatch(fetchResearcherSuccess(results));
    })
    .catch((error) => {
      const errorMsg = error.message;
      dispatch(fetchResearcherFailure(errorMsg));
    });
};
/**
 * Fetch statistics from API
 * @param {*} id
 * @param {*} startDate
 * @param {*} endDate
 * @returns
 */
export const fetchStatistics = (id, startDate, endDate) => (dispatch) => {
  dispatch(fetchStatsRequest());

  const url = `researchers/statistics/${id}?start_year=${startDate.getFullYear()}&end_year=${endDate.getFullYear()}`;

  api
    .get(url)
    .then((response) => {
      const results = response.data;
      dispatch(fetchStatsSuccess(results));
    })
    .catch((error) => {
      const errorMsg = error.message;
      dispatch(fetchStatsFailure(errorMsg));
    });
};

/**
 * Fecht projects from api
 * @param {*} id
 * @param {*} startDate
 * @param {*} endDate
 * @returns
 */
export const fetchProjects = (id, startDate, endDate) => (dispatch) => {
  dispatch(fetchProjectsRequest());

  const url = `researchers/projects/${id}?start_year=${startDate.getFullYear()}&end_year=${endDate.getFullYear()}`;

  api
    .get(url)
    .then((response) => {
      const results = response.data;
      dispatch(fetchProjectsSuccess(results));
    })
    .catch((error) => {
      const errorMsg = error.message;
      dispatch(fetchProjectsFailure(errorMsg));
    });
};

/**
 * Fetch publications from API
 * @param {*} id
 * @param {*} startDate
 * @param {*} endDate
 * @returns
 */
export const fetchPublications = (id, startDate, endDate) => (dispatch) => {
  dispatch(fetchPublicationsRequest());

  const url = `researchers/publications/${id}?start_year=${startDate.getFullYear()}&end_year=${endDate.getFullYear()}`;

  api
    .get(url)
    .then((response) => {
      const results = response.data;
      dispatch(fetchPublicationsSuccess(results));
    })
    .catch((error) => {
      const errorMsg = error.message;
      dispatch(fetchPublicationsFailure(errorMsg));
    });
};

export const setStartDate = (startDate) => ({
  type: SET_STATS_START_DATE,
  payload: startDate,
});

export const setEndDate = (endDate) => ({
  type: SET_STATS_END_DATE,
  payload: endDate,
});

export const setPeriod = (startDate, endDate) => ({
  type: SET_DATE_PERIOD,
  payload: { startDate, endDate },
});
