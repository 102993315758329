import React, { useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { useTheme } from "react-jss";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as yup from "yup";
import CustomButton from "../../CustomButton";
import Input from "../../Form/Input";
import useStyles from "./styles";

const Form = ({ onSubmit }) => {
  const { t } = useTranslation();

  const validationSchema = yup.object({
    name: yup.string().required(t("forms.errors.nameRequired")),
    email: yup
      .string("Enter your email")
      .email(t("forms.errors.emailInvalid"))
      .required(t("forms.errors.emailRequired")),
    subject: yup.string().required(t("forms.errors.subjectRequired")),
    messageBody: yup
      .string()
      .required(t("forms.errors.messageBodyRequired"))
      .max(2000, t("forms.errors.exceededMaxLength", { max: 2000 })),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      subject: "",
      messageBody: "",
    },

    validationSchema,
    onSubmit: (values, { resetForm }) => {
      if (onSubmit) onSubmit(values, { resetForm });
    },
  });

  useEffect(() => formik.validateForm(), []);

  const hasErrors = useMemo(
    () =>
      formik.errors.name ||
      formik.errors.email ||
      formik.errors.subject ||
      formik.errors.messageBody,
    [formik.errors]
  );

  const theme = useTheme();
  const classes = useStyles({ theme, hasErrors });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={classes.item}>
        <Input
          label={t("forms.name")}
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          placeholder={t("forms.fullNamePlaceholder")}
          variant="outlined"
          style={{ marginTop: 10 }}
        />
      </div>
      <div className={classes.item}>
        <Input
          label={t("forms.email")}
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          placeholder={t("forms.emailPlaceholder")}
          variant="outlined"
          style={{ marginTop: 10 }}
        />
      </div>
      <div className={classes.item}>
        <Input
          label={t("forms.subject")}
          name="subject"
          value={formik.values.subject}
          onChange={formik.handleChange}
          error={formik.touched.subject && Boolean(formik.errors.subject)}
          helperText={formik.touched.subject && formik.errors.subject}
          placeholder={t("forms.subjectPlaceHolder")}
          variant="outlined"
          style={{ marginTop: 10 }}
        />
      </div>
      <div className={classes.item}>
        <Input
          label={t("forms.writeMessage")}
          name="messageBody"
          value={formik.values.messageBody}
          onChange={formik.handleChange}
          error={
            formik.touched.messageBody && Boolean(formik.errors.messageBody)
          }
          helperText={formik.touched.messageBody && formik.errors.messageBody}
          placeholder={t("forms.writeMessagePlaceholder")}
          variant="outlined"
          style={{ marginTop: 10 }}
          multiline
          rows={5}
        />
      </div>
      <div className={classes.submitButtonWrapper}>
        <CustomButton type="submit">{t("forms.sendMessage")}</CustomButton>
      </div>
    </form>
  );
};

Form.propTypes = {
  onSubmit: PropTypes.func,
};

Form.defaultProps = {
  onSubmit: undefined,
};

export default Form;
