import React from "react";
import { useTheme } from "react-jss";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CustomTypography from "../../../../../CustomTypography";
import CustomTextField from "../../../../../CustomTextField";
import CustomButton from "../../../../../CustomButton";
import NotificationIcon from "../../../../../Icons/Notification";
import useStyles from "./styles";

const FormContainer = ({ onSendSuccess }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();

  const handleClick = () => {
    onSendSuccess();
  };

  return (
    <>
      <div className={classes.contentItem}>
        <CustomTypography variant="h3" color={theme.textPrimary}>
          {t("searchEngine.alertModal.createAlert")}
        </CustomTypography>
      </div>
      <div className={classes.contentItem}>
        <CustomTypography variant="body" color={theme.textSecondary}>
          {t("searchEngine.alertModal.description")}
        </CustomTypography>
      </div>
      <div className={classes.contentItem}>
        <Divider />
      </div>
      <div className={classes.contentItem}>
        <CustomTypography variant="tag" color={theme.textPrimary}>
          {t("searchEngine.alertModal.form.name")}
        </CustomTypography>
        <CustomTextField
          placeholder={t("landing.cta.box.namePlaceholder")}
          variant="outlined"
          style={{ marginTop: 10 }}
          className={classes.ctaTextFields}
        />
      </div>
      <div className={classes.contentItem}>
        <CustomTypography variant="tag" color={theme.textPrimary}>
          {t("searchEngine.alertModal.form.email")}
        </CustomTypography>
        <CustomTextField
          placeholder={t("landing.cta.box.emailPlaceholder")}
          variant="outlined"
          style={{ marginTop: 10 }}
          className={classes.ctaTextFields}
        />
      </div>
      <div className={classes.contentItem}>
        <CustomTypography variant="tagBold" color={theme.textPrimary}>
          {t("searchEngine.alertModal.form.frequency")}
        </CustomTypography>
        <RadioGroup
          row
          aria-label="frequency"
          name="frequency"
          defaultValue="weekly"
        >
          <FormControlLabel
            value="weekly"
            control={<Radio color="primary" />}
            label={
              <CustomTypography variant="body" color={theme.textPrimary}>
                {t("searchEngine.alertModal.form.weekly")}
              </CustomTypography>
            }
          />
          <FormControlLabel
            value="monthly"
            control={<Radio color="primary" />}
            label={
              <CustomTypography variant="body" color={theme.textPrimary}>
                {t("searchEngine.alertModal.form.monthly")}
              </CustomTypography>
            }
          />
        </RadioGroup>
      </div>
      <div className={classes.buttonWrapper}>
        <CustomButton
          color={theme.background0}
          className={classes.alertButton}
          startIcon={<NotificationIcon />}
          onClick={handleClick}
        >
          {t("searchEngine.alertModal.form.button")}
        </CustomButton>
      </div>
    </>
  );
};

FormContainer.propTypes = {
  onSendSuccess: PropTypes.func,
};

FormContainer.defaultProps = {
  onSendSuccess: undefined,
};

export default FormContainer;
