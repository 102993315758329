import { createUseStyles } from "react-jss";
import { hex2rgba } from "../../../../util/hooks";
// import { breakpoints } from "../../../constants/sizes";

const useStyles = createUseStyles((theme) => ({
  contentWrapper: {
    fontWeight: "bold",
    color: theme.primary1,
    borderRadius: 5,
    textTransform: "uppercase",
    marginRight: 10,
  },
  button: {
    "&.MuiButton-root": {
      backgroundColor: `${hex2rgba(theme.primary1, 0.25)}`,
      minWidth: 30,
      minHeight: 0,
      height: 20,
      color: theme.primary1,
      fontSize: 12,
      fontWeight: "700",
      padding: 0,

      "&:hover": {
        backgroundColor: `${hex2rgba(theme.primary1, 0.35)}`,
      },
    },
  },
}));

export default useStyles;
