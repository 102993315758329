import * as React from "react";

function ArrowLeft(props) {
  return (
    <svg
      width="8"
      height="14"
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.60053 12.7414C7.8047 12.3331 7.72467 11.8399 7.40185 11.5171L2.94242 7.05762L7.40185 2.59819C7.72467 2.27537 7.8047 1.78219 7.60053 1.37385C7.27677 0.726316 6.41377 0.58627 5.90185 1.09819L0.531676 6.46836C0.206239 6.7938 0.206239 7.32144 0.531675 7.64688L5.90185 13.0171C6.41377 13.529 7.27677 13.3889 7.60053 12.7414Z"
        fill="#5C5C5C"
      />
    </svg>
  );
}

export default ArrowLeft;
