import React, { memo, useMemo } from "react";
import PropTypes from "prop-types";
import { useTheme } from "react-jss";
import { useTranslation } from "react-i18next";
import CustomTypography from "../../../../CustomTypography";
import AnalysisCard from "../../AnalysisCard";
import TotalPublicationsCard from "../../TotalPublicationsCard";
import CustomPaper from "../../../../CustomPaper";
import PieChart from "../../../../Charts/PieChart";
import TimelineAnalysis from "../../../../TimelineAnalysis";
import WordCloud from "../../../../Charts/WordCloud";
import AnalysisCardSkeleton from "../../../../Skeleton/AnalysisCardSkeleton";
import useStyles from "./styles";

const Analysis = ({ statistics, loading }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();

  const articleForQualisFormated = useMemo(
    () =>
      statistics
        ? Object.keys(statistics.article_for_qualis).map((key) => ({
            value: statistics.article_for_qualis[key],
            name: key,
          }))
        : null,
    [statistics]
  );

  return (
    <div className={classes.container}>
      <div className={classes.title}>
        <CustomTypography variant="columnistName" color={theme.textPrimary}>
          {t("resultPage.analysis.title")}
        </CustomTypography>
      </div>

      <div className={classes.sectionWrapper}>
        <TimelineAnalysis />
      </div>

      <>
        <div className={classes.sectionWrapper}>
          <TotalPublicationsCard
            publicationsForYear={
              statistics ? statistics.publication_for_year : []
            }
            totalArticles={
              statistics
                ? parseFloat(statistics.total_article) +
                  parseFloat(statistics.total_book) +
                  parseFloat(statistics.total_book_chapter)
                : 0
            }
            loading={loading}
          />
        </div>
        <div className={classes.singleAnalysisWrapper}>
          <AnalysisCard
            hideTopSkeleton
            loading={loading}
            bottom={
              <div className={classes.bottomText}>
                {t("resultPage.analysis.journalArticles")}
              </div>
            }
          >
            <div className={classes.bodyText}>
              {statistics ? statistics.total_article : ""}
            </div>
          </AnalysisCard>
          <AnalysisCard
            hideTopSkeleton
            loading={loading}
            bottom={
              <div className={classes.bottomText}>
                {t("resultPage.analysis.bookChapter")}
              </div>
            }
          >
            <div className={classes.bodyText}>
              {statistics ? statistics.total_book_chapter : ""}
            </div>
          </AnalysisCard>
          <AnalysisCard
            hideTopSkeleton
            loading={loading}
            bottom={
              <div className={classes.bottomText}>
                {t("resultPage.analysis.books")}
              </div>
            }
          >
            <div className={classes.bodyText}>
              {statistics ? statistics.total_book : ""}
            </div>
          </AnalysisCard>
          <AnalysisCard
            hideTopSkeleton
            loading={loading}
            bottom={
              <div className={classes.bottomText}>
                {t("resultPage.analysis.patents")}
              </div>
            }
          >
            <div className={classes.bodyText}>-</div>
          </AnalysisCard>
        </div>
        <div className={classes.chartsSection}>
          <div className={classes.pieChartWrapper}>
            <CustomPaper>
              {loading ? (
                <AnalysisCardSkeleton
                  variant="piechart"
                  className={classes.pieChartWrapper}
                />
              ) : (
                <>
                  <CustomTypography
                    variant="description"
                    className={classes.wordCloudTitle}
                  >
                    {t("resultPage.analysis.classificationOfPublishedArticles")}
                  </CustomTypography>
                  <PieChart
                    valueName={t("resultPage.analysis.classification")}
                    data={articleForQualisFormated}
                    title=""
                    legend={t("resultPage.analysis.legend")}
                    emptyMessage={t("resultPage.analysis.emptyMessage.chart")}
                  />
                </>
              )}
            </CustomPaper>
          </div>
          <div className={classes.wordCloudWrapper}>
            <CustomPaper>
              {loading ? (
                <AnalysisCardSkeleton
                  variant="wordcloud"
                  className={classes.pieChartWrapper}
                />
              ) : (
                <>
                  <CustomTypography
                    variant="description"
                    className={classes.wordCloudTitle}
                  >
                    {t("resultPage.analysis.mainWorkTopics")}
                  </CustomTypography>

                  <WordCloud
                    words={statistics.cloud_words}
                    height={241}
                    seriesName={t("resultPage.analysis.mentions")}
                    emptyMessage={t("resultPage.analysis.emptyMessage.chart")}
                  />
                </>
              )}
            </CustomPaper>
          </div>
        </div>
      </>
    </div>
  );
};

Analysis.propTypes = {
  statistics: PropTypes.shape(),
  loading: PropTypes.bool,
};

Analysis.defaultProps = {
  statistics: undefined,
  loading: true,
};

export default memo(Analysis);
