import * as React from "react";

function SvgLink(props) {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.743 2.144a6.452 6.452 0 019.123 9.123l-.014.015-3.171 3.17a6.454 6.454 0 01-9.73-.696 1.167 1.167 0 111.87-1.398 4.118 4.118 0 006.21.445l3.163-3.164a4.119 4.119 0 00-5.824-5.822l-1.812 1.801a1.167 1.167 0 11-1.645-1.654l1.818-1.808.012-.012z"
        fill="#333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.627 8.064a6.452 6.452 0 017.421 2.18 1.167 1.167 0 01-1.868 1.398 4.118 4.118 0 00-6.21-.445h-.001l-3.163 3.164a4.118 4.118 0 005.823 5.823l1.8-1.8a1.167 1.167 0 011.65 1.65L11.27 21.84l-.014.014a6.452 6.452 0 01-9.123-9.122l.014-.015 3.171-3.17a6.452 6.452 0 012.308-1.484z"
        fill="#333"
      />
    </svg>
  );
}

export default SvgLink;
