import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTheme } from "react-jss";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress";
import Filters from "../Filters";
import AlertModal from "../AlertModal";
import ResultInfo from "../ResultInfo";
import useStyles from "./styles";
import resultTypes from "../../ResultTypes";
import CustomButton from "../../../../CustomButton";
import CategoryTabs from "../CategoryTabs";
import categoryTypes from "../CategoryTabs/CategoryTypes";
import { fetchMoreResults } from "../../../../../redux/result/resultActions";
import HeaderCastanha from "../../../Header";
import ResultList from "../../../../Cooperatives/ResultList";

const Search = ({ query, currentPage, initialCategory }) => {
  const { results, loading, loadingMore, loadingEnd, total } = useSelector(
    (state) => state.result
  );
  const { cooperatives } = useSelector((state) => state.cooperative);
  const theme = useTheme();
  const classes = useStyles(theme);
  const dispatch = useDispatch();
  const history = useHistory();
  const [page, setPage] = useState(currentPage);
  const [category, setCategory] = useState(
    initialCategory || categoryTypes.ALL
  );
  const [alertModalIsOpen, setAlertModalIsOpen] = useState(false);
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    setPage(1);
  }, [location]);

  useEffect(() => {
    console.log("results", results);
  }, [results]);

  useEffect(() => {
    console.log("cooperatives", cooperatives);
  }, [cooperatives]);

  const handleFetchResults = (
    targetQuerie,
    targetPage,
    targetCategory,
    dispatcher
  ) => {
    dispatch(
      dispatcher({
        q: "castanha",
        page: targetPage,
        category: targetCategory
      })
    );
  };

  const handleViewMore = () => {
    handleFetchResults("castanha", page + 1, category, fetchMoreResults);
    setPage((oldPage) => oldPage + 1);
  };

  const closeAlertModal = () => {
    setAlertModalIsOpen(false);
  };

  const openAlertModal = () => {
    setAlertModalIsOpen(true);
  };

  const handleCategoryTabsChange = (newCategory) => {
    if (category !== newCategory && newCategory === categoryTypes.COOPERATIVES) {
      setCategory(newCategory);
    } else if (category !== newCategory) {
      
        history.push(`/castanhas/resultados?q=castanha&category=${newCategory}`);
        setCategory(newCategory);
        setPage(1);
      
    }
  };

  const hideLabel =
    category !== categoryTypes.ALL && category !== categoryTypes.PUBLICATIONS;

  return (
    <div className={classes.container}>
      <HeaderCastanha />
      <div className={classes.resultContainer}>
        <Filters
          query={query}
          className={classes.filter}
          onAlertButtonClick={openAlertModal}
        />
        <AlertModal
          isOpen={alertModalIsOpen}
          onRequestClose={closeAlertModal}
          term={query}
        />
        <CategoryTabs
          onChange={handleCategoryTabsChange}
          initialCategory={initialCategory}
        />
        <div style={{ minHeight: 400, padding:10 }}>
          {loading && (
            <div className={classes.loading}>
              <CircularProgress />
            </div>
          )}
          {category === categoryTypes.COOPERATIVES ? (
            // Se a categoria for cooperatives, renderiza o ResultList
            <ResultList cooperatives={cooperatives} />
          ) : (
            // Caso contrário, renderiza os resultados padrão
            <div>
              {results &&
                results.map((result) => (
                  <ResultInfo
                    key={`${result.id}-${result.type}`}
                    result={result}
                    showBottomIcon={result.type === resultTypes.RESEARCHER}
                    hideLabel={hideLabel}
                  />
                ))}
            </div>
          )}
          {/* Exibe o botão de "ver mais" somente se não for a aba cooperatives */}
          {category !== categoryTypes.COOPERATIVES && (
            <div className={classes.buttonWrapper}>
              {!loading && !loadingMore && (
                <>
                  {!loadingEnd ? (
                    <CustomButton
                      variant="outlined"
                      color="primary"
                      outlineColor={theme.primary0}
                      className={classes.button}
                      onClick={handleViewMore}
                    >
                      {t("searchEngine.pagination.button")}
                    </CustomButton>
                  ) : total > 5 ? (
                    <div>{t("searchEngine.pagination.endReached")}</div>
                  ) : null}
                </>
              )}
              {loadingMore && <CircularProgress />}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Search.propTypes = {
  query: PropTypes.string,
  currentPage: PropTypes.number,
  initialCategory: PropTypes.string,
};
Search.defaultProps = {
  query: "",
  currentPage: 1,
  initialCategory: undefined,
};

export default Search;
