import { createUseStyles } from "react-jss";
import { breakpoints } from "../../constants/sizes";

const useStyles = createUseStyles((theme) => ({
  container: {
    width: 300,
    height: 200,
    padding: 15,
    [breakpoints.sm]: {
      height: 120,
    },
  },
  paper: {
    zIndex: 3,
    backgroundColor: theme.background0,
    border: `1px solid ${theme.stroke}`,
    borderRadius: 8,
    borderTopLeftRadius: 0,
  },
  closeButtonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
  title: {
    marginBottom: 10,
  },
  inputButton: {
    minHeight: 20,
    maxWidth: 100,
    border: "none",
    backgroundColor: "transparent",
    color: theme.primary0,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
    textTransform: "capitalize",
    "& svg": {
      marginLeft: 8,
      marginRight: 8,
    },
    "& path": {
      fill: theme.textPrimary,
    },
    "&:hover": {
      backgroundColor: theme.background3,
      borderRadius: 8,
      height: 34,
    },
    [breakpoints.sm]: {
      fontSize: 11,
      "& svg": {
        marginLeft: 4,
        marginRight: 4,
      },
      "& path": {
        fill: theme.textPrimary,
        transformOrigin: "center",
        transform: "scale(0.8)",
      },
    },
  },
  pickerWrapper: {
    display: "grid",
    gridTemplateColumns: "auto 85px",
    gap: 10,
    "& button": {
      marginRight: 5,
      marginBottom: 5,
    },
    "& button:last-child": {
      margin: 0,
    },
  },

  monthsWrapper: {
    "& .selected": {
      backgroundColor: theme.primary0,
      color: theme.background0,
      fontWeight: 700,
    },
    [breakpoints.sm]: {
      overflowX: "scroll",
      overflowY: "hidden",
      whiteSpace: "nowrap",
      height: 50,
    },
  },
  monthButton: {
    backgroundColor: theme.background0,
    border: `1px solid ${theme.stroke}`,
    borderRadius: 4,
    width: 38,
    height: 38,
    fontWeight: 400,
    textTransform: "capitalize",
    cursor: "pointer",
    color: theme.primary0,

    "&:hover": {
      backgroundColor: theme.primary0,
      fontWeight: 700,
      color: theme.background0,
    },
  },
  inputWrapper: {
    maxWidth: 340,
    padding: 15,
    "& .MuiButton-root": {
      width: "100%",
      minHeight: 0,
      height: 40,
      marginTop: 20,
      color: theme.background0,
    },
  },
  optionSelector: {
    "&.MuiInputBase-root": {
      width: "100%",
      borderRadius: 4,
      border: `1px solid ${theme.stroke}`,
      "&:hover": {
        border: `1px solid ${theme.textPrimary}`,
      },
    },
    "& .MuiInputBase-input": {
      paddingLeft: 10,
    },
  },
  inputCenter: {
    textAlign: "center",
    color: "red",
  },
}));

export default useStyles;
