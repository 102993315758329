import React from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";

function CustomMetaTags({ title, description, imageUrl }) {
  return (
    <MetaTags>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:site_name" content="Rede RHISA" />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:image:type" content="image/jpeg" />
      <meta property="og:image:width" content="200" />
      <meta property="og:image:height" content="200" />
    </MetaTags>
  );
}

CustomMetaTags.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  imageUrl: PropTypes.string,
};

CustomMetaTags.defaultProps = {
  title: "",
  description: "",
  imageUrl: "",
};

export default CustomMetaTags;
