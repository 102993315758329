import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import useStyles from "./styles";

export default function AnalysisSectionSkeleton() {
  const classes = useStyles();
  return (
    <div className={classes.content}>
      <div className={classes.avatarWrapper}>
        <Skeleton variant="circle" height={50} width={50}>
          <Avatar />
        </Skeleton>
      </div>

      <div className={classes.typographyWrapper}>
        <Skeleton width="100%">
          <Typography variant="h4">.</Typography>
        </Skeleton>
        <Skeleton width="100%">
          <Typography variant="body2">.</Typography>
        </Skeleton>
      </div>
    </div>
  );
}
