import React, { useEffect, useState } from "react";
import { useTheme } from "react-jss";
import { useParams, useLocation } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import queryString from "query-string";
import { fetchCooperatives } from "../../../redux/cooperative/cooperativeActions";
import ProductsNavigator from "../../../components/Cooperatives/ProductsNavigator";
import SectionHeader from "../../../components/Cooperatives/Result/SectionHeader";
import SearchBar from "../../../components/Cooperatives/SearchBar";
import ResultList from "../../../components/Cooperatives/ResultList";
import CustomPagination from "../../../components/CustomPagination";
import useStyles from "./styles";
import { mapFullStateNameToFederativeUnit } from "../../../util/states";

const itemsPerPage = 10;

const Result = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const dispatch = useDispatch();
  const { cooperativesCount, selectedProduct } = useSelector(
    (state) => state.cooperative
  );
  const { stateName } = useParams();
  const location = useLocation();
  const [queries, setQueries] = useState({ q: "", state: "", product: "" });
  const [page, setPage] = useState();
  const [isMounted, setIsMounted] = useState(false);

  const numberOfPages = Math.round(cooperativesCount / itemsPerPage);

  const dispatchFetchCooperatives = (newQuery, newPage) => {
    if (isMounted) {
      dispatch(fetchCooperatives({ ...newQuery, page: newPage }, itemsPerPage));
    }
  };

  useEffect(() => {
    const locationQueries = queryString.parse(location.search);

    setQueries((oldQueries) => ({
      ...oldQueries,
      q: locationQueries.q,
      state: mapFullStateNameToFederativeUnit(stateName),
      product: selectedProduct,
    }));
    setPage(1);
    setIsMounted(true);
  }, [location, selectedProduct]);

  useEffect(() => {
    dispatchFetchCooperatives(queries, page);
  }, [queries, page]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  return (
    <div className={classes.container}>
      <div className={classes.actionWrapper}>
        <SectionHeader state={stateName} />
        <SearchBar baseUrl={stateName} initialValue={queries?.q} />
        <ProductsNavigator />
      </div>
      <div className={classes.contentWrapper}>
        <ResultList />

        <div className={classes.paginatorWrapper}>
          <CustomPagination
            currentPage={page}
            count={numberOfPages}
            onChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default Result;
