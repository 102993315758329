import {
  ADD_CATEGORY,
  REMOVE_CATEGORY,
  TOOGLE_ENABLED,
  RESET_FILTERS,
} from "./resultFilterTypes";

export const addFilter = (filterToAdd) => ({
  type: ADD_CATEGORY,
  payload: filterToAdd,
});

export const removeFilter = (filterToRemove) => ({
  type: REMOVE_CATEGORY,
  payload: filterToRemove,
});

export const toogleEnabled = () => ({
  type: TOOGLE_ENABLED,
});

export const resetFilters = () => ({
  type: RESET_FILTERS,
});
