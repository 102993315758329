import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Button } from "@material-ui/core";
import useStyles from "./styles";

/**
 * This component renders a custom button styled for project.
 * @returns A custom button
 */
function CustomButton({
  children,
  className,
  backgroundColor,
  color,
  style,
  startIcon,
  pressed,
  outlineColor,
  ...props
}) {
  CustomButton.defaultProps = {
    children: undefined,
    className: "",
    backgroundColor: undefined,
    color: undefined,
    style: {},
    pressed,
    startIcon: undefined,
    outlineColor: undefined,
  };

  CustomButton.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    backgroundColor: PropTypes.string,
    color: PropTypes.string,
    style: PropTypes.shape(),
    startIcon: PropTypes.shape(),
    pressed: PropTypes.shape(),
    outlineColor: PropTypes.string,
  };

  const classes = useStyles({ backgroundColor, color, pressed, outlineColor });

  return (
    <Button
      disableElevation
      style={style}
      startIcon={startIcon}
      className={classNames(classes.root, className)}
      {...props}
    >
      {children}
    </Button>
  );
}

export default CustomButton;
