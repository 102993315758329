const brazilianStatesAcronyms = {
  acre: "AC",
  alagoas: "AL",
  amapa: "AP",
  amazonas: "AM",
  bahia: "BA",
  ceara: "CE",
  espiritosanto: "ES",
  goias: "GO",
  maranhao: "MA",
  matogrosso: "MT",
  matogrossodosul: "MS",
  minasgerais: "MG",
  para: "PA",
  paraiba: "PB",
  parana: "PR",
  pernambuco: "PE",
  piaui: "PI",
  riodejaneiro: "RJ",
  riograndedonorte: "RN",
  riograndedosul: "RS",
  rondonia: "RO",
  roraima: "RR",
  santacatarina: "SC",
  saopaulo: "SP",
  sergipe: "SE",
  tocantins: "TO",
  distritofederal: "DF",
};

function removeAccentsFromStateName(stateName) {
  return stateName.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export function mapFullStateNameToFederativeUnit(fullStateName) {
  const stateNameLowerCase = fullStateName.toLowerCase();
  const stateNameWithouSpaces = stateNameLowerCase.replace(/\s/g, "");

  const stateNameWithoutAccents = removeAccentsFromStateName(
    stateNameWithouSpaces
  );

  return brazilianStatesAcronyms[stateNameWithoutAccents];
}
