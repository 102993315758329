import * as React from "react";

function Plus(props) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.66548 6.66485L10.7148 6.66956C10.8924 6.66956 11.0626 6.59904 11.1882 6.4735C11.3137 6.34797 11.3842 6.1777 11.3842 6.00017C11.3842 5.82264 11.3137 5.65237 11.1882 5.52684C11.0626 5.4013 10.8924 5.33078 10.7149 5.33078L6.66548 5.33549L6.6702 1.28612C6.6702 1.10859 6.59967 0.938327 6.47414 0.812791C6.3486 0.687256 6.17834 0.61673 6.0008 0.61673C5.82327 0.61673 5.65301 0.687256 5.52747 0.812791C5.40194 0.938327 5.33141 1.10859 5.33141 1.28612L5.33612 5.33549L1.28676 5.33078C1.19875 5.33042 1.11154 5.34748 1.03017 5.381C0.948789 5.41451 0.874852 5.4638 0.81262 5.52603C0.750389 5.58826 0.701096 5.6622 0.667584 5.74358C0.634071 5.82496 0.617004 5.91216 0.617365 6.00017C0.617004 6.08818 0.634071 6.17539 0.667583 6.25676C0.701096 6.33814 0.75039 6.41208 0.812621 6.47431C0.874852 6.53654 0.948788 6.58583 1.03017 6.61935C1.11154 6.65286 1.19875 6.66993 1.28676 6.66956L5.33612 6.66485L5.33141 10.7142C5.33105 10.8022 5.34812 10.8894 5.38163 10.9708C5.41514 11.0522 5.46444 11.1261 5.52667 11.1884C5.5889 11.2506 5.66283 11.2999 5.74421 11.3334C5.82559 11.3669 5.9128 11.384 6.0008 11.3836C6.08881 11.384 6.17602 11.3669 6.2574 11.3334C6.33877 11.2999 6.41271 11.2506 6.47494 11.1884C6.53717 11.1261 6.58647 11.0522 6.61998 10.9708C6.65349 10.8894 6.67056 10.8022 6.6702 10.7142L6.66548 6.66485Z"
        fill="#0CA512"
      />
    </svg>
  );
}

export default Plus;
