import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useTheme } from "react-jss";
import IconButton from "@material-ui/core/IconButton";
import ArrowLeft from "../Icons/ArrowLeft";
import ArrowRight from "../Icons/ArrowRight";
import useStyles from "./styles";

const scrollFactor = 150;

const HorizontalScrollbar = ({ children }) => {
  const theme = useTheme();
  const scrl = useRef(null);
  const [scrollX, setscrollX] = useState(0);
  const [scrolEnd, setscrolEnd] = useState(false);

  const scrolled = scrollX !== 0;

  const classes = useStyles({ theme, scrolled });

  const scrollOver = scrl?.current
    ? Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    : 0;

  const slide = (shift) => {
    scrl.current.scrollLeft += shift;
    setscrollX(scrollX + shift);

    if (scrollOver) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  const scrollCheck = () => {
    setscrollX(scrl.current.scrollLeft);
    if (scrollOver) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  const scrollToRight = () => {
    slide(-scrollFactor);
  };
  const scrollToLeft = () => {
    slide(+scrollFactor);
  };

  return (
    <div className={classes.container}>
      <div className={classes.contentWrapper} onScroll={scrollCheck} ref={scrl}>
        {children}
      </div>
      <div
        className={classNames([
          classes.navigatorsWrapper,
          "navigators-wrapper",
        ])}
      >
        {scrolled && (
          <IconButton
            onClick={scrollToRight}
            className={classNames([classes.button, classes.buttonLeft])}
          >
            <ArrowLeft />
          </IconButton>
        )}
        {!scrolEnd && (
          <IconButton
            onClick={scrollToLeft}
            className={classNames([classes.button, classes.buttonRight])}
          >
            <ArrowRight />
          </IconButton>
        )}
      </div>
    </div>
  );
};

HorizontalScrollbar.propTypes = {
  children: PropTypes.node,
};

HorizontalScrollbar.defaultProps = {
  children: undefined,
};

export default HorizontalScrollbar;
