import React from "react";
import { useTheme } from "react-jss";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import CustomTypography from "../../../CustomTypography";
import useStyles from "./styles";
import { getStrapiMedia } from "../../../../services/hcms/media";

export default function ColumnistList({ columnist, onClick }) {
  ColumnistList.propTypes = {
    columnist: PropTypes.shape().isRequired,
    onClick: PropTypes.func,
  };

  ColumnistList.defaultProps = {
    onClick: null,
  };

  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <div key={columnist.id} className={classes.columnistBadge}>
      <div>
        <Link
          className={classes.link}
          to={`/agencia/colunistas/${columnist.id}`}
          onClick={onClick}
        >
          <img
            alt="columnist"
            src={getStrapiMedia(columnist.avatar)}
            className={classes.columnistAvatar}
          />
        </Link>
      </div>
      <div>
        <Link
          className={classes.link}
          to={`/agencia/colunistas/${columnist.id}`}
          onClick={onClick}
        >
          <div className={classes.description}>
            <CustomTypography
              className={classes.titleTypography}
              variant="columnistName"
              weight="bold"
            >
              {columnist.name}
            </CustomTypography>
            <CustomTypography
              className={classes.titleTypography}
              color={theme.textSecondary}
              variant="body"
              weigth="regular"
            >
              {columnist.career}
            </CustomTypography>
          </div>
        </Link>
      </div>
    </div>
  );
}
