import { createUseStyles } from "react-jss";
import { breakpoints } from "../../../../../../constants/sizes";

const useStyles = createUseStyles((theme) => ({
  contentWrapper: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    "& div": {
      marginRight: 5,
    },
  },
  title: {
    marginTop: 15,
    marginBottom: 15,
  },
  description: {
    display: "flex",
    alignItems: "center",
    color: theme.textSecondary,
    fontWeight: 400,
    fontSize: 14,
    "& div": {
      marginRight: 10,
    },
    [breakpoints.sm]: {
      fontSize: 12,
    },
  },
  institute: {
    color: theme.textPrimary,
    fontSize: 16,
    fontWeight: 700,
    [breakpoints.sm]: {
      fontSize: 14,
    },
  },
  elipse: {
    width: 6,
    height: 6,
    backgroundColor: theme.secondary0,
    borderRadius: "50%",
  },
}));

export default useStyles;
