import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useTheme } from "react-jss";
import Tab from "@material-ui/core/Tab";
import useStyles from "./styles";

const CustomTab = ({ className, children, ...props }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <Tab className={classNames(classes.tab, className)} {...props}>
      {children}
    </Tab>
  );
};

CustomTab.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

CustomTab.defaultProps = {
  className: undefined,
  children: undefined,
};

export default CustomTab;
