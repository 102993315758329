import React, { useState, useRef, useEffect, memo, useMemo } from "react";
import { useTheme } from "react-jss";
import { useHistory } from "react-router-dom";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Popper from "@material-ui/core/Popper";
import PropTypes from "prop-types";
import classNames from "classnames";
import debounce from "lodash.debounce";
import CustomTextField from "../../../CustomTextField";
import SvgSearch from "../../../Icons/Search";
import SvgClose from "../../../Icons/Close";
import SearchModal from "../SearchModal";
import useStyles from "../styles";

const SearchBar = ({ isSearching, handleClick, currentSearchText }) => {
  SearchBar.propTypes = {
    isSearching: PropTypes.bool.isRequired,
    handleClick: PropTypes.func.isRequired,
    currentSearchText: PropTypes.string,
  };

  SearchBar.defaultProps = {
    currentSearchText: "",
  };

  const theme = useTheme();
  const classes = useStyles({ theme });
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchText, setSearchText] = useState(currentSearchText);
  const textFieldRef = useRef();

  useEffect(() => {
    setSearchText(currentSearchText);
  }, [currentSearchText]);

  const handleChange = (event) => {
    if (event && event.target) {
      if (!anchorEl) setAnchorEl(event.target);
      setOpen(true);
      setSearchText(event.target.value);
    }
  };

  const debouncedChangeHandler = useMemo(() => debounce(handleChange, 300), []);

  const handleClickAway = () => {
    setOpen(false);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      if (searchText && searchText !== "") history.push(`/busca/${searchText}`);
      handleClickAway();
    }
  };

  const handleSearchClick = () => {
    if (open) handleClickAway();
    else
      setTimeout(() => {
        if (textFieldRef && textFieldRef.current) textFieldRef.current.focus();
      }, 100);
    handleClick();
  };

  const handleClickClose = () => {
    handleClickAway();
    handleClick();
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div
        className={isSearching ? classes.wrapperOnSearching : classes.wrapper}
      >
        <div
          role="button"
          onClick={handleSearchClick}
          onKeyDown={handleSearchClick}
          tabIndex={0}
          className={classes.searchButton}
        >
          <SvgSearch className={isSearching ? classes.svgSearch : undefined} />
        </div>
        <CustomTextField
          className={classes.searchTextField}
          placeholder="Pesquisar..."
          onChange={debouncedChangeHandler}
          onKeyPress={handleKeyPress}
          defaultValue={searchText}
          inputRef={textFieldRef}
          style={
            isSearching ? { visibility: "visible" } : { visibility: "hidden" }
          }
        />
        {open && searchText && searchText.length > 0 && (
          <Popper
            id="popper"
            className={classes.modal}
            open={open}
            anchorEl={anchorEl}
            placement="bottom-start"
          >
            <SearchModal searchText={searchText} onClick={handleClickAway} />
          </Popper>
        )}
        <div
          role="button"
          onClick={handleClickClose}
          onKeyDown={handleClickClose}
          tabIndex={0}
          className={
            isSearching
              ? classNames(classes.closeButton)
              : classNames(classes.closeButton, classes.hideOnSm)
          }
        >
          <SvgClose />
        </div>
      </div>
    </ClickAwayListener>
  );
};

export default memo(SearchBar);
