import { createUseStyles } from "react-jss";
// import { defaultContainer, breakpoints } from "../../../constants/sizes";

const useStyles = createUseStyles((/* theme */) => ({
  textField: {
    width: "100%",
  },
}));

export default useStyles;
