import { createUseStyles } from "react-jss";
import { breakpoints } from "../../../constants/sizes";

const useStyles = createUseStyles((theme) => ({
  header: {
    height: 48,
    marginTop: 64,
    marginBottom: 40,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [breakpoints.smd]: {
      marginTop: 20,
      marginBottom: 20,
    },
  },
  logo: {
    width: 104,
    [breakpoints.md]: {
      width: 104,
      height: 32,
      objectPosition: 0,
      objectFit: "fit",
    },
  },
  link: {
    textDecoration: "none",
    fontWeight: 700,
    fontSize: 16,
    color: theme.primary0,
  },
}));

export default useStyles;
