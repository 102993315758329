import * as React from "react";

function Insitute(props) {
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5912 2.33123V3.1968H11.5064V2.33123H9.5912ZM9.5912 4.35534H12.0973C12.4236 4.35534 12.6882 4.09599 12.6882 3.77607V1.75196C12.6882 1.43204 12.4236 1.17269 12.0973 1.17269H9.58652C9.5498 0.887348 9.30135 0.666664 9.00033 0.666664C8.674 0.666664 8.40945 0.926011 8.40945 1.24593V5.44217L4.60677 7.30618C4.40659 7.4043 4.28014 7.60488 4.28014 7.82429V9.26913H2.80636C2.48003 9.26913 2.21548 9.52848 2.21548 9.8484V15.3415H1.25786C0.931535 15.3415 0.666992 15.6008 0.666992 15.9207C0.666992 16.2407 0.931535 16.5 1.25786 16.5H16.7428C17.0691 16.5 17.3337 16.2407 17.3337 15.9207C17.3337 15.6008 17.0691 15.3415 16.7428 15.3415H15.7852V9.8484C15.7852 9.52848 15.5206 9.26913 15.1943 9.26913H13.7205V7.82429C13.7205 7.60488 13.5941 7.4043 13.3939 7.30618L9.5912 5.44217V4.35534ZM9.00033 6.44782L5.46189 8.1823V15.3415H7.37713V12.8846C7.37713 12.5646 7.64167 12.3053 7.968 12.3053H10.0327C10.359 12.3053 10.6235 12.5646 10.6235 12.8846V15.3415H12.5388V8.1823L9.00033 6.44782ZM13.7205 10.4277V15.3415H14.6034V10.4277H13.7205ZM8.55887 15.3415H9.44178V13.4638H8.55887V15.3415ZM4.28014 15.3415V10.4277H3.39723V15.3415H4.28014ZM9.00033 9.41561C8.88324 9.41561 8.77096 9.46121 8.68817 9.54237C8.60538 9.62354 8.55887 9.73362 8.55887 9.8484C8.55887 9.96318 8.60538 10.0733 8.68817 10.1544C8.77096 10.2356 8.88324 10.2812 9.00033 10.2812C9.11741 10.2812 9.22969 10.2356 9.31248 10.1544C9.39527 10.0733 9.44178 9.96318 9.44178 9.8484C9.44178 9.73362 9.39527 9.62354 9.31248 9.54237C9.22969 9.46121 9.11741 9.41561 9.00033 9.41561ZM7.85255 8.72316C8.15696 8.42473 8.56983 8.25708 9.00033 8.25708C9.43083 8.25708 9.84369 8.42473 10.1481 8.72316C10.4525 9.0216 10.6235 9.42635 10.6235 9.8484C10.6235 10.2704 10.4525 10.6752 10.1481 10.9736C9.84369 11.2721 9.43083 11.4397 9.00033 11.4397C8.56983 11.4397 8.15696 11.2721 7.85255 10.9736C7.54814 10.6752 7.37713 10.2704 7.37713 9.8484C7.37713 9.42635 7.54814 9.0216 7.85255 8.72316Z"
        fill="white"
      />
    </svg>
  );
}

export default Insitute;
