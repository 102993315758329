import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTheme } from "react-jss";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress";
import Filters from "./Filters";
// import AlertPaper from "./AlertPaper";
import AlertModal from "./AlertModal";
import ResultInfo from "./ResultInfo";
import useStyles from "./styles";
import resultTypes from "../ResultTypes";
import CustomButton from "../../CustomButton";
import CategoryTabs from "./CategoryTabs";
import categoryTypes from "./CategoryTabs/CategoryTypes";
import { fetchMoreResults } from "../../../redux/result/resultActions";

const SearchContainer = ({ query, currentPage, initialCategory }) => {
  const { results, loading, loadingMore, loadingEnd, total } = useSelector(
    (state) => state.result
  );
  const theme = useTheme();
  const classes = useStyles(theme);
  const dispatch = useDispatch();
  const history = useHistory();
  const [page, setPage] = useState(currentPage);
  const [category, setCategory] = useState(
    initialCategory || categoryTypes.ALL
  );
  const [alertModalIsOpen, setAlertModalIsOpen] = useState(false);
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    setPage(1);
  }, [location]);

  const handleFetchResults = (
    targetQuerie,
    targetPage,
    targetCategory,
    dispatcher
  ) => {
    dispatch(
      dispatcher({
        q: targetQuerie,
        page: targetPage,
        category: targetCategory,
      })
    );
  };

  const handleViewMore = () => {
    handleFetchResults(query, page + 1, category, fetchMoreResults);

    setPage((oldPage) => oldPage + 1);
  };

  const closeAlertModal = () => {
    setAlertModalIsOpen(false);
  };

  const openAlertModal = () => {
    setAlertModalIsOpen(true);
  };

  const handleCategoryTabsChange = (newCategory) => {
    if (category !== newCategory) {
      history.push(`/buscador?q=${query}&category=${newCategory}`);
      setCategory(newCategory);
      setPage(1);
    }
  };

  const hideLabel =
    category !== categoryTypes.ALL && category !== categoryTypes.PUBLICATIONS;

  return (
    <div className={classes.content}>
      <Filters
        query={query}
        className={classes.filter}
        onAlertButtonClick={openAlertModal}
      />
      {/* <AlertPaper
        className={classes.alertPaper}
        onAlertButtonClick={openAlertModal}
      /> */}
      <AlertModal
        isOpen={alertModalIsOpen}
        onRequestClose={closeAlertModal}
        term={query}
      />
      <CategoryTabs
        onChange={handleCategoryTabsChange}
        initialCategory={initialCategory}
      />
      <div style={{ minHeight: 400 }}>
        {loading && (
          <div className={classes.loading}>
            <CircularProgress />
          </div>
        )}
        <div>
          {results &&
            results.map((result) => (
              <ResultInfo
                key={`${result.id}-${result.type}`}
                result={result}
                showBottomIcon={result.type === resultTypes.RESEARCHER}
                hideLabel={hideLabel}
              />
            ))}
        </div>
        <div className={classes.buttonWrapper}>
          {!loading && !loadingMore && (
            <>
              {!loadingEnd ? (
                <CustomButton
                  variant="outlined"
                  color="primary"
                  outlineColor={theme.primary0}
                  className={classes.button}
                  onClick={handleViewMore}
                >
                  {t("searchEngine.pagination.button")}
                </CustomButton>
              ) : total > 5 ? (
                <div>{t("searchEngine.pagination.endReached")}</div>
              ) : null}
            </>
          )}
          {loadingMore && <CircularProgress />}
        </div>
      </div>
    </div>
  );
};

SearchContainer.propTypes = {
  query: PropTypes.string,
  currentPage: PropTypes.number,
  initialCategory: PropTypes.string,
};
SearchContainer.defaultProps = {
  query: "",
  currentPage: 1,
  initialCategory: undefined,
};

export default SearchContainer;
