import React from "react";
import PropTypes from "prop-types";
import useStyles from "./styles";

function RegisterContainer({ children }) {
  const classes = useStyles();

  return <div className={classes.container}>{children}</div>;
}

RegisterContainer.defaultProps = {
  children: undefined,
};

RegisterContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default RegisterContainer;
