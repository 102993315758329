/* eslint-disable no-unused-vars */
import { createUseStyles } from "react-jss";
import { breakpoints, defaultContainer } from "../../constants/sizes";

const useStyles = createUseStyles((theme) => ({
  sectionLine: {
    height: 2,
    backgroundColor: theme.background1,
    width: "100%",
    maxWidth:
      defaultContainer.Width + defaultContainer.paddingHorizontal * 2 - 20,
    [breakpoints.md]: {
      width: "95%",
      maxWidth: defaultContainer.Width + defaultContainer.paddingHorizontal * 2,
    },
    marginBottom: 40,
    marginTop: 20,
  },
}));

export default useStyles;
