import { createUseStyles } from "react-jss";
/* import { breakpoints } from "../../../../constants/sizes"; */

const useStyles = createUseStyles((/* theme */) => ({
  link: {
    textDecoration: "none",
    "&:hover": {},
  },
  viewMoreWrapper: {
    display: "flex",
    flexDirection: "horizontal",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default useStyles;
