import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useTheme } from "react-jss";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Header from "../../Header";
import Footer from "../../Footer";
import ViewOnMapButton from "../../Mapbox/ViewOnMapButton";
import Map from "../Map";
import { breakpoints } from "../../../constants/sizes";
import { HeaderVariants } from "../../Header/variants";
import useStyles from "./styles";

const MapView = ({
  children,
  results,
  selectedResult,
  handleCloseResult,
  handleClickResult,
  filter,
  disableClustering,
  drawEdges,
  showCategoryFilter,
  showDateRangeFilter,
  loading,
  hideCategoryFilter,
}) => {
  const theme = useTheme();
  const [showMapOnMd, setShowMapOnMd] = useState(false);
  const classes = useStyles({ theme, showMapOnMd });
  const isMd = useMediaQuery(breakpoints.md);
  const containerRef = useRef(null);

  useEffect(() => {
    if (!isMd) {
      if (showMapOnMd) {
        setShowMapOnMd(false);
      }
    }
  }, [isMd]);

  const handleShowMap = () => {
    setShowMapOnMd(true);
  };

  const handleCloseMap = () => {
    if (handleCloseResult) handleCloseResult();
    setShowMapOnMd(false);
  };

  return (
    <>
      <div className={classes.headerWrapper}>
        <Header
          currentSearchText={filter ? filter.q : ""}
          variant={HeaderVariants.SEARCH_ENGINE}
          onBackClick={handleCloseMap}
          showBackButton={showMapOnMd}
        />
      </div>

      <div ref={containerRef} className={classes.searchContainerWrapper}>
        <div className={classes.searchContainer}>{children}</div>
        {isMd && (
          <div className={classes.footerWrapper}>
            <Footer />
          </div>
        )}
      </div>

      <div className={classes.mapWrapperWide}>
        <div className={classes.mapGrid}>
          <div className={classes.leftSection} />
          <div className={classes.mapGridItem}>
            <Map
              results={results}
              selectedResult={selectedResult}
              onClosePopup={handleCloseResult}
              onClickMarker={handleClickResult}
              disableClustering={disableClustering}
              drawEdges={drawEdges}
              showCategoryFilter={showCategoryFilter}
              showDateRangeFilter={showDateRangeFilter}
              loading={loading}
              hideCategoryFilter={hideCategoryFilter}
            />
          </div>
        </div>
      </div>

      <div className={classes.bottomButtonWrapper}>
        <ViewOnMapButton onClick={handleShowMap} />
      </div>

      {!isMd && <Footer />}
    </>
  );
};

MapView.propTypes = {
  children: PropTypes.node,
  results: PropTypes.arrayOf(PropTypes.shape()),
  selectedResult: PropTypes.shape(),
  handleCloseResult: PropTypes.func,
  handleClickResult: PropTypes.func,
  filter: PropTypes.shape(),
  disableClustering: PropTypes.bool,
  drawEdges: PropTypes.bool,
  showCategoryFilter: PropTypes.bool,
  showDateRangeFilter: PropTypes.bool,
  loading: PropTypes.bool,
  hideCategoryFilter: PropTypes.bool,
};

MapView.defaultProps = {
  children: undefined,
  results: [],
  selectedResult: undefined,
  handleCloseResult: undefined,
  handleClickResult: undefined,
  filter: undefined,
  disableClustering: false,
  drawEdges: false,
  showCategoryFilter: false,
  showDateRangeFilter: false,
  loading: false,
  hideCategoryFilter: false,
};

export default MapView;
