import { createUseStyles } from "react-jss";
// import { breakpoints } from "../../../constants/sizes";

const useStyles = createUseStyles((theme) => ({
  errorWrapper: {
    paddingTop: 10,
    color: theme.alert,
  },
}));

export default useStyles;
