import { createUseStyles } from "react-jss";
import { breakpoints } from "../../constants/sizes";

const useStyles = createUseStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "4px",
      color: theme.colorText,
    },
    "& .MuiOutlinedInput-input": {
      padding: "15px 12px 12px 12px",
      [breakpoints.sm]: {
        fontSize: 14,
      },
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.grey,
      borderWidth: 1,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.stroke,
      borderWidth: 1,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.primary0,
      borderWidth: 1,
    },
    "& .MuiFormHelperText-contained": {
      marginLeft: 0,
    },
    "& .MuiOutlinedInput-multiline": {
      padding: 0,
    },
  },
  alertMessage: {
    display: "block",
    color: theme.alert,
    marginBottom: "10px",
  },
}));

export default useStyles;
