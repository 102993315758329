import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import resultTypes, { typeColor } from "../ResultTypes";
import useStyles from "./styles";

const ResultLabel = ({ type }) => {
  const theme = useTheme();
  const color = typeColor(type);
  const classes = useStyles({ theme, color });
  const { t } = useTranslation();

  const Label = () => {
    switch (type) {
      case resultTypes.RESEARCHER:
        return `${t("searchEngine.result.types.researcher")} `;
      case resultTypes.ARTICLE_JOURNALS:
        return `${t("searchEngine.result.types.articleJournals")} `;
      case resultTypes.BOOK:
        return `${t("searchEngine.result.types.book")}`;
      case resultTypes.BOOK_CHAPTER:
        return `${t("searchEngine.result.types.bookChapter")}`;
      case resultTypes.INSTITUTE:
        return `${t("searchEngine.result.types.institute")}`;
      default:
        return null;
    }
  };

  return (
    <span className={classes.contentWrapper}>
      <Label />
    </span>
  );
};

ResultLabel.propTypes = {
  type: PropTypes.string.isRequired,
};

ResultLabel.defaultProps = {};

export default ResultLabel;
