import { createUseStyles } from "react-jss";

const useStyles = createUseStyles(() => ({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingTop: 20,
    paddingBottom: 20,
  },
}));

export default useStyles;
