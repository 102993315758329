import { createUseStyles } from "react-jss";
import { breakpoints } from "../../../../constants/sizes";

const useStyles = createUseStyles((theme) => ({
  container: {
    display: "grid",
    gridTemplateColumns: "64px auto auto",
    gridColumnGap: 20,
    [breakpoints.md]: {
      gridTemplateColumns: "40px auto auto",
    },
  },
  centralizeHorizontal: {
    display: "flex",
    alignItems: "center",
  },
  buttonsWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  buttonsGrid: {
    // Hide edit and notify button
    display: "none",
    gridTemplateColumns: "auto auto",
    gridColumnGap: 5,
    minWidth: 200,
    [breakpoints.md]: {
      minWidth: 0,
    },
  },
  content: {},
  button: {
    width: "95%",
    "& path": {
      fill: theme.background0,
    },
    [breakpoints.sm]: {
      "&.MuiButton-root": {
        minWidth: 0,
        minHeight: 0,
        width: 40,
        height: 40,
      },
      "& .MuiButton-label": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      "& .MuiButton-startIcon": {
        margin: 0,
        display: "flex",
      },
    },
  },
  buttonText: {
    [breakpoints.sm]: {
      display: "none",
    },
  },
  editIcon: {
    width: "100%",
    height: "100%",
    display: "none",
    backgroundColor: "blue",
    [breakpoints.sm]: {
      display: "block",
    },
  },
  image: {
    width: 64,
    height: 64,
    borderRadius: "100%",
    objectFit: "cover",
    objectPosition: "center center",
    [breakpoints.md]: {
      width: 40,
      height: 40,
    },
  },
  imageSkeleton: {
    width: 64,
    height: 64,
    [breakpoints.md]: {
      width: 40,
      height: 40,
    },
  },
  divider: {
    marginTop: 16,
  },
}));

export default useStyles;
