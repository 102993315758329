import { createUseStyles } from "react-jss";
import { breakpoints } from "../../constants/sizes";

const useStyles = createUseStyles((theme) => ({
  ul: {
    listStyle: "none",
    padding: 0,
    margin: 0,
    display: "flex",
    flexWrap: "wrap",
  },
  button: {
    backgroundColor: theme.background0,
    border: "none",
    height: 48,
    width: 48,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    color: theme.primary0,
    [breakpoints.sm]: {
      width: 30,
      height: 30,
    },
    "& path": {
      fill: theme.primary0,
    },

    "&:hover": {
      backgroundColor: theme.background1,
    },
  },
  stepButton: {
    backgroundColor: theme.background0,
    border: "none",
    height: 48,
    width: 96,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    color: theme.primary0,

    [breakpoints.sm]: {
      width: 86,
      height: 30,
    },

    "& path": {
      fill: theme.primary0,
    },

    "&:hover": {
      backgroundColor: theme.background1,
    },

    "&:disabled": {
      color: theme.secondary0,
      "& path": {
        fill: theme.secondary0,
      },
      "&:hover": {
        backgroundColor: theme.background0,
      },
    },
  },
  selectable: {
    color: theme.textPrimary,
    fontWeight: 700,
    fontSize: 12,
  },
  selected: {
    height: 2,
    width: 24,
    backgroundColor: theme.primary0,
    position: "absolute",
    marginTop: 46,
    [breakpoints.sm]: {
      marginTop: 5,
      marginLeft: -4,
      width: 14,
    },
  },
  more: {
    backgroundColor: theme.background0,
    border: "none",
    height: 48,
    width: 48,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [breakpoints.sm]: {
      height: 30,
      width: 30,
    },
  },
  previous: {
    display: "flex",
    alignItems: "center",
    "& svg": {
      transform: "rotate(180deg)",
      marginRight: 9,
    },
  },
  next: {
    display: "flex",
    alignItems: "center",
    "& svg": {
      marginLeft: 9,
    },
  },
}));

export default useStyles;
