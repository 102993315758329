import React from "react";
import { useTheme } from "react-jss";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import ReactSoundCloud from "react-soundcloud-embedded";
import SpotifyPlayer from "react-spotify-player";
import { useTranslation } from "react-i18next";
import CustomTypography from "../../CustomTypography";
import Markdown from "../../Markdown";
import useStyles from "./styles";
import DeezerPlayer from "../../Players/DeezerPlayer";

const size = {
  width: "100%",
  height: 152,
};

/**
 * This component represents a PodcastViewer.
 */
export default function PodcastViewer({ data }) {
  PodcastViewer.propTypes = {
    data: PropTypes.shape().isRequired,
  };
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { t } = useTranslation();

  if (!data) {
    return null;
  }

  function podcastViewer(link) {
    if (link.includes("soundcloud"))
      return <ReactSoundCloud url={link} height={180} visual={false} />;
    if (link.includes("spotify"))
      return <SpotifyPlayer uri={link} compact size={size} theme="white" />;
    if (link.includes("deezer")) return <DeezerPlayer url={link} />;
    return null;
  }

  return (
    <div className={classes.wrapper}>
      {podcastViewer(data.link)}
      <Link
        style={{ margin: "16px 0px 16px 0px" }}
        className={classes.link}
        to={{ pathname: data.link }}
        target="_blank"
      >
        <CustomTypography style={{ fontSize: 18 }} variant="h3">
          {data.title}
        </CustomTypography>
      </Link>

      <CustomTypography
        color={theme.textSecondary}
        style={{ marginBottom: 16, fontSize: 16 }}
        className={classes.bodyPreview}
        variant="paragraph"
      >
        <Markdown>{data.body}</Markdown>
      </CustomTypography>

      <div style={{ marginTop: 16 }}>
        <CustomTypography
          variant="body"
          weight="regular"
          style={{ fontSize: 14 }}
          color={theme.textSecondary}
        >
          {t("agency.article.publishedAtDate", {
            date: new Date(data.date),
          })}
        </CustomTypography>
      </div>
    </div>
  );
}
