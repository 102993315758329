/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo } from "react";
import PropTypes from "prop-types";
import { useTheme } from "react-jss";
import { useTranslation } from "react-i18next";
import CustomTypography from "../../../CustomTypography";
import useStyles from "./styles";

const ProjectDescription = ({ yearStart, yearEnd, ...props }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();

  return (
    <div {...props}>
      <div className={classes.contentWrapper}>
        <CustomTypography variant="tag" color={theme.textSecondary}>
          {`${yearStart} - ${
            yearEnd || t("resultPage.researchProjects.current")
          }`}
        </CustomTypography>
      </div>
    </div>
  );
};

ProjectDescription.propTypes = {
  yearStart: PropTypes.string,
  yearEnd: PropTypes.string,
};

ProjectDescription.defaultProps = {
  yearStart: undefined,
  yearEnd: undefined,
};

export default memo(ProjectDescription);
