import { createUseStyles } from "react-jss";
import { breakpoints } from "../../constants/sizes";

const useStyles = createUseStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    minHeight: 0,
    "& path": {
      fill: theme.textPrimary,
    },
    [breakpoints.sm]: {
      backgroundColor: "transparent",
      filter: "none",
    },
    width: "100%",
  },
  divider: {
    fontWeight: "Bold",
    marginTop: 5,
  },
  dateIcon: {
    display: "flex",
    alignItems: "center",
    marginRight: 5,
  },
  separator: {
    width: 8,
    height: 2,
    backgroundColor: theme.stroke,
  },
  paper: {
    zIndex: 4,
    backgroundColor: theme.background0,
    border: `1px solid ${theme.stroke}`,
    borderRadius: 8,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderBottom: "none",
    width: "100%",
    height: 350,
    position: "fixed !important",
    transform: "none !important",
    top: "calc(100% - 350px) !important",
    padding: 15,
  },
  contentWrapper: {
    height: "100%",
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .title": {
      fontWeight: "bold",
      color: theme.textPrimary,
      fontSize: 16,
    },
  },
  filters: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    justifyContent: "space-between",
    paddingBottom: 30,
    paddingTop: 30,
    "& .Date-Input-root": {
      width: "100%",
      height: 80,
      padding: 0,
    },
  },
  buttonWrapper: {},
  applyButtonWrapper: {
    "& .MuiButtonBase-root": {
      color: theme.background0,
    },
  },
  applyButton: {
    width: "100%",
  },
}));

export default useStyles;
