import { createUseStyles } from "react-jss";

const useStyles = createUseStyles(() => ({
  generalWrapper: {
    marginTop: 25,
    height: "50vh",
    display: "flex",
    justifyContent: "center",
    gap: 20,
    flexDirection: "column",
    alignContent: "center",
    alignItems: "center",
    "& h1": {
      fontSize: "2.5rem",
      color: "#1D1D1D",
      // Ajusta o tamanho do h1 em telas menores
      "@media (max-width: 768px)": {
        fontSize: "2rem",
      },
    },
    // Ajusta o container principal em telas menores
    "@media (max-width: 768px)": {
      height: "auto",
      padding: "0 20px",
     
    },
  },
  title: {
    fontSize: "2rem",
    color: "#1D1D1D",
    marginBottom: 20,
    "@media (max-width: 768px)": {
      fontSize: "1.5rem",
    },
  },
  cardsWrapper: {
    display: "flex",
    width: "80%",
    justifyContent: "space-between",
    // Em telas menores, as cartas são empilhadas verticalmente
    "@media (max-width: 768px)": {
      flexDirection: "column",
      alignItems: "center",
      gap: 20,
    },
  },
}));

export default useStyles;
