/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useTheme } from "react-jss";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import ModalImage from "react-modal-image";
import ReactSoundCloud from "react-soundcloud-embedded";
import YouTube from "react-youtube";
import removeMd from "remove-markdown";
import CustomTypography from "../CustomTypography";
import {
  getMediaUrl,
  getYoutubeId,
  isPodcastUrl,
  isVideoUrl,
} from "../../services/hcms/media";
import useStyles from "./styles";
import SectionLine from "../SectionLine";

const renderers = (classes, theme) => ({
  hr: () => <SectionLine />,
  h4: ({ children }) => (
    <CustomTypography
      color={theme.textPrimary}
      style={{ fontStyle: "italic" }}
      variant="noticeBody"
    >
      {children}
    </CustomTypography>
  ),
  img: ({ alt, src }) => (
    <div className={classes.mediaWrapper}>
      <ModalImage
        className={classes.modalImage}
        small={getMediaUrl(src)}
        large={getMediaUrl(src)}
        alt={alt}
        hideZoom
      />
    </div>
  ),
  p: ({ children }) => <div style={{ marginBottom: 20 }}>{children}</div>,
  pre: ({ children }) => <div style={{ marginBottom: 20 }}>{children}</div>,
  code: ({ children }) => <>{children}</>,
  a: ({ href, children }) => (
    <>
      {isPodcastUrl(href) ? (
        <div className={classes.mediaWrapper}>
          <div className={classes.podcastWrapper}>
            <ReactSoundCloud url={href} height={180} />
          </div>
        </div>
      ) : isVideoUrl(href) ? (
        <div className={classes.mediaWrapper}>
          <YouTube
            className={classes.videoThumb}
            videoId={getYoutubeId(href)}
          />
        </div>
      ) : (
        <a href={href} target="_blank" rel="noreferrer">
          {children}
        </a>
      )}{" "}
    </>
  ),
});

const Markdown = ({ children, isBody, hasCustomRender }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const [hasThinLine, setHasThinLine] = useState(false);

  if (!children) {
    return null;
  }

  function checkIfIsThinLine() {
    if (typeof children !== "string") return;
    const regExp = /####[a-zA-Z]*/;
    const matchs = children.match(regExp);
    setHasThinLine(matchs && matchs.length > 0);
  }

  useEffect(checkIfIsThinLine, [children]);

  function renderOnlyH4(element, index) {
    if (element.tagName === "h4") {
      if (index === 0) return true;
    }
    return !hasThinLine;
  }

  return (
    <>
      {!hasCustomRender ? (
        removeMd(children)
      ) : (
        <CustomTypography
          color={theme.textPrimary}
          style={{ marginBottom: 40 }}
          variant="noticeBody"
        >
          <ReactMarkdown
            components={renderers(classes, theme)}
            allowElement={(element, index) =>
              isBody ? true : renderOnlyH4(element, index)
            }
          >
            {children}
          </ReactMarkdown>
        </CustomTypography>
      )}
    </>
  );
};

Markdown.propTypes = {
  children: PropTypes.string,
  isBody: PropTypes.bool,
  hasCustomRender: PropTypes.bool,
};

Markdown.defaultProps = {
  isBody: true,
  children: "",
  hasCustomRender: false,
};

export default Markdown;
