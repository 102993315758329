import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import useStyles from "./styles";

const NextLink = ({ to, className, style, children, ...props }) => {
  const classes = useStyles();
  return (
    <a
      href={to}
      style={{ margin: "16px 0px 16px 0px", ...style }}
      className={classNames([classes.link, className])}
      {...props}
    >
      {children}
    </a>
  );
};

NextLink.propTypes = {
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.shape(),
  children: PropTypes.node,
};

NextLink.defaultProps = {
  className: "",
  style: {},
  children: undefined,
};

export default NextLink;
