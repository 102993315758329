import * as React from "react";

function SvgArrowUp(props) {
  return (
    <svg
      width={6}
      height={12}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.49607 0.700009C3.26513 0.300009 2.68778 0.300009 2.45684 0.700009L0.147442 4.70001C-0.083498 5.10001 0.205177 5.60001 0.667057 5.60001H2.20979V11C2.20979 11.4234 2.55304 11.7667 2.97646 11.7667C3.39988 11.7667 3.74312 11.4234 3.74312 11V5.60001H5.28586C5.74774 5.60001 6.03642 5.10001 5.80547 4.70001L3.49607 0.700009Z"
        fill="#0CA512"
      />
    </svg>
  );
}

export default SvgArrowUp;
