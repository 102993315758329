import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => ({
  tagButton: {
    "& .MuiButton-root": {
      padding: "0px 20px 0px 20px",
      height: 35,
      borderRadius: 24,
      color: (props) => (props.selected ? theme.background0 : theme.primary0),
      backgroundColor: (props) =>
        props.selected ? theme.primary0 : theme.background0,
      fontWeight: "bold",
      fontSize: 16,
      border: `1px solid ${theme.primary0}`,
      whiteSpace: "nowrap",
      "&:hover": {
        color: theme.background0,
      },
    },
  },
}));

export default useStyles;
