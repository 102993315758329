import { createUseStyles } from "react-jss";
import { breakpoints } from "../../constants/sizes";

const useStyles = createUseStyles((theme) => ({
  link: {
    textDecoration: "none",
    position: "relative",
    fontSize: 16,
    fontWeight: 400,
    color: theme.textPrimary,
    [breakpoints.sm]: {
      fontSize: 13,
    },
  },
  outlined: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
    borderRadius: 30,
    border: `1px solid ${theme.stroke}`,
  },
}));

export default useStyles;
