import * as React from "react";

function SvgTarget(props) {
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 2.833a.5.5 0 001 0v-.478A5.676 5.676 0 0113.645 7.5h-.478a.5.5 0 000 1h.478A5.676 5.676 0 018.5 13.645v-.478a.5.5 0 00-1 0v.478A5.676 5.676 0 012.355 8.5h.478a.5.5 0 000-1h-.478A5.676 5.676 0 017.5 2.355v.478zm0 11.815A6.677 6.677 0 011.352 8.5H.5a.5.5 0 010-1h.852A6.677 6.677 0 017.5 1.352V.5a.5.5 0 011 0v.852A6.677 6.677 0 0114.648 7.5h.852a.5.5 0 010 1h-.852A6.677 6.677 0 018.5 14.648v.852a.5.5 0 01-1 0v-.852zM6 8c0 1.103.897 2 2 2s2-.897 2-2-.897-2-2-2-2 .897-2 2zm1 0a1.001 1.001 0 012 0 1.001 1.001 0 01-2 0z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgTarget;
