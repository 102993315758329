import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((/* theme  */) => ({
  sectionGrid: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "50px auto auto",
    columnGap: 10,
    paddingBottom: 10,
  },
  statsColumn: {
    display: "flex",
    justifyContent: "flex-end",
  },
  divider: {
    marginBottom: 16,
  },
  imageWrapper: {
    display: "flex",
    alignItems: "center",
  },
  titleWrapper: {
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginBottom: 5,
  },
}));

export default useStyles;
