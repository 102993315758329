import React from "react";
import { useTheme } from "react-jss";
import IconButton from "@material-ui/core/IconButton";
import { useDispatch } from "react-redux";
import { setShowMap } from "../../../redux/cooperative/cooperativeActions";
import HeaderBase from "../../V2/HeaderBase";
import Search from "../../Icons/Search";
import useStyles from "./styles";

const Header = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const dispatch = useDispatch();

  const handleHideMap = () => {
    dispatch(setShowMap(false));
  };

  return (
    <div className={classes.container}>
      <HeaderBase
        searchbar={
          <IconButton className={classes.searchButton} onClick={handleHideMap}>
            <Search />
          </IconButton>
        }
      />
    </div>
  );
};

export default Header;
