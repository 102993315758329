import { createUseStyles } from "react-jss";

const useStyles = createUseStyles(() => ({
  contentWrapper: {
    height: "100%",
    paddingTop: 20,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  flexSpaceBetween: {
    display: "flex",
    justifyContent: "space-between",
    columnGap: 5,
    alignItems: "center",
  },
}));

export default useStyles;
