import { createUseStyles } from "react-jss";
/* import { breakpoints } from "../../../constants/sizes"; */

const useStyles = createUseStyles((/* theme */) => ({
  link: {
    display: "flex",
    textDecoration: "none",
    "&:hover": {},
  },
  breadcumb: {
    marginBottom: 22,
  },
}));

export default useStyles;
