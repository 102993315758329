import * as React from "react";

function Lamp(props) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.5 25.8725V29C26.5 32.3137 23.8137 35 20.5 35C17.1863 35 14.5 32.3137 14.5 29V25.8725C14.5 25.5796 14.3962 25.26 14.1512 24.8393C13.9937 24.569 13.7878 24.272 13.477 23.8609L13.3725 23.7245L13.3724 23.7244C13.1649 23.4539 12.7117 22.8634 12.5445 22.6374C10.8497 20.3483 10 18.3048 10 15.5C10 9.70101 14.701 5 20.5 5C26.299 5 31 9.70101 31 15.5C31 18.3661 30.1387 20.4872 28.4584 22.772C28.2803 23.0141 27.831 23.598 27.5648 23.9439C27.4607 24.0792 27.3846 24.1781 27.3638 24.2058C27.0243 24.6575 26.8134 24.9778 26.6762 25.2559C26.5521 25.5075 26.5 25.702 26.5 25.8725ZM23.5655 25.159C23.642 24.7441 23.7843 24.337 23.9857 23.9286C24.2225 23.4487 24.5236 22.9914 24.9657 22.4032C24.9961 22.3629 25.0937 22.2359 25.2199 22.0718L25.2202 22.0715L25.2205 22.0711C25.4906 21.7199 25.891 21.1993 26.0416 20.9945C27.3745 19.1822 28 17.6419 28 15.5C28 11.3579 24.6421 8 20.5 8C16.3578 8 13 11.3579 13 15.5C13 17.5678 13.61 19.0349 14.9555 20.8523C15.1 21.0474 15.5079 21.5793 15.7282 21.8665L15.7284 21.8668C15.8032 21.9643 15.8563 22.0336 15.8702 22.0519C16.2471 22.5505 16.5137 22.9349 16.7435 23.3294C17.1013 23.9437 17.3389 24.5425 17.4418 25.1634C18.3765 25.7244 19.387 26 20.5 26C21.6159 26 22.6287 25.723 23.5655 25.159ZM23.5 29V28.4936C22.5513 28.8304 21.549 29 20.5 29C19.451 29 18.4487 28.8304 17.5 28.4936V29C17.5 30.6569 18.8431 32 20.5 32C22.1568 32 23.5 30.6569 23.5 29Z"
        fill="#00A5A8"
      />
    </svg>
  );
}

export default Lamp;
