import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import SmSelectWrapper from "../SmSelectWrapper";

function YearSelectSm({ currentYear, years, onApply }) {
  const { t } = useTranslation();
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const selectedButtonRef = useRef(null);

  useEffect(() => {
    setSelectedYear(currentYear);
    selectedButtonRef.current.scrollIntoView();
  }, [currentYear]);

  const handleYearClick = (year) => {
    setSelectedYear(year);
  };

  const handleApply = () => {
    if (onApply) onApply(selectedYear);
  };

  return (
    <SmSelectWrapper
      buttonLabel={t("date.period.applyYear")}
      onApplyClick={handleApply}
    >
      {years.map((year) => (
        <button
          ref={year === selectedYear ? selectedButtonRef : null}
          key={year}
          type="button"
          className={year === selectedYear ? "selected" : ""}
          onClick={() => handleYearClick(year)}
        >
          {year}
        </button>
      ))}
    </SmSelectWrapper>
  );
}

YearSelectSm.propTypes = {
  onApply: PropTypes.func,
  currentYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  years: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
};

YearSelectSm.defaultProps = {
  onApply: undefined,
  currentYear: "",
  years: [],
};

export default YearSelectSm;
