import { createUseStyles } from "react-jss";
/* import { breakpoints, defaultContainer } from "../../../constants/sizes"; */

const useStyles = createUseStyles((theme) => ({
  buttonWrapper: {
    marginBottom: (props) => (props.isLoadEnded ? 0 : 40),
  },
  viewMoreButton: {
    backgroundColor: theme.primary1,
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
}));

export default useStyles;
