import { createUseStyles } from "react-jss";
import { defaultContainer } from "../../../../constants/sizes";

const useStyles = createUseStyles((theme) => ({
  section: {
    display: "flex",
    width: "100%",
    maxWidth: defaultContainer.Width + defaultContainer.paddingHorizontal * 2,
    padding: `0px ${defaultContainer.paddingHorizontal}px`,
  },
  link: {
    display: "flex",
    textDecoration: "none",
    "&:hover": {},
  },
  columnistArticles: {
    width: defaultContainer.Width + defaultContainer.paddingHorizontal * 2,
    padding: `0px ${defaultContainer.paddingHorizontal}px`,
    minHeight: 445,
  },
  centralize: {
    display: "flex",
    justifyContent: "center",
  },
  content: {
    width: "100%",
  },
  contentGrayBg: {
    width: "100%",
    backgroundColor: theme.background1,
  },
  columnistArticlesWrapper: {
    width: "100%",
  },
}));

export default useStyles;
