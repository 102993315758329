import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "react-jss";
import useStyles from "./styles";

const Banner = ({ bannerUrl }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        {bannerUrl ? (
          <img alt="articleImg" src={bannerUrl} className={classes.bannerImg} />
        ) : (
          <div className={classes.emptyBanner} />
        )}
      </div>
    </div>
  );
};
Banner.propTypes = {
  bannerUrl: PropTypes.string,
};

Banner.defaultProps = {
  bannerUrl: undefined,
};

export default Banner;
