import React from "react";
import { useTheme } from "react-jss";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import CustomTypography from "../CustomTypography";
import useStyles from "./styles";
import SvgArrowRight from "../Icons/ArrowRight";

export default function SectionTitle({ title, righLink, showArrow }) {
  SectionTitle.propTypes = {
    title: PropTypes.string,
    righLink: PropTypes.shape(),
    showArrow: PropTypes.bool,
  };

  SectionTitle.defaultProps = {
    title: "",
    righLink: { pathName: "/", text: "" },
    showArrow: false,
  };

  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <div className={classes.titleWrapper}>
      <CustomTypography variant="h3">{title}</CustomTypography>
      <Link to={righLink.pathName} className={classes.link}>
        <div
          style={{
            display: "flex",
            flexDirection: "horizontal",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CustomTypography
            variant="paragraph"
            weight="bold"
            color={theme.primary0}
          >
            {righLink.text}
          </CustomTypography>
          <span className={classes.arrow}>
            {showArrow && <SvgArrowRight />}
          </span>
        </div>
      </Link>
    </div>
  );
}
