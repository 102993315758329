import React, { useEffect, useState, useContext } from "react";
import { useTheme } from "react-jss";
import { Redirect, useLocation, useParams } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedResult } from "../../../redux/result/resultActions";
import {
  fetchResearcher,
  fetchStatistics,
  fetchProjects,
  fetchPublications,
  resetStatsFilters,
} from "../../../redux/researcher/researcherActions";
// import { useTranslation } from "react-i18next";
import ResearcherContainer from "../../../components/Landing/ResultArea/Researcher/ResearcherContainer";
import useStyles from "./styles";
import MapView from "../../../components/Landing/MapView";
import AuthContext from "../../../contexts/auth";

/**
 *
 */
function ResearcherPage() {
  const location = useLocation();
  const theme = useTheme();
  const classes = useStyles(theme);
  // const { t } = useTranslation();
  const { id } = useParams();
  const { selectedResult } = useSelector((state) => state.result);
  const {
    researcher,
    statistics,
    projects,
    publications,
    loadingResearcher,
    loadingStats,
    loadingProjects,
    loadingPublications,
    startDate,
    endDate,
    error,
  } = useSelector((state) => state.researcher);
  const { authCxtMounted } = useContext(AuthContext);
  const dispatch = useDispatch();
  const [edges, setEdges] = useState([]);

  const dispatchFetchStatistics = () => {
    dispatch(fetchStatistics(id, startDate, endDate));
  };

  const dispatchFetchProjects = () => {
    dispatch(fetchProjects(id, startDate, endDate));
  };

  const dispatchFetchPublications = () => {
    dispatch(fetchPublications(id, startDate, endDate));
  };

  useEffect(() => {
    if (authCxtMounted) {
      dispatch(resetStatsFilters());
      dispatch(setSelectedResult(null));
      dispatch(fetchResearcher(id));
    }
  }, [location, authCxtMounted]);

  useEffect(() => {
    if (authCxtMounted) {
      if (!loadingStats && !loadingProjects) {
        setEdges([]);
        // It only make another request if isn't requesting
        if (!loadingStats) dispatchFetchStatistics();
        if (!loadingProjects) dispatchFetchProjects();
        if (!loadingPublications) dispatchFetchPublications();
      }
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (!researcher || !statistics) {
      return;
    }

    const maxWeight = Math.max(
      ...statistics.collaborations.map(
        (vertice) => parseFloat(vertice.coauthorship) || 0
      )
    );
    const minWeight = Math.min(
      ...statistics.collaborations.map(
        (vertice) => parseFloat(vertice.coauthorship) || 0
      )
    );

    setEdges([
      { ...researcher, maxWeight, minWeight, isFeatured: true },
      ...statistics.collaborations.map((vertice) => ({
        ...vertice,
        maxWeight,
        minWeight,
      })),
    ]);
  }, [statistics]);

  const handleCloseResult = () => {
    dispatch(setSelectedResult(null));
  };

  const handleClickResult = (result) => {
    dispatch(setSelectedResult(result));
  };

  if (error) {
    return <Redirect to="/404" />;
  }

  return (
    <>
      <MapView
        className={classes.mapView}
        handleClickResult={handleClickResult}
        handleCloseResult={handleCloseResult}
        results={edges}
        selectedResult={selectedResult}
        /* When drawEdges is true, the map plot edges between the first vertice on
         graph and all others */
        drawEdges={researcher && researcher.latitude !== null}
        hideCategoryFilter
        disableClustering={researcher && researcher.latitude !== null}
        showDateRangeFilter
        filter={{ q: researcher ? researcher.name : "" }}
        loading={loadingResearcher || loadingStats}
      >
        <ResearcherContainer
          researcher={researcher}
          statistics={statistics}
          projects={projects}
          publications={publications}
          loading={loadingResearcher}
          isLoadingAnalysis={loadingStats}
          isLoadingProjects={loadingProjects}
          isLoadingPublications={loadingPublications}
        />
      </MapView>
    </>
  );
}

export default ResearcherPage;
