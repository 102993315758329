import { createUseStyles } from "react-jss";
import { breakpoints } from "../../../constants/sizes";

const useStyles = createUseStyles((theme) => ({
  root: {
    margin: "0 auto",
    display: "flex",
    alignItems: "center",
    height: 64,
    borderRadius: 8,
    [breakpoints.sm]: {
      height: 56,
    },

    "&.MuiPaper-root": {
      "&.MuiPaper-outlined": {
        border: `1px solid ${theme.stroke}`,
      },
    },
  },
  input: {
    flex: 1,
    "&.MuiInputBase-root": {
      fontSize: 20,
      [breakpoints.sm]: {
        fontSize: 16,
        marginTop: 5,
      },
    },
  },
  optionSelector: {
    width: "18%",
    minWidth: 110,
  },
  customSelector: {
    "& .button-root.MuiButtonBase-root": {
      filter: "none",
      background: theme.selectBackground,
      height: 62,
      borderRadius: "4px 0px 0px 4px",
      borderRight: `1px solid ${theme.stroke}`,
      width: 133,
      [breakpoints.sm]: {
        height: 54,
      },
    },
  },
  selectPopup: {
    padding: "0px !important",
    width: "166px !important",
    "& .popup-children": {
      margin: 0,
    },
    [breakpoints.sm]: {
      width: "100% !important",
      padding: "24px !important",
      position: "fixed !important",
      top: "unset !important",
      bottom: "0px !important",
      height: "457px !important",
    },
  },
  selectOptionsWrapper: {
    [breakpoints.sm]: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      paddingTop: 15,
    },
  },
  selectorLabel: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    fontSize: 12,
    fontWeight: 400,
    "& svg": {
      transformOrigin: "center",
      transform: "scale(0.65)",
    },
  },
  selectOption: {
    "&.MuiButtonBase-root": {
      fontSize: 16,
      fontWeight: 400,
      width: "100%",
      backgroundColor: theme.background0,
      borderRadius: 0,
      justifyContent: "space-between",
      "&:hover": {
        background: theme.hoverBackground,
      },
    },
    "&.active": {
      backgroundColor: theme.hoverBackground,
    },
  },
  applyButton: {
    "&.MuiButtonBase-root": {
      color: theme.textTertiary,
    },
  },
  divider: {
    width: 1,
    height: 30,
    backgroundColor: theme.stroke,
    marginRight: 10,
  },
}));

export default useStyles;
