import React from "react";
import { useTheme } from "react-jss";
// import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Modal from "react-modal";
import Button from "@material-ui/core/Button";
import CloseSvg from "../../Icons/Close";
import useStyles from "./styles";
import SuccessContainer from "./SuccessContainer";

Modal.setAppElement("#root");

const AlertModal = ({
  isOpen,
  onRequestClose,
  children,
  title,
  textCloseButton,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  // const { t } = useTranslation();

  const onClose = () => {
    if (onRequestClose) onRequestClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Alert Modal"
      className={classes.modal}
      overlayClassName={classes.overlay}
    >
      <div className={classes.contentWrapper}>
        <div className={classes.content}>
          <div className={classes.closeButtonWrapper}>
            <Button classes={{ root: classes.button }} onClick={onClose}>
              <CloseSvg />
            </Button>
          </div>
          <SuccessContainer
            title={title}
            textCloseButton={textCloseButton}
            onClickClose={onClose}
          >
            {children}
          </SuccessContainer>
        </div>
      </div>
    </Modal>
  );
};

AlertModal.propTypes = {
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func,
  children: PropTypes.node,
  title: PropTypes.string,
  textCloseButton: PropTypes.string,
};

AlertModal.defaultProps = {
  isOpen: false,
  onRequestClose: undefined,
  children: undefined,
  title: "",
  textCloseButton: "",
};

export default AlertModal;
