import { createUseStyles } from "react-jss";
import { breakpoints, defaultContainer } from "../../../constants/sizes";

const useStyles = createUseStyles((/* theme */) => ({
  section: {
    position: "relative",
    width: "100%",
    maxWidth: defaultContainer.Width + defaultContainer.paddingHorizontal * 2,
    padding: `0px ${defaultContainer.paddingHorizontal}px`,
  },
  container: {
    display: "grid",
    width: "100%",
    height: "auto",
    marginBottom: 24,

    gridTemplateColumns: "auto 50%",
    gridColumnGap: 50,
    gridRowGap: 40,
    alignItems: "stretch",

    [breakpoints.md]: {
      gridColumnGap: 20,
    },

    [breakpoints.smd]: {
      gridTemplateColumns: "repeat(auto-fill, 100%)",
    },
  },
  item: {
    height: "auto",
  },
}));

export default useStyles;
