import React, { useState } from "react";
import { useTheme } from "react-jss";
import classNames from "classnames";
import PropTypes from "prop-types";
import Popper from "@material-ui/core/Popper";
import FormGroup from "@material-ui/core/FormGroup";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import CloseSvg from "../Icons/Close";
import CustomButton from "../CustomButton";
import CustomTypography from "../CustomTypography";

import useStyles from "./styles";

function ButtonWithPopup({
  startIcon,
  endIcon,
  children,
  title,
  filterButton,
  className,
  bottomButton,
  transparentOnSm,
  placement,
  hidePopupTitle,
  popperClassName,
  unsetTop,
}) {
  const theme = useTheme();
  const classes = useStyles({ theme, transparentOnSm, unsetTop });
  const [anchorEl, setAnchorEl] = useState(null);
  const modalIsOpen = Boolean(anchorEl);

  const handleFilterClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const scrollChange = currPos.y !== prevPos.y;

      if (scrollChange) setAnchorEl(null);
    },
    [anchorEl]
  );

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={classNames(classes.container, className)}>
        <CustomButton
          className={classNames(classes.filterButton, "button-root")}
          onClick={handleFilterClick}
          startIcon={startIcon}
          endIcon={endIcon}
        >
          {filterButton}
        </CustomButton>

        <Popper
          id="popper"
          placement={placement}
          className={classNames(classes.paper, popperClassName)}
          open={modalIsOpen}
          anchorEl={anchorEl}
        >
          <div className={classes.contentWrapper}>
            {!hidePopupTitle && (
              <div className={classes.titleWrapper}>
                <CustomTypography
                  variant="body"
                  weight="bold"
                  color={theme.textPrimary}
                >
                  {title}
                </CustomTypography>
                <div>
                  <Button
                    classes={{ root: classes.button }}
                    onClick={handleClickAway}
                  >
                    <CloseSvg />
                  </Button>
                </div>
              </div>
            )}

            <FormGroup
              className={classNames(classes.formGroup, "popup-children")}
            >
              {children}
            </FormGroup>

            {bottomButton}
          </div>
        </Popper>
      </div>
    </ClickAwayListener>
  );
}

ButtonWithPopup.propTypes = {
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
  children: PropTypes.node,
  title: PropTypes.string,
  filterButton: PropTypes.node,
  bottomButton: PropTypes.node,
  className: PropTypes.string,
  transparentOnSm: PropTypes.bool,
  placement: PropTypes.string,
  hidePopupTitle: PropTypes.bool,
  popperClassName: PropTypes.string,
  unsetTop: PropTypes.bool,
};

ButtonWithPopup.defaultProps = {
  startIcon: undefined,
  endIcon: undefined,
  children: undefined,
  title: "",
  filterButton: undefined,
  bottomButton: undefined,
  className: "",
  transparentOnSm: false,
  placement: "top-start",
  hidePopupTitle: false,
  popperClassName: "",
  unsetTop: false,
};

export default ButtonWithPopup;
