// const MAX_ZOOM_LEVEL = 9;

export const heatmapLayer = {
  type: "heatmap",
  source: "trees",
  maxzoom: 8,
  paint: {
    "heatmap-weight": {
      property: "dbh",
      type: "exponential",
      stops: [
        [1, 0],
        [62, 1],
      ],
    },
    "heatmap-intensity": {
      stops: [
        [11, 1],
        [15, 3],
      ],
    },
    "heatmap-color": [
      "interpolate",
      ["linear"],
      ["heatmap-density"],
      0,
      "rgba(33,102,172,0)",
      0.2,
      "#718ED7",
      0.4,
      "#79B7E1",
      0.6,
      "#78B5E0",
      0.8,
      "#86DDE8",
      1,
      "#90EF99",
    ],
    "heatmap-radius": {
      stops: [
        [11, 15],
        [15, 20],
      ],
    },
    "heatmap-opacity": {
      default: 1,
      stops: [
        [14, 1],
        [15, 0],
      ],
    },
  },
};
