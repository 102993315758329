import React, { useState, useRef, useEffect, memo } from "react";
import { useTheme } from "react-jss";
import PropTypes from "prop-types";
// import { useTranslation } from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress";
import About from "../About";
import ResultHeader from "../../ResultHeader";
import resultTypes from "../../../ResultTypes";
import CustomTabs from "../../../../CustomTabs";
import CustomTab from "../../../../CustomTab";
import GeneralContent from "../TabsContent/General";
import InstitutionalCollaborationsPaginatedList from "../TabsContent/InstitutionalCollaborationsPaginatedList";
import PublicationsPaginatedList from "../TabsContent/PublicationsPaginatedList";
import ProjectsPaginatedList from "../TabsContent/ProjectsPaginatedList";
import CoAuthorsPaginatedList from "../TabsContent/CoAuthorsPaginatedList";
import { tabsTypes } from "../../tabTypes";
import useStyles from "./styles";
import PaginatedListWrapper from "../../TabsContent/PaginatedListWrapper";

const ResearcherContainer = ({
  researcher,
  statistics,
  projects,
  publications,
  loading,
  isLoadingAnalysis,
  isLoadingProjects,
  isLoadingPublications,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [tabActive, setTabActive] = useState(tabsTypes.GENERAL);
  const tabsRef = useRef(null);
  // const { t } = useTranslation();
  // const { loadingStats } = useSelector((state) => state.researcher);

  useEffect(() => {
    setTabActive(tabsTypes.GENERAL);
  }, [researcher]);

  if (!researcher) {
    if (loading)
      return (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      );
    return null;
  }

  const handleTabChange = (_, newTabActive) => {
    setTabActive(newTabActive);
  };

  const handleViewAllClick = (sectionName) => {
    tabsRef.current.scrollIntoView();
    setTabActive(sectionName);
  };

  function TabSwicher() {
    switch (tabActive) {
      case tabsTypes.GENERAL:
        return (
          <GeneralContent
            statistics={statistics}
            projects={projects}
            publications={publications}
            isLoadingAnalysis={isLoadingAnalysis}
            isLoadingProjects={isLoadingProjects}
            isLoadingPublications={isLoadingPublications}
            onViewAllClick={handleViewAllClick}
          />
        );
      case tabsTypes.PUBLICATIONS:
        return <PublicationsPaginatedList items={publications} />;
      case tabsTypes.PROJECTS:
        return <ProjectsPaginatedList items={projects} />;
      case tabsTypes.COAUTHORS:
        return (
          <CoAuthorsPaginatedList
            items={statistics ? statistics.collaborations : []}
          />
        );
      case tabsTypes.COLABORATIONS:
        return (
          <InstitutionalCollaborationsPaginatedList
            items={statistics ? statistics.institutions_collaboration : []}
          />
        );
      default:
        return null;
    }
  }

  return (
    <div className={classes.content}>
      <ResultHeader
        image={researcher.image}
        name={researcher.name}
        link={`/${researcher.lattes_id}`}
      />
      <About
        type={resultTypes.RESEARCHER}
        about={researcher.abstract}
        lattesId={researcher.lattes_id}
        institutionalBond={researcher.institutional_bond}
      />
      <>
        <div ref={tabsRef} className={classes.section}>
          <CustomTabs value={tabActive} onChange={handleTabChange}>
            <CustomTab label="Visão geral" value={tabsTypes.GENERAL} />
            <CustomTab label="Publicações" value={tabsTypes.PUBLICATIONS} />
            <CustomTab label="Projetos" value={tabsTypes.PROJECTS} />
            <CustomTab label="Co-autores" value={tabsTypes.COAUTHORS} />
            <CustomTab label="Colaborações" value={tabsTypes.COLABORATIONS} />
          </CustomTabs>
        </div>
        <PaginatedListWrapper>
          <TabSwicher />
        </PaginatedListWrapper>
      </>
    </div>
  );
};

ResearcherContainer.propTypes = {
  statistics: PropTypes.shape(),
  researcher: PropTypes.shape(),
  loading: PropTypes.bool,
  projects: PropTypes.arrayOf(PropTypes.shape()),
  publications: PropTypes.arrayOf(PropTypes.shape()),
  isLoadingAnalysis: PropTypes.bool,
  isLoadingProjects: PropTypes.bool,
  isLoadingPublications: PropTypes.bool,
};

ResearcherContainer.defaultProps = {
  statistics: undefined,
  researcher: undefined,
  publications: undefined,
  loading: true,
  projects: undefined,
  isLoadingAnalysis: false,
  isLoadingProjects: false,
  isLoadingPublications: false,
};

export default memo(ResearcherContainer);
