import { createUseStyles } from "react-jss";
import { breakpoints } from "../../../constants/sizes";

const useStyles = createUseStyles((/* theme */) => ({
  wrapper: {
    maxWidth: "100%",
  },
  titleTypography: {
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  title: {
    margin: "16px 0px 16px 0px",
    height: 40,
    [breakpoints.xsm]: {
      height: 40,
    },
  },
  link: {
    display: "flex",
    textDecoration: "none",
    "&:hover": {},
  },
}));

export default useStyles;
