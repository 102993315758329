import * as React from "react";

function SvgSearch(props) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6433 4.72341C9.94067 4.72341 8.30773 5.39979 7.10376 6.60376C5.89979 7.80773 5.22341 9.44067 5.22341 11.1433C5.22341 12.846 5.89979 14.479 7.10376 15.6829C8.30773 16.8869 9.94067 17.5633 11.6433 17.5633C13.3382 17.5633 14.964 16.8931 16.1664 15.6994C16.173 15.6924 16.1797 15.6855 16.1865 15.6786C16.1928 15.6724 16.199 15.6663 16.2054 15.6603C17.3953 14.4586 18.0633 12.8354 18.0633 11.1433C18.0633 9.44067 17.3869 7.80773 16.1829 6.60376C14.979 5.39979 13.346 4.72341 11.6433 4.72341ZM17.9812 16.2567C19.1441 14.8154 19.7867 13.0125 19.7867 11.1433C19.7867 8.98359 18.9287 6.9123 17.4016 5.38513C15.8744 3.85796 13.8031 3 11.6433 3C9.48359 3 7.4123 3.85796 5.88513 5.38513C4.35796 6.9123 3.5 8.98359 3.5 11.1433C3.5 13.3031 4.35796 15.3744 5.88513 16.9016C7.4123 18.4287 9.48359 19.2867 11.6433 19.2867C13.5153 19.2867 15.3208 18.6421 16.7632 17.476L20.0285 20.7471C20.3647 21.0839 20.9103 21.0844 21.2471 20.7482C21.5839 20.412 21.5844 19.8664 21.2482 19.5295L17.9812 16.2567Z"
        fill="#A0A0A0"
      />
    </svg>
  );
}

export default SvgSearch;
