import { createUseStyles } from "react-jss";
import { breakpoints, defaultContainer } from "../../../../constants/sizes";

const useStyles = createUseStyles((/* theme */) => ({
  section: {
    position: "relative",
    width: "100%",
    maxWidth: defaultContainer.Width + defaultContainer.paddingHorizontal * 2,
    padding: `0px ${defaultContainer.paddingHorizontal}px`,
  },
  videosWrapper: {
    marginTop: 24,
    display: "grid",
    width: "100%",
    marginBottom: 60,

    gridTemplateColumns: "minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)",
    gridColumnGap: 32,
    gridRowGap: 40,
    alignItems: "stretch",

    [breakpoints.smd]: {
      gridColumnGap: 16,
    },

    [breakpoints.sm]: {
      gridTemplateColumns: "repeat(auto-fill, 100%)",
      marginTop: 16,
      display: "grid",
    },
  },
  video: {
    height: "auto",
    [breakpoints.sm]: {
      width: "100%",
    },
  },
}));

export default useStyles;
