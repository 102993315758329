import { createUseStyles } from "react-jss";
import { breakpoints } from "../../../constants/sizes";

const useStyles = createUseStyles((theme) => ({
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 10,
    backgroundColor: theme.background2,
    [breakpoints.sm]: {
      top: 10,
      left: 10,
      right: 10,
      bottom: 10,
    },
    "& :focus-visible": {
      outline: "none",
    },
  },
  contentWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  content: {
    backgroundColor: theme.background2,
    width: 200,
    height: 200,
    borderRadius: 5,
    padding: 32,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    border: "none",
    [breakpoints.sm]: {
      width: "100%",
      padding: 20,
    },
  },
  paperRoot: {
    margin: "0 auto",
    padding: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: 5,
    "&.MuiPaper-root": {
      backgroundColor: (props) =>
        props.backgroundColor ? props.backgroundColor : theme.background0,
      "&.MuiPaper-outlined": {
        border: `1px solid ${theme.stroke}`,
      },
    },
  },
}));

export default useStyles;
