import { combineReducers } from "redux";
import resultReducer from "./result/resultReducer";
import resultFilterReducer from "./resultFilter/resultFilterReducer";
import researcherReducer from "./researcher/researcherReducer";
import instituteReducer from "./institute/instituteReducer";
import cooperativeReducer from "./cooperative/cooperativeReducer";

const rootReducer = combineReducers({
  result: resultReducer,
  resultFilter: resultFilterReducer,
  researcher: researcherReducer,
  institute: instituteReducer,
  cooperative: cooperativeReducer,
});

export default rootReducer;
