/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import ReactGA from "react-ga";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Home from "./Home";
import Article from "./Article";
import Multimedia from "./Multimedia";
import Columnists from "./Columnists";
import TrendArticles from "./RecentlyArticles";
import useStyles from "./styles";

/**
 * This page wraps ever single page that Agency contains.
 */
export default function Agency() {
  const match = useRouteMatch();
  const classes = useStyles();
  const history = useHistory();

  // Initialize google analytics page view tracking
  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.set({ page: history.location.pathname }); // Update the user's current page
    ReactGA.pageview(history.location.pathname); // Record a pageview for the given page
  }, [history.location]);

  return (
    <div className={classes.container}>
      <div className={classes.section}>
        <Header />
      </div>
      <Switch>
        <Route exact path={match.path} component={Home} />
        <Route
          path={`${match.path}/artigos-recentes`}
          component={TrendArticles}
        />
        <Route path={`${match.path}/multimidia`} component={Multimedia} />
        <Route path={`${match.path}/colunistas`} component={Columnists} />
        <Route path={`${match.path}/artigo/:slug`} component={Article} />
        <Route path={`${match.path}/*`}>
          <Redirect to="/404" />
        </Route>
      </Switch>
      <Footer />
    </div>
  );
}
