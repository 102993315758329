import * as React from "react";

function SvgArrowRight(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.39947 15.7414C6.1953 15.3331 6.27533 14.8399 6.59815 14.5171L11.0576 10.0576L6.59815 5.59819C6.27533 5.27537 6.1953 4.78219 6.39947 4.37385C6.72323 3.72632 7.58623 3.58627 8.09815 4.09819L13.4683 9.46836C13.7938 9.7938 13.7938 10.3214 13.4683 10.6469L8.09815 16.0171C7.58623 16.529 6.72323 16.3889 6.39947 15.7414Z"
        fill="#5C5C5C"
      />
    </svg>
  );
}

export default SvgArrowRight;
