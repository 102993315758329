import React from "react";
import { useTheme } from "react-jss";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { getStrapiMedia } from "../../../services/hcms/media";
import CustomTypography from "../../CustomTypography";
import useStyles from "./styles";

export default function ArticleInfo({
  columnist,
  publishedAt,
  spaceBetween,
  hideAvatar,
}) {
  ArticleInfo.propTypes = {
    columnist: PropTypes.shape().isRequired,
    publishedAt: PropTypes.string.isRequired,
    spaceBetween: PropTypes.bool,
    hideAvatar: PropTypes.bool,
  };

  ArticleInfo.defaultProps = {
    spaceBetween: false,
    hideAvatar: false,
  };

  if (!columnist) {
    return null;
  }

  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles({ theme });
  return (
    <div className={classes.wrapper}>
      <Link className={classes.link} to={`/agencia/colunistas/${columnist.id}`}>
        <img
          alt="columnist"
          src={getStrapiMedia(columnist.avatar)}
          className={
            hideAvatar
              ? classNames(classes.columnistAvatar, classes.hide)
              : classNames(classes.columnistAvatar)
          }
        />
      </Link>
      <div
        style={{
          display: "flex",
          width: "100%",
          flexFlow: "row wrap",
          justifyContent: spaceBetween ? "space-between" : "flex-start",
        }}
      >
        <Link
          className={classes.link}
          to={`/agencia/colunistas/${columnist.id}`}
        >
          <CustomTypography
            variant="body"
            weight="regular"
            color={theme.primary1}
            style={{ marginRight: 12 }}
          >
            {columnist.name}
          </CustomTypography>
        </Link>

        <CustomTypography
          variant="body"
          weight="regular"
          size={16}
          color={theme.textSecondary}
        >
          {t("agency.article.publishedAtDate", {
            date: new Date(publishedAt),
          })}
        </CustomTypography>
      </div>
    </div>
  );
}
