import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTheme } from "react-jss";
import { useTranslation } from "react-i18next";
import Card from "./Card";
import useStyles from "./styles";

const GeneralStatistics = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  const { totalPublications, totalResearchers, totalInstitutes } = useSelector(
    (state) => state.result
   
  );

  useEffect(() => {
    console.log("GeneralStatistics mounted");
  }, []);



  return (
    <div className={classes.generalWrapper}>
   
    
        <span className={classes.title}>
        {t("cooperatives.statistics.general")}
        </span>



      <div className={classes.cardsWrapper}>
        <Card caption={t("publications")} title={totalPublications} />
        <Card
          caption={t("searchEngine.filters.authors")}
          title={totalResearchers}
        />
        <Card
          caption={t("searchEngine.filters.institutions")}
          title={totalInstitutes}
        />
      </div>
    </div>
  );
};


export default GeneralStatistics;
