import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTheme } from "react-jss";
import { useTranslation } from "react-i18next";
import api from "../../../services/api";
import BarChart from "../../Charts/BarChart";
import CustomTypography from "../../CustomTypography";
import AnalysisCardSkeleton from "../../Skeleton/AnalysisCardSkeleton";
import useStyles from "./styles";

const PublicationsChart = ({ id }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [statistics, setStatistics] = useState(null);

  const currentYear = new Date().getFullYear();
  const startYear = "1980";

  const fetchStatistics = async () => {
    setLoading(true);

    const response = await api.get(
      `researchers/statistics/${id}?start_year=${startYear}&end_year=${currentYear}`
    );

    setStatistics(response.data.statistics);

    setLoading(false);
  };

  useEffect(() => {
    fetchStatistics();
  }, [id]);

  const totalPublications =
    parseFloat(statistics?.total_article) +
      parseFloat(statistics?.total_book) +
      parseFloat(statistics?.total_book_chapter) || "-";

  const firstYear =
    statistics?.publication_for_year && !!statistics.publication_for_year[0]
      ? statistics.publication_for_year[0].year
      : startYear;

  return (
    <div className={classes.contentWrapper}>
      {loading ? (
        <AnalysisCardSkeleton variant="totalPublication" />
      ) : (
        <>
          <div className={classes.flexSpaceBetween}>
            <div className={classes.flexSpaceBetween}>
              <CustomTypography
                variant="paragraph"
                color={theme.textSecondary}
                weight="bold"
              >
                {totalPublications}
              </CustomTypography>
              <CustomTypography
                color={theme.textSecondary}
                variant="description"
                weight="bold"
              >
                {t("publications")}
              </CustomTypography>
            </div>

            <CustomTypography
              color={theme.textSecondary}
              variant="description"
              weight="bold"
            >
              {firstYear} - {currentYear}
            </CustomTypography>
          </div>

          <BarChart
            valueName={t("resultPage.analysis.total")}
            className={classes.barChart}
            data={statistics?.publication_for_year?.map((d) => ({
              value: parseFloat(d.total),
              name: d.year,
            }))}
            height={60}
            width={280}
            applyOpacity
          />
        </>
      )}
    </div>
  );
};

PublicationsChart.propTypes = {
  id: PropTypes.string,
};

PublicationsChart.defaultProps = {
  id: null,
};

export default PublicationsChart;
