import { createUseStyles } from "react-jss";
import { breakpoints } from "../../constants/sizes";

const useStyles = createUseStyles((theme) => ({
  root: {
    "&.MuiButton-root": {
      borderRadius: "4px",
      textTransform: "none",
      backgroundColor: (props) => props.backgroundColor || theme.primary0,
      color: (props) => props.color || theme.light,
      height: 48,
      padding: 20.5,

      [breakpoints.md]: {
        padding: 10,
        fontSize: 14,
      },

      "&:hover": {
        backgroundColor: theme.primary1,
      },

      "&.MuiButton-outlined": {
        backgroundColor: (props) =>
          props.pressed ? theme.textPrimary : theme.background0,
        border: "solid 1px",
        borderRadius: 8,
        color: (props) =>
          props.pressed
            ? theme.background0
            : props.outlineColor
            ? props.outlineColor
            : theme.textPrimary,
        "& .MuiButton-startIcon": {
          "& path": {
            fill: (props) =>
              props.pressed
                ? theme.background0
                : props.outlineColor
                ? props.outlineColor
                : theme.textPrimary,
          },
        },

        "&:hover": {
          backgroundColor: (props) =>
            props.outlineColor ? props.outlineColor : theme.textPrimary,
          color: theme.background0,
          "& .MuiButton-startIcon": {
            "& path": {
              fill: theme.background0,
            },
          },
        },
      },
    },
  },
  icon: {
    width: 28,
    marginRight: 11.46,
  },
}));

export default useStyles;
