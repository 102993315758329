import { createUseStyles } from "react-jss";
import { breakpoints } from "../../../constants/sizes";

const useStyles = createUseStyles((theme) => ({
  buttonsWrapper: {
    display: "flex",
    gap: 30,
    alignItems: "center",
    [breakpoints.md]: {
      width: "100%",
      display: (props) => (props.hideLogo ? "none" : "flex"),
    },
  },
  headerItem: {
    display: "flex",
    [breakpoints.md]: {
      display: "none",
    },
  },

  logoWrapper: {
    display: "flex",
    cursor: "pointer",
    userSelect: "none",

    "&:focus": {
      outline: "none",
    },
  },
  logo: {
    width: 104,
    [breakpoints.md]: {
      width: 104,
      height: 32,
      objectPosition: 0,
      objectFit: "fit",
    },
  },
  arrowMenu: {
    marginLeft: 10,
  },

  contactUsWrapper: {
    display: "flex",
    alignItems: "center",
    "& svg": {
      marginLeft: 15,
      marginTop: 6,
    },
  },
  aboutUsButton: {
    "& .button-root": {
      filter: "none",
    },
  },
  aboutUsButtonLabel: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    minWidth: 95,
    color: theme.textSecondary,
  },
  aboutUsContent: {
    width: 600,
    borderRadius: "0px 0px 8px 8px",
    border: "none",
    marginTop: 16,
  },
  singePopperContent: {
    width: 360,
    borderRadius: "0px 0px 8px 8px",
    border: "none",
    marginTop: 16,
  },
  aboutUsButtons: {
    display: "flex",
    flexWrap: "wrap",
    rowGap: 15,
    "& .popup-button": {
      minWidth: "50%",
      display: "flex",
      flexDirection: "column",
      rowGap: 5,
      "& a": {
        textDecoration: "none",
      },
    },
  },
}));

export default useStyles;
