import React from "react";
import { useTheme } from "react-jss";
import PropTypes from "prop-types";
import useStyles from "./styles";

const Card = ({ title, caption }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <div className={classes.container}>
      <span className={classes.number}>{title}</span>
      <span className={classes.subtitle}>{caption}</span>
    </div>
  );
};

Card.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  caption: PropTypes.string,
};

Card.defaultProps = {
  title: "",
  caption: "",
};

export default Card;
