import React, { useEffect } from "react";
import { useTheme } from "react-jss";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";
import mission from "../../assets/images/mission/mission.png";
import vision from "../../assets/images/mission/vision.png";
import principles from "../../assets/images/mission/principles.png";
import Header from "../../components/Header";
import Partners from "../../components/Partners";
import Footer from "../../components/Footer";
import CustomTypography from "../../components/CustomTypography";
import Cooperation from "../../components/Icons/Mission/Cooperation";
import Eye from "../../components/Icons/Mission/Eye";
import Circles from "../../components/Icons/Mission/Circles";
import Lamp from "../../components/Icons/Mission/Lamp";
import Product from "../../components/Icons/Mission/Product";
import Agro from "../../components/Icons/Mission/Agro";
import useStyles from "./styles";

function Mission() {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  const history = useHistory();

  // Initialize google analytics page view tracking
  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.set({ page: history.location.pathname }); // Update the user's current page
    ReactGA.pageview(history.location.pathname); // Record a pageview for the given page
  }, []);

  return (
    <div className={classes.container}>
      <Header />
      <div className={classes.firstFold}>
        <div className={classes.section}>
          <img alt="mission" src={mission} />
        </div>
        <div className={classes.section}>
          <CustomTypography variant="h1" color={theme.lowDark}>
            {t("mission.title")}
          </CustomTypography>
          <CustomTypography variant="paragraph" color={theme.textSecondary}>
            {t("mission.description")}
          </CustomTypography>
        </div>
      </div>
      <div className={classNames([classes.firstFold, classes.secondFold])}>
        <div className={classes.section}>
          <img alt="vision" src={vision} />
        </div>
        <div className={classes.section}>
          <CustomTypography variant="h1" color={theme.lowDark}>
            {t("vision.title")}
          </CustomTypography>
          <CustomTypography variant="paragraph" color={theme.textSecondary}>
            {t("vision.description")}
          </CustomTypography>
        </div>
      </div>
      <div className={classNames([classes.firstFold, classes.thirdFold])}>
        <div className={classes.section}>
          <img alt="principles" src={principles} />
        </div>

        <div
          className={classNames([classes.sideSectionWrapper, classes.section])}
        >
          <div className={classes.sideSection}>
            <CustomTypography variant="h1" color={theme.lowDark}>
              {t("principles.title")}
            </CustomTypography>
            <div className={classes.thirdFoldRow}>
              <div className={classes.sentenceWithIcon}>
                <Cooperation />
                <CustomTypography
                  variant="paragraph"
                  color={theme.textSecondary}
                >
                  {t("principles.cooperation")}
                </CustomTypography>
              </div>
              <div className={classes.sentenceWithIcon}>
                <Eye />
                <CustomTypography
                  variant="paragraph"
                  color={theme.textSecondary}
                >
                  {t("principles.transparency")}
                </CustomTypography>
              </div>
            </div>
          </div>
          <div className={classes.sideSection}>
            <CustomTypography variant="h1" color={theme.lowDark}>
              {t("principles.values")}
            </CustomTypography>
            <div className={classes.thirdFoldRow}>
              <div className={classes.sentenceWithIcon}>
                <Circles />
                <CustomTypography
                  variant="paragraph"
                  color={theme.textSecondary}
                >
                  {t("principles.diversity")}
                </CustomTypography>
              </div>
              <div className={classes.sentenceWithIcon}>
                <Lamp />
                <CustomTypography
                  variant="paragraph"
                  color={theme.textSecondary}
                >
                  {t("principles.innovation")}
                </CustomTypography>
              </div>
            </div>
          </div>
          <div className={classes.sideSection}>
            <CustomTypography variant="h1" color={theme.lowDark}>
              {t("principles.commitments")}
            </CustomTypography>
            <div className={classes.thirdFoldRow}>
              <div className={classes.sentenceWithIcon}>
                <Product />
                <CustomTypography
                  variant="paragraph"
                  color={theme.textSecondary}
                >
                  {t("principles.integration")}
                </CustomTypography>
              </div>
              <div className={classes.sentenceWithIcon}>
                <Agro />
                <CustomTypography
                  variant="paragraph"
                  color={theme.textSecondary}
                >
                  {t("principles.socioEnvironmentalJustice")}
                </CustomTypography>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Partners buttonTo="/#cta" />
      <Footer />
    </div>
  );
}

export default Mission;
