import * as React from "react";

function SvgInstagram(props) {
  return (
    <svg
      width={32}
      height={32}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.053 4.072C12.333 4.013 12.74 4 16 4s3.668.014 4.946.072c1.279.058 2.151.262 2.915.557.8.303 1.525.775 2.125 1.386a5.862 5.862 0 011.383 2.124c.297.764.5 1.636.559 2.913.059 1.282.072 1.69.072 4.948 0 3.26-.014 3.668-.072 4.947-.058 1.277-.262 2.15-.559 2.913a5.887 5.887 0 01-1.383 2.126 5.885 5.885 0 01-2.125 1.383c-.764.297-1.636.5-2.913.559-1.28.059-1.688.072-4.948.072-3.26 0-3.668-.014-4.947-.072-1.277-.058-2.15-.262-2.913-.559a5.889 5.889 0 01-2.126-1.383 5.883 5.883 0 01-1.385-2.125c-.295-.764-.498-1.636-.557-2.913C4.013 19.666 4 19.258 4 16c0-3.26.014-3.668.072-4.946.058-1.279.262-2.151.557-2.915.302-.8.775-1.526 1.386-2.125a5.882 5.882 0 012.124-1.385c.764-.295 1.636-.498 2.913-.557zm9.796 2.16c-1.265-.058-1.645-.07-4.849-.07s-3.584.012-4.85.07c-1.17.053-1.805.249-2.228.413-.56.219-.96.477-1.38.897a3.722 3.722 0 00-.897 1.38c-.164.423-.36 1.058-.413 2.229-.058 1.265-.07 1.645-.07 4.849s.012 3.584.07 4.85c.053 1.17.249 1.804.413 2.228.192.52.499.992.897 1.38.387.398.86.705 1.38.897.423.164 1.058.36 2.229.413 1.265.058 1.644.07 4.849.07s3.584-.012 4.85-.07c1.17-.053 1.804-.249 2.228-.413.56-.219.96-.477 1.38-.897.398-.387.705-.86.897-1.38.164-.424.36-1.058.413-2.229.058-1.265.07-1.645.07-4.849s-.012-3.584-.07-4.85c-.053-1.17-.249-1.805-.413-2.228a3.725 3.725 0 00-.897-1.38 3.723 3.723 0 00-1.38-.897c-.424-.164-1.058-.36-2.229-.413zm-6.382 13.467a4.002 4.002 0 004.364-6.53 4.002 4.002 0 00-5.797 5.52c.397.439.886.784 1.433 1.01zm-2.829-8.06a6.17 6.17 0 0110.06 2 6.167 6.167 0 11-10.06-2zm11.898-.888a1.456 1.456 0 00.03-2.093 1.459 1.459 0 10-.03 2.093z"
        fill="#00585A"
      />
    </svg>
  );
}

export default SvgInstagram;
