import { createUseStyles } from "react-jss";
import { defaultContainer } from "../../../constants/sizes";

const useStyles = createUseStyles((/* theme */) => ({
  mainContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "hidden",
  },
  section: {
    position: "relative",
    width: "100%",
    maxWidth: defaultContainer.Width + defaultContainer.paddingHorizontal * 2,
    padding: `0px ${defaultContainer.paddingHorizontal}px`,
  },
  link: {
    display: "flex",
    textDecoration: "none",
    "&:hover": {},
  },

  sectionContent: {
    marginBottom: 40,
  },
}));

export default useStyles;
