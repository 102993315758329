import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTheme } from "react-jss";
import classNames from "classnames";
import { CopyToClipboard } from "react-copy-to-clipboard";
import api from "../../../services/api";
import LinkClip from "../../Icons/LinkClip";
import CustomTypography from "../../CustomTypography";
import ProfileImg from "../../ProfileImg";
import CustomLink from "../../CustomLink";
import PublicationsChart from "../PublicationsChart";
import useStyles from "./styles";

const ResearcherCard = ({ id, researchAreas, ...props }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [data, setData] = useState();

  const fetchData = async () => {
    const response = await api.get(`researchers/${id}`);

    return setData(response.data);
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  return (
    <div
      className={classNames([classes.contentWrapper, "research-card-root"])}
      {...props}
    >
      <div className={classes.copyButtonWrapper}>
        <CopyToClipboard text={`${window.location.href}${id}`}>
          <LinkClip />
        </CopyToClipboard>
      </div>
      <div className={classes.imageWrapper}>
        <ProfileImg
          name={data?.name}
          url={data?.image}
          size={104}
          smSize={104}
        />
      </div>
      <CustomLink to={`/${id}`}>
        <CustomTypography variant="noticeTitle" className={classes.name}>
          {data?.name}
        </CustomTypography>
      </CustomLink>

      <div className={classes.areasWrapper}>
        <CustomTypography
          variant="subtitle"
          color={theme.primary0}
          className={classes.area}
        >
          {researchAreas?.slice(0, 2).join(" | ")}
        </CustomTypography>
      </div>
      <div className={classes.chartWrapper}>
        <PublicationsChart id={id} />
      </div>
    </div>
  );
};

ResearcherCard.propTypes = {
  id: PropTypes.string,
  researchAreas: PropTypes.arrayOf(PropTypes.string),
};

ResearcherCard.defaultProps = {
  id: null,
  researchAreas: null,
};

export default ResearcherCard;
