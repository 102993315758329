import * as React from "react";

function Agro(props) {
  return (
    <svg
      width="24"
      height="30"
      viewBox="0 0 24 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillTule="evenodd"
        clipRule="evenodd"
        d="M3.37719 22.7003C3.38349 22.8962 3.39277 23.0926 3.40509 23.2896L3.72377 28.3885C3.77602 29.2246 3.14059 29.9447 2.30449 29.997C1.46838 30.0493 0.748227 29.4138 0.69597 28.5777L0.377294 23.4789C-0.4179 10.7558 9.68663 0 22.4346 0C23.0787 0 23.6527 0.406888 23.8659 1.01476C24.0791 1.62264 23.8852 2.29889 23.3821 2.70131L21.3304 4.34266C17.6607 7.27845 15.5245 11.7232 15.5245 16.4227C15.5245 20.9933 11.3102 24.4002 6.84106 23.4425L3.37719 22.7003ZM3.52443 19.6292L7.47671 20.4762C10.0573 21.0291 12.4908 19.0619 12.4908 16.4227C12.4908 11.6347 14.3446 7.06781 17.6069 3.64912C10.1566 5.59113 4.52104 11.92 3.52443 19.6292Z"
        fill="#00A5A8"
      />
    </svg>
  );
}

export default Agro;
