import * as React from "react";

function OutlinedLeaf(props) {
  return (
    <svg
      width="15"
      height="18"
      viewBox="0 0 15 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.82629 13.6202C1.83007 13.7377 1.83564 13.8556 1.84303 13.9738L2.03423 17.0331C2.06559 17.5347 1.68433 17.9668 1.18267 17.9982C0.681006 18.0296 0.248912 17.6483 0.217558 17.1466L0.0263517 14.0873C-0.450764 6.45347 5.61195 0 13.2607 0C13.6472 0 13.9916 0.244133 14.1195 0.608858C14.2475 0.973583 14.1311 1.37933 13.8292 1.62079L12.5982 2.6056C10.3964 4.36707 9.11465 7.03391 9.11465 9.85364C9.11465 12.596 6.58608 14.6401 3.90461 14.0655L1.82629 13.6202ZM1.91464 11.7775L4.286 12.2857C5.83436 12.6175 7.29443 11.4371 7.29443 9.85364C7.29443 6.98083 8.40676 4.24069 10.3641 2.18947C5.89395 3.35468 2.5126 7.15198 1.91464 11.7775Z"
        fill="#A0A0A0"
      />
    </svg>
  );
}

export default OutlinedLeaf;
