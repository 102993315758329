import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((/* theme */) => ({
  content: {
    display: "flex",
    gap: 10,
  },
  avatarWrapper: {
    width: 60,
    display: "flex",
    alignItems: "center",
  },
  typographyWrapper: {
    width: "100%",
  },
}));
export default useStyles;
