import * as React from "react";

function Cooperative(props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.5213 7.31707C17.8207 6.9352 17.9883 6.46746 17.9994 5.98326C18.0105 5.49906 17.8644 5.02421 17.5829 4.62917L14.9337 0.938527C14.7256 0.648322 14.4508 0.411766 14.1323 0.248527C13.8137 0.0852884 13.4607 8.44724e-05 13.1024 0L4.89618 0C4.5379 8.44724e-05 4.18481 0.0852884 3.86628 0.248527C3.54775 0.411766 3.27299 0.648322 3.06482 0.938527L0.415701 4.62917C0.134691 5.02454 -0.0108552 5.49948 0.000631201 5.98362C0.0121176 6.46777 0.180025 6.93535 0.479472 7.31707L8.11985 16.8973C8.5702 17.462 9.42843 17.462 9.87885 16.8974L17.5213 7.31707ZM5.43786 6.71976L7.53855 13.7621L1.91845 6.71976H5.43786ZM16.0801 6.71976L10.4638 13.7621L12.5607 6.71976H16.0801ZM10.9957 6.71976L8.99928 13.4238L7.00287 6.71976H10.9957ZM6.99312 5.22648L8.06447 1.49328H9.93409L11.0054 5.22648H6.99312ZM13.7131 1.80612L16.1679 5.22648H12.5667L11.4946 1.49328H13.1024C13.2219 1.49327 13.3396 1.52165 13.4458 1.57607C13.552 1.63048 13.6437 1.70935 13.7131 1.80612ZM4.28548 1.80612C4.35489 1.70935 4.44652 1.63048 4.55274 1.57607C4.65896 1.52165 4.77671 1.49327 4.89618 1.49328H6.50396L5.4326 5.22648H1.83142L4.28548 1.80612Z"
        fill="#00A5A8"
      />
    </svg>
  );
}

export default Cooperative;
