export const FETCH_RESEARCHER_REQUEST = "FETCH_RESEARCHER_REQUEST";
export const FETCH_RESEARCHER_SUCCESS = "FETCH_RESEARCHER_SUCCESS";
export const FETCH_RESEARCHER_FAILURE = "FETCH_RESEARCHER_FAILURE";
export const FETCH_RESEARCHER_STATS_REQUEST = "FETCH_RESEARCHER_STATS_REQUEST";
export const FETCH_RESEARCHER_STATS_SUCCESS = "FETCH_RESEARCHER_STATS_SUCCESS";
export const FETCH_RESEARCHER_STATS_FAILURE = "FETCH_RESEARCHER_STATS_FAILURE";
export const FETCH_RESEARCHER_PROJECTS_REQUEST =
  "FETCH_RESEARCHER_PROJECTS_REQUEST";
export const FETCH_RESEARCHER_PROJECTS_SUCCESS =
  "FETCH_RESEARCHER_PROJECTS_SUCCESS";
export const FETCH_RESEARCHER_PROJECTS_FAILURE =
  "FETCH_RESEARCHER_PROJECTS_FAILURE";
export const FETCH_RESEARCHER_PUBLICATIONS_REQUEST =
  "FETCH_RESEARCHER_PUBLICATIONS_REQUEST";
export const FETCH_RESEARCHER_PUBLICATIONS_SUCCESS =
  "FETCH_RESEARCHER_PUBLICATIONS_SUCCESS";
export const FETCH_RESEARCHER_PUBLICATIONS_FAILURE =
  "FETCH_RESEARCHER_PUBLICATIONS_FAILURE";
export const SET_DATE_PERIOD = "SET_DATE_PERIOD";
export const SET_STATS_START_DATE = "SET_STATS_START_DATE";
export const SET_STATS_END_DATE = "SET_STATS_END_DATE";
export const RESET_STATS_FILTERS = "RESET_STATS_FILTERS";
