import React, { useState, useRef, useEffect, memo } from "react";
import classNames from "classnames";
import { useTheme } from "react-jss";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CustomTextField from "../../../CustomTextField";
// import SvgClose from "../../../Icons/Close";
import SvgSearch from "../../../Icons/Search";
import SvgArrowRight from "../../../Icons/ArrowRight";
import useStyles from "../styles";

const SearchEngineVariant = ({
  isSearching,
  handleClick,
  currentSearchText,
  onBackClick,
  showBackButton,
  setIsSearching,
}) => {
  SearchEngineVariant.propTypes = {
    isSearching: PropTypes.bool.isRequired,
    handleClick: PropTypes.func.isRequired,
    setIsSearching: PropTypes.func.isRequired,
    currentSearchText: PropTypes.string,
    onBackClick: PropTypes.func,
    showBackButton: PropTypes.bool,
  };

  SearchEngineVariant.defaultProps = {
    currentSearchText: "",
    showBackButton: false,
    onBackClick: undefined,
  };

  const theme = useTheme();
  const classes = useStyles({ theme });
  const history = useHistory();
  const [searchText, setSearchText] = useState(currentSearchText);
  const isMd = useMediaQuery("(max-width: 960px)");
  const textFieldRef = useRef();

  useEffect(() => {
    setSearchText(currentSearchText);
  }, [currentSearchText]);

  useEffect(() => {
    if (isMd) setIsSearching(false);
  }, [isMd]);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      if (searchText && searchText !== "")
        history.push(`/buscador?q=${textFieldRef.current.value}`);
    }
  };

  const handleSearchClick = () => {
    handleClick();
    if (showBackButton) {
      onBackClick();
      handleClick();
      setIsSearching(false);
    }
  };

  /* const handleClickClose = () => {
    handleClick();
    if (showBackButton) {
      onBackClick();
      handleClick();
      setIsSearching(false);
    }
  }; */

  return (
    <>
      <div className={classes.wrapperSearchEngine}>
        <div
          role="button"
          onClick={handleSearchClick}
          onKeyDown={handleSearchClick}
          tabIndex={0}
          className={classes.searchButton}
          style={{ cursor: isMd ? "pointer" : "default" }}
        >
          {!isMd ? (
            <SvgSearch
              className={classNames([
                classes.svgSearch,
                classes.svgSearchSearchEngine,
              ])}
            />
          ) : isSearching || showBackButton ? (
            <div className={classes.arrowRight}>
              <SvgArrowRight />
            </div>
          ) : (
            <SvgSearch
              className={classNames([
                classes.svgSearch,
                classes.svgSearchSearchEngine,
              ])}
            />
          )}
        </div>
        {(!isMd || isSearching || showBackButton) && (
          <CustomTextField
            key={searchText}
            className={classes.searchTextField}
            placeholder="Pesquisar..."
            onKeyPress={handleKeyPress}
            defaultValue={searchText}
            inputRef={textFieldRef}
            style={
              !isMd
                ? { visibility: "visible" }
                : isSearching || showBackButton
                ? { visibility: "visible" }
                : { visibility: "hidden" }
            }
          />
        )}
        {/* <div
          role="button"
          onClick={handleClickClose}
          onKeyDown={handleClickClose}
          tabIndex={0}
          className={
            isSearching || showBackButton
              ? classNames(classes.closeButton)
              : classNames(classes.closeButton, classes.hideOnSm)
          }
        >
          <SvgClose />
        </div> */}
      </div>
    </>
  );
};

export default memo(SearchEngineVariant);
