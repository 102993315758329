import React from "react";
// import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import researcher from "../../../assets/images/home/researcher.png";
import hands from "../../../assets/images/home/hands.png";
import executive from "../../../assets/images/home/executive.png";
import elipse from "../../../assets/images/home/elipse.png";
import destak from "../../../assets/images/home/destak.png";
import CustomTypography from "../../CustomTypography";
import CustomButton from "../../CustomButton";
import useStyles from "./styles";

const ExploreSection = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();

  return (
    <div className={classes.section}>
      <div className={classes.descriptionWrapper}>
        <CustomTypography variant="h1" color={theme.lowDark}>
          <img alt="executive" src={destak} className={classes.destakIcon} />
          {t("home.firstFold.title")}
        </CustomTypography>
        <CustomTypography variant="noticeBody" color={theme.textSecondary}>
          {t("home.firstFold.subtitle")}
        </CustomTypography>
        <div className={classes.exploreButton}>
          <CustomButton>{t("home.firstFold.exploreButton")}</CustomButton>
        </div>
      </div>
      <div className={classes.imagesWrapper}>
        <div>
          <img alt="researcher" src={researcher} />
        </div>
        <div>
          <img alt="hands" src={hands} />
        </div>
        <div>
          <img alt="executive" src={executive} />
          <img alt="elipse" src={elipse} className={classes.elipse} />
        </div>
      </div>
    </div>
  );
};

ExploreSection.propTypes = {};

ExploreSection.defaultProps = {};

export default ExploreSection;
