import { createUseStyles } from "react-jss";

const useStyles = createUseStyles(() => ({
  container: {
    width: "100%",
    height: 85,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}));

export default useStyles;
