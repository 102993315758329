import * as React from "react";

function Product(props) {
  return (
    <svg
      width="30"
      height="29"
      viewBox="0 0 30 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M29.2022 12.1951C29.7011 11.5587 29.9806 10.7791 29.999 9.9721C30.0175 9.1651 29.774 8.37368 29.3048 7.71528L24.8896 1.56421C24.5426 1.08054 24.0847 0.686276 23.5538 0.414212C23.0229 0.142147 22.4344 0.000140787 21.8373 0L8.1603 0C7.56316 0.000140787 6.97469 0.142147 6.44381 0.414212C5.91292 0.686276 5.45498 1.08054 5.10804 1.56421L0.692835 7.71528C0.224485 8.37423 -0.018092 9.1658 0.001052 9.9727C0.020196 10.7796 0.300042 11.5589 0.79912 12.1951L13.5331 28.1622C14.2837 29.1033 15.714 29.1034 16.4647 28.1623L29.2022 12.1951ZM9.06309 11.1996L12.5642 22.9368L3.19741 11.1996H9.06309ZM26.8002 11.1996L17.4396 22.9368L20.9345 11.1996H26.8002ZM18.3261 11.1996L14.9988 22.3731L11.6715 11.1996H18.3261ZM11.6552 8.7108L13.4408 2.4888H16.5568L18.3424 8.7108H11.6552ZM22.8551 3.0102L26.9465 8.7108H20.9445L19.1577 2.4888H21.8373C22.0364 2.48878 22.2327 2.53609 22.4097 2.62678C22.5867 2.71747 22.7395 2.84892 22.8551 3.0102ZM7.14246 3.0102C7.25815 2.84892 7.41086 2.71747 7.5879 2.62678C7.76494 2.53609 7.96118 2.48878 8.1603 2.4888H10.8399L9.05434 8.7108H3.05236L7.14246 3.0102Z"
        fill="#00A5A8"
      />
    </svg>
  );
}

export default Product;
