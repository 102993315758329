import React from "react";
import { useTheme } from "react-jss";
import PropTypes from "prop-types";
import { getStrapiMedia } from "../../../../services/hcms/media";
import CustomTypography from "../../../CustomTypography";
import ArticleInfo from "../../ArticleInfo";
import NextLink from "../../../NextLink";
import useStyles from "./styles";

export default function MoreArticle({
  article,
  columnist,
  hideImage,
  onClick,
}) {
  MoreArticle.propTypes = {
    article: PropTypes.shape().isRequired,
    columnist: PropTypes.shape().isRequired,
    hideImage: PropTypes.bool,
    onClick: PropTypes.func,
  };

  MoreArticle.defaultProps = {
    hideImage: false,
    onClick: null,
  };

  const theme = useTheme();
  const classes = useStyles({ theme });

  if (!article) {
    return null;
  }

  return (
    <div className={classes.wrapper}>
      {hideImage ? null : (
        <NextLink
          className={classes.link}
          to={`/agencia/artigo/${article.slug}`}
          onClick={onClick}
          replace
        >
          <img
            alt="highlightImg"
            src={getStrapiMedia(article.thumbnail)}
            className={classes.moreArticleImg}
          />
        </NextLink>
      )}
      <div className={classes.moreArticleTitle}>
        <NextLink
          className={classes.link}
          to={`/agencia/artigo/${article.slug}`}
          onClick={onClick}
          replace
        >
          <CustomTypography
            className={classes.titleTypography}
            variant="noticeTitle"
          >
            {article.title}
          </CustomTypography>
        </NextLink>
      </div>
      <ArticleInfo
        columnist={columnist}
        publishedAt={article.published}
        spaceBetween
      />
    </div>
  );
}
