/* eslint-disable no-unused-vars */
import { createUseStyles } from "react-jss";
import { breakpoints, defaultContainer } from "../../../constants/sizes";

const useStyles = createUseStyles((theme) => ({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "hidden",
  },
  bgSection: {
    position: "relative",
    width: "100%",
    maxWidth: 1280,
    display: "flex",
  },
  formWrapper: {
    position: "relative",
    width: "100%",
    maxWidth: 1280,
    display: "flex",
    [breakpoints.smd]: {
      flexDirection: "column",
    },
  },
  sectionWrapper: {
    position: "absolute",
    top: 0,
    width: "100%",
    maxWidth: defaultContainer.Width + defaultContainer.paddingHorizontal * 2,
    padding: `0px ${defaultContainer.paddingHorizontal}px`,
  },
  section: {
    backgroundColor: theme.background0,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: `0px ${defaultContainer.paddingHorizontal}px`,
  },
  form: {
    width: "100%",
    maxWidth: 560,
    [breakpoints.smd]: {
      maxWidth: "none",
    },
  },
  micorriza: {
    position: "fixed",
    height: 600,
    left: "50%",
    transform: "translateX(-50%)",
    zIndex: -2,
    userSelect: "none",
  },
  center404: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexFlow: "column",
    height: 350,
  },
  fifthFoldImg: {
    backgroundColor: "red",
    right: 0,
    maxWidth: 500,
    userSelect: "none",

    [breakpoints.smd]: {
      position: "static",
      marginBottom: 40,
    },
  },
  sideImage: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: 758,
    maxWidth: 516,
    zIndex: -1,
    [breakpoints.smd]: {
      display: "none",
    },
  },
  partnerImg: {
    position: "fixed",
    top: 60,
    marginLeft: -70,
    height: "90%",
    [breakpoints.smd]: {
      width: "100%",
    },
  },
  dividerWrapper: {
    width: "100%",
    marginTop: 24,
    marginBottom: 24,
  },
  textField: {
    width: "100%",
  },
  item: {
    paddingBottom: 8,
  },
  buttonWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    paddingTop: 20,
    paddingBottom: 20,
    "& .MuiButton-root": {
      width: 204,
      backgroundColor: theme.secondary0,
      color: theme.background0,
      "&:hover": {
        backgroundColor: theme.secondary1,
      },
    },
  },
}));

export default useStyles;
