import { createUseStyles } from "react-jss";
// import { breakpoints } from "../../../../constants/sizes";

const useStyles = createUseStyles((/* theme */) => ({
  button: {
    "&.MuiButton-root": {
      minWidth: 0,
      padding: 0,
    },
  },
}));

export default useStyles;
