import { createUseStyles } from "react-jss";
import { breakpointsHeight } from "../../../constants/sizes";
import northIcon from "../../../assets/icons/north.svg";
import plusIcon from "../../../assets/icons/plus.svg";
import minusIcon from "../../../assets/icons/minus.svg";

const useStyles = createUseStyles((theme) => ({
  map: {
    position: "relative",
    width: "100%",
    height: "100%",
    "& .mapboxgl-ctrl-bottom-right": {
      visibility: "hidden",
    },
    [breakpointsHeight.md]: {
      maxHeight: "none",
      height: "100%",
    },
  },
  navigationControl: {
    right: 0,
    bottom: 20,
    marginRight: 10,
    zIndex: 1,

    "& .mapboxgl-ctrl-group": {
      backgroundColor: "transparent",
      height: 200,
    },
    "& .mapboxgl-ctrl-group:not(:empty)": {
      boxShadow: "none",
    },
    "& .mapboxgl-ctrl button:not(:disabled):hover": {
      backgroundColor: theme.background1,
    },
    "& .mapboxgl-ctrl-zoom-in": {
      width: 35,
      height: 35,
      position: "absolute",
      marginTop: 51,
      backgroundColor: theme.background0,
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      border: "transparent",
      filter: `drop-shadow(2px 5px 3px ${theme.shadow3})`,
    },
    "& .mapboxgl-ctrl-zoom-out": {
      width: 35,
      height: 35,
      position: "absolute",
      marginTop: 85,
      backgroundColor: theme.background0,
      borderBottomLeftRadius: 5,
      borderBottomRightRadius: 5,
      border: "transparent",
      filter: `drop-shadow(2px 5px 3px ${theme.shadow3})`,
      "&:hover": {
        backgroundColor: "#000",
      },
    },
    "& .mapboxgl-ctrl-compass": {
      width: 35,
      height: 35,
      backgroundColor: theme.background0,
      borderRadius: 5,
      filter: "drop-shadow(2px 2px 3px #c7c7c7)",
    },
    "& .mapboxgl-ctrl button.mapboxgl-ctrl-compass .mapboxgl-ctrl-icon": {
      backgroundImage: `url(${northIcon})`,
    },
    "& .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon": {
      backgroundImage: `url(${plusIcon})`,
    },
    "& .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon": {
      backgroundImage: `url(${minusIcon})`,
    },
  },
  mapContent: {
    width: "100%",
    maxWidth: 1200,
    position: "absolute",
    display: "flex",
    justifyContent: "flex-end",
    userSelect: "none",
    bottom: 50,
    right: 0,
    zIndex: 1,
  },
  bottom: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: 10,
    "& .location-wrapper": {
      marginRight: 20,
    },
  },
}));

export default useStyles;
