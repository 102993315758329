import React from "react";
import { useTheme } from "react-jss";
import { useSelector } from "react-redux";
import CustomLink from "../../CustomLink";
import CustomTypography from "../../CustomTypography";
import Cooperative from "../../Icons/Cooperative";
import Location from "../../Icons/Location";
import CooperativeCorn from "../../Icons/CooperativeCorn";
import Spinner from "../../Spinner";
import useStyles from "./styles";

const ResultList = () => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { cooperatives, loadingCooperatives } = useSelector(
    (state) => state.cooperative
  );

  const formatProducts = (products) => {
    if (products.length > 0) {
      return products.map((product) => product.name).join(", ");
    }

    return "Sem produtos cadastrados";
  };

  return (
    <div className={classes.container}>
      <Spinner spinning={loadingCooperatives}>
        <>
          {cooperatives?.map((cooperative) => (
            <div key={cooperative.id} className="list-item">
              <div className={classes.itemsGrid}>
                <div className={classes.iconWrapper}>
                  <CooperativeCorn />
                </div>
                <div className={classes.infosWrapper}>
                  <CustomLink to={`/cooperativas/${cooperative.id}`}>
                    <CustomTypography
                      variant="body"
                      weight="bold"
                      color={theme.textSecondary}
                    >
                      {cooperative.name}
                    </CustomTypography>
                  </CustomLink>
                  <div className={classes.iconedRow}>
                    <Cooperative />
                    <CustomTypography
                      variant="smallText"
                      weight="bold"
                      color={theme.secondary0}
                    >
                      {formatProducts(cooperative.products)}
                    </CustomTypography>
                  </div>
                  <div className={classes.iconedRow}>
                    <Location />
                    <CustomTypography
                      variant="smallText"
                      weight="bold"
                      color={theme.secondary0}
                    >
                      {`${cooperative.city} - ${cooperative.state}`}
                    </CustomTypography>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      </Spinner>
    </div>
  );
};

export default ResultList;
