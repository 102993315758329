import { createUseStyles } from "react-jss";
import { breakpoints } from "../../../constants/sizes";

const useStyles = createUseStyles((/* theme */) => ({
  wrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  columnistAvatar: {
    width: 32,
    height: 32,
    marginRight: 8,
    borderRadius: "100%",
    objectFit: "cover",
    objectPosition: "center center",
  },
  hide: {
    [breakpoints.xsm]: {
      display: "none",
    },
  },
  link: {
    display: "flex",
    textDecoration: "none",
    "&:hover": {},
  },
}));

export default useStyles;
