import { createUseStyles } from "react-jss";
import { breakpoints } from "../../constants/sizes";

const useStyles = createUseStyles((theme) => ({
  tab: {
    "&.MuiTab-root": {
      minWidth: 0,
      textTransform: "capitalize",
      fontSize: 16,
      [breakpoints.sm]: {
        fontSize: 13,
      },
      "&.MuiTab-textColorInherit.Mui-selected": {
        color: theme.primary0,
        fontWeight: "700",
      },
    },
  },
}));

export default useStyles;
