import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((/* theme */) => ({
  content: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
  },
  hide: {
    height: 20,
  },
  wordcloudWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  totalPublication: {
    width: "100%",
  },
}));
export default useStyles;
