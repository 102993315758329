import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTheme } from "react-jss";
import { useTranslation } from "react-i18next";
import {
  ListItemIcon,
  ListItemText,
  IconButton,
  useMediaQuery,
} from "@material-ui/core";
import {
  TwitterShareButton,
  FacebookShareButton,
  LinkedinShareButton,
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CustomButton from "../../../CustomButton";
import StyledMenu from "../../../StyledMenu";
import StyledMenuItem from "../../../StyledMenuItem";
import SvgShare from "../../../Icons/Share";
import SvgTwitter from "../../../Icons/Twitter";
import SvgFacebook from "../../../Icons/Facebook";
import SvgLinkedin from "../../../Icons/Linkedin";
import SvgLink from "../../../Icons/Link";
import useStyles from "./styles";

/**
 * This component renders a share button styled for project.
 * @returns A share button
 */
const ShareButton = ({ onTwitterClick, onFacebookClick, onLinkedinClick }) => {
  ShareButton.propTypes = {
    onTwitterClick: PropTypes.func,
    onFacebookClick: PropTypes.func,
    onLinkedinClick: PropTypes.func,
  };
  ShareButton.defaultProps = {
    onTwitterClick: undefined,
    onFacebookClick: undefined,
    onLinkedinClick: undefined,
  };

  const theme = useTheme();
  const classes = useStyles({ theme });
  const isSm = useMediaQuery("(max-width: 600px)");
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {!isSm ? (
        <CustomButton
          variant="outlined"
          backgroundColor={theme.background0}
          startIcon={<SvgShare />}
          className={classes.shareButton}
          onClick={handleClick}
          pressed={anchorEl}
        >
          <>{t("agency.article.share")}</>
        </CustomButton>
      ) : (
        <IconButton onClick={handleClick} className={classes.shareIconButton}>
          <SvgShare />
        </IconButton>
      )}
      <StyledMenu
        id="share-button-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={classes.menuWrapper}
      >
        <TwitterShareButton url={window.location.href}>
          <StyledMenuItem onClick={onTwitterClick}>
            <div className={classes.buttonWrapper}>
              <div className={classes.iconWrapper}>
                <SvgTwitter />
              </div>
              <div className={classes.textButtonWrapper}>
                <ListItemText
                  primary={t("agency.article.shareButton.twitter")}
                />
              </div>
            </div>
          </StyledMenuItem>
        </TwitterShareButton>

        <FacebookShareButton url={window.location.href}>
          <StyledMenuItem onClick={onFacebookClick}>
            <div className={classes.buttonWrapper}>
              <div className={classes.iconWrapper}>
                <SvgFacebook />
              </div>
              <div className={classes.textButtonWrapper}>
                <ListItemText
                  primary={t("agency.article.shareButton.facebook")}
                />
              </div>
            </div>
          </StyledMenuItem>
        </FacebookShareButton>

        <LinkedinShareButton url={window.location.href}>
          <StyledMenuItem onClick={onLinkedinClick}>
            <div className={classes.buttonWrapper}>
              <div className={classes.iconWrapper}>
                <SvgLinkedin />
              </div>
              <div className={classes.textButtonWrapper}>
                <ListItemText
                  primary={t("agency.article.shareButton.linkedin")}
                />
              </div>
            </div>
          </StyledMenuItem>
        </LinkedinShareButton>
        <CopyToClipboard text={window.location.href}>
          <StyledMenuItem onClick={handleClose}>
            <ListItemIcon>
              <SvgLink />
            </ListItemIcon>
            <ListItemText primary={t("agency.article.shareButton.copyLink")} />
          </StyledMenuItem>
        </CopyToClipboard>
      </StyledMenu>
    </div>
  );
};

export default ShareButton;
