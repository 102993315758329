import React from "react";
import { useTheme } from "react-jss";
import useStyles from "./styles";

const Header = () => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <div className={classes.container}>
      <div className={classes.background}>
        <div className={classes.banner}>
          <div className={classes.content}>
            <h1>Módulo Castanha-do-Brasil</h1>
            <div className={classes.text}>
            <span>
              A castanha-do-brasil é um dos tesouros da Amazônia, gerando renda
              para comunidades, fortalecendo a bioeconomia e promovendo a
              conservação da floresta. No Módulo Castanha, você encontrará tudo
              sobre essa cadeia produtiva: cooperativas atuantes, pesquisas
              científicas, instituições de apoio e os especialistas que
              trabalham para impulsionar esse setor.
            </span>
       
            </div>
 
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
