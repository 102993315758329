import { createUseStyles } from "react-jss";
/* import { breakpoints, defaultContainer } from "../../../constants/sizes"; */

const useStyles = createUseStyles((/* theme */) => ({
  item: {
    height: "auto",
    marginBottom: 27,
  },
}));

export default useStyles;
