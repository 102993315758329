import { useState, useEffect } from "react";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

export function isEmpty(obj) {
  return !obj || Object.keys(obj).length === 0;
}
export function lengthIsGreaterThan(array, number) {
  return !isEmpty(array) && array.length > number;
}

export function nameFormat(name) {
  if (name.length < 30) return name;
  const nameArray = name.split(" ");
  return `${nameArray[0]} ${nameArray[nameArray.length - 1]}`;
}

export function urlToJson(urlQuery) {
  const jsonfiedQuery = JSON.parse(
    `{"${urlQuery.replace(/&/g, '","').replace(/=/g, '":"')}"}`,
    (key, value) => (key === "" ? value : decodeURIComponent(value))
  );
  return jsonfiedQuery;
}
export function hex2rgba(hex, alpha = 1) {
  const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
}

function hashCode(str) {
  // java String#hashCode
  let hash = 0;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < str.length; i++) {
    // eslint-disable-next-line no-bitwise
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
}

export function stringToHexColorParser(i) {
  // eslint-disable-next-line no-bitwise
  const c = (hashCode(i) & 0x00ffffff).toString(16).toUpperCase();

  return `#${"00000".substring(0, 6 - c.length) + c}`;
}

export function getInitials(insitute) {
  const portuguesArticles = [
    "o",
    "os",
    "a",
    "as",
    "um",
    "uns",
    "uma",
    "umas",
    "a",
    "ao",
    "aos",
    "à",
    "às",
    "de",
    "do",
    "dos",
    "da",
    "das",
    "dum",
    "duns",
    "duma",
    "dumas",
    "em",
    "no",
    "nos",
    "na",
    "nas",
    "num",
    "nuns",
    "numa",
    "numas",
    "por",
    "per",
    "pelo",
    "pelos",
    "pela",
    "pelas",
  ];
  return insitute
    .split(" ")
    .map((n) => (portuguesArticles.includes(n) ? "" : n[0]))
    .join("")
    .toUpperCase();
}
