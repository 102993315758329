/* eslint-disable no-unused-vars */
import { createUseStyles } from "react-jss";
import { breakpoints, defaultContainer } from "../../constants/sizes";

const useStyles = createUseStyles((theme) => ({
  section: {
    width: "100%",
  },
  content: {
    width: defaultContainer.Width + defaultContainer.paddingHorizontal * 2,
    padding: `0px ${defaultContainer.paddingHorizontal}px`,
  },
  contentWrapperGray: {
    width: "100%",
    backgroundColor: theme.background1,
  },
  contentWrapper: {
    width: "100%",
  },
  centralize: {
    display: "flex",
    justifyContent: "center",
  },
}));

export default useStyles;
