import * as React from "react";

function Circles(props) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9999 6.66699C16.2075 6.66699 13.1331 9.74136 13.1331 13.5338C13.1331 17.3262 16.2075 20.4006 19.9999 20.4006C23.7923 20.4006 26.8667 17.3262 26.8667 13.5338C26.8667 9.74136 23.7923 6.66699 19.9999 6.66699ZM16.0224 13.5338C16.0224 11.3371 17.8032 9.55629 19.9999 9.55629C22.1966 9.55629 23.9774 11.3371 23.9774 13.5338C23.9774 15.7305 22.1966 17.5113 19.9999 17.5113C17.8032 17.5113 16.0224 15.7305 16.0224 13.5338ZM11.8668 20.7646C8.07437 20.7646 5 23.8389 5 27.6313C5 31.4238 8.07437 34.4981 11.8668 34.4981C15.6592 34.4981 18.7336 31.4238 18.7336 27.6313C18.7336 23.8389 15.6592 20.7646 11.8668 20.7646ZM28.1332 20.7646C24.3408 20.7646 21.2664 23.8389 21.2664 27.6313C21.2664 31.4238 24.3408 34.4981 28.1332 34.4981C31.9256 34.4981 35 31.4238 35 27.6313C35 23.8389 31.9256 20.7646 28.1332 20.7646ZM7.8893 27.6313C7.8893 25.4346 9.67008 23.6539 11.8668 23.6539C14.0635 23.6539 15.8443 25.4346 15.8443 27.6313C15.8443 29.8281 14.0635 31.6088 11.8668 31.6088C9.67008 31.6088 7.8893 29.8281 7.8893 27.6313ZM24.1557 27.6313C24.1557 25.4346 25.9365 23.6539 28.1332 23.6539C30.3299 23.6539 32.1107 25.4346 32.1107 27.6313C32.1107 29.8281 30.3299 31.6088 28.1332 31.6088C25.9365 31.6088 24.1557 29.8281 24.1557 27.6313Z"
        fill="#00A5A8"
      />
    </svg>
  );
}

export default Circles;
