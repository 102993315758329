import { createUseStyles } from "react-jss";
import { breakpoints, defaultContainer } from "../../../constants/sizes";

const useStyles = createUseStyles((/* theme */) => ({
  content: {
    position: "relative",
    width: "100%",
    maxWidth: defaultContainer.Width + defaultContainer.paddingHorizontal * 2,
    padding: `0px ${defaultContainer.paddingHorizontal}px`,
  },
  paddingBottom: {
    paddingBottom: 30,
  },
  mapContainer: {
    width: "40vw",
    minWidth: 290,
    [breakpoints.md]: {
      width: "100%",
    },
  },
}));

export default useStyles;
