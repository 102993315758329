import { createUseStyles } from "react-jss";
import { breakpoints, defaultContainer } from "../../constants/sizes";

const useStyles = createUseStyles(() => ({
  fold: {
    position: "relative",
    width: "100%",
    maxWidth: defaultContainer.Width + defaultContainer.paddingHorizontal * 2,
    padding: `0px ${defaultContainer.paddingHorizontal}px`,
  },
  fifthFoldTitle: {
    marginBottom: 62,
  },
  fifthFoldWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: 70,
    paddingBottom: 70,

    [breakpoints.md]: {
      flexDirection: "column",
      marginTop: 25,
      marginBottom: 40,
    },
  },
  fifthFoldButton: {
    marginTop: 60,

    [breakpoints.md]: {
      display: "flex",
      flexDirection: "horizontal",
      justifyContent: "center",
    },
  },
  href: {
    textDecoration: "none",
  },
  footerImage: {
    justifySelf: "center",
    alignSelf: "center",
    userSelect: "none",
  },
  partnersSection: {
    position: "relative",
    width: "100%",
    maxWidth: defaultContainer.Width + defaultContainer.paddingHorizontal * 2,
    padding: `0px ${defaultContainer.paddingHorizontal}px`,
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
}));

export default useStyles;
