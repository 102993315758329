import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
import rootReducer from "./rootReducer";

const isDev = process.env.NODE_ENV === "development";

const store = createStore(
  rootReducer,
  isDev ? applyMiddleware(logger, thunk) : applyMiddleware(thunk)
);

export default store;
