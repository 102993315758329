import * as React from "react";

function SvgCheck(props) {
  return (
    <svg
      width="41"
      height="33"
      viewBox="0 0 41 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M34.647 1.05654C35.3417 0.395124 36.2662 0.0294693 37.2254 0.0367271C38.1846 0.0439849 39.1035 0.423589 39.7882 1.09544C40.4728 1.76728 40.8697 2.67883 40.895 3.63772C40.9204 4.59661 40.5722 5.52786 39.924 6.23494L20.2461 30.8447C19.9077 31.2092 19.4993 31.5016 19.0454 31.7047C18.5914 31.9077 18.1011 32.0171 17.6039 32.0263C17.1067 32.0355 16.6127 31.9443 16.1515 31.7582C15.6903 31.5722 15.2714 31.295 14.9197 30.9433L1.87014 17.8938C1.50673 17.5551 1.21525 17.1468 1.01308 16.6931C0.810917 16.2393 0.70221 15.7495 0.693447 15.2529C0.684684 14.7562 0.776045 14.2629 0.962078 13.8023C1.14811 13.3418 1.42501 12.9234 1.77624 12.5721C2.12748 12.2209 2.54587 11.944 3.00644 11.758C3.46701 11.5719 3.96034 11.4806 4.45699 11.4893C4.95364 11.4981 5.44343 11.6068 5.89716 11.809C6.35088 12.0111 6.75924 12.3026 7.09786 12.666L17.4251 22.9883L34.5533 1.16504C34.5841 1.12707 34.6171 1.09084 34.6519 1.05654H34.647Z"
        fill="#000000"
      />
    </svg>
  );
}

export default SvgCheck;
