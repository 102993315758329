import {
  ADD_CATEGORY,
  REMOVE_CATEGORY,
  TOOGLE_ENABLED,
  RESET_FILTERS,
} from "./resultFilterTypes";
import categoryTypes from "../../components/Landing/SearchContainer/CategoryTabs/CategoryTypes";

const appliedCategoriesCount = (categoryFilter) => {
  let count = 0;
  categoryTypes.list.forEach((category) => {
    if (categoryFilter[category]) count += 1;
  });
  return count;
};

const initialState = {
  categoryFilter: {
    [categoryTypes.ALL]: true,
    [categoryTypes.INSTITUTIONS]: true,
    [categoryTypes.PUBLICATIONS]: true,
    [categoryTypes.RESEARCHER]: true,
  },
  enabled: false,
  appliedCategoriesCount: 4,
};

const changeAll = (value) => ({
  [categoryTypes.ALL]: value,
  [categoryTypes.INSTITUTIONS]: value,
  [categoryTypes.PUBLICATIONS]: value,
  [categoryTypes.RESEARCHER]: value,
});

const buildFilter = (oldFilterState, payload, value) => {
  if (payload === categoryTypes.ALL)
    return {
      categoryFilter: { ...changeAll(value) },
      appliedCategoriesCount: value ? 4 : 0,
    };

  const categoryFilter = {
    ...oldFilterState,
    [categoryTypes.ALL]: false,
    [payload]: value,
  };

  const count = appliedCategoriesCount(categoryFilter);

  return { categoryFilter, appliedCategoriesCount: count };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CATEGORY:
      return {
        ...state,
        ...buildFilter(state.categoryFilter, action.payload, true),
      };
    case REMOVE_CATEGORY:
      return {
        ...state,
        ...buildFilter(state.categoryFilter, action.payload, false),
      };
    case TOOGLE_ENABLED:
      return {
        ...state,
        enabled: !state.enabled,
      };
    case RESET_FILTERS:
      return initialState;
    default:
      return state;
  }
};
export default reducer;
