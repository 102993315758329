import React, { memo } from "react";
import PropTypes from "prop-types";
import { HeaderVariants } from "../variants";
import SearchEngineVariant from "./SearchEngineVariant";
import AgencyVariant from "./AgencyVariant";

const SearchBar = ({
  isSearching,
  handleClick,
  currentSearchText,
  variant,
  onBackClick,
  showBackButton,
  setIsSearching,
}) => {
  SearchBar.propTypes = {
    isSearching: PropTypes.bool.isRequired,
    handleClick: PropTypes.func.isRequired,
    currentSearchText: PropTypes.string,
    variant: PropTypes.string,
    onBackClick: PropTypes.func,
    showBackButton: PropTypes.bool,
    setIsSearching: PropTypes.func,
  };

  SearchBar.defaultProps = {
    currentSearchText: "",
    variant: "",
    showBackButton: false,
    onBackClick: undefined,
    setIsSearching: undefined,
  };
  switch (variant) {
    case HeaderVariants.SEARCH_ENGINE:
      return (
        <SearchEngineVariant
          isSearching={isSearching}
          currentSearchText={currentSearchText}
          handleClick={handleClick}
          onBackClick={onBackClick}
          showBackButton={showBackButton}
          setIsSearching={setIsSearching}
        />
      );
    default:
      return (
        <AgencyVariant
          isSearching={isSearching}
          currentSearchText={currentSearchText}
          handleClick={handleClick}
          onBackClick={onBackClick}
          showBackButton={showBackButton}
          setIsSearching={setIsSearching}
        />
      );
  }
};

export default memo(SearchBar);
