import React, { useEffect, useState } from "react";
import { useTheme } from "react-jss";
import useSWR from "swr";
import { useTranslation } from "react-i18next";
import Highlight from "../../../components/Agency/Home/Highlight";
import Recently from "../../../components/Agency/Home/Recently";
import api from "../../../services/hcms/api";
import useStyles from "./styles";
import GridDisplayer from "../../../components/Multimedia/GridDisplayer";
import VideoInfo from "../../../components/Multimedia/VideoInfo";
import MoreVideoInfo from "../../../components/Multimedia/MoreVideoInfo";
import ContentList from "../../../components/Agency/ContentList";
import Banner from "../../../components/Agency/Banner";
import PodcastViewer from "../../../components/Multimedia/PodcastViewer";
import SvgHeadphone from "../../../components/Icons/Headphone";
import CustomTypography from "../../../components/CustomTypography";
import SectionLine from "../../../components/SectionLine";
import SectionTitle from "../../../components/SectionTitle";
import PodcastSkeleton from "../../../components/Skeleton/PodcastSkeleton";
import { isEmpty } from "../../../util/hooks";

/**
 * This component represents Agency homepage.
 * @returns Home page
 */
export default function Home() {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { t } = useTranslation();
  const [columnist, setColumnist] = useState();
  const [loading, setLoading] = useState(true);

  /**
   * Fetch highlight article.
   */
  const { data: highlight } = useSWR("/highlight", async (swrRoute) => {
    const response = await api.get(swrRoute);
    const article = response.data.articles;
    if (!isEmpty(article)) return article;
    return null;
  });

  useEffect(() => {
    if (highlight) {
      api
        .get(`/columnists/${highlight.columnist}`)
        .then(({ data }) => {
          setColumnist(data);
        })
        .catch(() => {});
    }
  }, [highlight]);

  /**
   * Fetch recently articles.
   */
  const { data: recently } = useSWR("/articles/recently", async (swrRoute) => {
    const response = await api.get(swrRoute);
    return response.data;
  });

  const { data: videos } = useSWR(
    "/videos?_sort=date:DESC&_limit=2",
    async (swrRoute) => {
      const response = await api.get(swrRoute);
      setLoading(false);
      return response.data;
    }
  );

  const { data: podcasts } = useSWR(
    "/podcasts?_sort=date:DESC&_limit=2",
    async (swrRoute) => {
      const response = await api.get(swrRoute);
      return response.data;
    }
  );

  return (
    <>
      <GridDisplayer
        leftElement={<Highlight article={highlight} columnist={columnist} />}
        rightElement={<Recently articles={recently} />}
      />

      <Banner />

      <SectionLine />

      <GridDisplayer
        leftElement={
          <>
            <SectionTitle
              title={t("multimedia.title")}
              righLink={{
                pathName: "/agencia/multimidia/videos",
                text: t("multimedia.recently.viewAll"),
              }}
              showArrow
            />
            <div className={classes.item}>
              <VideoInfo data={videos && videos[0]} />
            </div>
            <div className={classes.item}>
              <MoreVideoInfo data={videos && videos[1]} loading={loading} />
            </div>
          </>
        }
        rightElement={
          <>
            <ContentList
              grayBackground
              title={
                <CustomTypography weight="bold" variant="titleVerticalList">
                  {t("multimedia.podcasts.titleSingular")}
                </CustomTypography>
              }
              leftTitleIcon={<SvgHeadphone />}
              skeleton={
                <>
                  <PodcastSkeleton />
                  <PodcastSkeleton />
                </>
              }
              isLoading={Boolean(!podcasts)}
            >
              {podcasts &&
                podcasts.map((podcast) => (
                  <div key={podcast.id} className={classes.item}>
                    <PodcastViewer data={podcast} />
                  </div>
                ))}
            </ContentList>
          </>
        }
      />
      <Banner />
    </>
  );
}
