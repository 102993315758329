import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import ReactGA from "react-ga";
import { Provider } from "react-redux";
import Home from "./pages/Home";
import Agency from "./pages/Agency";
import AgencySearchPage from "./pages/Agency/SearchPage";
import SearchAreaRoot from "./pages/SearchAreaRoot";
import Register from "./pages/Auth/Register";
// import Login from "./pages/Auth/Login";
import NotFound from "./pages/NotFound";
// import Password from "./pages/Auth/Password";
import ContactUs from "./pages/ContactUs";
import Cooperatives from "./pages/Cooperatives";
import Create from "./pages/Cooperatives/Create";
import Mission from "./pages/Mission";
import TermsUse from "./pages/TermsUse";
// import RoutesUnauthenticated from "./components/Routes/Unauthenticated";
// import AuthContext from "./contexts/auth";
import { AuthProvider } from "./contexts/auth";
import store from "./redux/store";

ReactGA.initialize(process.env.REACT_APP_ANALYTICS_TOKEN);

function Routes() {
  // const { isAuthenticated, successModalIsOpen } = useContext(AuthContext);
  return (
    <Router>
      <AuthProvider>
        <Provider store={store}>
          <Switch>
            <Route path="/buscador" component={SearchAreaRoot} />
            <Route path="/busca/:query" component={AgencySearchPage} />
            <Route path="/agencia" component={Agency} />
            <Route path="/cadastro" component={Register} />
            <Route path="/contato" component={ContactUs} />
            <Route path="/missao" component={Mission} />
            <Route path="/cooperativas/criar" component={Create} />
            <Route path="/cooperativas" component={Cooperatives} />
            <Route path="/termos-uso" component={TermsUse} />

            {/* <Route path="/login" component={Login} />
          <Route path="/senha" component={Password} /> */}
            <Route exact path="/" component={Home} />
            <Route path="/404" component={NotFound} />
            <Redirect from="/:id" to="/buscador/pesquisador/:id" />
            <Redirect to="/404" />
          </Switch>
        </Provider>
      </AuthProvider>
    </Router>
  );
}

export default Routes;
