import { createUseStyles } from "react-jss";
import {
  defaultContainer,
  defaultContentContainer,
  breakpoints,
} from "../../../constants/sizes";

const useStyles = createUseStyles((theme) => ({
  sectionWrapper: {
    backgroundColor: theme.background0,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: `0px ${defaultContainer.paddingHorizontal}px`,
    paddingLeft: defaultContentContainer.paddingLeft,
    paddingRight: defaultContentContainer.paddingLeft,
    paddingTop: defaultContentContainer.paddingTop,
    paddingBottom: 130,
    columnGap: 20,
    [breakpoints.md]: {
      flexDirection: "column",
      padding: defaultContentContainer.paddingSm,
    },
  },
  textsWrapper: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  searchBar: {
    marginTop: 50,
    marginBottom: 60,
  },
  title: {
    fontWeight: 700,
    fontSize: 20,
    color: theme.textPrimary,
  },
  subTitle: {
    fontWeight: 400,
    fontSize: 16,
    color: theme.textSecondary,
  },
  searchBarTitle: {
    marginBottom: 10,
    fontSize: 18,
  },
  trendWrapper: {
    "& .navigators-wrapper": {
      display: "none",
    },
  },
  trendCard: {
    padding: 10,
    minWidth: 140,
    height: 80,
    border: `1px solid ${theme.stroke}`,
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  trendsTitle: {
    marginBottom: 15,
    display: "flex",
    columnGap: 15,
    alignItems: "center",
    "& svg": {
      transform: "scale(1.5)",
    },
    "& path": {
      fill: theme.primary2,
    },
  },
  researchesWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    "& .research-card-root:nth-child(2)": {
      marginTop: 80,
    },
  },
  carousel: {
    width: "100%",
  },
  cardsWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
}));

export default useStyles;
