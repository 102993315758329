import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import HeaderButton from "../HeaderBase/HeaderButton";
import logoRhisaImg from "../../../assets/images/logo_rhisa.png";
import ButtonWithPopup from "../../ButtonWithPopup";
import CustomLink from "../../CustomLink";
import ArrowDown from "../../Icons/ArrowDown";
import Whatsapp from "../../Icons/Whatsapp";
import CustomTypography from "../../CustomTypography";
import useStyles from "./styles";

const HeaderButtons = ({ selectedButton }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const classes = useStyles({
    theme,
  });

  return (
    <div className={classes.buttonsWrapper}>
      <div className={classes.logoWrapper}>
        <a href="/">
          <img alt="RHISA" src={logoRhisaImg} className={classes.logo} />
        </a>
      </div>
      <div className={classes.headerItem}>
        <HeaderButton
          isSelected={selectedButton.landing}
          text={t("header.start")}
          url="/"
        />
      </div>
      <div className={classes.headerItem}>
        <ButtonWithPopup
          filterButton={
            <div className={classes.aboutUsButtonLabel}>
              <div>Sobre nós </div>
              <ArrowDown />
            </div>
          }
          hidePopupTitle
          className={classes.aboutUsButton}
          popperClassName={classes.aboutUsContent}
        >
          <div className={classes.aboutUsButtons}>
            <div className="popup-button">
              <CustomLink to="/">
                <CustomTypography
                  variant="searchResultTitle"
                  color={theme.textSecondary}
                >
                  Quem somos
                </CustomTypography>
              </CustomLink>
              <CustomTypography variant="body" color={theme.secondary0}>
                Conheça a Rede RHISA
              </CustomTypography>
            </div>
            <div className="popup-button">
              <CustomLink to="/contato">
                <CustomTypography
                  variant="searchResultTitle"
                  color={theme.textSecondary}
                >
                  Envie um e-mail
                </CustomTypography>
              </CustomLink>
              <CustomTypography variant="body" color={theme.secondary0}>
                Entre em contato conosco
              </CustomTypography>
            </div>
            <div className="popup-button">
              <CustomLink to="/missao">
                <CustomTypography
                  variant="searchResultTitle"
                  color={theme.textSecondary}
                >
                  Missão, Visão e Valores
                </CustomTypography>
              </CustomLink>
              <CustomTypography variant="body" color={theme.secondary0}>
                O que guia nossos objetivos
              </CustomTypography>
            </div>
            <div className="popup-button">
              <CustomLink to="/contato">
                <CustomTypography
                  variant="searchResultTitle"
                  color={theme.textSecondary}
                >
                  Imprensa
                </CustomTypography>
              </CustomLink>
              <CustomTypography variant="body" color={theme.secondary0}>
                Fale sobre a Rede RHISA
              </CustomTypography>
            </div>
            <div className="popup-button">
              <a href="/#partners">
                <CustomTypography
                  variant="searchResultTitle"
                  color={theme.textSecondary}
                >
                  Nossos Patrocinadores
                </CustomTypography>
              </a>
              <CustomTypography variant="body" color={theme.secondary0}>
                Parceiros que apoiam o nosso trabalho
              </CustomTypography>
            </div>
          </div>
        </ButtonWithPopup>
      </div>
      <div className={classes.headerItem}>
        <ButtonWithPopup
          filterButton={
            <div
              className={classes.aboutUsButtonLabel}
              style={{ minWidth: 100 }}
            >
              <div>{t("header.rhisaNet")} </div>
              <ArrowDown />
            </div>
          }
          hidePopupTitle
          className={classes.aboutUsButton}
          popperClassName={classes.singePopperContent}
        >
          <div className={classes.aboutUsButtons}>
            <div className="popup-button">
              <CustomLink to="/buscador?q=&category=RESEARCHER">
                <CustomTypography
                  variant="searchResultTitle"
                  color={theme.textSecondary}
                >
                  Pesquisadores
                </CustomTypography>
              </CustomLink>
              <CustomTypography variant="body" color={theme.secondary0}>
                Conheça nossa rede de pesquisadores
              </CustomTypography>
            </div>
            <div className="popup-button">
              <CustomLink to="/buscador?q=&category=PUBLICATIONS">
                <CustomTypography
                  variant="searchResultTitle"
                  color={theme.textSecondary}
                >
                  Publicações
                </CustomTypography>
              </CustomLink>
              <CustomTypography variant="body" color={theme.secondary0}>
                Acesse publicações e artigos
              </CustomTypography>
            </div>
            <div className="popup-button">
              <CustomLink to="/buscador?q=Temas&category=ALL">
                <CustomTypography
                  variant="searchResultTitle"
                  color={theme.textSecondary}
                >
                  Temas
                </CustomTypography>
              </CustomLink>
              <CustomTypography variant="body" color={theme.secondary0}>
                Encontre conteúdos em variados temas
              </CustomTypography>
            </div>
            <div className="popup-button">
              <CustomLink to="/cooperativas">
                <CustomTypography
                  variant="searchResultTitle"
                  color={theme.textSecondary}
                >
                  Cooperativas
                </CustomTypography>
              </CustomLink>
              <CustomTypography variant="body" color={theme.secondary0}>
                Dashboard de cooperativas da Região Amazônica
              </CustomTypography>
            </div>
            <div className="popup-button">
              <a
                href={`${process.env.REACT_APP_RHISA_API_URL}docs`}
                target="_blank"
                rel="noreferrer"
              >
                <CustomTypography
                  variant="searchResultTitle"
                  color={theme.textSecondary}
                >
                  Documentação da API
                </CustomTypography>
              </a>
              <CustomTypography variant="body" color={theme.secondary0}>
                Saiba como usar nossa API pública
              </CustomTypography>
            </div>
            <div className="popup-button">
              <a
                href={`${process.env.REACT_APP_URL}/Boletim Rede RHISA Ano 1.pdf`}
                target="_blank"
                rel="noreferrer"
              >
                <CustomTypography
                  variant="searchResultTitle"
                  color={theme.textSecondary}
                >
                  Boletim
                </CustomTypography>
              </a>
              <CustomTypography variant="body" color={theme.secondary0}>
                Acesse o boletim do ano 1 da Rede Rhisa
              </CustomTypography>
            </div>
          </div>
        </ButtonWithPopup>
      </div>
      <div className={classes.headerItem}>
        <ButtonWithPopup
          filterButton={
            <div
              className={classes.aboutUsButtonLabel}
              style={{ minWidth: 130 }}
            >
              <div>{t("header.agency")}</div>
              <ArrowDown className={classes.arrowMenu} />
            </div>
          }
          hidePopupTitle
          className={classes.aboutUsButton}
          popperClassName={classes.singePopperContent}
        >
          <div className={classes.aboutUsButtons}>
            <div className="popup-button">
              <CustomLink to="/agencia">
                <CustomTypography
                  variant="searchResultTitle"
                  color={theme.textSecondary}
                >
                  {t("agency.news")}
                </CustomTypography>
              </CustomLink>
              <CustomTypography variant="body" color={theme.secondary0}>
                Atualizações sobre a Região Amazônica
              </CustomTypography>
            </div>
            <div className="popup-button">
              <CustomLink to="/agencia/multimidia">
                <CustomTypography
                  variant="searchResultTitle"
                  color={theme.textSecondary}
                >
                  {t("multimedia.title")}
                </CustomTypography>
              </CustomLink>
              <CustomTypography variant="body" color={theme.secondary0}>
                Assista conteúdos relacionados à Rede RHISA
              </CustomTypography>
            </div>
          </div>
        </ButtonWithPopup>
      </div>
      <div className={classes.headerItem}>
        <HeaderButton
          isSelected={selectedButton.contactUs}
          component={
            <div className={classes.contactUsWrapper}>
              <div>{t("header.contactUs")}</div>
            </div>
          }
          url="/contato"
          leftIcon={<Whatsapp />}
        />
      </div>
    </div>
  );
};

HeaderButtons.propTypes = {
  selectedButton: PropTypes.shape(),
};

HeaderButtons.defaultProps = {
  selectedButton: null,
};

export default HeaderButtons;
