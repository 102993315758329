import * as React from "react";

function Eye(props) {
  return (
    <svg
      width="34"
      height="24"
      viewBox="0 0 34 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 20.4256C11.1008 20.4256 6.03776 16.9479 3.91782 11.9997C6.03776 7.05143 11.1008 3.57375 17 3.57375C22.8993 3.57375 27.9623 7.05143 30.0823 11.9997C27.9623 16.9479 22.8993 20.4256 17 20.4256ZM17 0.333008C24.7913 0.333008 31.3941 5.22973 33.6667 11.9997C31.3941 18.7696 24.7913 23.6663 17 23.6663C9.20878 23.6663 2.60595 18.7696 0.333374 11.9997C2.60595 5.22973 9.20878 0.333008 17 0.333008ZM13.2958 11.9997C13.2958 10.0309 14.9543 8.43486 17.0001 8.43486C19.0459 8.43486 20.7044 10.0309 20.7044 11.9997C20.7044 13.9685 19.0459 15.5645 17.0001 15.5645C14.9543 15.5645 13.2958 13.9685 13.2958 11.9997ZM17.0001 5.19412C13.0944 5.19412 9.92825 8.24107 9.92825 11.9997C9.92825 15.7583 13.0944 18.8052 17.0001 18.8052C20.9058 18.8052 24.072 15.7583 24.072 11.9997C24.072 8.24107 20.9058 5.19412 17.0001 5.19412Z"
        fill="#00A5A8"
      />
    </svg>
  );
}

export default Eye;
