import { createUseStyles } from "react-jss";
import { hex2rgba } from "../../../util/hooks";

const useStyles = createUseStyles(() => ({
  contentWrapper: {
    backgroundColor: (props) => `${hex2rgba(props.color, 0.25)}`,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 2,
    paddingBottom: 2,
    fontWeight: "bold",
    color: (props) => props.color,
    borderRadius: 5,
    textTransform: "uppercase",
    marginRight: 5,
  },
  content: {
    color: "black",
  },
}));

export default useStyles;
