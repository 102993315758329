import React from "react";
import { useTheme } from "react-jss";
import { useHistory } from "react-router-dom";

import Button from "@material-ui/core/Button";

import { useTranslation } from "react-i18next";
import CustomButton from "../../../CustomButton";
import CustomTypography from "../../../CustomTypography";
import useStyles from "./styles";

const SectionHeader = () => {
  const theme = useTheme();
  const history = useHistory();
  const classes = useStyles(theme);
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <div className={classes.titleContainer}>
        <CustomTypography variant="subHeaderBody" color={theme.textSecondary}>
          Cooperativas Cadastradas
        </CustomTypography>
        <Button
          className={classes.buttonAccept}
          type="button"
          onClick={() => {
            history.push("cooperativas/criar");
          }}
          variant="contained"
        >
          Cadastrar
        </Button>
      </div>
      <CustomButton color={theme.background0} style={{ display: "none" }}>
        {t("forms.doSignUp")}
      </CustomButton>
    </div>
  );
};

export default SectionHeader;
