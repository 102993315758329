import React, { useState, useEffect } from "react";
import { useTheme } from "react-jss";
import useSWR from "swr";
import { useTranslation } from "react-i18next";
import useStyles from "./styles";
import api from "../../../../services/hcms/api";
import SectionLine from "../../../../components/SectionLine";
import GridDisplayer from "../../../../components/Multimedia/GridDisplayer";
import VideoInfo from "../../../../components/Multimedia/VideoInfo";
import CustomTypography from "../../../../components/CustomTypography";
import Breadcrumb from "../../../../components/Agency/Breadcrumb";
import LoadMoreButton from "../../../../components/LoadMoreButton";
import ContentListSkeleton from "../../../../components/Skeleton/ContentListSkeleton";
import { isEmpty } from "../../../../util/hooks";

/**
 * This component represents Agency Multimedia Videos Page.
 * @returns Multimedia Multimedia Videos Page
 */
export default function Videos() {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [videos, setVideos] = useState([]);
  const [isLoadEnded, setisLoadEnded] = useState(false);
  const [isLoading, setisLoading] = useState(true);

  /**
   * Fetch recently videos.
   */
  const { data: recently } = useSWR(
    "/videos?_sort=id:DESC&_limit=2",
    async (swrRoute) => {
      const response = await api.get(swrRoute);
      return response.data;
    }
  );

  const { data: moreVideos } = useSWR(
    `/videos?_limit=6&_start=${page * 6}`,
    async (swrRoute) => {
      const response = await api.get(swrRoute);
      setisLoading(false);
      return response.data;
    }
  );

  function loadVideos() {
    if (moreVideos) {
      setVideos((oldVideos) => {
        const oldLength = oldVideos.length;
        const newLength = oldLength + moreVideos.length;
        setisLoadEnded(newLength - oldLength < 6);
        if (oldLength === newLength) return oldVideos;
        return [...oldVideos, ...moreVideos];
      });
    }
  }

  useEffect(() => {
    loadVideos();
  }, [moreVideos]);

  function loadMore() {
    setisLoading(true);
    setPage((oldPageValue) => oldPageValue + 1);
  }

  return (
    <>
      <div className={classes.section}>
        <Breadcrumb
          preview={[
            { link: "/agencia/multimidia", title: t("multimedia.title") },
          ]}
          current={t("multimedia.videos.title")}
          hideOnSmall={false}
        />
      </div>

      <GridDisplayer
        title={t("multimedia.videos.moreRecently")}
        leftElement={<VideoInfo data={recently ? recently[0] : recently} />}
        rightElement={<VideoInfo data={recently ? recently[1] : recently} />}
      />

      <SectionLine />

      <div className={classes.section}>
        <CustomTypography style={{ marginBottom: 16 }} variant="h3">
          {t("multimedia.videos.all")}
        </CustomTypography>
        {!isEmpty(videos) ? (
          <div className={classes.videosWrapper}>
            {videos.map((video) => (
              <div key={video.id} className={classes.video}>
                <VideoInfo data={video} miniThumb />
              </div>
            ))}
          </div>
        ) : (
          <ContentListSkeleton />
        )}
      </div>
      <LoadMoreButton
        isLoadEnded={isLoadEnded}
        loadEndedMessage={t("multimedia.videos.pageEnded")}
        onClick={() => loadMore()}
        visible={!isLoading}
        isLoading={isLoading}
      />
    </>
  );
}
