import { createUseStyles } from "react-jss";
import { breakpoints, defaultContainer } from "../../../constants/sizes";

const useStyles = createUseStyles((theme) => ({
  content: {
    maxWidth: defaultContainer.Width + defaultContainer.paddingHorizontal * 2,
    paddingBottom: 20,
  },
  alertPaper: {
    paddingBottom: 20,
    [breakpoints.sm]: {
      display: "none",
    },
  },
  filter: {
    paddingBottom: 34,
    [breakpoints.sm]: {
      paddingBottom: 15,
    },
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "50vh",
  },
  buttonWrapper: {
    paddingTop: 20,
    display: "flex",
    justifyContent: "center",
  },
  button: {
    width: 190,
    "& path": {
      fill: theme.background0,
    },
    [breakpoints.sm]: {
      "& div": {
        display: "none",
      },
    },
    "&.MuiButton-root": {
      minWidth: 50,
    },

    "& .MuiButton-startIcon": {
      [breakpoints.sm]: {
        margin: 0,
      },
    },
  },
}));

export default useStyles;
