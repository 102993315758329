import React, { useRef } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useTheme } from "react-jss";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import InputBase from "@material-ui/core/InputBase";
import CustomButton from "../../CustomButton";
import SearchButton from "../../Buttons/SearchButton";
import { buildCooperativeUrl } from "../../../util/cooperative";
import useStyles from "./styles";

const SearchBar = ({ baseUrl, initialValue }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { t } = useTranslation();
  const textFieldRef = useRef();
  const history = useHistory();

  const handleSearch = () => {
    history.push(
      buildCooperativeUrl(baseUrl, {
        q: textFieldRef.current.value,
      })
    );
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <div className={classNames([classes.container, "search-bar"])}>
      <div className={classes.searchBarWrapper}>
        <SearchButton />
        <InputBase
          key={`input-${initialValue}`}
          defaultValue={initialValue}
          className={classes.input}
          placeholder={t("cooperatives.searchPlaceholder")}
          inputRef={textFieldRef}
          onKeyPress={handleKeyPress}
        />
      </div>

      <CustomButton color={theme.background0} onClick={handleSearch}>
        {t("search")}
      </CustomButton>
    </div>
  );
};

SearchBar.propTypes = {
  baseUrl: PropTypes.string,
  initialValue: PropTypes.string,
};
SearchBar.defaultProps = {
  baseUrl: null,
  initialValue: null,
};

export default SearchBar;
