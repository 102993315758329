import React, { useEffect, useState, memo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
import SearchBar from "./SearchBar";
import MobileMenu from "./MobileMenu";
import CustomButton from "../CustomButton";
import { HeaderVariants } from "./variants";
import useStyles from "./styles";

import HeaderButtons from "../V2/HeaderButtons";
import TranslationButton from "./TranslationButton";
// import AuthContext from "../../contexts/auth";

/**
 * This component returns the main Header.
 */
const Header = ({
  currentSearchText,
  variant,
  onBackClick,
  showBackButton,
}) => {
  Header.propTypes = {
    currentSearchText: PropTypes.string,
    variant: PropTypes.string,
    onBackClick: PropTypes.func,
    showBackButton: PropTypes.bool,
  };

  Header.defaultProps = {
    currentSearchText: "",
    variant: "",
    onBackClick: undefined,
    showBackButton: false,
  };

  const isSearchEngineVariant = HeaderVariants.SEARCH_ENGINE === variant;

  const theme = useTheme();
  const { t } = useTranslation();

  const location = useLocation();
  const [selectedButton, setSelectedButton] = useState({
    landing: false,
    agency: false,
    multimidia: false,
  });
  const [hideSearchBar, setHideSearchBar] = useState(false);
  // const { isAuthenticated } = useContext(AuthContext);

  const [isSearching, setIsSearching] = useState(
    Boolean(currentSearchText && currentSearchText !== "")
  );

  const classes = useStyles({
    theme,
    variant,
    hideLogo: isSearching || showBackButton,
  });

  const handleClickSearchBar = () => {
    setIsSearching(!isSearching);
  };

  /* const handleSignOut = () => {
    signOut();
  }; */

  /**
   * This userEffect set the selected header button according by pathname.
   */
  useEffect(() => {
    if (location.pathname === "/") {
      setSelectedButton({ landing: true });
      setHideSearchBar(true);
    } else if (location.pathname === "/agencia/multimidia") {
      setSelectedButton({ multimidia: true, agency: true });
      setHideSearchBar(false);
    } else if (location.pathname.includes("/agencia")) {
      setSelectedButton({ agency: true });
      setHideSearchBar(false);
    } else if (location.pathname.includes("/buscador")) {
      setSelectedButton({});
      setHideSearchBar(false);
    } else if (location.pathname.includes("/busca")) {
      setSelectedButton({ agency: true });
      setHideSearchBar(false);
    } else if (location.pathname.includes("/contato")) {
      setHideSearchBar(true);
      setSelectedButton({ contactUs: true });
    } else if (location.pathname.includes("/cooperativas")) {
      setHideSearchBar(true);
      setSelectedButton({ cooperatives: true });
    } else if (location.pathname.includes("/missao")) {
      setHideSearchBar(true);
      setSelectedButton({ duty: true });
    }
  }, [location]);

  return (
    <div
      className={`${classes.header} ${
        showBackButton || isSearching ? classes.headerSmWithBackButton : null
      }`}
    >
      <div className={classes.headerItens}>
        <HeaderButtons selectedButton={selectedButton} />
        <div className={classes.actionsWrapper}>
          <div
            className={
              isSearchEngineVariant
                ? classes.headerSearchSearchEngineVariant
                : classes.headerSearch
            }
          >
            {!hideSearchBar && (
              <SearchBar
                isSearching={isSearching}
                setIsSearching={setIsSearching}
                handleClick={handleClickSearchBar}
                currentSearchText={currentSearchText}
                variant={variant}
                onBackClick={onBackClick}
                showBackButton={showBackButton}
              />
            )}
          </div>
          <div
            className={
              isSearching || showBackButton
                ? classNames(classes.headerMobile, classes.hideOnSm)
                : classes.headerMobile
            }
          >
            <MobileMenu
              className={
                isSearchEngineVariant ? classes.menuSearchEngineVariant : null
              }
              selectedMenuButton={selectedButton}
            />
          </div>
          <div className={classes.buttonWrapper}>
            {/* <div className={classes.signInButton}>
              <CustomButton
                backgroundColor={theme.background0}
                color={theme.primary0}
                href="/login"
              >
                {t("forms.signIn")}
              </CustomButton>
        </div> */}
            <div className={classes.signUpButtonWrapper}>
              <CustomButton
                color={theme.background0}
                href="/cadastro"
                className={classes.authButton}
              >
                {t("forms.signUp")}
              </CustomButton>
            </div>
            <div>
              <TranslationButton />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Header);
