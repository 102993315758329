import { createUseStyles } from "react-jss";
// import { breakpoints } from "../../../constants/sizes";

const useStyles = createUseStyles((theme) => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  menuButton: {
    cursor: "pointer",
    display: "flex",
    border: "none",
    backgroundColor: "transparent",
    padding: 0,
  },

  closeButton: {
    backgroundColor: theme.background0,
    width: 32,
    height: 32,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default useStyles;
