import { Breadcrumbs, useMediaQuery } from "@material-ui/core";
import React from "react";
import { useTheme } from "react-jss";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import CustomTypography from "../../CustomTypography";
import useStyles from "./styles";
import SvgArrowRight from "../../Icons/ArrowRight";

/**
 * This component represents a custom Breadcrumb.
 */
export default function Breadcrumb({ preview, current, hideOnSmall }) {
  Breadcrumb.defaultProps = {
    hideOnSmall: true,
  };

  Breadcrumb.propTypes = {
    preview: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    current: PropTypes.string.isRequired,
    hideOnSmall: PropTypes.bool,
  };

  const theme = useTheme();
  const classes = useStyles({ theme });
  const isMd = useMediaQuery("(max-width: 900px)");

  return (
    <div className={classes.breadcumb}>
      <Breadcrumbs separator={<SvgArrowRight />} aria-label="breadcrumb">
        {preview &&
          preview.map((page) => (
            <Link key={page} className={classes.link} to={page.link}>
              <CustomTypography
                color={theme.textSecondary}
                weight="regular"
                variant="body"
              >
                {page.title}
              </CustomTypography>
            </Link>
          ))}

        <CustomTypography
          color={theme.textPrimary}
          weight="bold"
          variant="body"
        >
          {!hideOnSmall || !isMd ? current : ""}
        </CustomTypography>
      </Breadcrumbs>
    </div>
  );
}
