import api from "../../services/api";
import { getDateFromYear } from "../../util/date";
import {
  FETCH_INSTITUTE_REQUEST,
  FETCH_INSTITUTE_SUCCESS,
  FETCH_INSTITUTE_FAILURE,
  FETCH_INSTITUTE_STATS_REQUEST,
  FETCH_INSTITUTE_STATS_SUCCESS,
  FETCH_INSTITUTE_STATS_FAILURE,
} from "./instituteTypes";

export const fetchInstituteRequest = () => ({
  type: FETCH_INSTITUTE_REQUEST,
});

export const fetchInstituteSuccess = (Institute) => ({
  type: FETCH_INSTITUTE_SUCCESS,
  payload: Institute,
});

export const fetchInstituteFailure = (error) => ({
  type: FETCH_INSTITUTE_FAILURE,
  payload: error,
});

export const fetchStatsRequest = () => ({
  type: FETCH_INSTITUTE_STATS_REQUEST,
});

export const fetchStatsSuccess = (response) => ({
  type: FETCH_INSTITUTE_STATS_SUCCESS,
  payload: response,
});

export const fetchStatsFailure = (error) => ({
  type: FETCH_INSTITUTE_STATS_FAILURE,
  payload: error,
});

/**
 * Fetch Institute from API
 * @param {*} id
 * @returns
 */
export const fetchInstitute = (id) => async (dispatch) => {
  dispatch(fetchInstituteRequest());

  const url = `institutions/${id}`;

  try {
    const response = await api.get(url);
    const results = response.data;
    dispatch(fetchInstituteSuccess(results));
  } catch (error) {
    const errorMsg = error.message;
    dispatch(fetchInstituteFailure(errorMsg));
  }
};
/**
 * Fetch statistics from API
 * @param {*} id
 * @param {*} startDate
 * @param {*} endDate
 * @returns
 */
export const fetchStatistics = (id) => async (dispatch) => {
  dispatch(fetchStatsRequest());

  const startDate = getDateFromYear(1980);
  const endDate = new Date();

  const url = `institutions/statistics/${id}?start_year=${startDate.getFullYear()}&end_year=${endDate.getFullYear()}`;

  try {
    const response = await api.get(url);
    const results = response.data;
    dispatch(fetchStatsSuccess(results));
  } catch (error) {
    const errorMsg = error.message;
    dispatch(fetchStatsFailure(errorMsg));
  }
};
