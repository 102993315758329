/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "react-jss";
import CustomTypography from "../../../CustomTypography";
import useStyles from "./styles";
import MoreArticle from "../MoreArticle";
import { isEmpty } from "../../../../util/hooks";
import ContentListSkeleton from "../../../Skeleton/ContentListSkeleton";

/**
 * This component represents a list horizontal of articles.
 * @returns A list horizontal of articles
 */
export default function HorizontalArticleList({
  articles,
  title,
  topRightElement,
  columnist,
  limit,
}) {
  const limitArticles = 3;
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <div className={classes.articlesWrapper}>
      <div className={classes.titleWrapper}>
        <CustomTypography variant="h3">{title}</CustomTypography>
        {topRightElement}
      </div>

      {!isEmpty(articles) ? (
        <div className={classes.moreArticles}>
          {articles.slice(0, limit || limitArticles).map((moreArticle) => (
            <div key={moreArticle.id} className={classes.moreArticle}>
              <MoreArticle
                article={moreArticle}
                columnist={columnist || moreArticle.columnist}
              />
            </div>
          ))}
        </div>
      ) : (
        <ContentListSkeleton />
      )}
    </div>
  );
}

HorizontalArticleList.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.shape()),
  title: PropTypes.string,
  topRightElement: PropTypes.shape(),
  columnist: PropTypes.shape(),
  limit: PropTypes.number,
};

HorizontalArticleList.defaultProps = {
  articles: undefined,
  title: "",
  topRightElement: undefined,
  columnist: undefined,
  limit: 3,
};
