import { createUseStyles } from "react-jss";
// import { breakpoints } from "../../../../constants/sizes";

const useStyles = createUseStyles((/* theme */) => ({
  link: {
    display: "flex",
    textDecoration: "none",
    "&:hover": {},
  },
  titleTypography: {
    marginTop: 10,
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  columnistAvatar: {
    width: 64,
    height: 64,

    borderRadius: "100%",
    objectFit: "cover",
    objectPosition: "center center",
  },
  columnistBadge: {
    display: "grid",
    width: "100%",
    height: "auto",
    marginBottom: 20,

    gridTemplateColumns: "auto 100%",
    gridColumnGap: 16,
    gridRowGap: 40,
    alignItems: "stretch",
  },
  description: {},
}));

export default useStyles;
