import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { useTheme } from "react-jss";
import PropTypes from "prop-types";
import CloseButton from "../../CloseButton";
import { months, currentYear } from "../Constants";
import MonthSelectSm from "./MonthSelectSm";
import YearSelectSm from "./YearSelectSm";
import MonthYearSelect from "./MonthYearSelect";
import useStyles from "./styles";

const DateInput = ({
  date,
  minYear,
  maxYear,
  onChange,
  title,
  onClickAway,
  hideCloseButton,
  onInputChange,
}) => {
  const theme = useTheme();
  const classes = useStyles({ theme, hideCloseButton });
  const [selectedDate, setSelectedDate] = useState(date || new Date());
  const [isSmChanging, setisSmChanging] = useState({
    month: false,
    year: false,
  });

  useEffect(() => {
    if (date) setSelectedDate(date);
  }, [date]);

  /**
   * Create an array of years in (min-max)Year range
   */
  const years = new Array((maxYear || currentYear) - (minYear || 1980))
    .fill()
    .map((_, index) => (minYear + 1 || 1980) + index)
    .reverse();

  const selectedMonth = () => months[selectedDate.getMonth()];
  const selectedYear = () => selectedDate.getFullYear();

  function parseDate(month, year) {
    return new Date(`${year}/${months.indexOf(month) + 1}/1`);
  }

  /**
   * Handles a month change
   * @param {*} month
   */
  const onMonthChanged = (month) => {
    const newDate = parseDate(month, selectedYear());
    setSelectedDate(newDate);
    if (onChange) onChange(newDate);
  };

  /**
   * Handles a year change
   * @param {*} year
   */
  const onYearChanged = (year) => {
    const newDate = parseDate(selectedMonth(), year);
    setSelectedDate(newDate);
    if (onChange) onChange(newDate);
  };

  /**
   * Function to handle click at month selector button when screen is small size
   */
  const handleClickChangeMonthSm = () => {
    if (onInputChange) onInputChange("month", true);
    setisSmChanging({ month: true, year: false });
  };

  /**
   * Function to handle click at year selector button when screen is small size
   */
  const handleClickChangeYearSm = () => {
    if (onInputChange) onInputChange("year", true);
    setisSmChanging({ month: false, year: true });
  };

  const handleApplyMonth = (month) => {
    if (onInputChange) onInputChange("month", false);
    setisSmChanging({ month: false, year: false });
    if (month) onMonthChanged(month);
  };

  const handleApplyYear = (year) => {
    if (onInputChange) onInputChange("year", false);
    setisSmChanging({ month: false, year: false });
    if (year) onYearChanged(year);
  };

  return (
    <div className={classNames([classes.container, "Date-Input-root"])}>
      {hideCloseButton && (
        <div className={classes.closeButtonWrapper}>
          <CloseButton onClick={onClickAway} />
        </div>
      )}
      {isSmChanging.month ? (
        <MonthSelectSm
          onApply={handleApplyMonth}
          currentMonth={selectedMonth()}
        />
      ) : isSmChanging.year ? (
        <YearSelectSm
          onApply={handleApplyYear}
          years={years}
          currentYear={selectedYear()}
        />
      ) : (
        <MonthYearSelect
          onMonthChanged={onMonthChanged}
          onYearChanged={onYearChanged}
          selectedMonth={selectedMonth()}
          selectedYear={selectedYear()}
          title={title}
          onClickChangeMonthSm={handleClickChangeMonthSm}
          onClickChangeYearSm={handleClickChangeYearSm}
          years={years}
        />
      )}
    </div>
  );
};

DateInput.propTypes = {
  date: PropTypes.instanceOf(Date),
  minYear: PropTypes.number,
  maxYear: PropTypes.number,
  /* Function signture (date) => void */
  onChange: PropTypes.func,
  onClickAway: PropTypes.func,
  title: PropTypes.string,
  hideCloseButton: PropTypes.bool,
  onInputChange: PropTypes.func,
};

DateInput.defaultProps = {
  date: undefined,
  minYear: undefined,
  maxYear: undefined,
  onChange: undefined,
  title: undefined,
  onClickAway: undefined,
  hideCloseButton: false,
  onInputChange: undefined,
};

export default DateInput;
