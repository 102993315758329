import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { useTheme } from "react-jss";
import { useTranslation } from "react-i18next";
import CustomTypography from "../../CustomTypography";
import useStyles from "./styles";
import DoneImg from "../../../assets/images/done.png";
import CustomButton from "../../CustomButton";
import CloseImg from "../../../assets/images/close.svg";
import SvgWhatsapp from "../../Icons/Whatsapp";

/**
 * This component renders a modal containing a message to share the Rhisa with Whatsapp.
 * @returns A share modal
 */
function ShareModal({ className, open, onClose, ...rest }) {
  ShareModal.defaultProps = {
    className: "",
  };

  ShareModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    className: PropTypes.string,
  };

  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classNames(classes.modal, className)}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      {...rest}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <div
            role="button"
            tabIndex={0}
            onKeyDown={onClose}
            onClick={onClose}
            className={classes.closeImage}
          >
            <img src={CloseImg} alt="close" />
          </div>
          <img className={classes.doneImage} src={DoneImg} alt="done" />
          <CustomTypography
            variant="paragraph"
            weight="bold"
            id="transition-modal-title"
            align="center"
            className={classes.title}
            color={theme.textPrimary}
          >
            {t("landing.shareModal.title")}
          </CustomTypography>
          <CustomTypography
            variant="body"
            id="transition-modal-description"
            align="center"
            color={theme.textSecondary}
            className={classes.description}
          >
            {t("landing.shareModal.description0")}{" "}
            <CustomTypography
              variant="body"
              component="span"
              weight="bold"
              color={theme.textSecondary}
            >
              {t("landing.shareModal.description1")}
            </CustomTypography>
          </CustomTypography>
          <a
            href="https://api.whatsapp.com/send?text=Olá, estou convidando você para conhecer a Rede RHISA!%0aInscreva-se para receber a newsletter.%0ahttps://rhisa.org/"
            target="_blank"
            className={classes.href}
            rel="noreferrer"
          >
            <CustomButton startIcon={<SvgWhatsapp />}>
              <CustomTypography color={theme.background0} weight="bold">
                {t("landing.shareModal.button")}
              </CustomTypography>
            </CustomButton>
          </a>
        </div>
      </Fade>
    </Modal>
  );
}

export default ShareModal;
