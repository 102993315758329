import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "react-jss";
import useStyles from "./styles";
import CustomTypography from "../../../../CustomTypography";
import CustomLink from "../../../../CustomLink";
import ProfileImg from "../../../../ProfileImg";

const RelatedInfo = ({ result, hideImageOnSm, isPopup }) => {
  const theme = useTheme();
  const classes = useStyles({ theme, hideImageOnSm });

  return (
    <>
      <div className={classes.container}>
        <div className={classes.imageWrapper}>
          <ProfileImg url={result.image} size={24} name={result.name} />
        </div>
        <div className={classes.nameWrapper}>
          <CustomLink
            to={result.id ? `/buscador/pesquisador/${result.id}` : "/404"}
          >
            <CustomTypography
              variant={isPopup ? "subtitle" : "searchResultSubTitle"}
              color={theme.primary0}
            >
              {result.name}
            </CustomTypography>
          </CustomLink>
        </div>
      </div>
    </>
  );
};

RelatedInfo.propTypes = {
  result: PropTypes.shape().isRequired,
  hideImageOnSm: PropTypes.bool,
  isPopup: PropTypes.bool,
};

RelatedInfo.defaultProps = {
  hideImageOnSm: false,
  isPopup: false,
};

export default RelatedInfo;
