import axios from "axios";

/**
 * Create an Axios instance.
 */
const api = axios.create({
  baseURL: process.env.REACT_APP_STRAPI_API_URL,
});

/**
 * This function returns the path with Strapi URL base
 * @param {*} path
 */
export function getStrapiURL(path = "") {
  return `${api.defaults.baseURL || "http://localhost:1337"}${path}`;
}

export default api;
