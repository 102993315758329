import { createUseStyles } from "react-jss";
import castanhaImg from "../../../assets/images/castanha.png";

const useStyles = createUseStyles(() => ({
  background: {
    width: "100%",
    height: "100vh",
    backgroundImage: `url(${castanhaImg})`,
    backgroundSize: "cover",
    display: "flex",
    borderRadius: "10px",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 0,
  },
  container: {
    width: "100%",
    padding: "20px",
  },
  content: {
    color: "white",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignContent: "end",
    padding: "20px",
    alignItems: "flex-end",
    justifyContent: "center",
    textAlign: "center",
    zIndex: 1,
    "& h1": {
      fontSize: "5rem",
      fontWeight: "bold",
      marginBottom: "20px",
      "@media (max-width: 768px)": {
        fontSize: "3rem", 
      },
    },
  },
  text: {
    width: "70%",
    textAlign: "justify",
    "@media (max-width: 768px)": {
      width: "100%", 
    },
  },
}));

export default useStyles;
