import React, { useState, useEffect } from "react";
import { useTheme } from "react-jss";
import { useTranslation } from "react-i18next";
import { Portal } from "react-portal";
import PropTypes from "prop-types";
import CustomButton from "../../CustomButton";
import DateInput from "../../CustomDatePicker/DateInput";
import useStyles from "./styles";
import CloseButton from "../../CloseButton";

function DateRangePickerPopper({
  startDate,
  endDate,
  onClickAway,
  onStartChange,
  onEndChange,
  open,
  anchorEl,
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  const [dateRange, setDateRange] = useState([startDate, endDate]);
  const [isChangingPeriod, setisChangingPeriod] = useState({
    start: false,
    end: false,
  });
  const [sectionOpened, setSecionOpened] = useState("");
  const classes = useStyles({ theme, isChangingPeriod });

  /**
   * Reset selection state when this component is open
   */
  useEffect(() => {
    if (anchorEl) {
      setSecionOpened("");
      setDateRange([startDate, endDate]);
      setisChangingPeriod({
        start: false,
        end: false,
      });
    }
  }, [anchorEl]);

  useEffect(() => {
    setDateRange([startDate, endDate]);
  }, [startDate, endDate]);

  const onStartSmChange = (date) => {
    setDateRange((oldDate) => [date, oldDate[1]]);
  };

  const onEndSmChange = (date) => {
    setDateRange((oldDate) => [oldDate[0], date]);
  };

  const handleApply = () => {
    if (startDate.getTime() !== dateRange[0].getTime())
      onStartChange(dateRange[0]);
    if (endDate.getTime() !== dateRange[1].getTime()) onEndChange(dateRange[1]);
    if (onClickAway) onClickAway();
  };

  function title() {
    switch (sectionOpened) {
      case "month":
        return t("date.period.selectMonth");
      case "year":
        return t("date.period.selectYear");
      default:
        return t("date.period.selectPeriod");
    }
  }

  return (
    open && (
      <Portal>
        <div className={classes.paper}>
          <div className={classes.contentWrapper}>
            <div className={classes.title}>
              <div className="title">{title()}:</div>
              <div>
                <CloseButton onClick={onClickAway} />
              </div>
            </div>
            <div className={classes.filters}>
              {!isChangingPeriod.end && (
                <div>
                  <DateInput
                    onChange={onStartSmChange}
                    date={dateRange[0]}
                    maxYear={dateRange[1].getFullYear()}
                    title={`${t("date.period.startAt")}:`}
                    hideCloseButtom
                    onInputChange={(type, opening) => {
                      setSecionOpened(opening ? type : "");
                      setisChangingPeriod({ start: opening, end: false });
                    }}
                  />
                </div>
              )}
              {!isChangingPeriod.start && (
                <div>
                  <DateInput
                    onChange={onEndSmChange}
                    date={dateRange[1]}
                    minYear={dateRange[0].getFullYear()}
                    title={`${t("date.period.endAt")}:`}
                    hideCloseButtom
                    onInputChange={(type, opening) => {
                      setSecionOpened(opening ? type : "");
                      setisChangingPeriod({ start: false, end: opening });
                    }}
                  />
                </div>
              )}
              {!isChangingPeriod.end && !isChangingPeriod.start && (
                <div className={classes.applyButtonWrapper}>
                  <CustomButton
                    className={classes.applyButton}
                    onClick={handleApply}
                  >
                    {t("date.period.apply")}
                  </CustomButton>
                </div>
              )}
            </div>
          </div>
        </div>
      </Portal>
    )
  );
}

DateRangePickerPopper.propTypes = {
  onClickAway: PropTypes.func,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  onStartChange: PropTypes.func,
  onEndChange: PropTypes.func,
  open: PropTypes.bool,
  anchorEl: PropTypes.oneOfType([PropTypes.func, PropTypes.shape()]),
};

DateRangePickerPopper.defaultProps = {
  onClickAway: undefined,
  startDate: new Date("1980/02/01"),
  endDate: new Date(),
  onStartChange: undefined,
  onEndChange: undefined,
  open: false,
  anchorEl: undefined,
};

export default DateRangePickerPopper;
