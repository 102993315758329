import React, { memo } from "react";
import PropTypes from "prop-types";
import { useTheme } from "react-jss";
// import { useTranslation } from "react-i18next";
import CustomTypography from "../../../CustomTypography";
import useStyles from "./styles";

const PublicationDescription = ({ year, ...props }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  // const { t } = useTranslation();

  return (
    <div {...props}>
      <div className={classes.contentWrapper}>
        <CustomTypography variant="tag" color={theme.textSecondary}>
          {year}
        </CustomTypography>
      </div>
    </div>
  );
};

PublicationDescription.propTypes = {
  pages: PropTypes.string,
  year: PropTypes.string,
};

PublicationDescription.defaultProps = {
  pages: undefined,
  year: undefined,
};

export default memo(PublicationDescription);
