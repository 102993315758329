import React from "react";
import { useTheme } from "react-jss";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import CustomTypography from "../../../CustomTypography";
import OutlinedStar from "../../../Icons/OutlinedStar";
import PhoneOutline from "../../../Icons/PhoneOutline";
import OutlinedLeaf from "../../../Icons/OutlinedLeaf";
import Whatsapp from "../../../Icons/Whatsapp";
import EmailOutline from "../../../Icons/EmailOutline";
import Location from "../../../Icons/Location";
import CustomButton from "../../../CustomButton";
import TitledSection from "../../../TitledSection";
import useStyles from "./styles";

const Description = ({ cooperative }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <TitledSection icon={<OutlinedStar />} title="Presidente ou responsável">
        {cooperative?.responsible}
      </TitledSection>
      <TitledSection
        icon={<PhoneOutline />}
        title="Contato(s)"
        className={classes.contactSection}
      >
        <CustomTypography variant="body" color={theme.textSecondary}>
          {t("contact.phoneRow", {
            phone: cooperative?.phone || t("notProvided"),
          })}
        </CustomTypography>
        <CustomTypography variant="body" color={theme.textSecondary}>
          {t("contact.emailRow", {
            email: cooperative?.email || t("notAvailable"),
          })}
        </CustomTypography>

        <div className="buttons-wrapper">
          <CustomButton color={theme.background0} className="message-button">
            <Whatsapp />
            <CustomTypography variant="body" weight="bold">
              Mensagem
            </CustomTypography>
          </CustomButton>
          <CustomButton
            variant="outlined"
            color="primary"
            outlineColor={theme.primary0}
            className="contact-button"
          >
            <EmailOutline />
            <CustomTypography variant="body" weight="bold">
              Envie um E-mail
            </CustomTypography>
          </CustomButton>
        </div>
      </TitledSection>
      <TitledSection icon={<OutlinedLeaf />} title="Produto(s)">
        {cooperative?.products?.map((product) => (
          <CustomTypography
            key={product.id}
            variant="body"
            color={theme.textSecondary}
          >
            {product.name}
          </CustomTypography>
        ))}
      </TitledSection>
      <TitledSection icon={<Location />} title="Localização">
        <CustomTypography variant="body" color={theme.textSecondary}>
          {cooperative?.address}
        </CustomTypography>
        <CustomTypography variant="body" color={theme.textSecondary}>
          {cooperative?.complement}
        </CustomTypography>
        <CustomTypography variant="body" color={theme.textSecondary}>
          {t("address.districtRow", { district: cooperative?.district })}
        </CustomTypography>
        <CustomTypography variant="body" color={theme.textSecondary}>
          {t("address.cepRow", { cep: cooperative?.cep })}
        </CustomTypography>
        <CustomTypography variant="body" color={theme.textSecondary}>
          {t("address.cityRow", {
            city: `${cooperative?.city} - ${cooperative?.state}`,
          })}
        </CustomTypography>
      </TitledSection>
    </div>
  );
};

Description.propTypes = {
  cooperative: PropTypes.shape(),
};
Description.defaultProps = {
  cooperative: null,
};

export default Description;
