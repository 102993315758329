import { createUseStyles } from "react-jss";
import { breakpoints, breakpointsHeight } from "../../../constants/sizes";
import northIcon from "../../../assets/icons/north.svg";
import plusIcon from "../../../assets/icons/plus.svg";
import minusIcon from "../../../assets/icons/minus.svg";

const useStyles = createUseStyles((theme) => ({
  map: {
    position: "relative",
    width: "100%",
    height: "100%",
    "& .mapboxgl-ctrl-bottom-right": {
      visibility: "hidden",
    },
    [breakpointsHeight.md]: {
      maxHeight: "none",
      height: "100%",
    },
  },
  navigationControl: {
    right: 0,
    bottom: 20,
    marginRight: 10,
    zIndex: 2,

    "& .mapboxgl-ctrl-group": {
      backgroundColor: "transparent",
      height: 120,
    },
    "& .mapboxgl-ctrl-group:not(:empty)": {
      boxShadow: "none",
    },
    "& .mapboxgl-ctrl button:not(:disabled):hover": {
      backgroundColor: theme.background1,
    },
    "& .mapboxgl-ctrl-zoom-in": {
      width: 35,
      height: 35,
      position: "absolute",
      marginTop: 51,
      backgroundColor: theme.background0,
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      border: "transparent",
      filter: `drop-shadow(2px 5px 3px ${theme.shadow3})`,
    },
    "& .mapboxgl-ctrl-zoom-out": {
      width: 35,
      height: 35,
      position: "absolute",
      marginTop: 85,
      backgroundColor: theme.background0,
      borderBottomLeftRadius: 5,
      borderBottomRightRadius: 5,
      border: "transparent",
      filter: `drop-shadow(2px 5px 3px ${theme.shadow3})`,
      "&:hover": {
        backgroundColor: "#000",
      },
    },
    "& .mapboxgl-ctrl-compass": {
      width: 35,
      height: 35,
      backgroundColor: theme.background0,
      borderRadius: 5,
      filter: "drop-shadow(2px 2px 3px #c7c7c7)",
    },
    "& .mapboxgl-ctrl button.mapboxgl-ctrl-compass .mapboxgl-ctrl-icon": {
      backgroundImage: `url(${northIcon})`,
    },
    "& .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon": {
      backgroundImage: `url(${plusIcon})`,
    },
    "& .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon": {
      backgroundImage: `url(${minusIcon})`,
    },
  },
  clusterMark: {
    borderRadius: "50%",
    border: `1px solid ${theme.background0}`,
    backgroundColor: theme.primary0,
    color: theme.background0,
    fontSize: 16,
    cursor: "pointer",
  },
  topElemntsWrapper: {
    zIndex: 2,
    position: "absolute",
    width: "calc(100% - 20px)",
    backgroundColor: "transparent",
    top: 15,
    left: 10,
    display: "flex",
    justifyContent: "space-between",

    [breakpoints.md]: {
      top: 70,
    },
    [breakpoints.sm]: {
      top: 70,
      left: 10,
      height: 40,
      borderRadius: 10,
      backgroundColor: theme.background0,
      filter: `drop-shadow(2px 5px 3px ${theme.shadow3})`,
    },
  },
  topElementsDivider: {
    height: "100%",
    width: 1,
    backgroundColor: theme.secondary0,
  },
  rightTopElement: {
    top: 15,
    right: 10,

    [breakpoints.md]: {
      top: 0,
    },
  },
  rightLeftElement: {
    position: "absolute",
    top: 15,
    left: 10,
    zIndex: 4,

    [breakpoints.md]: {
      top: 70,
    },
  },
  categoryFilter: {
    position: "absolute",
    top: 15,
    right: 10,
    zIndex: 4,

    [breakpoints.md]: {
      top: 70,
    },
  },
  loadingIndicatorWrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default useStyles;
