import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minWidth: 180,
    height: 85,
    borderRadius: 4,
    border: `1px solid ${theme.stroke}`,
  },
}));

export default useStyles;
