import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "react-jss";
import { useTranslation } from "react-i18next";
import MapIcon from "../../Icons/Map";
import CustomButton from "../../CustomButton";
import useStyles from "./styles";

const ViewOnMapButton = ({ onClick }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();

  return (
    <CustomButton
      className={classes.viewMapButton}
      color={theme.primary0}
      backgroundColor={theme.background0}
      startIcon={<MapIcon />}
      onClick={onClick}
    >
      {t("searchPage.viewResultsOnMap")}
    </CustomButton>
  );
};

ViewOnMapButton.propTypes = {
  onClick: PropTypes.func,
};

ViewOnMapButton.defaultProps = {
  onClick: null,
};

export default ViewOnMapButton;
