import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useTheme } from "react-jss";
import SvgArrowDown from "../Icons/ArrowDownThin";

import useStyles from "./styles";

const AccordionItem = ({ open, buttonChildren, children }) => {
  const theme = useTheme();
  const [active, setActive] = useState(open);
  const [maxHeight, setMaxHeight] = useState("0px");
  const content = useRef(null);
  const classes = useStyles({ theme, active });

  const toogleAccordion = () => {
    setMaxHeight(active ? "0px" : `${content.current.scrollHeight}px`);
    setActive((oldState) => !oldState);
  };

  useEffect(() => {
    setMaxHeight(open ? `${content.current.scrollHeight}px` : "0px");
  }, [content]);

  return (
    <div className={classes.content}>
      <button type="button" onClick={toogleAccordion}>
        {buttonChildren}
        <SvgArrowDown />
      </button>
      <div ref={content} className={classes.children} style={{ maxHeight }}>
        {children}
      </div>
    </div>
  );
};

AccordionItem.propTypes = {
  buttonChildren: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  open: PropTypes.bool,
};
AccordionItem.defaultProps = {
  open: false,
};

export default AccordionItem;
