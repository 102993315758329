import React from "react";
import { useTheme } from "react-jss";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import CustomTypography from "../../../../../CustomTypography";
import CustomButton from "../../../../../CustomButton";
import CheckSvg from "../../../../../Icons/Check";
import useStyles from "./styles";

const SuccessContainer = ({ term, email, frequency, onClickClose }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();

  return (
    <>
      <div className={classes.contentItem}>
        <div className={classes.checkedWraper}>
          <div className={classes.checked}>
            <div className={classes.checkIconWrapper}>
              <CheckSvg />
            </div>
          </div>
        </div>

        <CustomTypography variant="h3" color={theme.textPrimary}>
          {t("searchEngine.alertModal.success.completed")}
        </CustomTypography>
      </div>
      <div className={classes.contentItem}>
        <CustomTypography variant="body" color={theme.textSecondary}>
          {t("searchEngine.alertModal.success.allRight")}{" "}
          {frequency === "monthly"
            ? t("searchEngine.alertModal.form.monthly")
            : t("searchEngine.alertModal.form.weekly")}{" "}
          você receberá novidades sobre{" "}
          <span
            style={{ fontWeight: "bold", color: theme.textPrimary }}
          >{`"${term}"`}</span>{" "}
          {t("searchEngine.alertModal.success.atEmail")}{" "}
          <span
            style={{ fontWeight: "bold", color: theme.textPrimary }}
          >{`"${email}"`}</span>
        </CustomTypography>
      </div>

      <div className={classes.buttonWrapper}>
        <CustomButton
          color={theme.background0}
          className={classes.alertButton}
          onClick={onClickClose}
        >
          {t("searchEngine.alertModal.success.close")}
        </CustomButton>
      </div>
    </>
  );
};

SuccessContainer.propTypes = {
  term: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  frequency: PropTypes.string.isRequired,
  onClickClose: PropTypes.func,
};

SuccessContainer.defaultProps = {
  onClickClose: undefined,
};

export default SuccessContainer;
