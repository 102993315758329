/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, memo } from "react";
import PropTypes from "prop-types";
import { useTheme } from "react-jss";
import { useTranslation } from "react-i18next";
import Link from "@material-ui/core/Link";
import ReactMarkdown from "react-markdown";
import CustomTypography from "../../../../CustomTypography";
import trimText from "../../../../../util/trimText";
import useStyles from "./styles";
import { EmailOutline, Location, PhoneOutline } from "../../../../Icons";

const About = ({ id, about, address, email, telephone, ...props }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  const [viewMore, setViewMore] = useState(false);
  const trimmedText = trimText(about, 200);

  const shouldTrim = about && about.length > 200;

  const handleViewMore = () => {
    setViewMore((oldState) => !oldState);
  };

  return (
    <div {...props}>
      <div className={classes.content}>
        <div className={classes.title}>
          <CustomTypography variant="noticeTitle" color={theme.textPrimary}>
            {t("resultPage.aboutInstitute")}
          </CustomTypography>
        </div>

        <div>
          <CustomTypography variant="body1" color={theme.textPrimary}>
            {about ? (
              <ReactMarkdown>
                {viewMore || !shouldTrim ? about : `${trimmedText[0]}...`}
              </ReactMarkdown>
            ) : (
              <div
                style={{
                  display: "flex",
                  minHeight: 60,
                  alignItems: "center",
                }}
              >
                <span className={classes.infoPlaceholder}>
                  {t("resultPage.emptyInfo")}
                </span>
              </div>
            )}
          </CustomTypography>

          {shouldTrim && (
            <CustomTypography color={theme.primary1} variant="body">
              <Link
                onClick={handleViewMore}
                style={{ textDecoration: "none" }}
                className={classes.link}
              >
                {viewMore ? t("resultPage.hide") : t("resultPage.viewMore")}
              </Link>
            </CustomTypography>
          )}
        </div>
        <div className={classes.contactWrapper}>
          <CustomTypography variant="noticeTitle" color={theme.textPrimary}>
            {t("resultPage.contact")}
          </CustomTypography>
          <div className={classes.contactContent}>
            <Location />
            <CustomTypography variant="body">
              {address || (
                <span className={classes.infoPlaceholder}>
                  {t("resultPage.emptyAddress")}
                </span>
              )}
            </CustomTypography>

            <EmailOutline />
            <CustomTypography variant="body">
              {email || (
                <span className={classes.infoPlaceholder}>
                  {t("resultPage.emptyEmail")}
                </span>
              )}
            </CustomTypography>

            <PhoneOutline />
            <CustomTypography variant="body">
              {telephone || (
                <span className={classes.infoPlaceholder}>
                  {t("resultPage.emptyPhone")}
                </span>
              )}
            </CustomTypography>
          </div>
        </div>
      </div>
    </div>
  );
};

About.propTypes = {
  about: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  telephone: PropTypes.string,
  email: PropTypes.string,
  address: PropTypes.string,
};

About.defaultProps = {
  about: "",
  id: undefined,
  telephone: "",
  email: "",
  address: "",
};

export default memo(About);
