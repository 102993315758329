/* eslint-disable no-unused-vars */
import React, { memo, useMemo } from "react";
import { MenuItem } from "@material-ui/core";
import useStyles from "./styles";

const StyledMenuItem = (props) => {
  const classes = useStyles();

  return useMemo(
    () => <MenuItem className={classes.menuItem} {...props} />,
    [props]
  );
};

export default memo(StyledMenuItem);
