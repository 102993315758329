import React, { useEffect /* , useContext */ } from "react";
import { useTheme } from "react-jss";
import { Route, Switch, useRouteMatch, useHistory } from "react-router-dom";
import ReactGA from "react-ga";
import SearchPage from "./SearchPage";
import ResearcherPage from "./ResearcherPage";
import InsitutePage from "./InstitutePage";
// import RoutesPrivate from "../../components/Routes/Private";
// import AuthContext from "../../contexts/auth";
import useStyles from "./styles";

const SearchAreaRoot = () => {
  const match = useRouteMatch();
  const theme = useTheme();
  const classes = useStyles(theme);
  // const { isAuthenticated } = useContext(AuthContext);
  const history = useHistory();

  // Initialize google analytics page view tracking
  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.set({ page: history.location.pathname }); // Update the user's current page
    ReactGA.pageview(history.location.pathname + window.location.search); // Record a pageview for the given page
  }, [history.location]);

  return (
    <div className={classes.container}>
      <Switch>
        <Route
          path={`${match.path}/pesquisador/:id`}
          component={ResearcherPage}
        />
        <Route path={`${match.path}/instituto/:id`} component={InsitutePage} />
        <Route path={`${match.path}`} component={SearchPage} />
      </Switch>
    </div>
  );
};

export default SearchAreaRoot;
