import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTheme } from "react-jss";
import useSWR from "swr";
import { useTranslation } from "react-i18next";
import api from "../../../../services/hcms/api";
import useStyles from "./styles";
import Breadcrumb from "../../../../components/Agency/Breadcrumb";
import CustomTypography from "../../../../components/CustomTypography";
import HorizontalArticleList from "../../../../components/Agency/Article/HorizontalArticleList";
import LoadMoreButton from "../../../../components/LoadMoreButton";
import { isEmpty } from "../../../../util/hooks";

/**
 * This component represents Agency Columnist homepage.
 * @returns Home page
 */
export default function Home() {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [columnists, setColumnists] = useState([]);
  const [isLoadEnded, setisLoadEnded] = useState(false);
  const [isLoading, setisLoading] = useState(true);

  const { data: moreColumnists } = useSWR(
    `/columnists?_sort=name:ASC&_limit=3&_start=${page * 3}`,
    async (swrRoute) => {
      const response = await api.get(swrRoute);
      setisLoading(false);
      return response.data;
    }
  );
  function loadVideos() {
    if (moreColumnists) {
      setColumnists((oldColumnists) => {
        const oldLength = oldColumnists.length;
        const newLength = oldLength + moreColumnists.length;
        setisLoadEnded(newLength - oldLength < 3);
        if (oldLength === newLength) return oldColumnists;
        return [...oldColumnists, ...moreColumnists];
      });
    }
  }

  useEffect(() => {
    loadVideos();
  }, [moreColumnists]);

  function loadMore() {
    setisLoading(true);
    setPage((oldPageValue) => oldPageValue + 1);
  }

  const isEvenNumber = (index) => index % 2 === 0;

  const articleContainer = (columnist) => (
    <HorizontalArticleList
      articles={columnist.articles}
      columnist={columnist}
      title={columnist.name}
      topRightElement={
        <>
          <Link
            to={`/agencia/colunistas/${columnist.id}`}
            className={classes.link}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "horizontal",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CustomTypography
                variant="paragraph"
                weight="bold"
                color={theme.primary0}
              >
                {t("agency.article.seeAllArticles")}
              </CustomTypography>
            </div>
          </Link>
        </>
      }
    />
  );

  return (
    <>
      <div className={classes.section}>
        <Breadcrumb
          preview={[{ link: "/agencia", title: "Agência RHISA" }]}
          current="Colunistas"
          hideOnSmall={false}
        />
      </div>

      {!isEmpty(columnists) ? (
        columnists.map((columnist, index) => (
          <div key={columnist.id} className={classes.columnistArticlesWrapper}>
            <div
              className={`${
                isEvenNumber(index) ? classes.content : classes.contentGrayBg
              } ${classes.centralize}`}
            >
              <div className={classes.columnistArticles}>
                {articleContainer(columnist)}
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className={classes.columnistArticlesWrapper}>
          <div className={classes.centralize}>
            <div className={classes.columnistArticles}>
              <HorizontalArticleList />
              <HorizontalArticleList />
            </div>
          </div>
        </div>
      )}

      <LoadMoreButton
        isLoadEnded={isLoadEnded}
        onClick={() => loadMore()}
        visible={!isLoading}
        isLoading={isLoading}
      />
    </>
  );
}
