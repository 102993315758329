import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import SkeletonTypography from "../SkeletonTypography";

export default function HighlightSkeleton() {
  return (
    <>
      <Skeleton variant="rect" width="100%">
        <div style={{ paddingTop: 250 }} />
      </Skeleton>
      <SkeletonTypography variant="h3" />
      <SkeletonTypography variant="body1" />
    </>
  );
}
