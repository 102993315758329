import React from "react";
import { createUseStyles } from "react-jss";
import GeneralStatistics from "../../GeneralStatistics";
import Header from "../../Header";
import GridText from "../../GridText";
import Partners from "../../Partners";

const useStyles = createUseStyles({
  backgroundContainer: {
    background: "linear-gradient(rgba(232, 242, 243, 0.15), rgba(232, 242, 243, 0.82))",
    padding: "20px", 
  },
});

const SearchContainer = () => {
  const classes = useStyles();

  return (
    <div>
      <Header />
      <GeneralStatistics />
      <div className={classes.backgroundContainer}>
        <GridText />
        <Partners />
      </div>
    </div>
  );
};

export default SearchContainer;
