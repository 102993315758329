import React from "react";
import { createUseStyles } from "react-jss";
import {
  ShoppingBag,
  Book,
  User,
  Clipboard,
  LifeBuoy,
  MapPin,
} from "lucide-react";
import CustomTypography from "../../CustomTypography";

const useStyles = createUseStyles({
  container: {
    display: "grid",
    height: "100vh",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridAutoRows: "1fr",
    gap: "30px",
    justifyContent: "center",
    alignItems: "stretch",
    textAlign: "center",
    padding: "50px",
    // Para telas médias, ex: tablets
    "@media (max-width: 1024px)": {
      gridTemplateColumns: "repeat(2, 1fr)",
      padding: "30px",
    },
    // Para telas pequenas, ex: smartphones
    "@media (max-width: 768px)": {
      gridTemplateColumns: "1fr",
      padding: "20px",
      height: "auto",
    },
  },
  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "15px",
    border: "1px solid rgb(108, 211, 223)",
    borderRadius: "10px",
    padding: "40px",
    // Reduz o padding em telas pequenas
    "@media (max-width: 768px)": {
      padding: "20px",
    },
  },
});

const GridText = () => {
  const classes = useStyles();

  const texts = [
    {
      id: "cooperativas",
      icon: ShoppingBag,
      label: "Encontre Cooperativas e Produtos",
      content:
        "Conecte-se com cooperativas e associações da agricultura familiar que trabalham com a castanha-do-brasil! Descubra onde estão localizadas, quais produtos oferecem e como fazem parte da bioeconomia amazônica.",
    },
    {
      id: "pesquisas_artigos",
      icon: Book,
      label: "Explore Pesquisas e Artigos",
      content:
        "Acesse estudos, publicações, reportagens, relatórios e pesquisas relevantes sobre a cadeia da castanha, seu impacto econômico e ambiental.",
    },
    {
      id: "pesquisadores",
      icon: User,
      label: "Conheça os Pesquisadores",
      content:
        "Descubra quem está por trás dos estudos sobre a castanha. Veja os especialistas, suas áreas de atuação e como contribuem para o avanço da bioeconomia.",
    },
    {
      id: "instituicoes_pesquisa",
      icon: Clipboard,
      label: "Instituições de Pesquisa",
      content:
        "Saiba quais estruturas de CT&I estão investigando e promovendo inovações na cadeia produtiva da castanha.",
    },
    {
      id: "instituicoes_apoio",
      icon: LifeBuoy,
      label: "Instituições de Apoio",
      content:
        "Conheça os órgãos, programas, OSCs e iniciativas que ajudam a fortalecer a produção, beneficiamento e comercialização da castanha na Amazônia.",
    },
    {
      id: "municipios",
      icon: MapPin,
      label: "Municípios da Castanha!",
      content:
        "Confira em quais municípios há projetos, organizações, pesquisas e iniciativas ativas relacionadas à castanha, fortalecendo redes produtivas e sustentáveis.",
    },
  ];

  return (
    <div className={classes.container}>
      {texts.map((text) => {
        const Icon = text.icon;
        return (
          <div key={text.id} className={classes.item}>
            <Icon size={40} />
            <CustomTypography variant="h3" color="primary" fontWeight="bold">
              {text.label}
            </CustomTypography>
            <CustomTypography variant="noticeBody" color="secondary">
              {text.content}
            </CustomTypography>
          </div>
        );
      })}
    </div>
  );
};

export default GridText;
