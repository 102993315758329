import * as React from "react";

function ArrowDown(props) {
  return (
    <svg
      width="10"
      height="11"
      viewBox="0 0 10 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.84177 3.7286C7.63759 3.62652 7.39101 3.66653 7.2296 3.82795L4.99988 6.05766L2.77016 3.82795C2.60875 3.66653 2.36216 3.62652 2.15799 3.7286V3.7286C1.83423 3.89049 1.7642 4.32199 2.02016 4.57795L4.70525 7.26303C4.86797 7.42575 5.13179 7.42575 5.29451 7.26303L7.97959 4.57795C8.23555 4.32199 8.16553 3.89049 7.84177 3.7286V3.7286Z"
        fill="#1A1A1A"
      />
    </svg>
  );
}

export default ArrowDown;
