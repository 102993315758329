import React, { useState, useMemo, useEffect } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useTheme } from "react-jss";
import Slider from "@material-ui/core/Slider";
import DateRangeFilter from "../DateRangeFilter";
import ArrowThumbComponent from "./ArrowThumbComponent";
import useStyles from "./styles";

function parseDateToDecimal(date) {
  return date.getFullYear() + date.getMonth() * 0.09;
}

const Timeline = ({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  onPeriodChange,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const maxYear = useMemo(() => parseDateToDecimal(new Date()), []);
  const minYear = useMemo(() => new Date("1980/02/01").getFullYear(), []);
  const [dateRange, setDateRange] = useState([minYear, maxYear]);

  useEffect(() => {
    setDateRange([parseDateToDecimal(startDate), parseDateToDecimal(endDate)]);
  }, [startDate, endDate]);

  const getYear = (numberYear) => Math.floor(numberYear);

  const getMonth = (numberDate) => {
    const decimalMonth = numberDate % 1;
    return Math.ceil(decimalMonth * 11) + 1;
  };

  const parseToDate = (decimalYear) =>
    new Date(`${getYear(decimalYear)}/${getMonth(decimalYear)}/02`);

  const setNewData = (_, number) => {
    const newStartDate = parseToDate(number[0]);
    const newEndDate = parseToDate(number[1]);

    if (onPeriodChange) {
      onPeriodChange(newStartDate, newEndDate);
    }
  };

  const handleStartDateChange = (date) => {
    setDateRange((oldDate) => [parseDateToDecimal(date), oldDate[1]]);

    if (onStartDateChange) {
      onStartDateChange(date);
    }
  };

  const handleEndDateChange = (date) => {
    setDateRange((oldDate) => [oldDate[0], parseDateToDecimal(date)]);
    if (onEndDateChange) {
      onEndDateChange(date);
    }
  };

  const handleChange = (event, newValue) => {
    setDateRange(newValue);
  };

  return (
    <div
      className={classNames([classes.container, "timeline-container-wrapper"])}
    >
      <div className={classes.dateRangeWrapper}>
        <DateRangeFilter
          startDate={parseToDate(dateRange[0])}
          endDate={parseToDate(dateRange[1])}
          maxDate={new Date()}
          onStartChange={handleStartDateChange}
          onEndChange={handleEndDateChange}
        />
      </div>
      <div className={classNames([classes.divider, "timeline-divider"])} />
      <div
        className={classNames([
          classes.sliderWrapper,
          "timeline-slider-wrapper",
        ])}
      >
        <Slider
          step={0.09}
          max={maxYear}
          min={minYear}
          value={dateRange}
          onChange={handleChange}
          onChangeCommitted={setNewData}
          valueLabelDisplay="off"
          aria-labelledby="range-slider"
          ThumbComponent={ArrowThumbComponent}
        />
      </div>
    </div>
  );
};

Timeline.propTypes = {
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  onStartDateChange: PropTypes.func,
  onEndDateChange: PropTypes.func,
  onPeriodChange: PropTypes.func,
};

Timeline.defaultProps = {
  startDate: new Date("1980/02/01"),
  endDate: new Date(),
  onStartDateChange: undefined,
  onEndDateChange: undefined,
  onPeriodChange: undefined,
};

export default Timeline;
