import React from "react";
import { useTheme } from "react-jss";
import PropTypes from "prop-types";
import CustomTypography from "../../../../CustomTypography";

/**
 * This component represents a content of header button.
 */
const Content = ({ text, component, isSelected }) => {
  Content.propTypes = {
    text: PropTypes.string,
    isSelected: PropTypes.bool,
    component: PropTypes.shape(),
  };
  Content.defaultProps = {
    text: "",
    isSelected: false,
    component: undefined,
  };

  const theme = useTheme();

  return (
    <>
      <CustomTypography
        component="span"
        variant="headerButton"
        weight={isSelected ? "bold" : "regular"}
        color={isSelected ? theme.primary0 : theme.textSecondary}
      >
        {text}
        {component}
      </CustomTypography>
    </>
  );
};

export default Content;
