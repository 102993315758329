import { createUseStyles } from "react-jss";
import { breakpoints } from "../../../constants/sizes";

const useStyles = createUseStyles((theme) => ({
  container: {
    display: "flex",
    width: "100%",
    "& .mobile-menu-button-icon": {
      "& rect": {
        fill: theme.background0,
      },
    },
    "& path": {
      fill: theme.textSecondary,
    },

    [breakpoints.md]: {
      "& .header-root": {
        height: 62,
      },
    },
  },
  searchButton: {
    display: "none !important",
    [breakpoints.md]: {
      display: "block !important",
    },
  },
}));

export default useStyles;
