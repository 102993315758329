import { createUseStyles } from "react-jss";

const useStyles = createUseStyles(() => ({
  languageIcon: {
    height: 16,
  },
  downIcon: {
    height: 16,
    marginTop: 5,
    transform: "scale(0.6)",
  },
  buttonText: {
    textTransform: "none",
  },
  menuPopOver: {
    "& .MuiPaper-root": {
      marginTop: 5,
      borderRadius: 8,
    },
    "& .MuiList-padding": {
      paddingTop: 0,
      paddingBottom: 0,
    },
    "& .MuiMenuItem-root": {
      paddingTop: 8,
      paddingBottom: 8,

      "&:active": {
        transition: "background-color 0.15s ease",
        cursor: "pointer",
      },
      "& .MuiTouchRipple-root span": {
        display: "none",
      },
    },
  },
}));

export default useStyles;
