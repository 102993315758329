import React, { memo } from "react";
import PropTypes from "prop-types";
import { useTheme } from "react-jss";
import { Marker } from "react-map-gl";

import CustomMarkerButton from "../../CustomMarker";
import useStyles from "./styles";

const CustomMarker = ({ result, onClick, isSelected, isFeatured }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const [longitude, latitude] = result.geometry.coordinates;

  if (!longitude) {
    return null;
  }

  return (
    <Marker
      latitude={parseFloat(latitude)}
      longitude={parseFloat(longitude)}
      className={
        isSelected
          ? classes.markerDestak
          : isFeatured
          ? classes.markerFeatured
          : classes.marker
      }
      offsetLeft={-15}
      offsetTop={-10}
    >
      <CustomMarkerButton
        size={32}
        type={result.properties.category}
        onClick={onClick}
        isFeatured={isFeatured}
      />
    </Marker>
  );
};

CustomMarker.propTypes = {
  result: PropTypes.shape().isRequired,
  onClick: PropTypes.func,
  isSelected: PropTypes.bool,
  isFeatured: PropTypes.bool,
};

CustomMarker.defaultProps = {
  onClick: undefined,
  isSelected: false,
  isFeatured: false,
};

export default memo(CustomMarker);
