import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { useTheme } from "react-jss";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Popper from "@material-ui/core/Popper";
import ArrowDown from "../Icons/ArrowDown";
import DateInput from "./DateInput";
import { months } from "./Constants";
import useStyles from "./styles";
import CustomButton from "../CustomButton";
/**
 * If showModalOnLabelClick is provided the component won't show the default popper
 * @param {} param0
 * @returns
 */
const CustomDatePicker = ({
  date,
  minYear,
  maxYear,
  onApplyClick,
  title,
  /* Hide default datepicker popup */
  hideDefaultPopper,
  /* Only disptach this prop if hideDefaultPopper is true */
  onLabelClick,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedDate, setSelectedDate] = useState(date);
  const modalIsOpen = Boolean(anchorEl);
  const { t } = useTranslation();
  const isSm = useMediaQuery("(max-width: 600px)");

  useEffect(() => {
    setSelectedDate(date);
  }, [date]);

  function selectedMonth() {
    if (!date) return 0;
    return months[date.getMonth()];
  }

  function selectedYear() {
    if (!date) return 0;
    return date.getFullYear();
  }

  const handleDateClick = (event) => {
    if (hideDefaultPopper && onLabelClick) onLabelClick(event.currentTarget);
    else setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    handleClickAway();
  }, [isSm]);

  const handleApplyClick = () => {
    if (onApplyClick) {
      if (date.getTime() !== selectedDate.getTime()) onApplyClick(selectedDate);
      if (handleClickAway) handleClickAway();
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway} mouseEvent="onMouseUp">
      <div>
        <button
          type="button"
          onClick={handleDateClick}
          className={classNames([classes.inputButton, "date-selector-button"])}
        >
          {`${t(`date.month.${selectedMonth()}.short`)} ${selectedYear()}`}
          <ArrowDown />
        </button>
        <Popper
          id="popper"
          placement="bottom-start"
          className={classes.paper}
          open={modalIsOpen}
          anchorEl={anchorEl}
        >
          <div className={classes.inputWrapper}>
            <DateInput
              date={date}
              minYear={minYear}
              maxYear={maxYear}
              onChange={(newDate) => setSelectedDate(newDate)}
              title={title}
              onClickAway={handleClickAway}
            />
            <div>
              <CustomButton onClick={handleApplyClick}>Aplicar</CustomButton>
            </div>
          </div>
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

CustomDatePicker.propTypes = {
  date: PropTypes.instanceOf(Date),
  minYear: PropTypes.number,
  maxYear: PropTypes.number,
  title: PropTypes.string,
  onLabelClick: PropTypes.func,
  onApplyClick: PropTypes.func,
  hideDefaultPopper: PropTypes.bool,
};

CustomDatePicker.defaultProps = {
  date: undefined,
  minYear: undefined,
  maxYear: undefined,
  title: undefined,
  onLabelClick: undefined,
  onApplyClick: undefined,
  hideDefaultPopper: false,
};

export default CustomDatePicker;
