import React, { useEffect, useState } from "react";
import { useTheme } from "react-jss";
import { Redirect, useParams } from "react-router";
import useSWR from "swr";
import { useTranslation } from "react-i18next";
import CustomTypography from "../../../components/CustomTypography";
import api from "../../../services/hcms/api";
import { getStrapiMedia } from "../../../services/hcms/media";
import useStyles from "./styles";
import Breadcrumb from "../../../components/Agency/Breadcrumb";
import ArticleInfo from "../../../components/Agency/ArticleInfo";
import ShareButton from "../../../components/Agency/Article/ShareButton";
import HorizontalArticleList from "../../../components/Agency/Article/HorizontalArticleList";
import Markdown from "../../../components/Markdown";
import ArticleBodySkeleton from "../../../components/Skeleton/ArticleBodySkeleton";
import CustomMetaTags from "../../../components/CustomMetaTags";

/**
 * This component represents an Article page.
 * @returns article page
 */
export default function Article() {
  const { slug } = useParams();
  const [moreArticles, setMoreArticles] = useState(undefined);

  const fetcher = (url) => api.get(url).then((res) => res.data);
  /**
   * Fetch current article.
   */
  const { data: article, error } = useSWR(`/articles/slug/${slug}`, fetcher);

  /**
   * This userEffect fetch more articles.
   */
  useEffect(() => {
    let isSubscribed = true;

    if (article) {
      api
        .get(`/articles/more/${article.id}`)
        .then(({ data }) => {
          if (isSubscribed) {
            setMoreArticles(data);
          }
        })
        .catch(() => {});
    }

    return () => {
      isSubscribed = false;
    };
  }, [article]);

  const theme = useTheme();
  const classes = useStyles({ theme });
  const { t } = useTranslation();

  if (!article) {
    if (error) {
      return <Redirect to="/404" />;
    }
  }

  return (
    <>
      {!article ? (
        <div className={classes.section}>
          <ArticleBodySkeleton />
        </div>
      ) : (
        <div className={classes.section}>
          <CustomMetaTags
            title={article.title}
            imageUrl={getStrapiMedia(article.thumbnail)}
            description={article.body.slice(0, 200)}
          />

          <Breadcrumb
            preview={[{ link: "/agencia", title: "Agência RHISA" }]}
            current={article.title}
          />

          <CustomTypography variant="h2">{article.title}</CustomTypography>

          <div className={classes.articleInfoWrapper}>
            <ArticleInfo
              columnist={article.columnist}
              publishedAt={article.published}
            />

            <ShareButton />
          </div>

          <img
            alt="articleImg"
            src={getStrapiMedia(article.thumbnail)}
            className={classes.articleImg}
          />
          <CustomTypography
            color={theme.textPrimary}
            style={{ marginBottom: 40 }}
            variant="noticeBody"
          >
            <Markdown hasCustomRender>{article.body}</Markdown>
          </CustomTypography>
        </div>
      )}

      <div className={classes.moreArticlesBackground}>
        <div className={classes.section}>
          <HorizontalArticleList
            articles={moreArticles}
            title={t("agency.article.moreArticles")}
          />
        </div>
      </div>
    </>
  );
}
