import React from "react";
import Grid from "@material-ui/core/Grid";
import PodcastSkeleton from "../PodcastSkeleton";

export default function PodcastListSkeleton() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <PodcastSkeleton />
      </Grid>
      <Grid item xs={6}>
        <PodcastSkeleton />
      </Grid>
    </Grid>
  );
}
