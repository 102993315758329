import React, { memo } from "react";
import PropTypes from "prop-types";
import { useTheme } from "react-jss";
import { useMediaQuery } from "@material-ui/core";
import { Popup } from "react-map-gl";
import Button from "@material-ui/core/Button";
import CloseSvg from "../../../Icons/Close";
import ResultInfo from "../../SearchContainer/ResultInfo";
import resultTypes from "../../ResultTypes";
import useStyles from "./styles";

const CustomPopup = ({ latitude, longitude, selectedResult, onClosePopup }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const iSm = useMediaQuery("(max-width: 600px)");

  return (
    <>
      {!iSm && (
        <div className={classes.popupLargeWrapper}>
          <Popup
            latitude={latitude}
            longitude={longitude}
            onClose={onClosePopup}
            anchor="top"
            closeOnClick={false}
          >
            <div className={classes.popup}>
              <ResultInfo
                result={selectedResult.result}
                hideImage={
                  selectedResult.result.type !== resultTypes.RESEARCHER
                }
                showBottomIcon={false}
                centralizeImage
                hideDivider
                hideLabel
              />
            </div>
          </Popup>
        </div>
      )}
      {iSm && (
        <div className={classes.popupSmWrapper}>
          <div className={classes.closeButtonWrapper}>
            <Button classes={{ root: classes.button }} onClick={onClosePopup}>
              <CloseSvg />
            </Button>
          </div>
          <div className={classes.popup}>
            <ResultInfo
              result={selectedResult.result}
              hideImage={selectedResult.result.type !== resultTypes.RESEARCHER}
              showBottomIcon={false}
              centralizeImage
              hideDivider
              hideLabel
              variant="popup"
            />
          </div>
        </div>
      )}
    </>
  );
};

CustomPopup.propTypes = {
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  selectedResult: PropTypes.shape(),
  onClosePopup: PropTypes.func,
};

CustomPopup.defaultProps = {
  latitude: undefined,
  longitude: undefined,
  selectedResult: undefined,
  onClosePopup: undefined,
};

export default memo(CustomPopup);
