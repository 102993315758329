import * as React from "react";

function Cooperation(props) {
  return (
    <svg
      width="30"
      height="28"
      viewBox="0 0 30 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.6588 3.32472C4.27857 1.70533 6.45109 0.758145 8.74042 0.673236C11.0298 0.588327 13.2665 1.37197 15.0019 2.86693C16.7357 1.37446 18.9695 0.591699 21.2561 0.675345C23.5427 0.758991 25.7133 1.70288 27.3333 3.31803C28.9534 4.93319 29.9034 7.10041 29.993 9.38583C30.0827 11.6712 29.3055 13.9062 27.8169 15.6432L16.9748 26.5157C16.4767 27.0138 15.8086 27.3053 15.1045 27.3317C14.4005 27.3581 13.7124 27.1175 13.1784 26.6581L13.0248 26.5171L2.18407 15.6432C0.696317 13.9077 -0.0812824 11.6748 0.00673252 9.39098C0.0947474 7.10716 1.04189 4.94069 2.6588 3.32472V3.32472ZM4.63314 5.29825C3.49132 6.43991 2.83313 7.97722 2.79517 9.59111C2.75721 11.205 3.3424 12.7715 4.42928 13.9656L4.63314 14.1805L15.0005 24.5436L22.4049 17.1408L17.4691 12.207L15.9891 13.6864C15.6001 14.0754 15.1384 14.3839 14.6302 14.5945C14.122 14.805 13.5773 14.9135 13.0271 14.9136C11.9161 14.9139 10.8505 14.4729 10.0647 13.6878C9.27884 12.9027 8.83723 11.8377 8.83697 10.7271C8.83671 9.61654 9.27782 8.55134 10.0633 7.76585L12.9982 4.83069C11.8302 3.89888 10.3682 3.41361 8.87459 3.46196C7.38096 3.5103 5.95344 4.08909 4.84816 5.09447L4.63314 5.29825V5.29825ZM16.4819 9.2453C16.7438 8.98364 17.0989 8.83665 17.4691 8.83665C17.8394 8.83665 18.1944 8.98364 18.4563 9.2453L24.3793 15.1659L25.3678 14.1805C26.5288 13.0209 27.1897 11.4532 27.2093 9.81274C27.2289 8.17229 26.6055 6.5893 25.4726 5.4023C24.3397 4.21531 22.7871 3.51854 21.1468 3.46102C19.5066 3.40349 17.909 3.98977 16.6956 5.09447L16.4819 5.29825L12.039 9.73938C11.797 9.98113 11.6522 10.3033 11.6321 10.6447C11.612 10.9861 11.7181 11.323 11.9301 11.5915L12.039 11.7129C12.2808 11.9548 12.6031 12.0995 12.9447 12.1196C13.2863 12.1397 13.6233 12.0337 13.8918 11.8218L14.0133 11.7129L16.4819 9.2453V9.2453Z"
        fill="#00A5A8"
      />
    </svg>
  );
}

export default Cooperation;
