import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import Home from "./Home";
import PersonalPage from "./PersonalPage";
import useStyles from "./styles";

/**
 * This page wraps ever single page that Columnists contains.
 */
export default function Columnists() {
  const match = useRouteMatch();
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Switch>
        <Route path={`${match.path}/:id`} component={PersonalPage} />
        <Route path={match.path} component={Home} />
      </Switch>
    </div>
  );
}
