import { createUseStyles } from "react-jss";
import { breakpoints, sideBar } from "../../../constants/sizes";

const useStyles = createUseStyles((theme) => ({
  container: {
    width: "100%",
    minWidth: sideBar.Width,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    [breakpoints.smd]: {
      minWidth: "initial",
    },
  },
  actionWrapper: {
    paddingLeft: sideBar.paddingHorizontal,
    paddingRight: sideBar.paddingHorizontal,
  },
  paginatorWrapper: {
    marginTop: 10,
    marginBottom: 10,
    display: "flex",
    justifyContent: "center",
    "& .pagination-button": {
      borderRadius: 8,
      height: 35,
      "& svg": {
        display: "none",
      },
    },
    "& .pagination-button-page, .pagination-button-page-selected": {
      width: 35,
      height: 35,
      borderRadius: 8,
      marginLeft: 5,
    },
    "& .pagination-button-page": {
      color: theme.primary0,
    },
    "& .pagination-button-page-selected": {
      backgroundColor: theme.primary0,
      color: theme.textTertiary,
    },
    "& .pagination-page-bottom-selected": {
      display: "none",
    },
  },
  contentWrapper: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    [breakpoints.md]: {
      height: "initial",
      paddingBottom: 60,
    },
  },
}));

export default useStyles;
