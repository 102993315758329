import React, { useState, useRef, useContext } from "react";
import { useTheme } from "react-jss";
import { useTranslation } from "react-i18next";
import Divider from "@material-ui/core/Divider";
import { isEmail } from "validator";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useHistory } from "react-router-dom";
import micorriza404 from "../../../assets/images/404rhisa.svg";
import CustomTypography from "../../../components/CustomTypography";
import CustomTextField from "../../../components/CustomTextField";
import CustomButton from "../../../components/CustomButton";
import PartnerImg from "../../../assets/images/partner.svg";
import Header from "../../../components/Auth/Header";
import PasswordTextField from "../../../components/Auth/PasswordTextField";
import SuccessModal from "../../../components/Modal/SuccessModal";
import LoadingModal from "../../../components/Modal/LoadingModal";
import AuthContext from "../../../contexts/auth";
import useStyles from "./styles";
import ErrorViewer from "../../../components/Auth/ErrorViewer";

const Register = () => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const {
    signUp,
    error,
    loading,
    successModalIsOpen,
    closeSuccesModal,
    clearErrors,
  } = useContext(AuthContext);
  const formRef = useRef(null);
  const [errors, setErrors] = useState({});
  const lattesLinkRegex = /^\s*http:\/\/lattes\.cnpq\.br\/\d{16}\s*$/gm;
  const { t } = useTranslation();
  const history = useHistory();

  const validateFields = () => ({
    name: formRef.current.name.value.length < 5,
    email: !isEmail(formRef.current.email.value),
    phone: !isValidPhoneNumber(
      `${formRef.current.phone.value.includes("+55") ? "" : "+55"}${
        formRef.current.phone.value
      }`
    ),
    password: formRef.current.password.value.length < 8,
    lattesLink: !lattesLinkRegex.test(formRef.current.lattesLink.value),
  });

  const handleFormSubmit = () => {
    clearErrors();
    const currentErrors = validateFields();
    const hasErrors =
      currentErrors.name ||
      currentErrors.email ||
      currentErrors.phone ||
      currentErrors.password ||
      currentErrors.lattesLink;
    if (!hasErrors) {
      signUp({
        name: formRef.current.name.value,
        email: formRef.current.email.value,
        phone: formRef.current.phone.value,
        password: formRef.current.password.value,
        lattesId: formRef.current.lattesLink.value.split("/")[3],
      });
    }
    setErrors(currentErrors);
  };

  const handleCloseModal = () => {
    closeSuccesModal();
    history.push("/");
  };

  return (
    <>
      <LoadingModal isLoading={loading} />
      <SuccessModal
        isOpen={successModalIsOpen}
        onRequestClose={handleCloseModal}
      >
        {t("forms.signUpSuccess")}
      </SuccessModal>
      <div className={classes.container}>
        <div className={classes.bgSection}>
          <img
            alt="micorriza"
            src={micorriza404}
            className={classes.micorriza}
          />
        </div>
        <div className={classes.formWrapper}>
          <div className={classes.section}>
            <div className={classes.form}>
              <Header />
              <form ref={formRef}>
                <div>
                  <CustomTypography variant="h3" className={classes.item}>
                    {t("forms.signUp")}
                  </CustomTypography>
                  <CustomTypography variant="body" color={theme.textSecondary}>
                    {t("forms.signUpSubtitle")}
                  </CustomTypography>
                  <div className={classes.dividerWrapper}>
                    <Divider />
                  </div>
                  <div className={classes.item}>
                    <CustomTypography variant="tag" color={theme.textPrimary}>
                      {t("forms.name")}*
                    </CustomTypography>
                    <CustomTextField
                      name="name"
                      error={errors.name}
                      helperText={errors.name ? t("forms.nameError") : ""}
                      placeholder={t("forms.fullNamePlaceholder")}
                      variant="outlined"
                      style={{ marginTop: 10 }}
                      className={classes.textField}
                    />
                  </div>
                  <div className={classes.item}>
                    <CustomTypography variant="tag" color={theme.textPrimary}>
                      {t("forms.email")}*
                    </CustomTypography>
                    <CustomTextField
                      name="email"
                      error={errors.email}
                      helperText={errors.email ? t("forms.emailError") : ""}
                      placeholder={t("forms.emailPlaceholder")}
                      variant="outlined"
                      style={{ marginTop: 10 }}
                      className={classes.textField}
                    />
                  </div>
                  <div className={classes.item}>
                    <CustomTypography variant="tag" color={theme.textPrimary}>
                      {t("forms.phone")}*
                    </CustomTypography>
                    <CustomTextField
                      name="phone"
                      error={errors.phone}
                      helperText={errors.phone ? t("forms.phoneError") : ""}
                      placeholder={t("forms.phonePlaceholder")}
                      variant="outlined"
                      style={{ marginTop: 10 }}
                      className={classes.textField}
                    />
                  </div>
                  <div className={classes.item}>
                    <CustomTypography variant="tag" color={theme.textPrimary}>
                      {t("forms.lattesLink")}*
                    </CustomTypography>
                    <CustomTextField
                      name="lattesLink"
                      error={errors.lattesLink}
                      helperText={
                        errors.lattesLink ? t("forms.lattesLinkError") : ""
                      }
                      placeholder={t("forms.lattesLinkPlaceholder")}
                      variant="outlined"
                      style={{ marginTop: 10 }}
                      className={classes.textField}
                    />
                  </div>
                  <div className={classes.item}>
                    <CustomTypography variant="tag" color={theme.textPrimary}>
                      {t("forms.createPassword")}*
                    </CustomTypography>
                    <PasswordTextField
                      name="password"
                      error={errors.password}
                      helperText={
                        errors.password ? t("forms.passwordError") : ""
                      }
                      placeholder={t("forms.createPasswordPlaceholder")}
                      variant="outlined"
                      style={{ marginTop: 10 }}
                      className={classes.textField}
                    />
                  </div>
                  <ErrorViewer error={error} />
                  <div className={classes.buttonWrapper}>
                    <CustomButton onClick={handleFormSubmit}>
                      {t("forms.doSignUpButton")}
                    </CustomButton>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className={classes.sideImage}>
            <div className={classes.fifthFoldImg}>
              <img
                alt="partner"
                src={PartnerImg}
                className={classes.partnerImg}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
