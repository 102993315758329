import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";

// Dimension prop type
const dimensionPropType = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.string,
]);

// Size presets
const sizePresets = {
  large: {
    width: "100%",
    height: 152,
  },
  compact: {
    width: 300,
    height: 80,
  },
};

/**
 * DeezerPlayer component
 */
const DeezerPlayer = ({ url, size }) => {
  const [trackId, setTrackId] = useState("");

  useEffect(() => {
    axios
      .get(
        `https://cors-anywhere.herokuapp.com/https://api.deezer.com/oembed?url=${url}`,
        {
          method: "GET",
          mode: "no-cors",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        const { data } = response;
        const splitedData = data.url.split("/");
        const id = splitedData[splitedData.length - 1];
        setTrackId(id);
      });
  }, []);

  if (!trackId) return null;

  if (typeof size === "string") {
    size = sizePresets[size];
  }

  return (
    <iframe
      title="Deezer"
      className="DeezerPlayer"
      src={`https://widget.deezer.com/widget/dark/episode/${trackId}`}
      width={size.width}
      height={size.height}
      frameBorder="0"
      allowtransparency="true"
      allow="encrypted-media; clipboard-write"
    />
  );
};

DeezerPlayer.propTypes = {
  // Deezer share URL
  url: PropTypes.string.isRequired,

  // Size as either a preset or as custom dimensions
  size: PropTypes.oneOfType([
    PropTypes.oneOf(["large", "compact"]),
    PropTypes.shape({
      width: dimensionPropType,
      height: dimensionPropType,
    }),
  ]),
};

DeezerPlayer.defaultProps = {
  size: "large",
};

export default DeezerPlayer;
