/* eslint-disable no-unused-vars */
import React, { useMemo, memo } from "react";
import { Menu } from "@material-ui/core";
import useStyles from "./styles";

const StyledMenu = (props) => {
  const classes = useStyles();

  return useMemo(
    () => (
      <Menu
        classes={{ paper: classes.paper }}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        {...props}
      />
    ),
    [props]
  );
};
export default memo(StyledMenu);
