import * as React from "react";

function DownArrowFilled(props) {
  return (
    <svg
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.15397 9.40992C6.1925 9.46854 6.24406 9.51647 6.30421 9.54959C6.36436 9.5827 6.43129 9.6 6.49924 9.6C6.56718 9.6 6.63411 9.5827 6.69426 9.54959C6.75441 9.51647 6.80597 9.46854 6.8445 9.40992L10.6248 3.69031C10.6686 3.62434 10.6942 3.54707 10.699 3.4669C10.7038 3.38672 10.6875 3.30671 10.6519 3.23556C10.6163 3.1644 10.5628 3.10482 10.4971 3.06329C10.4315 3.02176 10.3562 2.99987 10.2795 3H2.71894C2.64243 3.00033 2.56745 3.0225 2.50207 3.06413C2.43669 3.10576 2.38338 3.16527 2.34787 3.23626C2.31236 3.30725 2.296 3.38704 2.30055 3.46704C2.30509 3.54704 2.33038 3.62424 2.37367 3.69031L6.15397 9.40992Z"
        fill="#00A5A8"
      />
    </svg>
  );
}

export default DownArrowFilled;
