/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo } from "react";
import PropTypes from "prop-types";
import { useTheme } from "react-jss";
import { useTranslation } from "react-i18next";
import Link from "@material-ui/core/Link";
import CustomTypography from "../../../CustomTypography";
import CustomPaper from "../../../CustomPaper";
import useStyles from "./styles";
import AnalysisSectionSkeleton from "../../../Skeleton/AnalysisSectionSkeleton";
// import resultTypes from "../../ResultTypes";

const Section = ({
  name,
  title,
  children,
  viewMore,
  onViewMoreClick,
  showViewMore,
  loading,
  isEmpty,
  emptyMessage,
  ...props
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();

  const handleViewMore = () => {
    if (onViewMoreClick) onViewMoreClick(name);
  };

  return (
    <div {...props}>
      <div className={classes.content}>
        <div className={classes.header}>
          <div className={classes.title}>
            <CustomTypography variant="noticeTitle" color={theme.textPrimary}>
              {title}
            </CustomTypography>
          </div>

          <div style={{ textAlign: "right" }}>
            <CustomTypography
              color={theme.primary1}
              variant="searchResultTitle"
            >
              {showViewMore && (
                <Link
                  onClick={handleViewMore}
                  style={{ textDecoration: "none" }}
                  className={classes.link}
                >
                  {viewMore ? t("resultPage.hide") : t("resultPage.viewAll")}
                </Link>
              )}
            </CustomTypography>
          </div>
        </div>
      </div>

      <CustomPaper>
        <div className={classes.contentWrapper}>
          {loading ? (
            <AnalysisSectionSkeleton />
          ) : isEmpty ? (
            <div className={classes.emptyMessageWrapper}>{emptyMessage}</div>
          ) : (
            children
          )}
        </div>
      </CustomPaper>
    </div>
  );
};

Section.propTypes = {
  children: PropTypes.node,
  viewMore: PropTypes.bool,
  onViewMoreClick: PropTypes.func,
  name: PropTypes.string,
  title: PropTypes.string,
  showViewMore: PropTypes.bool,
  loading: PropTypes.bool,
  isEmpty: PropTypes.bool,
  emptyMessage: PropTypes.string,
};

Section.defaultProps = {
  children: undefined,
  viewMore: undefined,
  onViewMoreClick: undefined,
  name: "none",
  title: "Section title",
  showViewMore: true,
  loading: true,
  isEmpty: false,
  emptyMessage: "Empty section",
};

export default memo(Section);
