/* eslint-disable no-unused-vars */
import { createUseStyles } from "react-jss";
import { breakpoints } from "../../../constants/sizes";

const useStyles = createUseStyles((/* theme */) => ({
  wrapper: {
    display: "flex",
    height: "auto",
    marginBottom: 20,

    [breakpoints.xsm]: {
      flexFlow: "row wrap",
    },
  },
  bodyPreview: {
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    height: "3.05rem;",
    marginBottom: 10,
    [breakpoints.sm]: {
      maxHeight: "2.65rem;",
    },
  },

  titleTypography: {
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  moreVideoTitle: {
    margin: "0px 0px 10px 0px",
    height: 50,
    [breakpoints.xsm]: {
      height: 40,
    },
  },
  videoThumb: {
    width: 182,
    marginRight: 16,
    height: 103,

    [breakpoints.xsm]: {
      width: "100%",
      minHeight: 170,
      marginRight: 0,
    },
  },
  link: {
    display: "flex",
    textDecoration: "none",
    "&:hover": {},
  },
  videoInfo: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "2px 0px 5px 0px",
  },

  wrapperHighlight: {
    height: "auto",
    marginBottom: 20,

    [breakpoints.xsm]: {
      flexFlow: "row wrap",
    },
  },
  videoThumbHighlight: {
    width: "100%",
    maxHeight: (props) => (props.miniThumb ? 170 : 250),
    [breakpoints.smd]: {
      height: 170,
    },
  },
  moreVideoTitleHighlight: {
    margin: "0px 0px 10px 0px",
    height: 40,
    [breakpoints.xsm]: {
      height: 40,
    },
  },
}));

export default useStyles;
