import { createUseStyles } from "react-jss";
// import { defaultContainer, breakpoints } from "../../../constants/sizes";

const useStyles = createUseStyles((theme) => ({
  item: {
    width: "100%",
    marginBottom: 20,
  },
  textField: {
    width: "100%",
  },

  submitButtonWrapper: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 20,
    "& .MuiButton-root": {
      width: 204,
      backgroundColor: (props) =>
        props.hasErrors ? theme.secondary0 : theme.primary0,
      color: theme.background0,
      "&:hover": {
        backgroundColor: (props) =>
          props.hasErrors ? theme.secondary1 : theme.primary1,
      },
    },
  },
}));

export default useStyles;
