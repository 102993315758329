import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useTheme } from "react-jss";
import useSWR from "swr";
import { useTranslation } from "react-i18next";
import api from "../../../../services/hcms/api";
import useStyles from "./styles";
import Breadcrumb from "../../../../components/Agency/Breadcrumb";
import ArticlesViewer from "../../../../components/Agency/ArticlesViewer";
import LoadMoreButton from "../../../../components/LoadMoreButton";

/**
 * This component represents personal page from one Columnist .
 * @returns Columnist page
 */
export default function PersonalPage() {
  const { id } = useParams();
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [articles, setArticles] = useState([]);
  const [isLoadEnded, setisLoadEnded] = useState(false);
  const [isLoadingContent, setisLoadingContet] = useState({
    articles: true,
    columnists: true,
  });

  useEffect(() => {
    // Id change represents access to another columnist page,
    // When this happens, the aplication must reaload the content, for that
    setisLoadingContet(() => {
      // Inside the loadingContet setter reset the number of page
      setPage(0); // That avoids unexpected behaviors
      return { articles: true, columnists: true };
    });
    setArticles([]);
  }, [id]);

  /**
   * Fetch a list of three articles when the columnist id or the page number
   * changes.
   */
  const { data: moreArticles } = useSWR(
    `articles?columnist.id=${id}&_limit=3&_start=${page * 3}`,
    async (swrRoute) => {
      const response = await api.get(swrRoute);
      return response.data;
    }
  );

  function loadArticles() {
    if (moreArticles) {
      setArticles((oldArticles) => {
        const oldLength = oldArticles.length;
        const newLength = oldLength + moreArticles.length;
        setisLoadEnded(newLength - oldLength < 3);
        setisLoadingContet((oldState) => ({ ...oldState, articles: false }));
        if (page === 0) return moreArticles;
        if (oldLength === newLength) return oldArticles;
        return [...oldArticles, ...moreArticles];
      });
    }
  }

  useEffect(() => {
    loadArticles(); // Load more articles when moreArticles dependency change its value
  }, [moreArticles]);

  function loadMore() {
    if (!isLoadingContent.articles) {
      setisLoadingContet((oldState) => ({
        ...oldState,
        articles: true,
      }));
      setPage((oldPageValue) => oldPageValue + 1);
    }
  }

  const columnist =
    articles && articles.length > 0 ? articles[0].columnist : null;

  const { data: columnists } = useSWR(
    "/columnists?_sort=name:ASC&_limit=6",
    async (swrRoute) => {
      const response = await api.get(swrRoute);
      setisLoadingContet((oldState) => ({ ...oldState, columnists: false }));
      return response.data;
    }
  );

  return (
    <>
      <div className={classes.section}>
        <ArticlesViewer
          breadcrumb={
            <>
              <Breadcrumb
                preview={[
                  { link: "/agencia", title: "Agência RHISA" },
                  { link: "/agencia/colunistas", title: "Colunistas" },
                ]}
                current={`${columnist ? columnist.name : ""}`}
                hideOnSmall={false}
              />
            </>
          }
          isLoadingArticles={isLoadingContent.articles}
          isLoadingColumnists={isLoadingContent.columnists}
          articles={articles}
          columnists={columnists}
          currentColumnist={columnist}
          title={`${t("agency.article.articlesFrom")}${
            columnist ? columnist.name : ""
          }`}
          articlesBottom={
            <LoadMoreButton
              isLoadEnded={isLoadEnded}
              onClick={() => loadMore()}
              visible={!isLoadingContent.articles}
              isLoading={isLoadingContent.articles}
            />
          }
        />
      </div>
    </>
  );
}
