import { createUseStyles } from "react-jss";
// import { breakpoints, defaultContainer } from "../../../constants/sizes";

const useStyles = createUseStyles((theme) => ({
  contentWrapper: {
    width: "100%",
    paddingBottom: 5,
  },
  title: {
    width: "100%",
    fontWeight: 400,
    fontSize: 12,
    color: theme.textPrimary,
  },
  bottomWrapper: {
    position: "relative",
    paddingTop: 10,
  },
  bottom: {
    width: "100%",
    fontWeight: 400,
    fontSize: 12,
    color: theme.textPrimary,
    bottom: 0,
  },
  content: {
    display: "grid",
    gridTemplateColumns: "20px auto auto",
    gridGap: 5,
    width: "100%",
    fontWeight: 700,
    fontSize: 20,
    color: theme.textPrimary,
    paddingTop: 10,
  },
}));

export default useStyles;
