import { createUseStyles } from "react-jss";
// import { breakpoints } from "../../../../constants/sizes";

const useStyles = createUseStyles((theme) => ({
  container: {},
  applyButton: {
    "&.MuiButton-root": {
      minWidth: 0,
      minHeight: 0,
      width: "100%",
      padding: 0,
      display: "flex",
      alignItems: "center",

      color: theme.background0,
    },
  },
  filterAplied: {
    width: 22,
    height: 22,
    borderRadius: "100%",
    backgroundColor: theme.alert,
    color: theme.background0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 14,
    fontWeight: "bold",
    marginLeft: 10,
  },
}));

export default useStyles;
