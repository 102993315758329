import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "react-jss";
import { useTranslation } from "react-i18next";
import useStyles from "./styles";

const ErrorViewer = ({ error }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { t } = useTranslation();

  function ErrorMessage() {
    switch (error) {
      case "Email is already in use":
        return t("forms.errors.emailInUse");
      default:
        return t("forms.errors.general");
    }
  }

  return error ? (
    <div className={classes.errorWrapper}>
      <ErrorMessage />
    </div>
  ) : null;
};

ErrorViewer.propTypes = {
  error: PropTypes.string,
};

ErrorViewer.defaultProps = {
  error: "",
};

export default ErrorViewer;
