import { createUseStyles } from "react-jss";
// import { breakpoints } from "../../../constants/sizes";

const useStyles = createUseStyles((theme) => ({
  clusterMark: {
    borderRadius: "50%",
    border: `1px solid ${theme.background0}`,
    backgroundColor: theme.primary0,
    color: theme.background0,
    fontSize: 16,
    cursor: "pointer",
  },
}));

export default useStyles;
