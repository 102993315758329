import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "react-jss";
import { useTranslation } from "react-i18next";
import CustomPaper from "../../../../../CustomPaper";
import resultTypes from "../../../../ResultTypes";
import CustomTypography from "../../../../../CustomTypography";
import InstituteImg from "../../../../../InstituteImg";
import useStyles from "./styles";

function InstitutionalBond({
  institute,
  yearStartBond,
  yearEndBond,
  timeBond,
  image,
}) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();

  return (
    <div>
      <CustomTypography
        variant="noticeTitle"
        color={theme.textPrimary}
        className={classes.title}
      >
        {t("resultPage.institutionalBond.title")}
      </CustomTypography>
      <CustomPaper>
        <div className={classes.contentWrapper}>
          <div>
            <InstituteImg url={image} type={resultTypes.INSTITUTE} />
          </div>
          <div>
            <div className={classes.institute}>{institute}</div>
            <div className={classes.description}>
              <div>{yearStartBond}</div>
              <div>-</div>
              <div>
                {yearEndBond || t("resultPage.institutionalBond.current")}
              </div>
              <div className={classes.elipse} />
              <div>
                {t("resultPage.institutionalBond.yearWithCount", {
                  count: parseFloat(timeBond),
                })}
              </div>
            </div>
          </div>
        </div>
      </CustomPaper>
    </div>
  );
}

InstitutionalBond.propTypes = {
  institute: PropTypes.string,
  yearStartBond: PropTypes.number,
  yearEndBond: PropTypes.number,
  timeBond: PropTypes.number,
  image: PropTypes.string,
};

InstitutionalBond.defaultProps = {
  institute: undefined,
  yearStartBond: undefined,
  yearEndBond: undefined,
  timeBond: undefined,
  image: undefined,
};

export default InstitutionalBond;
