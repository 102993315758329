import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "react-jss";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import SvgPlus from "../../../Icons/Plus";
import CustomPaper from "../../../CustomPaper";
import BarChart from "../../../Charts/BarChart";

import useStyles from "./styles";
import AnalysisCardSkeleton from "../../../Skeleton/AnalysisCardSkeleton";

const TotalPublicationsCard = ({
  totalArticles,
  publicationsForYear,
  loading,
  hideLastPublicationIndicator,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  const { startDate, endDate } = useSelector((state) => state.researcher);

  const currentYear = new Date().getFullYear();
  const findByYear = (pfy) => currentYear.toString() === pfy.year;

  return (
    <CustomPaper>
      {loading ? (
        <AnalysisCardSkeleton variant="totalPublication" />
      ) : (
        <>
          <div className={classes.contentWrapper}>
            <div className={classes.totalWrapper}>
              <div className={classes.title}>
                {t("resultPage.analysis.publicationsTotalTitle")}
              </div>
              <div className={classes.body}>
                {totalArticles && (
                  <>
                    <div className={classes.totalArticles}>{totalArticles}</div>
                    {!hideLastPublicationIndicator &&
                      publicationsForYear.find(findByYear) && (
                        <div className={classes.percentage}>
                          <SvgPlus />
                          <span style={{ marginLeft: 5 }}>
                            {t(
                              "resultPage.analysis.publicationsIncreaseThisYear",
                              {
                                total:
                                  publicationsForYear.find(findByYear).total,
                              }
                            )}
                          </span>
                        </div>
                      )}
                  </>
                )}
              </div>

              <div className={classes.bottomWrapper}>
                {!hideLastPublicationIndicator && (
                  <div className={classes.bottom}>
                    {publicationsForYear[0]
                      ? t("resultPage.analysis.lastPublicationDate", {
                          date: publicationsForYear[
                            publicationsForYear.length - 1
                          ].year,
                        })
                      : t("resultPage.analysis.emptyMessage.chart")}
                  </div>
                )}
              </div>
            </div>
            {publicationsForYear[0] && (
              <div className={classes.chartWrapper}>
                <div className={classes.title} style={{ textAlign: "right" }}>
                  {t("resultPage.analysis.yearRange", {
                    yearStart: startDate.getFullYear(),
                    yearEnd: endDate.getFullYear(),
                  })}
                </div>
                <div style={{ marginTop: 10, width: "100%", maxWidth: 250 }}>
                  <BarChart
                    valueName={t("resultPage.analysis.total")}
                    className={classes.barChart}
                    data={publicationsForYear.map((d) => ({
                      value: parseFloat(d.total),
                      name: d.year,
                    }))}
                    height={60}
                    width={280}
                    applyOpacity
                  />
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </CustomPaper>
  );
};

TotalPublicationsCard.propTypes = {
  publicationsForYear: PropTypes.arrayOf(PropTypes.shape()),
  totalArticles: PropTypes.number,
  loading: PropTypes.bool,
  hideLastPublicationIndicator: PropTypes.bool,
};

TotalPublicationsCard.defaultProps = {
  publicationsForYear: undefined,
  totalArticles: undefined,
  loading: true,
  hideLastPublicationIndicator: false,
};

export default TotalPublicationsCard;
