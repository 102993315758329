import React, { useState, useEffect } from "react";
import { useTheme } from "react-jss";
import useSWR from "swr";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga";
import { useParams } from "react-router";
import { Link, useHistory } from "react-router-dom";
import api from "../../../services/hcms/api";
import Header from "../../../components/Header";
import Cta from "../../../components/Cta";
import Footer from "../../../components/Footer";
import SectionContent from "../../../components/SectionContent";
import ContentList from "../../../components/Agency/ContentList";
import CustomTypography from "../../../components/CustomTypography";
import ColumnistBadge from "../../../components/Agency/Columnist/ColumnistBadge";
import MoreArticle from "../../../components/Agency/Article/MoreArticle";
import WriteMachine from "../../../components/Icons/WriteMachine";
import VideoInfo from "../../../components/Multimedia/VideoInfo";
import PodcastViewer from "../../../components/Multimedia/PodcastViewer";
import ContentListSkeleton from "../../../components/Skeleton/ContentListSkeleton";
import { isEmpty } from "../../../util/hooks";
import useStyles from "./styles";

/**
 * This component represents the page that shows the trending articles.
 * @returns Trending articles page
 */
export default function SearchPage() {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const history = useHistory();
  const { t } = useTranslation();
  const { query } = useParams();
  const [isLoadingContent, setisLoadingContet] = useState(true);

  // Initialize google analytics page view tracking
  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.set({ page: history.location.pathname }); // Update the user's current page
    ReactGA.pageview(history.location.pathname + window.location.search); // Record a pageview for the given page
  }, [history.location]);

  const { data: { columnists, articles, videos, podcasts } = {} } = useSWR(
    `search?query=${query}`,
    async (swrRoute) => {
      setisLoadingContet(true);
      const response = await api.get(swrRoute);
      setisLoadingContet(false);
      return response.data;
    }
  );

  const hasResults =
    !isEmpty(articles) ||
    !isEmpty(columnists) ||
    !isEmpty(videos) ||
    !isEmpty(podcasts);

  return (
    <>
      <div className={classes.mainContainer}>
        <div className={classes.section}>
          <Header currentSearchText={query} />
        </div>

        <SectionContent
          title={t("searchPage.title")}
          className={classes.sectionContent}
        />

        {isLoadingContent ? (
          <div className={classes.section}>
            <ContentListSkeleton />
          </div>
        ) : !hasResults ? (
          <div className={classes.section}>{t("searchPage.notFound")}</div>
        ) : (
          <>
            {" "}
            {!isEmpty(columnists) && (
              <SectionContent className={classes.sectionContent}>
                <ContentList
                  leftTitleIcon={<WriteMachine />}
                  horizontal
                  grayBackground
                  title={
                    <CustomTypography weight="bold" variant="titleVerticalList">
                      {t("agency.columnist.columnists")}
                    </CustomTypography>
                  }
                  rightTitle={
                    <Link to="/agencia/colunistas" className={classes.link}>
                      <div className={classes.rightTitle}>
                        <CustomTypography variant="h4" color={theme.primary0}>
                          {t("agency.columnist.viewAll")}
                        </CustomTypography>
                      </div>
                    </Link>
                  }
                >
                  {!isEmpty(columnists)
                    ? columnists.map((columnist) => (
                        <ColumnistBadge
                          key={columnist.id}
                          columnist={columnist}
                        />
                      ))
                    : null}
                </ContentList>
              </SectionContent>
            )}
            {!isEmpty(articles) && (
              <SectionContent className={classes.sectionContent}>
                <ContentList
                  horizontal
                  title={
                    <CustomTypography weight="bold" variant="h3">
                      {t("agency.article.title")}
                    </CustomTypography>
                  }
                  rightTitle={
                    <Link
                      to="/agencia/artigos-recentes"
                      className={classes.link}
                    >
                      <div className={classes.rightTitle}>
                        <CustomTypography variant="h4" color={theme.primary0}>
                          {t("searchPage.seeAllArticles")}
                        </CustomTypography>
                      </div>
                    </Link>
                  }
                >
                  {!isEmpty(articles)
                    ? articles.map((article) => (
                        <MoreArticle
                          key={article.id}
                          article={article}
                          columnist={article.columnist}
                        />
                      ))
                    : null}
                </ContentList>
              </SectionContent>
            )}
            {!isEmpty(videos) && (
              <SectionContent grayBackground className={classes.sectionContent}>
                <ContentList
                  horizontal
                  title={
                    <CustomTypography weight="bold" variant="h3">
                      {t("multimedia.videos.title")}
                    </CustomTypography>
                  }
                  rightTitle={
                    <Link
                      to="/agencia/multimidia/videos"
                      className={classes.link}
                    >
                      <div className={classes.rightTitle}>
                        <CustomTypography variant="h4" color={theme.primary0}>
                          {t("searchPage.seeAllVideos")}
                        </CustomTypography>
                      </div>
                    </Link>
                  }
                >
                  {!isEmpty(videos)
                    ? videos.map((video) => (
                        <VideoInfo key={video.id} data={video} miniThumb />
                      ))
                    : null}
                </ContentList>
              </SectionContent>
            )}
            {!isEmpty(podcasts) && (
              <SectionContent className={classes.sectionContent}>
                <ContentList
                  horizontal
                  twoItemsGrid
                  title={
                    <CustomTypography weight="bold" variant="h3">
                      {t("multimedia.podcasts.title")}
                    </CustomTypography>
                  }
                  rightTitle={
                    <Link to="/agencia/multimidia" className={classes.link}>
                      <div className={classes.rightTitle}>
                        <CustomTypography variant="h4" color={theme.primary0}>
                          {t("searchPage.seeAllPodcasts")}
                        </CustomTypography>
                      </div>
                    </Link>
                  }
                >
                  {!isEmpty(podcasts)
                    ? podcasts.map((podcast) => (
                        <PodcastViewer key={podcast.id} data={podcast} />
                      ))
                    : null}
                </ContentList>
              </SectionContent>
            )}
          </>
        )}

        <Cta />
        <Footer />
      </div>
    </>
  );
}
