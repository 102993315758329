import { createUseStyles } from "react-jss";
// import { breakpoints, defaultContainer } from "../../../constants/sizes";

const useStyles = createUseStyles((theme) => ({
  content: {
    paddingTop: 26,
  },
  header: {
    display: "grid",
    gridTemplateColumns: "auto auto",
  },
  divider: {
    marginTop: 16,
  },
  title: {
    paddingBottom: 20,
    marginTop: -5,
  },
  bottomPaper: {
    fontSize: 14,
    width: "100%",
    textAlign: "center",
    color: theme.textPrimary,
  },
  link: {
    textDecoration: "none",
    cursor: "pointer",
  },
  contentWrapper: {
    width: "100%",
    "& .divider:last-child": {
      display: "none",
    },
  },
  emptyMessageWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 50,
    fontSize: 12,
    fontStyle: "italic",
  },
}));

export default useStyles;
