import * as React from "react";

function SvgMap(props) {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.5023 2.7604L12.1023 0.0939618C11.9773 0.0321709 11.8394 0 11.6995 0C11.5597 0 11.4218 0.0321709 11.2968 0.0939618L6.3 2.5622L1.3023 0.0939618C1.16505 0.0262318 1.01255 -0.00572311 0.859281 0.00113223C0.70601 0.00798757 0.557058 0.0534254 0.426572 0.13313C0.296087 0.212835 0.1884 0.32416 0.113741 0.456532C0.0390819 0.588905 -7.14147e-05 0.737928 9.7786e-08 0.889449V12.444C9.7786e-08 12.7809 0.1926 13.0884 0.4977 13.2395L5.8977 15.9059C6.02274 15.9677 6.16063 15.9999 6.30045 15.9999C6.44027 15.9999 6.57816 15.9677 6.7032 15.9059L11.7 13.4377L16.6977 15.9051C16.8346 15.9734 16.9869 16.0058 17.1402 15.9991C17.2934 15.9924 17.4423 15.9469 17.5725 15.8668C17.838 15.7042 18 15.4189 18 15.1105V3.55589C18 3.21903 17.8074 2.9115 17.5023 2.7604ZM7.2 4.10518L10.8 2.32755V11.8947L7.2 13.6724V4.10518ZM1.8 2.32755L5.4 4.10518V13.6724L1.8 11.8947V2.32755ZM16.2 13.6724L12.6 11.8947V2.32755L16.2 4.10518V13.6724Z"
        fill="black"
      />
    </svg>
  );
}

export default SvgMap;
