import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import SkeletonTypography from "../SkeletonTypography";

export default function ArticletSkeleton(props) {
  return (
    <div style={{ marginBottom: 20 }} {...props}>
      <SkeletonTypography variant="h3" />
      <div style={{ width: "40%" }}>
        <SkeletonTypography variant="h4" />
      </div>

      <Skeleton variant="rect" width="100%">
        <div style={{ paddingTop: 250, marginBottom: 10 }} />
      </Skeleton>
      <SkeletonTypography variant="h3" />
      <SkeletonTypography variant="body1" />
      <SkeletonTypography variant="body1" />
      <SkeletonTypography variant="body1" />
      <SkeletonTypography variant="body1" />
    </div>
  );
}
