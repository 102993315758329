import React, { useState, memo } from "react";
import PropTypes from "prop-types";
import { useTheme } from "react-jss";
import Skeleton from "@material-ui/lab/Skeleton";
import CustomMarker from "../Landing/CustomMarker";
import emptyProfile from "../../assets/images/empty_profile.jpg";
import useStyles from "./styles";

const ProfileImg = ({ url, name, size, smSize, showBottomIcon, type }) => {
  const theme = useTheme();
  const classes = useStyles({
    theme,
    size,
    name,
    smSize: smSize < size ? smSize : size,
  });
  const [imageLoaded, setImageLoaded] = useState(false);
  // const { t } = useTranslation();

  const onLoadImage = () => {
    setImageLoaded(true);
  };

  const SubIcon = () => (
    <>
      {showBottomIcon && (
        <div
          style={{
            marginTop: -20,
            marginLeft: 27,
          }}
        >
          <CustomMarker size={20} type={type} />
        </div>
      )}
    </>
  );

  if (url || !name)
    return (
      <>
        <div className={classes.wrapper}>
          <img
            alt="foto"
            style={imageLoaded ? {} : { display: "none" }}
            src={url || emptyProfile}
            className={classes.image}
            onLoad={onLoadImage}
          />
          {!imageLoaded && (
            <div className={classes.imageSkeleton}>
              <Skeleton variant="circle" width="100%" height="100%" />
            </div>
          )}
          {imageLoaded && <SubIcon />}
        </div>
      </>
    );

  return (
    <>
      <div className={classes.emptyProfile}>
        <div>{name.charAt(0)}</div>
      </div>
      <SubIcon />
    </>
  );
};

ProfileImg.propTypes = {
  url: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.number,
  smSize: PropTypes.number,
  showBottomIcon: PropTypes.bool,
  type: PropTypes.string,
};

ProfileImg.defaultProps = {
  url: undefined,
  name: undefined,
  size: 40,
  smSize: 40,
  showBottomIcon: false,
  type: "",
};

export default memo(ProfileImg);
