import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => ({
  container: {
    width: "100%",
    height: 85,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiButton-root": {
      height: 40,
      fontWeight: "bold",
    },
  },
  buttonAccept: {
    color: `${theme.textTertiary} !important`,
    backgroundColor: `${theme.primary0} !important`,
    borderColor: `${theme.primary0} !important`,
    fontWeight: "700 !important",
  },
  titleContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
}));

export default useStyles;
