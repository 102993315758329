import { createUseStyles } from "react-jss";

const useStyles = createUseStyles(() => ({
  container: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 30,
  },
}));

export default useStyles;
