import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "react-jss";
// import { useTranslation } from "react-i18next";
import Divider from "@material-ui/core/Divider";
import useStyles from "./styles";
import CustomTypography from "../../../CustomTypography";
import CustomMarker from "../../CustomMarker";
import CustomLink from "../../../CustomLink";
import ResultMoreInfo from "../ResultMoreInfo";
import ProfileImg from "../../../ProfileImg";
import InstituteImg from "../../../InstituteImg";
import resultTypes from "../../ResultTypes";

const ResultInfo = ({
  result,
  hideImage,
  hideDivider,
  showBottomIcon,
  hideLabel,
  centralizeImage,
  variant,
}) => {
  // const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();

  const isPopup = variant === "popup";

  function Name() {
    switch (result.type) {
      case resultTypes.RESEARCHER:
        return (
          <CustomLink
            to={`/buscador/pesquisador/${
              result.lattes_id ? result.lattes_id : result.id.split("-")[0]
            }`}
          >
            <CustomTypography
              variant={isPopup ? "footerSn" : "searchResultTitle"}
              color={theme.textPrimary}
              className={classes.title}
            >
              {result.name}
            </CustomTypography>
          </CustomLink>
        );
      case resultTypes.INSTITUTE:
        return (
          <CustomLink to={`/buscador/instituto/${result.id.split("-")[0]}`}>
            <CustomTypography
              variant={isPopup ? "footerSn" : "searchResultTitle"}
              color={theme.textPrimary}
              className={classes.title}
            >
              {result.name}
            </CustomTypography>
          </CustomLink>
        );
      default:
        return (
          <CustomTypography
            variant={isPopup ? "footerSn" : "searchResultTitle"}
            color={theme.textPrimary}
            className={classes.title}
          >
            {result.title || result.name}
          </CustomTypography>
        );
    }
  }

  return (
    <>
      <div className={classes.root}>
        <div
          className={
            !hideImage
              ? centralizeImage
                ? classes.contentWrapper
                : classes.gridContainer
              : null
          }
        >
          {!hideImage && (
            <div
              className={centralizeImage ? classes.centeredImageWrapper : null}
            >
              {result.type === resultTypes.RESEARCHER ? (
                <ProfileImg
                  url={result.image}
                  name={result.name}
                  showBottomIcon={showBottomIcon}
                  type={result.type}
                  size={centralizeImage ? 50 : 40}
                />
              ) : result.type === resultTypes.INSTITUTE ? (
                <InstituteImg url={result.image} type={resultTypes.INSTITUTE} />
              ) : (
                <CustomMarker type={result.type} />
              )}
            </div>
          )}
          <div>
            <div
              className={classes.ResultArea}
              style={{
                marginLeft: !hideDivider
                  ? 0
                  : hideImage
                  ? 0
                  : centralizeImage
                  ? 0
                  : 20,
              }}
            >
              <div className={classes.resultContent}>
                <Name />
              </div>

              <ResultMoreInfo
                result={result}
                type={result.type}
                hideImageOnSm={variant !== "popup"}
                hideLabel={hideLabel}
                variant={variant}
              />
            </div>
          </div>
        </div>
      </div>
      {!hideDivider && <Divider />}
    </>
  );
};

ResultInfo.propTypes = {
  result: PropTypes.shape().isRequired,
  hideImage: PropTypes.bool,
  hideDivider: PropTypes.bool,
  showBottomIcon: PropTypes.bool,
  hideLabel: PropTypes.bool,
  centralizeImage: PropTypes.bool,
  variant: PropTypes.string,
};

ResultInfo.defaultProps = {
  hideImage: false,
  hideDivider: false,
  showBottomIcon: true,
  hideLabel: false,
  centralizeImage: false,
  variant: "result",
};

export default ResultInfo;
