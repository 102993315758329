/* eslint-disable no-unused-vars */
import { createUseStyles } from "react-jss";
import { breakpoints } from "../../constants/sizes";

const useStyles = createUseStyles((/* theme */) => ({
  mediaWrapper: {
    width: "100%",
    marginTop: 20,
    marginBottom: 20,
    alignItems: "center",
    alignContent: "center",
    "& iframe": {
      display: "block",
      margin: "0 auto",
    },
  },
  modalImage: {
    width: 648,
    height: "initial",
    objectFit: "cover",
    objectPosition: "center center",
    display: "block",
    margin: "0 auto",
    [breakpoints.md]: {
      height: "100%",
    },
  },

  videoThumb: {
    width: 648,
    height: 434,
    [breakpoints.smd]: {
      width: "100%",
      height: 300,
    },
  },

  podcastWrapper: {
    "& iframe": {
      borderWidth: 0,
    },
  },
}));

export default useStyles;
