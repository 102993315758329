import * as React from "react";

function Rhisa(props) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={17}
      height={20}
      viewBox="0 0 64.000000 64.000000"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <g
        transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M95 611 c-10 -32 15 -229 36 -286 33 -89 84 -143 198 -209 41 -24 67
-34 74 -27 12 12 0 31 -20 31 -30 0 -122 61 -167 110 -58 65 -77 122 -83 246
-5 99 -5 102 18 114 32 18 165 7 220 -18 91 -40 108 -84 109 -284 0 -82 -4
-148 -8 -148 -4 0 -25 23 -46 50 -21 28 -51 60 -67 71 -35 25 -37 45 -5 70 58
46 56 136 -5 180 -51 36 -209 25 -172 -12 8 -8 21 -8 48 1 70 23 135 -15 135
-81 0 -30 -7 -44 -35 -70 -19 -19 -35 -40 -35 -48 0 -8 47 -63 105 -122 72
-74 107 -118 111 -138 4 -17 12 -31 19 -31 22 0 25 13 8 33 -16 17 -18 39 -15
189 4 188 -8 252 -54 308 -51 60 -148 90 -290 90 -63 0 -73 -3 -79 -19z"
        />
      </g>
    </svg>
  );
}

export default Rhisa;
