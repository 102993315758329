/* eslint-disable react/no-array-index-key */
import React from "react";
import { useTheme } from "react-jss";
import PropTypes from "prop-types";
import CustomTypography from "../../CustomTypography";
import useStyles from "./styles";

const ContentList = ({
  isLoading,
  isEmptyMessage,
  children,
  title,
  rightTitle,
  leftTitleIcon,
  horizontal,
  grayBackground,
  twoItemsGrid,
  skeleton,
}) => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <div
      className={`${classes.wrapper} ${
        grayBackground ? classes.grayBackground : null
      }`}
    >
      <div className={classes.titleWrapper}>
        <div className={classes.title}>
          {leftTitleIcon ? (
            <span className={classes.icon}>{leftTitleIcon}</span>
          ) : null}
          {title}
        </div>

        {rightTitle}
      </div>
      <div>
        <div
          className={`${horizontal ? classes.gridContent : null} ${
            twoItemsGrid ? classes.twoGrids : null
          }`}
        >
          {children &&
            children.map((content, index) => (
              <div key={index} className={classes.item}>
                {content}
              </div>
            ))}
        </div>

        {children ? null : isLoading ? skeleton : null}

        {!children && !isLoading && (
          <CustomTypography variant="body">{isEmptyMessage}</CustomTypography>
        )}
      </div>
    </div>
  );
};

ContentList.propTypes = {
  isLoading: PropTypes.bool,
  isEmptyMessage: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.shape()),
  title: PropTypes.shape(),
  rightTitle: PropTypes.shape(),
  leftTitleIcon: PropTypes.shape(),
  horizontal: PropTypes.bool,
  grayBackground: PropTypes.bool,
  twoItemsGrid: PropTypes.bool,
  skeleton: PropTypes.shape(),
};

ContentList.defaultProps = {
  isLoading: false,
  isEmptyMessage: "",
  children: undefined,
  title: undefined,
  rightTitle: undefined,
  leftTitleIcon: undefined,
  horizontal: false,
  grayBackground: false,
  twoItemsGrid: false,
  skeleton: undefined,
};

export default ContentList;
