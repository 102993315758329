import React from "react";
import { useTheme } from "react-jss";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import useStyles from "./styles";

const CustomPaper = ({ children, backgroundColor, ...props }) => {
  const theme = useTheme();
  const classes = useStyles({ theme, backgroundColor });

  return (
    <div {...props}>
      <Paper variant="outlined" square className={classes.root}>
        {children}
      </Paper>
    </div>
  );
};

CustomPaper.propTypes = {
  children: PropTypes.node,
  backgroundColor: PropTypes.string,
};
CustomPaper.defaultProps = {
  children: undefined,
  backgroundColor: undefined,
};

export default CustomPaper;
