import React, { useRef, useState } from "react";
import { useTheme } from "react-jss";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import categoryTypes from "../SearchContainer/CategoryTabs/CategoryTypes";
import Selector from "./Selector";
import useStyles from "./styles";

const SearchBar = ({ hasSelector, onKeyPress }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  const [category, setCategory] = useState(categoryTypes.ALL);

  const inputRef = useRef();

  const formatedQuerie = () => ({ q: inputRef.current.value, category });

  const handleChange = (value) => {
    setCategory(value);
  };

  const handleKeyPress = (event) => {
    if (inputRef.current.value && inputRef.current.value !== "")
      onKeyPress(event, formatedQuerie());
  };

  return (
    <Paper component="form" variant="outlined" square className={classes.root}>
      {hasSelector && (
        <Selector
          onChange={handleChange}
          value={category}
          parentRef={inputRef}
        />
      )}
      <div className={classes.divider} />
      <InputBase
        inputRef={inputRef}
        className={classes.input}
        onKeyPress={handleKeyPress}
        placeholder={t("landing.searchSection.searchBarPlaceholder")}
      />
    </Paper>
  );
};

SearchBar.propTypes = {
  hasSelector: PropTypes.bool,
  onKeyPress: PropTypes.func,
};

SearchBar.defaultProps = {
  hasSelector: false,
  onKeyPress: undefined,
};

export default SearchBar;
