/* eslint-disable no-unused-vars */
import { createUseStyles } from "react-jss";
import { breakpoints } from "../../../constants/sizes";

const useStyles = createUseStyles((/* theme */) => ({
  wrapper: {
    "& iframe": {
      borderWidth: 0,
    },
  },

  bodyPreview: {
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    height: "3.05rem;",
    marginBottom: 10,
    [breakpoints.sm]: {
      height: "2.7rem;",
    },
  },
  link: {
    display: "flex",
    textDecoration: "none",
    "&:hover": {},
  },
}));

export default useStyles;
