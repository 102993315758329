import React, { useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useTheme } from "react-jss";
import CustomButton from "../CustomButton";
import ArrowLeft from "../Icons/ArrowLeft";
import useStyles from "./styles";

function Sidebar({ children, onToggleVisibility }) {
  const [isHiding, setIsHide] = useState(false);
  const theme = useTheme();
  const classes = useStyles({ theme, isHiding });

  const handleToogleHideShow = () => {
    if (onToggleVisibility) {
      onToggleVisibility(isHiding);
    }

    setIsHide((oldValue) => !oldValue);
  };

  return (
    <div
      className={classNames([classes.container, "sidebar-root"])}
      style={{ maxWidth: isHiding ? "60px" : "700px" }}
    >
      <div className={classes.content}>{children}</div>
      <div
        className={classNames([classes.toogleButton, "sidebar-toogle-button"])}
      >
        <CustomButton onClick={handleToogleHideShow}>
          <ArrowLeft />
        </CustomButton>
      </div>
    </div>
  );
}

Sidebar.propTypes = {
  children: PropTypes.node,
  onToggleVisibility: PropTypes.func,
};
Sidebar.defaultProps = {
  children: undefined,
  onToggleVisibility: undefined,
};

export default Sidebar;
