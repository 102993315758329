import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "react-jss";
import { useTranslation } from "react-i18next";
import CustomTypography from "../../../../../CustomTypography";
import useStyles from "./styles";

const InsituteInfo = ({ institute, isPopup }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <div>
        <CustomTypography
          variant={isPopup ? "subtitle" : "searchResultSubTitle"}
          color={institute ? theme.primary0 : theme.textSecondary}
        >
          {institute ? `${institute}` : t("researcher.notAffiliated")}
        </CustomTypography>
      </div>
    </div>
  );
};

InsituteInfo.propTypes = {
  institute: PropTypes.string,
  isPopup: PropTypes.bool,
};

InsituteInfo.defaultProps = {
  institute: "",
  isPopup: false,
};

export default InsituteInfo;
