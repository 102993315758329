import React from "react";
import { useTheme } from "react-jss";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Analysis from "../../Analysis";
import resultTypes from "../../../../ResultTypes";
import Section from "../../../Section";
import ProfileImg from "../../../../../ProfileImg";
import SectionRow from "../../../SectionRow";
import InstituteImg from "../../../../../InstituteImg";
import { isEmpty } from "../../../../../../util/hooks";
import { tabsTypes } from "../../../tabTypes";
import useStyles from "./styles";

const GeneralContent = ({ statistics, isLoadingAnalysis, onViewAllClick }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  // const { loadingStats } = useSelector((state) => state.researcher);

  const handleViewMore = (sectionName) => {
    if (onViewAllClick) onViewAllClick(sectionName);
  };

  return (
    <div className={classes.content}>
      <div className={classes.section}>
        <Analysis statistics={statistics} loading={isLoadingAnalysis} />
      </div>

      <div className={classes.section}>
        <Section
          title={t("resultPage.mainResearchers")}
          name={tabsTypes.RESEARCHERS}
          showViewMore={statistics && !isEmpty(statistics.main_researchers)}
          onViewMoreClick={handleViewMore}
          loading={isLoadingAnalysis}
          isEmpty={statistics && isEmpty(statistics.main_researchers)}
          emptyMessage={t("resultPage.emptyMainResearcher")}
        >
          {statistics &&
            !isEmpty(statistics.main_researchers) &&
            statistics.main_researchers.slice(0, 5).map((colaborator) => (
              <SectionRow
                key={colaborator.lattes_id}
                title={colaborator.name}
                linkTo={
                  colaborator.name
                    ? `/buscador/pesquisador/${colaborator.lattes_id}`
                    : undefined
                }
                subtitle={
                  <span>
                    {colaborator.institute || t("researcher.notAffiliated")}
                  </span>
                }
                imageCell={
                  <ProfileImg
                    url={colaborator.image}
                    name={colaborator.name}
                    size={40}
                  />
                }
                stats={t("resultPage.collaborationsWithCount", {
                  count: parseFloat(colaborator.total_publications),
                })}
                placeholder={t("resultPage.infoUnavailable")}
              />
            ))}
        </Section>
      </div>

      <div className={classes.section}>
        <Section
          title={t("resultPage.institutionalCollab")}
          name={tabsTypes.COLABORATIONS}
          showViewMore={
            statistics && !isEmpty(statistics.institutions_collaboration)
          }
          onViewMoreClick={handleViewMore}
          loading={isLoadingAnalysis}
          isEmpty={statistics && isEmpty(statistics.institutions_collaboration)}
          emptyMessage={t("resultPage.emptyInstitutionalCollab")}
        >
          {statistics &&
            !isEmpty(statistics.institutions_collaboration) &&
            statistics.institutions_collaboration
              .slice(0, 5)
              .map((instituteColab) => (
                <SectionRow
                  key={instituteColab.name}
                  title={instituteColab.name}
                  imageCell={
                    <InstituteImg
                      url={instituteColab.image}
                      type={resultTypes.INSTITUTE}
                    />
                  }
                  stats={t("resultPage.collaborationsWithCount", {
                    count: parseFloat(instituteColab.total_collaborations),
                  })}
                  placeholder={t("resultPage.infoUnavailable")}
                  linkTo={`/buscador/instituto/${instituteColab.id}`}
                />
              ))}
        </Section>
      </div>
    </div>
  );
};

GeneralContent.propTypes = {
  statistics: PropTypes.shape(),
  isLoadingAnalysis: PropTypes.bool,
  onViewAllClick: PropTypes.func,
};

GeneralContent.defaultProps = {
  statistics: undefined,
  isLoadingAnalysis: false,
  onViewAllClick: undefined,
};

export default GeneralContent;
