/**
 * This file defines all color schemes used on theming.
 */

export const lightScheme = {
  primary0: "#00A5A8",
  primary1: "#15BDCF",
  primary2: "#00585A",
  secondary0: "#A0A0A0",
  secondary0Opacity: "rgb(160,160,160, 0.3)",
  secondary1: "#bbb5b5",
  textPrimary: "#333333",
  textSecondary: "#5C5C5C",
  textTertiary: "#FFFFFF",
  background0: "#FFFFFF",
  background1: "#F9FAFA",
  background2: "rgba(255, 255, 255, 0.5)",
  background3: "rgba(191, 212, 215, 0.2)",
  background4: "#F8F8F8",
  stroke: "#BFD4D7",
  innerShadow: "rgba(79, 182, 178, 0.1)",
  shadow0: "#E5F3F5",
  shadow1: "#333333",
  shadow3: "#c7c7c7",
  backgroundHover: "#e6f6f6",
  article: "#15BDCF",
  researcher: "#F2C94C",
  featuredResearcher: "#FF9900",
  chapter: "#F76908",
  book: "#8415CF",
  institute: "#0CA512",
  success: "#4CAF50",
  successLight: "#07E173",
  alert: "#FF4A4A",
  research: "#00585A",
  selectBackground:
    "linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #F9FAFA",
  hoverBackground: "#15BDCF33",
  lowDark: "#1A1A1A",
  forestShade: "#CCDFE0",
};

export const darkScheme = {
  ...lightScheme,
};
