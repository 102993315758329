import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import { useTheme } from "react-jss";
import SearchIconSvg from "../../Icons/Search";
import useStyles from "./styles";

const SearchButton = ({ onClick }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <IconButton
      className={classNames([classes.iconButton, "icon-button-base"])}
      aria-label="search"
      onClick={onClick}
    >
      <SearchIconSvg />
    </IconButton>
  );
};

SearchButton.propTypes = {
  onClick: PropTypes.func,
};

SearchButton.defaultProps = {
  onClick: undefined,
};

export default SearchButton;
