import * as React from "react";

function Microscope(props) {
  return (
    <svg
      width="14"
      height="17"
      viewBox="0 0 14 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.11365 1.43039L3.70635 5.7299C3.60837 5.90524 3.58188 6.1135 3.63271 6.30895C3.68354 6.5044 3.80753 6.67106 3.97745 6.77233L4.94037 7.34509L4.55432 8.03377C4.35656 8.38656 4.47595 8.83268 4.82347 9.03956C5.18419 9.25429 5.651 9.1292 5.85602 8.76287L6.22254 8.108L7.18472 8.68153C7.35484 8.78279 7.55701 8.81024 7.74676 8.75782C7.93651 8.7054 8.0983 8.57742 8.19652 8.40202L9.55794 5.97199C10.0437 6.12419 10.4939 6.37768 10.8807 6.71675C11.2674 7.05582 11.5825 7.47322 11.8063 7.94305C12.0301 8.41288 12.1578 8.92512 12.1816 9.44797C12.2054 9.97082 12.1247 10.4931 11.9445 10.9825C11.352 10.5944 10.6643 10.3888 9.96237 10.3899C8.71502 10.3899 7.61136 11.026 6.94028 12.0005L1.91428 9.0085C1.54795 8.79042 1.07387 8.91738 0.865562 9.28936C0.664911 9.64767 0.786207 10.1006 1.13907 10.3107L6.34031 13.4072C6.2339 13.9227 6.23138 14.4553 6.3329 14.972H1.09688C0.675094 14.972 0.333172 15.3139 0.333172 15.7357C0.333172 16.1574 0.675065 16.4993 1.09683 16.4994L12.9252 16.5001C13.4072 15.8395 13.6672 15.0349 13.6659 14.2083C13.6659 13.4393 13.4452 12.7229 13.0659 12.1234C13.4095 11.4529 13.6109 10.7146 13.6565 9.9577C13.7022 9.20077 13.5911 8.44249 13.3307 7.73321C13.0703 7.02394 12.6665 6.37989 12.1462 5.84384C11.6259 5.30779 11.0009 4.89201 10.3127 4.6241L10.6038 4.10327C10.8003 3.75246 10.8535 3.33558 10.7518 2.94431C10.6501 2.55304 10.4019 2.21944 10.0616 2.01689L8.13727 0.871372C7.79702 0.668841 7.39267 0.613956 7.01317 0.71879C6.63367 0.823625 6.31011 1.07959 6.11365 1.43039ZM9.96237 11.9172C10.318 11.917 10.6684 12.0048 10.9843 12.1733C11.3001 12.3417 11.5722 12.5858 11.7776 12.8851C11.9829 13.1843 12.1156 13.5301 12.1645 13.8932C12.2134 14.2564 12.1771 14.6263 12.0586 14.972H7.86617C7.74764 14.6263 7.71129 14.2564 7.76019 13.8932C7.80909 13.5301 7.94181 13.1843 8.14719 12.8851C8.35256 12.5858 8.62461 12.3417 8.94047 12.1733C9.25633 12.0048 9.60677 11.917 9.96237 11.9172ZM7.39656 2.19407L9.32092 3.33959L7.28397 6.97699L5.35961 5.83147L7.39656 2.19407Z"
        fill="white"
      />
    </svg>
  );
}

export default Microscope;
