import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => ({
  contentWrapper: {
    width: "100%",
    maxWidth: 315,
    height: 410,
    borderRadius: 16,
    filter: "drop-shadow(-20px 20px 20px #E5E5E5)",
    backgroundColor: theme.background0,
    padding: 10,
  },
  copyButtonWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
    "& svg": {
      cursor: "pointer",
    },
  },
  imageWrapper: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 10,
    paddingBottom: 20,
  },
  name: {
    display: "flex",
    justifyContent: "center",
  },
  areasWrapper: {
    marginTop: 10,
    display: "flex",
    justifyContent: "center",
    columnGap: 5,
    flexFlow: "wrap",
    maxHeight: 40,
  },
  area: {
    textAlign: "center",
  },
  chartWrapper: {
    height: 160,
  },
}));

export default useStyles;
