import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "react-jss";
import useStyles from "./styles";

const PaginatedListWrapper = ({ children }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return <div className={classes.container}>{children}</div>;
};

PaginatedListWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PaginatedListWrapper;
