import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { InputAdornment, TextField } from "@material-ui/core";
import { useTheme } from "react-jss";
import useStyles from "./styles";
import CustomTypography from "../CustomTypography";

/**
 * This component renders a custom textfield styled for project.
 * @returns A custom textfield
 */
function CustomTextField({
  children,
  startIcon,
  endIcon,
  className,
  label,
  required,
  errorMessage,
  error,
  ...props
}) {
  CustomTextField.defaultProps = {
    children: undefined,
    className: "",
    startIcon: undefined,
    endIcon: undefined,
    label: "",
    required: false,
    errorMessage: "",
    error: false,
  };

  CustomTextField.propTypes = {
    children: PropTypes.shape(),
    className: PropTypes.string,
    startIcon: PropTypes.elementType,
    endIcon: PropTypes.elementType,
    label: PropTypes.string,
    required: PropTypes.bool,
    errorMessage: PropTypes.string,
    error: PropTypes.bool,
  };

  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div style={{ width: "100%" }}>
      {label && (
        <CustomTypography variant="labelForm" color={theme.textPrimary}>
          {label}
          {required ? "*" : ""}
          {error && errorMessage && (
            <span className={classes.alertMessage}>{errorMessage}</span>
          )}
        </CustomTypography>
      )}
      <TextField
        required={required}
        classes={{ root: classNames(classes.root, className) }}
        error={error}
        {...props}
        InputProps={{
          startAdornment: startIcon && (
            <InputAdornment position="start">{startIcon}</InputAdornment>
          ),
          endAdornment: endIcon && (
            <InputAdornment position="end">{endIcon}</InputAdornment>
          ),
        }}
      >
        {children}
      </TextField>
    </div>
  );
}

export default CustomTextField;
