import * as React from "react";

function Star(props) {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.4182 9.05277L15.0222 8.26854L12.61 3.37831C12.5441 3.24441 12.4357 3.13602 12.3018 3.07014C11.966 2.90437 11.558 3.04251 11.3901 3.37831L8.97791 8.26854L3.58186 9.05277C3.4331 9.07402 3.29708 9.14415 3.19294 9.25042C3.06704 9.37982 2.99767 9.5539 3.00006 9.73443C3.00245 9.91495 3.07641 10.0871 3.20569 10.2132L7.10981 14.0195L6.18744 19.3943C6.16581 19.5193 6.17965 19.6479 6.22738 19.7655C6.27511 19.8831 6.35483 19.9849 6.4575 20.0595C6.56016 20.134 6.68166 20.1783 6.80823 20.1874C6.9348 20.1964 7.06136 20.1698 7.17356 20.1105L12 17.573L16.8265 20.1105C16.9583 20.1807 17.1113 20.204 17.258 20.1785C17.6277 20.1148 17.8764 19.7641 17.8126 19.3943L16.8903 14.0195L20.7944 10.2132C20.9007 10.109 20.9708 9.97301 20.992 9.82424C21.0494 9.45232 20.7901 9.10802 20.4182 9.05277ZM15.2474 13.4839L16.0147 17.9534L12 15.8451L7.98542 17.9555L8.75264 13.4861L5.50523 10.3194L9.99379 9.66697L12 5.60133L14.0063 9.66697L18.4949 10.3194L15.2474 13.4839Z"
        fill="black"
      />
    </svg>
  );
}

export default Star;
