import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import SkeletonTypography from "../SkeletonTypography";

export default function RecentlySkeleton() {
  return (
    <div style={{ marginBottom: 30 }}>
      <Skeleton variant="rect" width="100%">
        <div style={{ paddingTop: 120 }} />
      </Skeleton>
      <SkeletonTypography variant="body1" />
      <SkeletonTypography variant="body1" />
    </div>
  );
}
