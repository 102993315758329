import * as React from "react";

function Dialog(props) {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.5 30C10.1022 30 9.72064 29.842 9.43934 29.5607C9.15804 29.2794 9 28.8978 9 28.5V24H3C2.20435 24 1.44129 23.6839 0.878679 23.1213C0.31607 22.5587 0 21.7956 0 21V3C0 2.20435 0.31607 1.44129 0.878679 0.878679C1.44129 0.31607 2.20435 0 3 0H27C27.7956 0 28.5587 0.31607 29.1213 0.878679C29.6839 1.44129 30 2.20435 30 3V21C30 21.7956 29.6839 22.5587 29.1213 23.1213C28.5587 23.6839 27.7956 24 27 24H17.85L12.3 29.565C12 29.85 11.625 30 11.25 30H10.5ZM12 21V25.62L16.62 21H27V3H3V21H12ZM6 9C6 8.17157 6.67157 7.5 7.5 7.5H22.5C23.3284 7.5 24 8.17157 24 9C24 9.82843 23.3284 10.5 22.5 10.5H7.5C6.67157 10.5 6 9.82843 6 9ZM6 15C6 14.1716 6.67157 13.5 7.5 13.5H18C18.8284 13.5 19.5 14.1716 19.5 15C19.5 15.8284 18.8284 16.5 18 16.5H7.5C6.67157 16.5 6 15.8284 6 15Z"
        fill="#00A5A8"
      />
    </svg>
  );
}

export default Dialog;
