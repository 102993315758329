import React, { useState, useEffect } from "react";
import { useTheme } from "react-jss";
import { useHistory } from "react-router-dom";
import ReactGA from "react-ga";
import useStyles from "./styles";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import ExploreSection from "../../components/Landing/ExploreSection";
import TrendingSection from "../../components/Landing/TrendingSection";
import Partners from "../../components/Partners";
import api from "../../services/api";
import Cta from "../../components/Cta";
import categoryTypes from "../../components/Landing/SearchContainer/CategoryTabs/CategoryTypes";

/**
 * This component is the first page of the application.
 * @returns Landing page
 */
function Home() {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [trends, setTrends] = useState([]);
  const [researchers, setResearchers] = useState([]);
  const history = useHistory();

  // Initialize google analytics page view tracking
  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.set({ page: history.location.pathname }); // Update the user's current page
    ReactGA.pageview(history.location.pathname); // Record a pageview for the given page
  }, []);

  useEffect(() => {
    api
      .get("popular-words")
      .then((response) => {
        const fetchedTrends = response.data;

        setTrends(fetchedTrends);
      })
      .catch(() => {});
    api
      .get("researchers/random?limit=2")
      .then((response) => {
        const { researchers: data } = response.data;
        setResearchers(data);
      })
      .catch(() => {});
  }, []);

  const handleSearch = (query) => {
    const { q, category } = query;
    history.push(`/buscador?q=${q}&category=${category || categoryTypes.ALL}`);
  };

  const handleKeyPress = (event, query) => {
    if (event.key === "Enter") {
      handleSearch(query);
    }
  };

  const handleSearchClick = (query) => {
    handleSearch(query);
  };

  return (
    <div className={classes.container}>
      <Header />

      <ExploreSection
        onKeyPress={handleKeyPress}
        onSearchClick={handleSearchClick}
      />

      <TrendingSection
        onKeyPress={handleKeyPress}
        onSearchClick={handleSearchClick}
        trends={trends}
        researchers={researchers}
      />

      <Cta id="cta" />

      <Partners />
      <Footer />
    </div>
  );
}

export default Home;
