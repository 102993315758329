import * as React from "react";

function Article(props) {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.25 4H17.75C18.5801 3.99995 19.3788 4.31755 19.9822 4.88767C20.5856 5.45779 20.948 6.23719 20.995 7.066L21 7.25V16.75C21.0001 17.5801 20.6824 18.3788 20.1123 18.9822C19.5422 19.5856 18.7628 19.948 17.934 19.995L17.75 20H6.25C5.41986 20.0001 4.62117 19.6824 4.01777 19.1123C3.41437 18.5422 3.052 17.7628 3.005 16.934L3 16.75V7.25C2.99995 6.41986 3.31755 5.62117 3.88767 5.01777C4.45779 4.41437 5.23719 4.052 6.066 4.005L6.25 4ZM17.75 5.5H6.25C5.81081 5.50002 5.38768 5.66517 5.06461 5.96268C4.74154 6.26019 4.54214 6.6683 4.506 7.106L4.5 7.25V16.75C4.50002 17.1892 4.66517 17.6123 4.96268 17.9354C5.26019 18.2585 5.6683 18.4579 6.106 18.494L6.25 18.5H17.75C18.1893 18.5 18.6126 18.3347 18.9357 18.037C19.2588 17.7392 19.4581 17.3309 19.494 16.893L19.5 16.75V7.25C19.5 6.81065 19.3347 6.38739 19.037 6.06429C18.7392 5.74119 18.3309 5.5419 17.893 5.506L17.75 5.5Z"
        fill="black"
      />
      <path
        d="M16.5 9H7.49998C7.00292 9 6.59998 9.40294 6.59998 9.9C6.59998 10.3971 7.00292 10.8 7.49998 10.8H16.5C16.997 10.8 17.4 10.3971 17.4 9.9C17.4 9.40294 16.997 9 16.5 9Z"
        fill="black"
      />
      <path
        d="M13.8 12.6H7.49998C7.00292 12.6 6.59998 13.0029 6.59998 13.5C6.59998 13.9971 7.00292 14.4 7.49998 14.4H13.8C14.297 14.4 14.7 13.9971 14.7 13.5C14.7 13.0029 14.297 12.6 13.8 12.6Z"
        fill="black"
      />
    </svg>
  );
}

export default Article;
