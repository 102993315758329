import React, { memo } from "react";
import { useTheme } from "react-jss";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CustomTypography from "../../CustomTypography";
import useStyles from "./styles";

const SummaryContent = ({ data, onClick }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { t } = useTranslation();

  if (!data) return null;

  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>
        <Link
          className={classes.link}
          to={{ pathname: data.link }}
          target="_blank"
          onClick={onClick}
        >
          <CustomTypography
            className={classes.titleTypography}
            variant="noticeTitle"
          >
            {data.title}
          </CustomTypography>
        </Link>
      </div>
      <div>
        <CustomTypography
          className={classes.titleTypography}
          color={theme.textSecondary}
          variant="body"
          weigth="regular"
        >
          {t("agency.article.publishedAtDate", {
            date: new Date(data.date),
          })}
        </CustomTypography>
      </div>
    </div>
  );
};

SummaryContent.propTypes = {
  data: PropTypes.shape(),
  onClick: PropTypes.func,
};

SummaryContent.defaultProps = {
  data: undefined,
  onClick: null,
};

export default memo(SummaryContent);
