import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((/* theme */) => ({
  paper: {
    border: "1px solid #d3d4d5",
    marginTop: 10,
  },
}));

export default useStyles;
