import React, { useMemo, useState } from "react";
import { useTheme } from "react-jss";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import PropTypes from "prop-types";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import categoryTypes from "../../SearchContainer/CategoryTabs/CategoryTypes";
import useStyles from "./styles";
import ButtonWithPopup from "../../../ButtonWithPopup";
import CustomButton from "../../../CustomButton";
import ArrowDown from "../../../Icons/ArrowDown";
import CheckThin from "../../../Icons/CheckThin";

const Selector = ({ onChange, value, parentRef }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  const [selectValue, setSelectValue] = useState(value);
  const isSm = useMediaQuery("(max-width: 600px)");

  const closeSelector = () => {
    if (parentRef) parentRef.current.click();
  };

  const handleClickCategory = (newValue) => {
    if (!isSm) {
      if (onChange) onChange(newValue);
      closeSelector();
    } else {
      setSelectValue(newValue);
    }
  };

  const handleApplyClick = () => {
    if (selectValue && selectValue !== "") {
      if (onChange) onChange(selectValue);
      closeSelector();
    }
  };

  const options = useMemo(
    () => [
      {
        name: t("landing.searchSection.searchBar.all"),
        value: categoryTypes.ALL,
      },
      {
        name: t("landing.searchSection.searchBar.researchers"),
        value: categoryTypes.RESEARCHER,
      },
      {
        name: t("landing.searchSection.searchBar.articles"),
        value: categoryTypes.PUBLICATIONS,
      },
      {
        name: t("landing.searchSection.searchBar.institutes"),
        value: categoryTypes.INSTITUTIONS,
      },
    ],
    []
  );

  return (
    <ButtonWithPopup
      placement="bottom-start"
      className={classes.customSelector}
      hidePopupTitle={!isSm}
      popperClassName={classes.selectPopup}
      title="Pesquisar"
      unsetTop
      filterButton={
        <div className={classes.selectorLabel}>
          {options.find((option) => option.value === value).name}
          <ArrowDown />
        </div>
      }
    >
      <div className={classes.selectOptionsWrapper}>
        <div style={{ borderRadius: 8 }}>
          {options.map((option) => (
            <div key={option.name}>
              <CustomButton
                className={classNames(
                  classes.selectOption,
                  isSm && selectValue === option.value ? "active" : ""
                )}
                onClick={() => handleClickCategory(option.value)}
              >
                {option.name}
                {isSm && selectValue === option.value && <CheckThin />}
              </CustomButton>
            </div>
          ))}
        </div>
        {isSm && (
          <CustomButton
            className={classes.applyButton}
            onClick={handleApplyClick}
          >
            {t("landing.searchSection.searchBar.apply")}
          </CustomButton>
        )}
      </div>
    </ButtonWithPopup>
  );
};

Selector.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  parentRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
};

Selector.defaultProps = {
  onChange: undefined,
  value: undefined,
};

export default Selector;
