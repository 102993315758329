import React, { useState, useEffect } from "react";
import useSWR from "swr";
import { useTranslation } from "react-i18next";
/* import { useTheme } from "react-jss";
import useStyles from "./styles"; */
import Highlight from "../../../../components/Multimedia/Home/Highlight";
import Recently from "../../../../components/Multimedia/Home/Recently";
import api from "../../../../services/hcms/api";
import SectionLine from "../../../../components/SectionLine";
import GridDisplayer from "../../../../components/Multimedia/GridDisplayer";
import PodcastGridDisplayer from "../../../../components/Multimedia/PodcastGridDisplayer";
import LoadMoreButton from "../../../../components/LoadMoreButton";

/**
 * This component represents Agency multimedia home page.
 * @returns Multimedia home age
 */
export default function Home() {
  /* const theme = useTheme();
  const classes = useStyles({ theme }); */
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [podcasts, setPodcasts] = useState([]);
  const [isLoadEnded, setisLoadEnded] = useState(false);
  const [isLoading, setisLoading] = useState(true);

  /**
   * Fetch highlight video.
   */
  const { data: highlight } = useSWR("/highlight-video", async (swrRoute) => {
    const response = await api.get(swrRoute);
    const { video } = response.data;
    return video;
  });

  /**
   * Fetch recently videos.
   */
  const { data: recently } = useSWR("/videos/recently", async (swrRoute) => {
    const response = await api.get(swrRoute);
    return response.data;
  });

  const { data: morePodcasts } = useSWR(
    `/podcasts?_limit=4&_start=${page * 4}`,
    async (swrRoute) => {
      const response = await api.get(swrRoute);
      setisLoading(false);
      return response.data;
    }
  );

  function loadPodcasts() {
    if (morePodcasts) {
      setPodcasts((oldPodcasts) => {
        const oldLength = podcasts.length;
        const newLength = oldLength + morePodcasts.length;
        setisLoadEnded(newLength - oldLength < 4);
        if (oldLength === newLength) return oldPodcasts;
        return [...oldPodcasts, ...morePodcasts];
      });
    }
  }

  useEffect(() => {
    loadPodcasts();
  }, [morePodcasts]);

  function loadMore() {
    setisLoading(true);
    setPage((oldPageValue) => oldPageValue + 1);
  }

  return (
    <>
      <GridDisplayer
        title={t("multimedia.highlight.title")}
        leftElement={<Highlight data={highlight} />}
        rightElement={<Recently videos={recently} />}
      />

      <SectionLine />

      <PodcastGridDisplayer
        title={t("multimedia.podcasts.title")}
        podcasts={podcasts}
      />
      <LoadMoreButton
        isLoadEnded={isLoadEnded}
        loadEndedMessage={t("multimedia.podcasts.pageEnded")}
        onClick={() => loadMore()}
        visible={!isLoading}
        isLoading={isLoading}
      />
    </>
  );
}
