import { createUseStyles } from "react-jss";
import { breakpoints } from "../../../../constants/sizes";

const useStyles = createUseStyles((theme) => ({
  popupLargeWrapper: {
    display: "block",
    "& .mapboxgl-popup": {
      zIndex: 1,
    },
    [breakpoints.sm]: {
      display: "none",
    },
  },
  popupSmWrapper: {
    display: "none",
    [breakpoints.sm]: {
      zIndex: 5,
      display: "block",
      position: "absolute",
      bottom: 0,
      backgroundColor: theme.background0,
      width: "100%",
      padding: 24,
    },
  },
  popup: {
    width: 276,
    [breakpoints.sm]: {
      width: "100%",
    },
  },
  closeButtonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    "&.MuiButton-root": {
      minWidth: 0,
      padding: 0,
    },
  },
}));

export default useStyles;
