import { createUseStyles } from "react-jss";
import { breakpoints } from "../../../../../constants/sizes";

const useStyles = createUseStyles((theme) => ({
  container: {},
  title: {
    paddingBottom: 30,
  },
  singleAnalysisWrapper: {
    display: "grid",
    flexWrap: "wrap",
    gridTemplateColumns: "3fr 3fr 2fr 2fr",
    gridGap: 10,
    marginBottom: 16,
    [breakpoints.sm]: {
      gridTemplateColumns: "1fr 1fr",
    },
  },
  sectionWrapper: {
    marginBottom: 16,
  },
  bottomText: {
    textAlign: "center",
    fontSize: 12,
  },
  bodyText: {
    fontSize: 20,
    fontWeight: "bold",
    textAlign: "center",
  },
  chartsSection: {
    display: "grid",
    gridTemplateColumns: "50% 50%",

    [breakpoints.sm]: {
      gridTemplateColumns: "repeat(auto-fill, 100%)",
      rowGap: 10,
    },
  },
  pieChartWrapper: {
    minHeight: 270,
    paddingRight: 5,
    [breakpoints.sm]: {
      paddingRight: 0,
    },
  },
  wordCloudWrapper: {
    minHeight: 270,
    paddingLeft: 5,
    [breakpoints.sm]: {
      paddingLeft: 0,
    },
  },
  wordCloudTitle: {
    color: theme.textPrimary,
    width: "100%",
    fontWeight: "bold",
    paddingBottom: 8,
  },
}));

export default useStyles;
