import { createUseStyles } from "react-jss";
import { breakpoints } from "../../../constants/sizes";

const useStyles = createUseStyles((theme) => ({
  container: {
    height: "100%",
    maxHeight: 500,
    overflow: "scroll",
    overflowX: "hidden",
    marginTop: 30,
    "& .list-item": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      minHeight: 95,
      borderBottom: `1px solid ${theme.secondary0}`,
    },
    "& .list-item:nth-child(2n -1)": {
      backgroundColor: theme.background4,
    },
    [breakpoints.md]: {
      maxHeight: "none",
      overflow: "initial",
      width: "420px",
    },
  },
  itemsGrid: {
    paddingLeft: 20,
    paddingRight: 20,
    minHeight: 70,
    display: "flex",
    columnGap: 10,
  },
  iconWrapper: {
    width: "100%",
    maxWidth: 40,
    height: 40,
    backgroundColor: "#5360FF",
    borderRadius: 16,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  infosWrapper: {
    width: "100%",
  },
  iconedRow: {
    display: "flex",
    alignItems: "center",
    columnGap: 5,
    "& svg": {
      width: 15,
      height: 15,
    },
    "& path": {
      fill: theme.secondary0,
    },
  },
}));

export default useStyles;
