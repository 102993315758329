import React from "react";
import { useTheme } from "react-jss";
import PropTypes from "prop-types";
import useStyles from "./styles";
import CustomTypography from "../../CustomTypography";

/**
 * This component represents a grid displayer fro multimedia section.
 * @returns GridDisplayer component
 */
export default function GridDisplayer({ leftElement, rightElement, title }) {
  GridDisplayer.defaultProps = {
    leftElement: undefined,
    rightElement: undefined,
    title: "",
  };

  GridDisplayer.propTypes = {
    leftElement: PropTypes.shape(),
    rightElement: PropTypes.shape(),
    title: PropTypes.string,
  };
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <>
      <div className={classes.section}>
        <CustomTypography style={{ marginBottom: 16 }} variant="h3">
          {title}
        </CustomTypography>
        <div className={classes.container}>
          <div className={classes.item}>{leftElement}</div>
          <div className={classes.item}>{rightElement}</div>
        </div>
      </div>
    </>
  );
}
