/* eslint-disable no-unused-vars */
import axios from "axios";
import api from "../../services/api";
import {
  FETCH_RESULTS_REQUEST,
  FETCH_RESULTS_SUCCESS,
  FETCH_RESULTS_FAILURE,
  SET_SELECTED_RESULT,
  FETCH_MORE_RESULTS_REQUEST,
  FETCH_MORE_RESULTS_SUCCESS,
  SET_FILTERS,
  SET_TOTAL,
} from "./resultTypes";
import categoryTypes from "../../components/Landing/SearchContainer/CategoryTabs/CategoryTypes";

export const fetchResultsRequest = () => ({
  type: FETCH_RESULTS_REQUEST,
});

export const fetchResultsSuccess = (results) => ({
  type: FETCH_RESULTS_SUCCESS,
  payload: results,
});

export const fetchResultsFailure = (error) => ({
  type: FETCH_RESULTS_FAILURE,
  payload: error,
});

export const fetchMoreResultsRequest = (results) => ({
  type: FETCH_MORE_RESULTS_REQUEST,
});

export const fetchMoreResultsSuccess = (results) => ({
  type: FETCH_MORE_RESULTS_SUCCESS,
  payload: results,
});

export const setSelectedResult = (selectedResult) => ({
  type: SET_SELECTED_RESULT,
  payload: selectedResult,
});

export const setFilters = (filters) => ({
  type: SET_FILTERS,
  payload: filters,
});

export const setTotal = (results) => ({
  type: SET_TOTAL,
  payload: results,
});

const buildSearchUrl = (queries) => {
  const { q, limit, page, category } = queries;

  const isAll = category === categoryTypes.ALL;
  const isResearcher = category === categoryTypes.RESEARCHER || isAll;
  const isInstituition = category === categoryTypes.INSTITUTIONS || isAll;
  const isPublications = category === categoryTypes.PUBLICATIONS || isAll;

  const url = `search?q=${q}&limit=${limit || 5}&page=${
    page || 1
  }&r=${isResearcher}&p=${isPublications}&i=${isInstituition}`;

  return url;
};

const fetchTotal = ({ q, dispatch }) => {
  const url = `search?q=${q}&limit=5&page=1&p=true&r=true&i=true`;
  api
    .get(url)
    .then((response) => {
      const results = response.data;
      dispatch(setTotal(results));
    })
    .catch((error) => {
      const errorMsg = error.message;
      dispatch(fetchResultsFailure(errorMsg));
    });
};

export const fetchResults = (queries) => (dispatch) => {
  dispatch(fetchResultsRequest());

  const searchUrl = buildSearchUrl(queries);

  fetchTotal({ q: queries.q, dispatch });

  api
    .get(searchUrl)
    .then((response) => {
      const results = response.data;
      dispatch(fetchResultsSuccess(results));
    })
    .catch((error) => {
      const errorMsg = error.message;
      dispatch(fetchResultsFailure(errorMsg));
    });
};

export const fetchMoreResults = (queries) => (dispatch) => {
  dispatch(fetchMoreResultsRequest());

  const searchUrl = buildSearchUrl(queries);

  api
    .get(searchUrl)
    .then((response) => {
      const results = response.data;
      dispatch(fetchMoreResultsSuccess(results));
    })
    .catch((error) => {
      const errorMsg = error.message;
      dispatch(fetchResultsFailure(errorMsg));
    });
};
