import api from "../../services/api";
import {
  FETCH_COOPERATIVES_STATISTICS_REQUEST,
  FETCH_COOPERATIVES_STATISTICS_SUCCESS,
  FETCH_COOPERATIVES_STATISTICS_FAILURE,
  FETCH_COOPERATIVES_REQUEST,
  FETCH_COOPERATIVES_SUCCESS,
  FETCH_COOPERATIVES_FAILURE,
  SET_COOPERATIVE_PRODUCT,
  FETCH_COOPERATIVE_REQUEST,
  FETCH_COOPERATIVE_SUCCESS,
  FETCH_COOPERATIVE_FAILURE,
  SET_SHOW_MAP,
} from "./cooperativeTypes";

const fetchCooperativesStatisticsRequest = () => ({
  type: FETCH_COOPERATIVES_STATISTICS_REQUEST,
});

const fetchCooperativesStatisticsSuccess = (results) => ({
  type: FETCH_COOPERATIVES_STATISTICS_SUCCESS,
  payload: results,
});

const fetchCooperativesStatisticsFailure = (error) => ({
  type: FETCH_COOPERATIVES_STATISTICS_FAILURE,
  payload: error,
});

export const fetchStatistics = () => async (dispatch) => {
  dispatch(fetchCooperativesStatisticsRequest());

  const searchUrl = "cooperatives/statistics";

  try {
    const { data } = await api.get(searchUrl);
    dispatch(fetchCooperativesStatisticsSuccess(data));
  } catch (error) {
    dispatch(fetchCooperativesStatisticsFailure(error?.message));
  }
};

const fetchCooperativesRequest = () => ({
  type: FETCH_COOPERATIVES_REQUEST,
});

const fetchCooperativesSuccess = (results) => ({
  type: FETCH_COOPERATIVES_SUCCESS,
  payload: results,
});

const fetchCooperativesFailure = (error) => ({
  type: FETCH_COOPERATIVES_FAILURE,
  payload: error,
});

export const fetchCooperatives =
  (queries, itemsPerPage) => async (dispatch) => {
    dispatch(fetchCooperativesRequest());

    const searchUrl = `cooperatives?q=${queries?.q || ""}&page=${
      queries?.page || "1"
    }&limit=${queries?.limit || itemsPerPage}&product=${
      queries?.product || ""
    }&state=${queries?.state || ""}`;

    try {
      const { data } = await api.get(searchUrl);
      dispatch(fetchCooperativesSuccess(data));
    } catch (error) {
      dispatch(fetchCooperativesFailure(error?.message));
    }
  };

export const setCooperativeProduct = (product) => ({
  type: SET_COOPERATIVE_PRODUCT,
  payload: product,
});

const fetchCooperativeRequest = () => ({
  type: FETCH_COOPERATIVE_REQUEST,
});

const fetchCooperativeSuccess = (cooperative) => ({
  type: FETCH_COOPERATIVE_SUCCESS,
  payload: cooperative,
});

const fetchCooperativeFailure = (error) => ({
  type: FETCH_COOPERATIVE_FAILURE,
  payload: error,
});

export const fetchCooperative = (id) => async (dispatch) => {
  dispatch(fetchCooperativeRequest());

  const cooperativeUrl = `cooperatives/${id}`;

  try {
    const { data } = await api.get(cooperativeUrl);
    dispatch(fetchCooperativeSuccess(data));
  } catch (error) {
    dispatch(fetchCooperativeFailure(error?.message));
  }
};

export const setShowMap = (show) => ({
  type: SET_SHOW_MAP,
  payload: show,
});
