import { createUseStyles } from "react-jss";
// import { breakpoints, defaultContainer } from "../../../constants/sizes";

const useStyles = createUseStyles((/* theme */) => ({
  loading: {
    minHeight: "70vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  section: {
    marginTop: 20,
  },
  paginationWrapper: {
    marginTop: 30,
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
}));

export default useStyles;
