import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import Grid from "@material-ui/core/Grid";
import { useMediaQuery } from "@material-ui/core";
import Carousel from "react-material-ui-carousel";
import SearchBar from "../SearchBar";
import CustomTypography from "../../CustomTypography";
import CustomLink from "../../CustomLink";
import ArrowUp from "../../Icons/ArrowUp";
import HorizontalScrollbar from "../../HorizontalScrollbar";
import ResearcherCard from "../ResearcherCard";
import { breakpoints } from "../../../constants/sizes";
import useStyles from "./styles";

const TrendingSection = ({
  trends,
  onKeyPress,
  onSearchClick,
  researchers,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  const isLg = useMediaQuery(breakpoints.lg);

  return (
    <div className={classes.sectionWrapper}>
      <Grid item md={6} xs={12}>
        <div className={classes.researchesWrapper}>
          {!isLg ? (
            <>
              {researchers?.map((researcher) => (
                <ResearcherCard
                  key={researcher.lattes_id}
                  id={researcher.lattes_id}
                  researchAreas={researcher.research_areas}
                />
              ))}
            </>
          ) : (
            <Carousel className={classes.carousel}>
              {researchers?.map((researcher) => (
                <div
                  key={researcher.lattes_id}
                  className={classes.cardsWrapper}
                >
                  <ResearcherCard
                    id={researcher.lattes_id}
                    researchAreas={researcher.research_areas}
                  />
                </div>
              ))}
            </Carousel>
          )}
        </div>
      </Grid>
      <Grid item md={6} xs={12}>
        <div className={classes.leftSection}>
          <CustomTypography variant="h2">
            {t("landing.searchSection.trendingSection.title")}
          </CustomTypography>

          <div className={classes.searchBar}>
            <CustomTypography
              variant="noticeBody2"
              weight="bold"
              color={theme.secondary0}
              className={classes.searchBarTitle}
            >
              {t("landing.searchSection.trendingSection.whatAreYouLooking")}
            </CustomTypography>
            <SearchBar
              onKeyPress={onKeyPress}
              onSearchClick={onSearchClick}
              hasSelector
            />
          </div>

          <div className={classes.trendWrapper}>
            <CustomTypography
              color={theme.primary2}
              className={classes.trendsTitle}
              variant="noticeTitle"
            >
              <ArrowUp />
              Assuntos em alta
            </CustomTypography>
            <HorizontalScrollbar>
              {trends?.map((item) => (
                <div key={item.word} className={classes.trendCard}>
                  <CustomLink to={`/buscador?q=${item.word}`}>
                    <CustomTypography
                      variant="noticeTitle"
                      color={theme.lowDark}
                    >
                      {item.word}
                    </CustomTypography>
                  </CustomLink>

                  <CustomTypography variant="body" color={theme.textSecondary}>
                    {t("landing.searchSection.numbersOfSearches", {
                      number: item.frequency,
                    })}
                  </CustomTypography>
                </div>
              ))}
            </HorizontalScrollbar>
          </div>
        </div>
      </Grid>
    </div>
  );
};

TrendingSection.propTypes = {
  trends: PropTypes.arrayOf(PropTypes.shape()),
  onKeyPress: PropTypes.func,
  onSearchClick: PropTypes.func,
  researchers: PropTypes.arrayOf(PropTypes.shape()),
};

TrendingSection.defaultProps = {
  trends: null,
  onKeyPress: null,
  onSearchClick: null,
  researchers: null,
};

export default TrendingSection;
