import { createUseStyles } from "react-jss";
import { breakpoints } from "../../../../constants/sizes";

const useStyles = createUseStyles(() => ({
  root: {
    width: "100%",
    padding: 15,
    paddingBottom: 0,
    [breakpoints.sm]: {
      padding: 0,
      paddingTop: 10,
      paddingBottom: 10,
    },
  },
  title: {
    wordWrap: "break-word",
    maxWidth: 480,
    [breakpoints.lg]: {
      maxWidth: "40vw",
    },
    [breakpoints.md]: {
      maxWidth: "78vw",
    },
    [breakpoints.sm]: {
      maxWidth: "82vw",
    },
    [breakpoints.xsm]: {
      maxWidth: "none",
    },
  },
  ResultArea: {
    marginBottom: 10,
  },
  resultContent: {},
  articleInfo: {
    wordSpacing: 12,
  },
  image: {
    width: 40,
    height: 40,
    marginRight: 5,
    borderRadius: "100%",
    objectFit: "cover",
    objectPosition: "center center",
  },
  date: {
    textTransform: "capitalize",
  },
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "40px auto",
    gridColumnGap: 10,
    minHeight: 50,
  },
  contentWrapper: {
    width: "100%",
  },
  centeredImageWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: 10,
  },
}));

export default useStyles;
