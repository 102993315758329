import { createUseStyles } from "react-jss";
import { breakpoints } from "../../constants/sizes";
import { stringToHexColorParser } from "../../util/hooks";

const useStyles = createUseStyles((theme) => ({
  wrapper: {
    width: (props) => props.size,
    height: (props) => props.size,
    [breakpoints.md]: {
      width: (props) => props.smSize,
      height: (props) => props.smSize,
    },
  },
  image: {
    width: (props) => props.size,
    height: (props) => props.size,
    [breakpoints.md]: {
      width: (props) => props.smSize,
      height: (props) => props.smSize,
    },
    marginRight: 5,
    borderRadius: "100%",
    objectFit: "cover",
    objectPosition: "center center",
  },
  imageSkeleton: {
    width: (props) => props.size,
    height: (props) => props.size,
    [breakpoints.md]: {
      width: (props) => props.smSize,
      height: (props) => props.smSize,
    },
  },
  emptyProfile: {
    borderRadius: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: (props) =>
      props.name ? stringToHexColorParser(props.name) : theme.background0,
    width: (props) => props.size,
    height: (props) => props.size,
    color: theme.background0,
    fontSize: (props) => props.size * 0.6,
    fontWeight: "500",
    [breakpoints.md]: {
      width: (props) => props.smSize,
      height: (props) => props.smSize,
      fontSize: (props) => props.smSize * 0.6,
    },
  },
}));

export default useStyles;
