import * as React from "react";

function Quote(props) {
  return (
    <svg
      width={16}
      height={13}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.0741 13H14.5185C14.9113 12.9995 15.2879 12.8281 15.5656 12.5235C15.8433 12.2189 15.9995 11.8058 16 11.375V6.175C15.9995 5.74418 15.8433 5.33114 15.5656 5.0265C15.2879 4.72186 14.9113 4.5505 14.5185 4.55H10.3704V4.225C10.371 3.62184 10.5897 3.04357 10.9785 2.61707C11.3674 2.19057 11.8946 1.95067 12.4444 1.95C12.6802 1.95 12.9063 1.84728 13.073 1.66443C13.2397 1.48158 13.3333 1.23359 13.3333 0.975C13.3333 0.716414 13.2397 0.46842 13.073 0.285572C12.9063 0.102724 12.6802 9.53674e-07 12.4444 9.53674e-07C11.4232 0.00126934 10.4442 0.446809 9.72205 1.23888C8.99994 2.03094 8.59375 3.10485 8.59259 4.225V11.375C8.59304 11.8058 8.74927 12.2189 9.02701 12.5235C9.30474 12.8281 9.6813 12.9995 10.0741 13ZM10.3704 6.5H14.2222V11.05H10.3704V6.5ZM1.48148 13H5.92593C6.3187 12.9995 6.69526 12.8281 6.97299 12.5235C7.25073 12.2189 7.40696 11.8058 7.40741 11.375V6.175C7.40696 5.74418 7.25073 5.33114 6.97299 5.0265C6.69526 4.72186 6.3187 4.5505 5.92593 4.55H1.77778V4.225C1.77839 3.62184 1.9971 3.04357 2.38593 2.61707C2.77476 2.19057 3.30196 1.95067 3.85185 1.95C4.0876 1.95 4.31369 1.84728 4.48039 1.66443C4.64709 1.48158 4.74074 1.23359 4.74074 0.975C4.74074 0.716414 4.64709 0.46842 4.48039 0.285572C4.31369 0.102724 4.0876 9.53674e-07 3.85185 9.53674e-07C2.83063 0.00126934 1.85157 0.446809 1.12946 1.23888C0.407347 2.03094 0.00115585 3.10485 0 4.225V11.375C0.000450134 11.8058 0.15668 12.2189 0.434414 12.5235C0.712148 12.8281 1.08871 12.9995 1.48148 13ZM1.77778 6.5H5.62963V11.05H1.77778V6.5Z"
        fill="#333333"
      />
    </svg>
  );
}

export default Quote;
