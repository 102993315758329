import * as React from "react";

function OutlinedStar(props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.4182 6.05277L12.0222 5.26854L9.60999 0.378305C9.54411 0.244413 9.43572 0.136025 9.30183 0.0701416C8.96604 -0.0956291 8.55799 0.0425132 8.39009 0.378305L5.97791 5.26854L0.581865 6.05277C0.433096 6.07402 0.297079 6.14415 0.192941 6.25042C0.0670438 6.37982 -0.00233122 6.5539 5.98176e-05 6.73443C0.00245086 6.91495 0.0764123 7.08714 0.205692 7.21316L4.10981 11.0195L3.18744 16.3943C3.16581 16.5193 3.17965 16.6479 3.22738 16.7655C3.27511 16.8831 3.35483 16.9849 3.4575 17.0595C3.56016 17.134 3.68166 17.1783 3.80823 17.1874C3.9348 17.1964 4.06136 17.1698 4.17356 17.1105L9.00004 14.573L13.8265 17.1105C13.9583 17.1807 14.1113 17.204 14.258 17.1785C14.6277 17.1148 14.8764 16.7641 14.8126 16.3943L13.8903 11.0195L17.7944 7.21316C17.9007 7.10902 17.9708 6.97301 17.992 6.82424C18.0494 6.45232 17.7901 6.10802 17.4182 6.05277ZM12.2474 10.4839L13.0147 14.9534L9.00004 12.8451L4.98542 14.9555L5.75264 10.4861L2.50523 7.31942L6.99379 6.66697L9.00004 2.60133L11.0063 6.66697L15.4949 7.31942L12.2474 10.4839Z"
        fill="#A0A0A0"
      />
    </svg>
  );
}

export default OutlinedStar;
