import { createUseStyles } from "react-jss";
import { breakpoints } from "../../../constants/sizes";

const useStyles = createUseStyles((theme) => ({
  header: {
    display: "flex",
    flexFlow: "row nowrap",
    height: 72,
    userSelect: "none",
    width: "100%",
    padding: "0px 30px 0px 30px",
    borderBottom: `1px solid ${theme.stroke}`,
  },
  headerItens: {
    width: "100%",
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    justifyContent: "space-between",

    [breakpoints.md]: {
      gap: 0,
      justifyContent: "flex-end",
    },
  },
  buttonsWrapper: {
    display: "flex",
    gap: 30,
    alignItems: "center",
    [breakpoints.md]: {
      width: "100%",
    },
  },
  headerItem: {
    display: "flex",
    [breakpoints.md]: {
      display: "none",
    },
  },
  actionsWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 20,
    [breakpoints.md]: {
      gap: 0,
      width: "100%",
    },
  },
  headerMobile: {
    display: "none",
    [breakpoints.md]: {
      display: "flex",
      alignItems: "center",
      height: "100%",
    },
  },
  logoWrapper: {
    display: "flex",
    cursor: "pointer",
    userSelect: "none",

    "&:focus": {
      outline: "none",
    },
  },
  logo: {
    width: 104,
    [breakpoints.md]: {
      width: 104,
      height: 32,
      objectPosition: 0,
      objectFit: "fit",
    },
  },
  link: {
    textDecoration: "none",
    position: "relative",
    whiteSpace: "nowrap",
    width: 150,
    color: theme.textSecondary,
    fontSize: 16,
  },
  arrowMenu: {
    marginLeft: 10,
  },
  buttonWrapper: {
    display: "flex",
    gap: 10,
    [breakpoints.md]: {
      display: "none",
    },
  },
  signInButton: {
    "& .MuiButton-root": {
      "&:hover": {
        backgroundColor: theme.background0,
      },
    },
  },
  signUpButtonWrapper: {
    minWidth: 155,
    "& .MuiButton-root": {
      height: 40,
      fontWeight: "bold",
    },
  },
  contactUsWrapper: {
    display: "flex",
    alignItems: "center",
    "& svg": {
      marginLeft: 15,
      marginTop: 6,
    },
  },
}));

export default useStyles;
