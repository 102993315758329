import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { TextField } from "@material-ui/core";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import useStyles from "./styles";

/**
 * This component renders a custom textfield styled for project.
 * @returns A custom textfield
 */
function CustomTextField({
  children,
  startIcon,
  endIcon,
  className,
  ...props
}) {
  CustomTextField.defaultProps = {
    children: undefined,
    className: "",
    startIcon: undefined,
    endIcon: undefined,
  };

  CustomTextField.propTypes = {
    children: PropTypes.shape(),
    className: PropTypes.string,
    startIcon: PropTypes.elementType,
    endIcon: PropTypes.elementType,
  };

  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword((oldValue) => !oldValue);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <TextField
      classes={{ root: classNames(classes.root, className) }}
      {...props}
      type={showPassword ? "text" : "password"}
      InputProps={{
        startAdornment: startIcon && (
          <InputAdornment position="start">{startIcon}</InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? (
                <VisibilityOffOutlinedIcon />
              ) : (
                <VisibilityOutlinedIcon />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
    >
      {children}
    </TextField>
  );
}

export default CustomTextField;
