/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef, useMemo, memo } from "react";
import { useTheme } from "react-jss";
import PropTypes from "prop-types";
import ReactMapGL, { NavigationControl, FlyToInterpolator } from "react-map-gl";
import useSupercluster from "use-supercluster";
import WebMercatorViewport from "viewport-mercator-project";
import { useSelector } from "react-redux";
import { useSize } from "react-hook-size";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getBounds, circularArrange } from "../../../util/mapHooks";
import MapMarker from "./MapMarker";
import useStyles from "./styles";
import ClusterMarker from "./ClusterMarker";
import CategoryFilter from "./CategoryFilter";
import Edge from "./Edge";
import RealtionalFilter from "./RealtionalFilter";
import CustomPopup from "./CustomPopup";

const MAP_CONFIG = {
  mapboxApiAccessToken: process.env.REACT_APP_MAPBOX_TOKEN,
};

const Map = ({
  results,
  selectedResult,
  onClosePopup,
  onClickMarker,
  disableClustering,
  drawEdges, // When is true paints edges between the first and all others results
  showCategoryFilter,
  showDateRangeFilter,
  loading,
  hideCategoryFilter,
}) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const mapRef = useRef();
  const mapContainerRef = useRef(null);
  const { width, height } = useSize(mapContainerRef);
  const { categoryFilter, enabled } = useSelector(
    (state) => state.resultFilter
  );

  const [viewport, setViewport] = useState({
    width: "100%",
    height: "100%",
    latitude: -14.235,
    longitude: -51.9253,
    zoom: 3,
  });

  const filter = (result) => {
    const hasCoordinate = result.latitude && result.latitude !== "";
    if (!hasCoordinate) return false;
    if (enabled) {
      return categoryFilter[result.category];
    }
    return true;
  };

  useEffect(() => {
    if (width && height) {
      setViewport((oldViewport) => ({
        ...oldViewport,
        width,
        height,
      }));
    }
  }, [width, height]);

  useEffect(() => {
    if (width && height && results && results.filter(filter).length) {
      const filteredResults = results.filter(filter);
      // If results has just one result the zoom should be smaller
      if (filteredResults.length === 1) {
        setViewport((oldViewport) => ({
          ...oldViewport,
          latitude: parseFloat(filteredResults[0].latitude),
          longitude: parseFloat(filteredResults[0].longitude),
          zoom: 12,
        }));
        return;
      }
      // Get bounds from each results and fit zoom to wrappe all of them
      const MARKERS_BOUNDS = getBounds(filteredResults);
      setViewport((oldViewport) => {
        const NEXT_VIEWPORT = new WebMercatorViewport({
          ...oldViewport,
          width,
          height,
        }).fitBounds(MARKERS_BOUNDS, {
          padding: 100,
        });

        return NEXT_VIEWPORT;
      });
    }
  }, [width, height, results]);

  const onViewportChange = (nextViewport) => setViewport(nextViewport);

  const handleClickResult = (result) => {
    onClickMarker(result);
    setViewport((oldViewport) => ({
      ...oldViewport,
      latitude: result.geometry.coordinates[1],
      longitude: result.geometry.coordinates[0],
    }));
  };

  const points = useMemo(
    () =>
      circularArrange(results.filter(filter)).map((result) => ({
        type: "Feature",
        id: result.id || result.lattes_id,
        properties: {
          cluster: false,
          resultId: result.id,
          category: result.type,
        },
        isFeatured: result.isFeatured,
        result: { ...result },
        geometry: {
          type: "Point",
          coordinates: [
            parseFloat(result.longitude),
            parseFloat(result.latitude),
          ],
        },
      })),
    [results, categoryFilter, enabled]
  );

  const bounds = mapRef.current
    ? mapRef.current.getMap().getBounds().toArray().flat()
    : null;

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom: viewport.zoom,
    options: {
      radius: disableClustering ? 0 : 75,
      maxZoom: 20,
    },
  });

  const handleSuperClusterClick = (result, latitude, longitude) => {
    const expansionZoom = Math.min(
      supercluster.getClusterExpansionZoom(result.id),
      20
    );

    setViewport({
      ...viewport,
      latitude,
      longitude,
      zoom: expansionZoom,
      transitionInterpolator: new FlyToInterpolator({
        speed: 2,
      }),
      transitionDuration: "auto",
    });
  };

  return useMemo(
    () => (
      <div ref={mapContainerRef} className={classes.map}>
        <ReactMapGL
          ref={mapRef}
          {...viewport}
          {...MAP_CONFIG}
          onViewportChange={onViewportChange}
          width="100%"
          height="100%"
        >
          {loading ? (
            <div className={classes.loadingIndicatorWrapper}>
              <CircularProgress />
            </div>
          ) : (
            <>
              {(!drawEdges || showCategoryFilter) && !hideCategoryFilter && (
                <CategoryFilter className={classes.categoryFilter} />
              )}
              {hideCategoryFilter && (
                <RealtionalFilter className={classes.rightLeftElement} />
              )}
            </>
          )}

          <NavigationControl className={classes.navigationControl} />

          {drawEdges &&
            points.map((point) => (
              <>
                <Edge
                  key={`edge-${point.id}`}
                  edge={{
                    source: [
                      points[0].result.latitude,
                      points[0].result.longitude,
                    ],
                    target: [point.result.latitude, point.result.longitude],
                    weight: point.result.coauthorship,
                    maxWeight: point.result.maxWeight,
                    minWeight: point.result.minWeight,
                  }}
                />

                <MapMarker
                  key={`marker-${point.id}`}
                  result={point}
                  onClick={() => handleClickResult(point)}
                  isSelected={
                    selectedResult &&
                    (point.id === selectedResult.id ||
                      point.id === selectedResult.lattes_id)
                  }
                  isFeatured={point.result.isFeatured}
                />
              </>
            ))}

          {selectedResult && (
            <CustomPopup
              key={`selected-${selectedResult.result.id}`}
              latitude={parseFloat(selectedResult.geometry.coordinates[1])}
              longitude={parseFloat(selectedResult.geometry.coordinates[0])}
              onClosePopup={onClosePopup}
              selectedResult={selectedResult}
            />
          )}

          {!drawEdges &&
            clusters.map((result) => {
              const [longitude, latitude] = result.geometry.coordinates;
              const { cluster: isCluster, point_count: pointCount } =
                result.properties;

              if (isCluster) {
                return (
                  <ClusterMarker
                    key={`cluster-${result.id}-${latitude}-${longitude}`}
                    id={result.id.toString()}
                    latitude={latitude}
                    longitude={longitude}
                    onClick={() =>
                      handleSuperClusterClick(result, latitude, longitude)
                    }
                    pointCount={pointCount}
                    points={points}
                  />
                );
              }

              return (
                <>
                  <MapMarker
                    key={`simpleMarker-${result.id}`}
                    result={result}
                    onClick={() => handleClickResult(result)}
                    isSelected={
                      selectedResult &&
                      (result.id === selectedResult.id ||
                        result.id === selectedResult.lattes_id)
                    }
                    isFeatured={result.isFeatured}
                  />
                </>
              );
            })}
        </ReactMapGL>
      </div>
    ),
    [clusters, selectedResult, drawEdges, showDateRangeFilter, viewport, points]
  );
};
Map.propTypes = {
  results: PropTypes.arrayOf(PropTypes.shape()),
  selectedResult: PropTypes.shape(),
  onClosePopup: PropTypes.func,
  onClickMarker: PropTypes.func,
  disableClustering: PropTypes.bool,
  drawEdges: PropTypes.bool,
  showCategoryFilter: PropTypes.bool,
  showDateRangeFilter: PropTypes.bool,
  loading: PropTypes.bool,
  hideCategoryFilter: PropTypes.bool,
};
Map.defaultProps = {
  results: undefined,
  selectedResult: undefined,
  onClosePopup: undefined,
  onClickMarker: undefined,
  disableClustering: false,
  drawEdges: false,
  showCategoryFilter: false,
  showDateRangeFilter: false,
  loading: false,
  hideCategoryFilter: false,
};

export default memo(Map);
