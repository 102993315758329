import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useTheme } from "react-jss";
import { useTranslation } from "react-i18next";
import CustomPagination from "../../../../../CustomPagination";
import Section from "../../../Section";
import ProfileImg from "../../../../../ProfileImg";
import SectionRow from "../../../SectionRow";
import useStyles from "./styles";
import { isEmpty } from "../../../../../../util/hooks";

const CoAuthorsPaginatedList = ({ items }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const itemsPerPage = 10;

  if (!items) {
    console.error("items cannot be null");
    return null;
  }

  const numberOfPages = useMemo(
    () => Math.ceil(items.length / itemsPerPage),
    [itemsPerPage]
  );

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  return (
    <>
      <div>
        <Section
          title={t("resultPage.mainCoAuthors")}
          name="coauthors"
          isEmpty={isEmpty(items)}
          emptyMessage={t("resultPage.emptyCoAuthors")}
          loading={false}
          showViewMore={false}
        >
          {items
            .slice((page - 1) * itemsPerPage, page * itemsPerPage)
            .map((colaborator) => (
              <SectionRow
                key={colaborator.lattes_id}
                title={colaborator.name}
                linkTo={
                  colaborator.name
                    ? `/buscador/pesquisador/${colaborator.lattes_id}`
                    : undefined
                }
                subtitle={
                  <span>
                    {colaborator.institute || t("researcher.notAffiliated")}
                  </span>
                }
                imageCell={
                  <ProfileImg
                    url={colaborator.image}
                    name={colaborator.name}
                    size={40}
                  />
                }
                stats={t("resultPage.collaborationsWithCount", {
                  count: parseFloat(colaborator.coauthorship),
                })}
                placeholder={t("resultPage.infoUnavailable")}
              />
            ))}
        </Section>
      </div>

      <div className={classes.paginationWrapper}>
        <CustomPagination count={numberOfPages} onChange={handlePageChange} />
      </div>
    </>
  );
};

CoAuthorsPaginatedList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default CoAuthorsPaginatedList;
