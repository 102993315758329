import { createUseStyles } from "react-jss";
// import { breakpoints, defaultContainer } from "../../../constants/sizes";

const useStyles = createUseStyles((/* theme */) => ({
  content: {},
  alertPaper: {
    paddingBottom: 20,
  },
  filter: {
    paddingBottom: 34,
  },
  loading: {
    minHeight: "70vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  section: {
    marginTop: 20,
  },
}));

export default useStyles;
