import React, { memo, useMemo } from "react";
import PropTypes from "prop-types";
import { Source, Layer } from "react-map-gl";
import {
  destination,
  lineDistance,
  projection,
  midpoint,
  bearing,
  lineArc,
  distance,
} from "@turf/turf";

// import { Container } from './styles';
/**
 * Id is obrigatory
 * @param {*} param0
 * @returns
 */
const Edge = ({ edge }) => {
  /**
   * Apply linear scalling to reescale data in range between 0 - 1
   */
  const normalizedWeight = useMemo(() => {
    if (edge.maxWeight - edge.minWeight !== 0) {
      return (
        (parseFloat(edge.weight) - edge.minWeight) /
        (edge.maxWeight - edge.minWeight)
      );
    }

    return 1;
  }, [edge.maxWeight, edge.minWeight]);

  const data = useMemo(() => {
    let route = {
      type: "LineString",
      coordinates: [
        [edge.source[1], edge.source[0]],
        [edge.target[1], edge.target[0]],
      ],
    };

    route = projection.toWgs84(route);
    const lineD = lineDistance(route, { units: "kilometers" });
    const mp = midpoint(route.coordinates[0], route.coordinates[1]);
    const center = destination(
      mp,
      lineD,
      bearing(route.coordinates[0], route.coordinates[1]) - 90
    );

    const lA = lineArc(
      center,
      distance(center, route.coordinates[0]),
      bearing(center, route.coordinates[1]),
      bearing(center, route.coordinates[0])
    );
    return projection.toMercator(lA);
  }, [edge.source, edge.target]);

  return (
    <Source id={`route-${edge.target}`} type="geojson" data={data} lineMetrics>
      <Layer
        id={`route-${edge.target}`}
        type="line"
        source={`route-${edge.target}`}
        layout={{
          "line-join": "round",
          "line-cap": "round",
        }}
        paint={{
          "line-color": "#FF9900",
          "line-width": 4 + 4 * normalizedWeight || 0,
          "line-opacity": 0.5 + normalizedWeight * 0.5 || 0,

          "line-gradient": [
            "interpolate",
            ["linear"],
            ["line-progress"],
            0,
            "#f3a52f",
            1,
            "#FF9900",
          ],
        }}
      />
    </Source>
  );
};

Edge.propTypes = {
  edge: PropTypes.shape().isRequired,
};

Edge.defaultProps = {};

export default memo(Edge);
