/* eslint-disable no-unused-vars */
import { createUseStyles } from "react-jss";
import { breakpoints, defaultContainer } from "../../../../constants/sizes";

const useStyles = createUseStyles((theme) => ({
  menssage: {
    padding: 5,
  },
  buttonWrapper: {
    display: "flex",
    width: 155,
  },
  iconWrapper: {
    marginTop: 5,
  },
  textButtonWrapper: {
    marginLeft: 35,
  },
  menuWrapper: {
    maxWidth: 250,
  },
}));

export default useStyles;
