import {
  FETCH_COOPERATIVES_STATISTICS_REQUEST,
  FETCH_COOPERATIVES_STATISTICS_SUCCESS,
  FETCH_COOPERATIVES_STATISTICS_FAILURE,
  FETCH_COOPERATIVES_REQUEST,
  FETCH_COOPERATIVES_SUCCESS,
  FETCH_COOPERATIVES_FAILURE,
  SET_COOPERATIVE_PRODUCT,
  FETCH_COOPERATIVE_REQUEST,
  FETCH_COOPERATIVE_SUCCESS,
  FETCH_COOPERATIVE_FAILURE,
  SET_SHOW_MAP,
} from "./cooperativeTypes";

const initialState = {
  statistics: null,
  loadingStatistics: false,
  loadingCooperatives: false,
  loadingCooperative: false,
  statisticsError: null,
  cooperatives: null,
  cooperativesCount: null,
  cooperativesError: null,
  selectedProduct: null,
  cooperative: null,
  cooperativeError: null,
  showMap: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COOPERATIVES_STATISTICS_REQUEST:
      return { ...state, statistics: null, loadingStatistics: true };
    case FETCH_COOPERATIVES_STATISTICS_SUCCESS:
      return {
        ...state,
        statistics: {
          ...action.payload,
          cooperatives_products: action.payload.cooperatives_products.slice(
            0,
            10
          ),
        },
        loadingStatistics: false,
      };
    case FETCH_COOPERATIVES_STATISTICS_FAILURE:
      return {
        ...state,
        statisticsError: action.payload,
        loadingStatistics: false,
      };
    case FETCH_COOPERATIVES_REQUEST:
      return {
        ...state,
        cooperatives: null,
        cooperativesCount: 0,
        loadingCooperatives: true,
        cooperativesError: null,
      };
    case FETCH_COOPERATIVES_SUCCESS:
      return {
        ...state,
        cooperatives: action.payload.data,
        cooperativesCount: action.payload.count,
        loadingCooperatives: false,
      };
    case FETCH_COOPERATIVES_FAILURE:
      return {
        ...state,
        cooperativesError: action.payload,
        loadingCooperatives: false,
      };
    case SET_COOPERATIVE_PRODUCT:
      return { ...state, selectedProduct: action.payload };
    case FETCH_COOPERATIVE_REQUEST:
      return {
        ...state,
        loadingCooperative: true,
      };
    case FETCH_COOPERATIVE_SUCCESS:
      return {
        ...state,
        loadingCooperative: false,
        cooperative: action.payload,
      };
    case FETCH_COOPERATIVE_FAILURE:
      return {
        ...state,
        cooperativeError: action.payload,
        loadingCooperative: false,
      };
    case SET_SHOW_MAP:
      return {
        ...state,
        showMap: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
