import { createUseStyles } from "react-jss";
// import { breakpoints } from "../../../constants/sizes";

const useStyles = createUseStyles((/* theme */) => ({
  emptyMessage: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    fontStyle: "italic",
    fontSize: 12,
    "& p": {
      marginTop: -10,
      textAlign: "center",
    },
  },
}));

export default useStyles;
