/* eslint-disable  no-prototype-builtins */
import { maxBy, minBy } from "lodash";

const getMinOrMax = (markers, minOrMax, latOrLng) => {
  if (minOrMax === "max") {
    return maxBy(markers, (value) => parseFloat(value[latOrLng]))[latOrLng];
  }
  return minBy(markers, (value) => parseFloat(value[latOrLng]))[latOrLng];
};

export const getBounds = (markers) => {
  const maxLat = getMinOrMax(markers, "max", "latitude");
  const minLat = getMinOrMax(markers, "min", "latitude");
  const maxLng = getMinOrMax(markers, "max", "longitude");
  const minLng = getMinOrMax(markers, "min", "longitude");

  const southWest = [parseFloat(minLng), parseFloat(minLat)];
  const northEast = [parseFloat(maxLng), parseFloat(maxLat)];

  return [southWest, northEast];
};

export const circularArrange = (data) => {
  const RADIUS = 0.0002;

  const getKey = (item) => `${item.latitude}:${item.longitude}`;

  const lookupMap = {};

  // Find out how many points are in the same location.
  data.forEach((item) => {
    const key = getKey(item);
    if (lookupMap.hasOwnProperty(key)) {
      lookupMap[key] += 1;
    } else {
      lookupMap[key] = 1;
    }
  });

  // Now put each point which is at the same location around a circle.
  data.forEach((item) => {
    const key = getKey(item);

    if (lookupMap[key] > 1) {
      let count = 0;
      data.forEach((item2, index2) => {
        const key2 = getKey(item2);

        if (key2 === key) {
          const angle = (count / (lookupMap[key] / 2)) * Math.PI;
          const newlat = parseFloat(item2.latitude) + RADIUS * Math.cos(angle);
          const newlng = parseFloat(item2.longitude) + RADIUS * Math.sin(angle);
          data[index2].latitude = newlat;
          data[index2].longitude = newlng;
          count += 1;
        }
      });
    }
  });

  return data;
};
