import React, { useState } from "react";
import { useTheme } from "react-jss";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import YouTube from "react-youtube";
import { getYoutubeId } from "../../../services/hcms/media";
import CustomTypography from "../../CustomTypography";
import Markdown from "../../Markdown";
import useStyles from "./styles";
import RecentlySkeleton from "../../Skeleton/RecentlySkeleton";

/**
 * This component represents an Article in Highlight.
 */
export default function MoreVideoInfo({ data, hideImage, onClick, loading }) {
  MoreVideoInfo.propTypes = {
    data: PropTypes.shape(),
    hideImage: PropTypes.bool,
    onClick: PropTypes.func,
    loading: PropTypes.bool,
  };

  MoreVideoInfo.defaultProps = {
    hideImage: false,
    onClick: null,
    data: undefined,
    loading: false,
  };
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { t } = useTranslation();
  const [isHighlightness, setisHighlightness] = useState(false);

  function doHighlightness() {
    setisHighlightness(true);
  }

  function lostHighlightness() {
    setisHighlightness(false);
  }

  return !data ? (
    <> {loading && <RecentlySkeleton />}</>
  ) : (
    <>
      <div
        className={isHighlightness ? classes.wrapperHighlight : classes.wrapper}
      >
        <div
          className={
            isHighlightness ? classes.videoThumbHighlight : classes.videoThumb
          }
        >
          {hideImage ? null : (
            <YouTube
              className={
                isHighlightness
                  ? classes.videoThumbHighlight
                  : classes.videoThumb
              }
              videoId={getYoutubeId(data.link)}
              onPlay={() => doHighlightness()}
              onPause={() => lostHighlightness()}
              onEnd={() => lostHighlightness()}
            />
          )}
        </div>

        <div className={classes.videoInfo}>
          <div
            className={
              isHighlightness
                ? classes.moreVideoTitleHighlight
                : classes.moreVideoTitle
            }
          >
            <Link
              className={classes.link}
              to={{ pathname: data.link }}
              target="_blank"
              onClick={onClick}
              replace
            >
              <CustomTypography
                className={classes.titleTypography}
                variant="noticeTitle"
                style={{ fontSize: 18 }}
              >
                {data.title}
              </CustomTypography>
            </Link>
          </div>
          <CustomTypography
            color={theme.textSecondary}
            style={{ marginBottom: 16, fontSize: 16 }}
            className={classes.bodyPreview}
            variant="paragraph"
          >
            <Markdown>{data.body.toString()}</Markdown>
          </CustomTypography>

          <div>
            <CustomTypography
              variant="body"
              weight="regular"
              style={{ fontSize: 14 }}
              color={theme.textSecondary}
            >
              {t("agency.article.publishedAtDate", {
                date: new Date(data.date),
              })}
            </CustomTypography>
          </div>
        </div>
      </div>
    </>
  );
}
