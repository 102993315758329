const PUNCTUATION_LIST = [
  ".",
  ",",
  "!",
  "?",
  "'",
  "{",
  "}",
  "(",
  ")",
  "[",
  "]",
  "/",
  " ",
];

const trimText = (text, max = 200) => {
  if (!text || text === "") return text;
  let end = max;
  while (
    max < text.length &&
    !PUNCTUATION_LIST.includes(`${text.charAt(end)}`)
  ) {
    end += 1;
  }

  return [text.slice(0, end), text.slice(end).trim()];
};

export default trimText;
