import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "react-jss";
import { Scrollbars } from "react-custom-scrollbars";
import CustomButton from "../../../CustomButton";
import useStyles from "./styles";

function SmSelectWrapper({ children, onApplyClick, buttonLabel }) {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <>
      <Scrollbars
        style={{ height: 340, width: "100%" }}
        renderView={(props) => (
          <div
            {...props}
            style={{
              // eslint-disable-next-line react/prop-types
              ...props.style,
              overflowX: "hidden",
              marginTop: 0,
              marginBottom: 0,
            }}
          />
        )}
      >
        <div className={classes.container}>
          <div className={classes.buttonsWrapper}>{children}</div>
        </div>
      </Scrollbars>
      <div className={classes.applyButton}>
        <CustomButton onClick={onApplyClick}>{buttonLabel}</CustomButton>
      </div>
    </>
  );
}

SmSelectWrapper.propTypes = {
  children: PropTypes.node,
  onApplyClick: PropTypes.func,
  buttonLabel: PropTypes.string,
};

SmSelectWrapper.defaultProps = {
  children: undefined,
  onApplyClick: undefined,
  buttonLabel: "Apply",
};

export default SmSelectWrapper;
