import { createUseStyles } from "react-jss";

const useStyles = createUseStyles(() => ({
  generalWrapper: {
    marginTop: 25,
  },
  cardsWrapper: {
    marginTop: 20,
    display: "flex",
    columnGap: 10,
  },
}));

export default useStyles;
