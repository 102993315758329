import { createUseStyles } from "react-jss";
import { breakpoints } from "../../../constants/sizes";

const useStyles = createUseStyles(() => ({
  iconButton: {
    padding: 10,
    "&.MuiIconButton-root": {
      width: 48,
      [breakpoints.sm]: {
        transform: "scale(0.8)",
      },
    },
  },
}));

export default useStyles;
