import React, { useEffect } from "react";
import classNames from "classnames";
import { useTheme } from "react-jss";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { usePagination } from "@material-ui/lab/Pagination";

import ArrowRight from "../Icons/ArrowRight";
import useStyles from "./styles";

const CustomPagination = ({ count, currentPage, onChange }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  const isSm = useMediaQuery("(max-width: 600px)");
  const { items } = usePagination({
    count,
    page: currentPage,
    siblingCount: isSm ? 0 : 1,
  });

  useEffect(() => {
    items.forEach(({ page, selected }) => {
      if (selected) if (onChange) onChange(page);
    });

    return () => {};
  }, [items]);

  function typeSwitcher(type) {
    switch (type) {
      case "previous":
        return (
          <div className={classNames(classes.previous, "previous")}>
            <ArrowRight />
            {t("pagination.previous")}
          </div>
        );
      case "next":
        return (
          <div className={classNames(classes.next, "next")}>
            {t("pagination.next")}
            <ArrowRight />
          </div>
        );
      default:
        return type;
    }
  }

  return (
    <nav>
      <ul className={classes.ul}>
        {items.map(({ page, type, selected, ...item }, index) => {
          let children = null;

          if (type === "start-ellipsis" || type === "end-ellipsis") {
            children = <div className={classes.more}>...</div>;
          } else if (type === "page") {
            children = (
              <button
                type="button"
                className={classNames([
                  classes.button,
                  classes.selectable,
                  selected
                    ? "pagination-button-page-selected"
                    : "pagination-button-page",
                ])}
                {...item}
                onClick={() => onChange(page)}
              >
                {page}
                {selected && (
                  <div
                    className={classNames([
                      classes.selected,
                      "pagination-page-bottom-selected",
                    ])}
                  />
                )}
              </button>
            );
          } else {
            children = (
              <button
                className={classNames([
                  classes.stepButton,
                  "pagination-button",
                ])}
                type="button"
                {...item}
                onClick={() => onChange(page)}
              >
                {typeSwitcher(type)}
              </button>
            );
          }

          // eslint-disable-next-line react/no-array-index-key
          return <li key={index}>{children}</li>;
        })}
      </ul>
    </nav>
  );
};

CustomPagination.propTypes = {
  count: PropTypes.number,
  currentPage: PropTypes.number,
  onChange: PropTypes.func,
};
CustomPagination.defaultProps = {
  count: 10,
  currentPage: null,
  onChange: undefined,
};

export default CustomPagination;
