import { createUseStyles } from "react-jss";
import { breakpoints } from "../../../../constants/sizes";

const useStyles = createUseStyles((/* theme */) => ({
  wrapper: {
    width: "100%",
  },
  titleWrapper: {
    display: "flex",
    width: "100%",
    flexFlow: "row wrap",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
  },
  link: {
    display: "flex",
    textDecoration: "none",
    [breakpoints.xsm]: {
      width: "100%",
    },
  },
  recentlyArticle: {
    display: "flex",
    height: "auto",
    marginBottom: 40,

    [breakpoints.xsm]: {
      flexFlow: "row wrap",
    },
  },
  recentlyArticleInfo: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "2px 0px 5px 0px",
  },
  recentlyArticleTitle: {
    margin: "0px 0px 16px 0px",
    width: "100%",

    [breakpoints.xsm]: {
      marginTop: 16,
    },
  },
  titleTypography: {
    display: "-webkit-box",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  recentlyArticleImg: {
    width: 140,
    marginRight: 16,
    height: 140,
    objectFit: "cover",
    objectPosition: "center center",

    [breakpoints.xsm]: {
      width: "100%",
      maxHeight: "150px",
      marginRight: 0,
    },
  },
}));

export default useStyles;
