import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import useStyles from "./styles";
import MoreVideoInfo from "../../MoreVideoInfo";
import CustomTypography from "../../../CustomTypography";
import ArrowRight from "../../../Icons/ArrowRight";
import RecentlyListSkeleton from "../../../Skeleton/RecentlyListSkeleton";

export default function Recently({ videos }) {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { t } = useTranslation();

  return (
    <div className={classes.wrapper}>
      <div className={classes.recentlyContainer}>
        {videos ? (
          videos.map((video) => <MoreVideoInfo key={video.id} data={video} />)
        ) : (
          <RecentlyListSkeleton />
        )}
        <div className={classes.seeAllLinkWrapper}>
          <Link to="/agencia/multimidia/videos" className={classes.link}>
            <CustomTypography
              variant="paragraph"
              weight="bold"
              color={theme.primary0}
            >
              {t("multimedia.recently.viewAll")}
              <ArrowRight className={classes.seeAllLink} />
            </CustomTypography>
          </Link>
        </div>
      </div>
    </div>
  );
}

Recently.propTypes = {
  videos: PropTypes.arrayOf(PropTypes.shape()),
};

Recently.defaultProps = {
  videos: undefined,
};
