import { useTheme } from "react-jss";

const resultTypes = {
  RESEARCHER: "RESEARCHER",
  BOOK_CHAPTER: "BOOK_CHAPTER",
  ARTICLE_JOURNALS: "ARTICLE_JOURNALS",
  BOOK: "BOOK",
  INSTITUTE: "INSTITUTE",
  RESEARCH: "RESEARCH",
};

export function typeColor(type, isFeatured) {
  const theme = useTheme();

  switch (type) {
    case resultTypes.RESEARCHER:
      return isFeatured ? theme.featuredResearcher : theme.researcher;
    case resultTypes.ARTICLE_JOURNALS:
      return theme.article;
    case resultTypes.BOOK_CHAPTER:
      return theme.chapter;
    case resultTypes.BOOK:
      return theme.book;
    case resultTypes.INSTITUTE:
      return theme.institute;
    case resultTypes.RESEARCH:
      return theme.research;
    default:
      return theme.primary1;
  }
}

export default resultTypes;
