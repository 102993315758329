import * as React from "react";

function Search(props) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5722 7.87234C15.7345 7.87234 13.0129 8.99965 11.0063 11.0063C8.99965 13.0129 7.87234 15.7345 7.87234 18.5722C7.87234 21.41 8.99965 24.1316 11.0063 26.1382C13.0129 28.1448 15.7344 29.2721 18.5722 29.2721C21.397 29.2721 24.1067 28.1551 26.1106 26.1657C26.1216 26.154 26.1328 26.1424 26.1442 26.131C26.1546 26.1207 26.1651 26.1105 26.1757 26.1006C28.1588 24.0976 29.2721 21.3923 29.2721 18.5722C29.2721 15.7344 28.1448 13.0129 26.1382 11.0063C24.1316 8.99965 21.41 7.87234 18.5722 7.87234ZM29.1353 27.0945C31.0734 24.6924 32.1445 21.6875 32.1445 18.5722C32.1445 14.9727 30.7145 11.5205 28.1693 8.97522C25.624 6.42993 22.1718 5 18.5722 5C14.9727 5 11.5205 6.42993 8.97522 8.97522C6.42993 11.5205 5 14.9727 5 18.5722C5 22.1718 6.42993 25.624 8.97522 28.1693C11.5205 30.7145 14.9727 32.1445 18.5722 32.1445C21.6922 32.1445 24.7013 31.0702 27.1053 29.1266L32.5474 34.5785C33.1078 35.1398 34.0171 35.1406 34.5785 34.5803C35.1398 34.0199 35.1406 33.1106 34.5803 32.5492L29.1353 27.0945Z"
        fill="#00A5A8"
      />
    </svg>
  );
}

export default Search;
