import { createUseStyles } from "react-jss";
import { breakpoints, sideBar } from "../../constants/sizes";

const useStyles = createUseStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflowX: "hidden",
    [breakpoints.md]: {
      "& .footer-root": {
        display: "none",
      },
      paddingBottom: 70,
      padding: "0",
    },
  },
  containerCreate: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflowX: "hidden",
    padding: "28.5px 32px",
    [breakpoints.md]: {
      "& .footer-root": {
        display: "none",
      },
      paddingBottom: 70,
      padding: "0",
    },
  },
  subtitle: {
    marginBottom: "24px",
    color: theme.textSecondary,
    textAlign: "start",
    width: "100%",
  },
  contentWrapper: {
    width: "100%",
    height: "100%",
    minWidth: sideBar.Width,
    [breakpoints.smd]: {
      minWidth: "initial",
    },
  },
  title: {
    fontSize: "1.5rem",
    color: theme.textSecondary,
  },
  steps: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  step: {
    color: theme.secondary0,
    fontFamily: "Manrope",
    fontWeight: "700",
    border: "1px solid #A0A0A0",
    padding: "8px 15px",
    borderRadius: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "35px",
    cursor: "pointer",
  },
  stepActive: {
    color: "#fff",
    border: "none",
    background: theme.primary0,
    width: "89px",
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "1rem",
  },
  buttonCancel: {
    color: `${theme.primary0} !important`,
    borderColor: `${theme.primary0} !important`,
    fontWeight: "700 !important",
  },
  buttonAccept: {
    color: `${theme.textTertiary} !important`,
    backgroundColor: `${theme.primary0} !important`,
    borderColor: `${theme.primary0} !important`,
    fontWeight: "700 !important",
  },
  buttonDisabled: {
    color: `${theme.textTertiary} !important`,
    backgroundColor: `${theme.secondary0} !important`,
    borderColor: `${theme.secondary0} !important`,
    fontWeight: "700 !important",
  },
  textField: {
    marginBottom: "24px",
  },
  rowFieldsWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "24px",
    marginBottom: "24px",
    [breakpoints.md]: {
      display: "block",
    },
  },
  secondStepTitle: {
    width: "100%",
    fontWeight: "400",
    fontSize: "1.125rem !important",
    color: `${theme.textSecondary}`,
  },
  labelDemographic: {
    width: "100%",
    fontSize: "1rem !important",
    fontWeight: "700 !important",
    lineHeight: "2.0 !important",
    color: theme.textSecondary,
    marginBottom: "10px",
  },
  inputStyle: {
    width: "100% !important",
    fontSize: "1rem !important",
    fontWeight: "500 !important",
    color: `${theme.textSecondary} !important`,
  },
  stepDone: {
    background: "#fff",
    color: theme.successLight,
    padding: "4px 9px",
    border: `2px solid ${theme.successLight}`,
    borderRadius: "45%",
  },
  disabledButton: {
    background: theme.background4,
    "& :hover": {
      cursor: "not-allowed",
    },
  },
}));

export default useStyles;
