import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import Header from "../../components/Header";
import useStyles from "./styles";

const TermsUse = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();

  let countTopics = 0;

  function getTopic(list) {
    return list.map((topic, i) => {
      const di = i + 1;
      if (topic.type === "paragraph") {
        return <p key={di}>{topic.text}</p>;
      }
      if (topic.type === "strong") {
        return (
          <p key={di}>
            <strong>{topic.text}</strong>
          </p>
        );
      }
      if (topic.type === "map") {
        return (
          <p key={di}>
            <strong>{topic.key}: </strong> {topic.value}
          </p>
        );
      }
      if (topic.type === "list") {
        const types = {
          letters: "a",
          romans: "I",
          numbers: "",
        };
        return (
          <ol key={di} type={types[topic.enumeration]}>
            {topic.items.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ol>
        );
      }
      if (topic.type === "table") {
        return (
          <table key={di} className={classes.border}>
            {
              (topic.has_header ? (
                <tr>
                  {topic.headers.map((head) => (
                    <th key={head} className={classes.border}>
                      {head}
                    </th>
                  ))}
                </tr>
              ) : (
                ""
              ),
              topic.items.map((item) => (
                <tr>
                  {item.map((value) => (
                    <td key={value} className={classes.border}>
                      {value}
                    </td>
                  ))}
                </tr>
              )))
            }
          </table>
        );
      }
      return <p key={di} />;
    });
  }

  function mapTopics(type) {
    return t(type, { returnObjects: true }).map((topic) => {
      countTopics += 1;
      return (
        <div key={countTopics}>
          <h3>
            {countTopics}. {topic.title}
          </h3>
          {getTopic(topic.description)}
        </div>
      );
    });
  }

  return (
    <div className={classes.container}>
      <Header />
      <div className={classes.content}>
        <h2 className={classes.title}>{t("termsUse.title")}</h2>
        <div className={classes.contentBox}>
          {mapTopics("termsUse.topics")}
          <h2 className={classes.title}>{t("termsUse.privacyPolicy.title")}</h2>
          {getTopic(
            t("termsUse.privacyPolicy.description", { returnObjects: true })
          )}
          {mapTopics("termsUse.topicsPrivacyPolicy")}
        </div>
      </div>
    </div>
  );
};

export default TermsUse;
