/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useTheme } from "react-jss";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Divider from "@material-ui/core/Divider";
import Modal from "react-modal";
import Button from "@material-ui/core/Button";
import CloseSvg from "../../../Icons/Close";
import CustomTypography from "../../../CustomTypography";
import CustomTextField from "../../../CustomTextField";
import CustomButton from "../../../CustomButton";
import NotificationIcon from "../../../Icons/Notification";
import FormContainer from "./FormContainer";
import useStyles from "./styles";
import SuccessContainer from "./SuccessContainer";

Modal.setAppElement("#root");

const AlertModal = ({ isOpen, term, onRequestClose }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  const [sendSuccess, setSendSuccess] = useState(false);

  const handleFormSended = () => {
    setSendSuccess(true);
  };

  const onClose = () => {
    onRequestClose();
    setSendSuccess(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Alert Modal"
      className={classes.modal}
      overlayClassName={classes.overlay}
    >
      <div className={classes.contentWrapper}>
        <div className={classes.content}>
          <div className={classes.closeButtonWrapper}>
            <Button classes={{ root: classes.button }} onClick={onClose}>
              <CloseSvg />
            </Button>
          </div>
          {sendSuccess ? (
            <SuccessContainer
              email="joãosilva@email.com"
              frequency="weekly"
              term={term}
              onClickClose={onClose}
            />
          ) : (
            <FormContainer onSendSuccess={handleFormSended} />
          )}
        </div>
      </div>
    </Modal>
  );
};

AlertModal.propTypes = {
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func,
  term: PropTypes.string.isRequired,
};

AlertModal.defaultProps = {
  isOpen: false,
  onRequestClose: undefined,
};

export default AlertModal;
