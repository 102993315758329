import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "react-jss";
import useStyles from "./styles";

// import { Container } from './styles';

function EmptyMessage({ emptyMessage }) {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <div className={classes.emptyMessage}>
      <p>{emptyMessage}</p>
    </div>
  );
}

EmptyMessage.propTypes = {
  emptyMessage: PropTypes.string.isRequired,
};

EmptyMessage.defaultProps = {};

export default EmptyMessage;
