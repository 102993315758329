import { createUseStyles } from "react-jss";
import { breakpoints } from "../../../constants/sizes";

const useStyles = createUseStyles((theme) => ({
  container: {
    padding: 10,
    width: "100%",
    height: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    maxWidth: (props) => (props.contracted ? "830px" : "460px"),
    [breakpoints.md]: {
      maxWidth: "250px !important",
    },
    borderRadius: 8,
    backgroundColor: theme.background0,
    transition: "max-width 0.15s ease-out",
    "& .timeline-container-wrapper": {
      border: "none",
      backgroundColor: theme.background0,
      padding: 0,
      width: "100%",
      maxWidth: 600,
    },
    "& .timeline-slider-wrapper, .timeline-divider": {
      display: (props) => (props.contracted ? "block" : "none"),
    },
    "& .date-icon-wrapper": {
      "& path": {
        fill: theme.primary0,
      },
    },
    "& .date-selector-button": {
      color: theme.lowDark,
      fontFamily: `"Manrope", "Roboto", "Helvetica", "Arial", sans-serif`,
      fontWeight: "bold",
      fontSize: 12,
    },
  },
  description: {
    width: "100%",
    maxWidth: 200,
    [breakpoints.md]: {
      display: "none",
    },
  },
}));

export default useStyles;
