import * as React from "react";

function SvgLinkedin(props) {
  return (
    <svg
      width={22}
      height={22}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.5 2.254A1.754 1.754 0 012.254.5h17.49A1.754 1.754 0 0121.5 2.254v17.49a1.755 1.755 0 01-1.755 1.756H2.255A1.755 1.755 0 01.5 19.745V2.255zm8.312 6.253h2.844v1.428c.41-.821 1.46-1.56 3.038-1.56 3.025 0 3.742 1.635 3.742 4.635v5.558h-3.061v-4.874c0-1.709-.41-2.673-1.453-2.673-1.446 0-2.048 1.04-2.048 2.673v4.874H8.812V8.507zm-5.25 9.93h3.062V8.375H3.562V18.437zm3.5-13.344a1.97 1.97 0 11-3.937.087 1.97 1.97 0 013.938-.087z"
        fill="#333"
      />
    </svg>
  );
}

export default SvgLinkedin;
