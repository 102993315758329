import React, { useState } from "react";
import { useTheme } from "react-jss";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { setFilters } from "../../../../redux/result/resultActions";
import categoryTypes from "./CategoryTypes";
import Tabs from "../../../CustomTabs";
import Tab from "../../../CustomTab";
import useStyles from "./styles";

const CategoryTabs = ({ onChange, initialCategory }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [value, setValue] = useState(initialCategory || categoryTypes.ALL);
  const { totalPublications, totalResearchers, totalInstitutes, loading } =
    useSelector((state) => state.result);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleChange = (event, newCategory) => {
    onChange(newCategory);
    setValue(newCategory);
    dispatch(setFilters({ category: newCategory }));
  };

  return (
    <div>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="ant example"
      >
        <Tab
          className={classes.tab}
          value={categoryTypes.ALL}
          label={t("searchEngine.filters.all")}
        />
        <Tab
          className={classes.tab}
          value={categoryTypes.PUBLICATIONS}
          label={`${t("searchEngine.filters.publications")} ${
            loading ? `(...)` : `(${totalPublications})`
          }`}
        />
        <Tab
          className={classes.tab}
          value={categoryTypes.RESEARCHER}
          label={`${t("searchEngine.filters.authors")} ${
            loading ? `(...)` : `(${totalResearchers})`
          }`}
        />
        <Tab
          className={classes.tab}
          value={categoryTypes.INSTITUTIONS}
          label={`${t("searchEngine.filters.institutions")} ${
            loading ? `(...)` : `(${totalInstitutes})`
          }`}
        />
      </Tabs>
    </div>
  );
};

CategoryTabs.propTypes = {
  onChange: PropTypes.func,
  initialCategory: PropTypes.string,
};

CategoryTabs.defaultProps = {
  onChange: undefined,
  initialCategory: undefined,
};

export default CategoryTabs;
