import { createUseStyles } from "react-jss";
import {
  defaultContainer,
  breakpoints,
  defaultContentContainer,
} from "../../../constants/sizes";

const marginFactor = 20;

const useStyles = createUseStyles((theme) => ({
  section: {
    position: "relative",
    width: "100%",
    padding: `${defaultContainer.paddingHorizontal}px ${defaultContainer.paddingHorizontal}px`,
    marginBottom: 25,
    background:
      "linear-gradient(rgba(240, 229, 225, 0.65), rgba(240, 230, 225, 0))",
    display: "flex",
    [breakpoints.md]: {
      flexDirection: "column-reverse",
      rowGap: 20,
    },
    [breakpoints.sm]: {
      boxShadow: `inset  0 7px 9px -7px ${theme.innerShadow}`,
    },
  },
  descriptionWrapper: {
    width: "50%",
    paddingTop: defaultContentContainer.paddingTop,
    paddingLeft: defaultContentContainer.paddingLeft,
    paddingRight: defaultContentContainer.paddingLeft,
    [breakpoints.md]: {
      padding: defaultContentContainer.paddingSm,
      width: "100%",
    },
  },
  destakIcon: {
    position: "absolute",
    marginLeft: -20,
    marginTop: -10,
  },
  exploreButton: {
    marginTop: 40,
    "& .MuiButton-root": {
      width: 250,
      "& .MuiButton-label": {
        color: theme.textTertiary,
        fontWeight: "bold",
      },
    },
  },
  imagesWrapper: {
    width: "50%",
    minWidth: 700,
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: defaultContentContainer.paddingLeft,
    "& div": {
      width: "100%",
      height: "100%",
      maxWidth: 205,
      maxHeight: 505,
      borderRadius: 15,
      "& img": {
        width: "100%",
        maxWidth: 205,
      },
    },
    "& div:nth-child(3)": {
      marginTop: marginFactor,
    },
    "& div:nth-child(2)": {
      marginTop: marginFactor * 4,
    },
    "& div:nth-child(1)": {
      marginTop: marginFactor * 8,
    },
    columnGap: 15,
    [breakpoints.md]: {
      justifyContent: "center",
      width: "100%",
      minWidth: 0,
      padding: 0,
    },
  },
  elipse: {
    position: "absolute",
    zIndex: -1,
    marginLeft: -320,
    marginTop: 210,
    [breakpoints.sm]: {
      marginLeft: -180,
      marginTop: 210,
    },
  },
}));

export default useStyles;
