import { createUseStyles } from "react-jss";
import { breakpointsHeight } from "../../../../constants/sizes";

const useStyles = createUseStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    [breakpointsHeight.md]: {},
  },
  buttonsWrapper: {
    display: "grid",
    gridTemplateColumns: "auto auto",
    gap: 15,
    "& button": {
      fontFamily: `"Manrope", "Roboto", "Helvetica", "Arial", sans-serif`,
      borderRadius: 4,
      fontSize: 16,
      height: 40,
      cursor: "pointer",
      border: `1px solid ${theme.textSecondary}`,
      backgroundColor: theme.background0,
      color: theme.textSecondary,
    },
    "& .selected, button:hover": {
      backgroundColor: theme.primary0,
      fontWeight: "bold",
      color: theme.background0,
      border: `1px solid ${theme.stroke}`,
    },
  },
  applyButton: {
    marginTop: 20,
    "& .MuiButtonBase-root": {
      minHeight: 0,
      height: 40,
      width: "100%",
      color: theme.textTertiary,
      fontWeight: "bold",
      fontSize: 16,
    },
  },
}));

export default useStyles;
