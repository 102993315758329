import * as React from "react";

function SvgGoogle(props) {
  return (
    <svg
      width={21}
      height={22}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.796 8.944h-.846V8.9H10.5v4.2h5.934A6.298 6.298 0 014.2 11a6.3 6.3 0 016.3-6.3c1.606 0 3.067.606 4.18 1.595l2.97-2.97A10.451 10.451 0 0010.5.5C4.701.5 0 5.201 0 11s4.701 10.5 10.5 10.5S21 16.799 21 11c0-.704-.073-1.391-.204-2.056z"
        fill="#FFC107"
      />
      <path
        d="M1.21 6.113l3.45 2.53A6.297 6.297 0 0110.5 4.7c1.606 0 3.067.606 4.18 1.595l2.97-2.97A10.451 10.451 0 0010.5.5C6.467.5 2.97 2.777 1.21 6.113z"
        fill="#FF3D00"
      />
      <path
        d="M10.5 21.5c2.712 0 5.177-1.038 7.04-2.726l-3.25-2.75A6.252 6.252 0 0110.5 17.3a6.297 6.297 0 01-5.924-4.172l-3.424 2.639C2.89 19.166 6.42 21.5 10.5 21.5z"
        fill="#4CAF50"
      />
      <path
        d="M20.796 8.943h-.846V8.9H10.5v4.2h5.934a6.321 6.321 0 01-2.146 2.925l.002-.001 3.25 2.75C17.31 18.983 21 16.25 21 11c0-.704-.073-1.391-.204-2.057z"
        fill="#1976D2"
      />
    </svg>
  );
}

export default SvgGoogle;
