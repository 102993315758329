import { createUseStyles } from "react-jss";
// import { breakpoints } from "../../../../constants/sizes";

const useStyles = createUseStyles((theme) => ({
  contentWrapper: {
    display: "flex",
    width: "100%",
    "& > * + *": {
      marginLeft: 20,
    },
  },
  title: {
    width: "100%",
    fontWeight: 700,
    fontSize: 12,
    color: theme.textPrimary,
  },
  bottomWrapper: {
    position: "relative",
    paddingTop: 15,
  },
  bottom: {
    width: "100%",
    fontWeight: 400,
    fontSize: 12,
    color: theme.textPrimary,
    bottom: 0,
  },
  content: {
    display: "grid",
    gridTemplateColumns: "20px auto auto",
    gridGap: 5,
    width: "100%",
    fontWeight: 700,
    fontSize: 20,
    color: theme.textPrimary,
    paddingTop: 10,
  },
  percentage: {
    color: "#0CA512",
    fontWeight: 400,
    fontSize: 12,
    marginLeft: 5,
  },
  body: {
    paddingTop: 12,
    display: "flex",
    alignItems: "center",
  },
  totalWrapper: {
    width: "50%",
  },
  chartWrapper: {
    width: "50%",
    display: "flex",
    alignItems: "flex-end",
    flexDirection: "column",
  },
  barChart: {
    marginRight: -28,
  },
  totalArticles: {
    fontSize: 20,
    fontWeight: "bold",
  },
}));

export default useStyles;
