import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "react-jss";
import { useTranslation } from "react-i18next";
import logoRhisaImg from "../../../assets/images/logo_rhisa.png";
import CustomLink from "../../CustomLink";
import useStyles from "./styles";

const Header = ({ variant }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { t } = useTranslation();

  function RightLink() {
    switch (variant) {
      case "login":
        return (
          <CustomLink to="/cadastro" className={classes.link}>
            {t("forms.doSignUp")}
          </CustomLink>
        );
      case "password":
        return (
          <CustomLink to="/login" className={classes.link}>
            {t("forms.backToLogin")}
          </CustomLink>
        );
      default:
        return (
          /* <CustomLink to="/login" className={classes.link}>
            {t("forms.doLogin")}
          </CustomLink> */
          null
        );
    }
  }

  return (
    <div className={classes.header}>
      <div>
        <CustomLink to="/">
          <img alt="RHISA" src={logoRhisaImg} className={classes.logo} />
        </CustomLink>
      </div>
      <div>
        <RightLink />
      </div>
    </div>
  );
};

Header.propTypes = {
  variant: PropTypes.string,
};

Header.defaultProps = {
  variant: "register",
};

export default Header;
