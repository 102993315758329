import * as React from "react";

function Notification(props) {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.8214 18.3318C20.8212 18.3316 20.8212 18.3316 20.821 18.3315C18.2412 16.2379 17.1346 13.5284 17.1212 9.27224C17.1171 7.98149 16.548 6.71775 15.56 5.80511C15.1371 5.41449 14.6569 5.10183 14.1392 4.87386V4.04088C14.1392 2.91553 13.1592 2 11.9544 2C10.7498 2 9.76981 2.91553 9.76981 4.04088V4.92406C8.08664 5.70471 6.92435 7.32902 6.92435 9.19305C6.92435 11.3868 6.63411 13.1636 6.03712 14.6248C5.4501 16.0617 4.53052 17.274 3.22597 18.3308C3.02539 18.4932 2.95304 18.7586 3.04581 18.9908C3.13875 19.223 3.37934 19.379 3.6446 19.379L8.75288 19.3791C8.87326 20.8431 10.1858 22 11.7831 22C13.3803 22 14.6929 20.8432 14.8133 19.3794L20.4024 19.3796C20.6677 19.3796 20.9082 19.2236 21.001 18.9914C21.0938 18.7593 21.0216 18.4943 20.8214 18.3318ZM11.0272 4.04088C11.0272 3.56327 11.4432 3.17463 11.9544 3.17463C12.4658 3.17463 12.8818 3.56327 12.8818 4.04088V4.50124C12.5862 4.45272 12.2847 4.42876 11.9796 4.4309C11.654 4.43349 11.3357 4.46523 11.0272 4.52291V4.04088ZM11.7831 20.8255C10.8797 20.8255 10.1316 20.1944 10.0163 19.3793L13.55 19.3794C13.4347 20.1944 12.6865 20.8255 11.7831 20.8255ZM5.15446 18.2045C6.05182 17.2667 6.72982 16.2231 7.21166 15.0438C7.86434 13.4463 8.18154 11.5327 8.18154 9.1932C8.18154 7.23136 9.89018 5.62201 11.9902 5.60537C12.9917 5.59591 13.945 5.96518 14.6761 6.64039C15.4277 7.33482 15.8607 8.29537 15.8638 9.27575C15.8712 11.5895 16.1924 13.4849 16.8461 15.0705C17.3275 16.2384 18.0025 17.2734 18.8933 18.2051L5.15446 18.2045Z"
        fill="black"
      />
    </svg>
  );
}

export default Notification;
