import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import CloseSvg from "../Icons/Close";
import useStyles from "./styles";

// import { Container } from './styles';

function CloseButton({ onClick, className, ...props }) {
  const classes = useStyles();
  return (
    <Button
      classes={{ root: classes.button }}
      className={className}
      onClick={onClick}
      {...props}
    >
      <CloseSvg />
    </Button>
  );
}
CloseButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
};

CloseButton.defaultProps = {
  onClick: undefined,
  className: undefined,
};

export default CloseButton;
