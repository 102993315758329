import { createUseStyles } from "react-jss";
import { breakpoints } from "../../../constants/sizes";

const useStyles = createUseStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "4px",
      color: theme.colorText,
    },
    "& .MuiOutlinedInput-input": {
      padding: "15px 12px 12px 12px",
      [breakpoints.sm]: {
        fontSize: 14,
      },
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.grey,
      borderWidth: 1,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.stroke,
      borderWidth: 1,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.primary0,
      borderWidth: 1,
    },
    "& .MuiFormHelperText-contained": {
      marginLeft: 0,
    },
    "& .MuiInputAdornment-root": {
      justifyContent: "flex-end",
      [breakpoints.sm]: {
        width: 30,
        marginRight: -10,
      },
    },
    "& .MuiIconButton-root": {
      "& path": {
        fill: theme.secondary0,
        [breakpoints.sm]: {
          transform: "scale(0.8)",
          transformOrigin: "center",
        },
      },
    },
  },
}));

export default useStyles;
