import { createUseStyles } from "react-jss";
import { breakpoints } from "../../constants/sizes";

const useStyles = createUseStyles({
  h1: {
    fontFamily: `"Manrope", "Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: 700,
    fontSize: 40,
    lineHeight: "60px",
    letterSpacing: "-0.00833em",

    [breakpoints.sm]: {
      fontSize: 28,
      lineHeight: "41.6px",
    },
  },
  h2: {
    fontFamily: `"Manrope", "Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: 700,
    fontSize: 32,
    lineHeight: "41.6px",
    letterSpacing: "-0.00833em",

    [breakpoints.sm]: {
      fontSize: 24,
      lineHeight: "26px",
    },
  },
  h3: {
    fontFamily: `"Manrope", "Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: 700,
    fontSize: 24,
    lineHeight: "31.2px",
    letterSpacing: "-0.00833em",

    [breakpoints.sm]: {
      fontSize: 18,
    },
  },
  paragraph: {
    fontFamily: `"Manrope", "Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: 400,
    fontSize: 20,
    lineHeight: "26px",
    letterSpacing: "-0.00833em",

    [breakpoints.sm]: {
      fontSize: 16,
      lineHeight: "20.8px",
    },
  },
  headerButton: {
    fontFamily: `"Manrope", "Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: 500,
    fontSize: 16,
    lineHeight: "20.8px",

    [breakpoints.sm]: {
      fontSize: 16,
      lineHeight: "20.8px",
    },
  },
  paragraph1: {
    fontFamily: `"Manrope", "Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: 400,
    fontSize: 16,
    lineHeight: "20.8px",
    letterSpacing: "-0.00833em",
  },
  body: {
    fontFamily: `"Manrope", "Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: 400,
    fontSize: 16,
    lineHeight: "20.8px",
    letterSpacing: "-0.00833em",

    [breakpoints.sm]: {
      fontSize: 14,
      lineHeight: "18.8px",
    },
  },
  noticeBody: {
    fontFamily: `"Manrope", "Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: 400,
    fontSize: 18,
    lineHeight: "23.4px",
    letterSpacing: "-0.00833em",
    textAlign: "justify",
  },
  noticeBody2: {
    fontFamily: `"Manrope", "Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: 400,
    fontSize: 20,
    lineHeight: "26px",
    letterSpacing: "-0.00833em",
    [breakpoints.sm]: {
      fontSize: 18,
      lineHeight: "23.4px",
    },
  },
  noticeTitle: {
    fontFamily: `"Manrope", "Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: 700,
    fontSize: 18,
    lineHeight: "23.4px",
    letterSpacing: "-0.00833em",

    [breakpoints.xsm]: {
      fontSize: 15,
    },
  },
  bold: {
    fontWeight: 700,
  },
  regular: {
    fontWeight: 400,
    lineHeight: "17px",
  },
  columnistName: {
    fontFamily: `"Manrope", "Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: 700,
    fontSize: 18,
    lineHeight: "23.4px",
    letterSpacing: "-0.00833em",
    [breakpoints.sm]: {
      fontSize: 15,
    },
  },
  footerSn: {
    fontFamily: `"Manrope", "Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: 700,
    fontSize: 16,
    lineHeight: "19.2px",
    letterSpacing: "-0.00833em",
  },
  titleVerticalList: {
    fontFamily: `"Manrope", "Roboto", "Helvetica", "Arial", sans-serif`,
    textTransform: "uppercase",
    fontWeight: 700,
    fontSize: 16,
    lineHeight: "20.8px",
    letterSpacing: "-0.00833em",

    [breakpoints.sm]: {
      fontSize: 14,
      lineHeight: "10.8px",
    },
  },
  searchModalTitle: {
    textTransform: "uppercase",
  },
  description: {
    fontFamily: `"Manrope", "Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: 400,
    fontSize: 12,
    lineHeight: "20.8px",
    letterSpacing: "-0.00833em",
  },
  searchResultTitle: {
    fontFamily: `"Manrope", "Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: 700,
    fontSize: 16,
    lineHeight: "20.8px",
    letterSpacing: "-0.00833em",

    [breakpoints.sm]: {
      fontSize: 14,
      lineHeight: "16.8px",
    },
  },
  subtitle: {
    fontFamily: `"Manrope", "Roboto", "Helvetica", "Arial", sans-serif`,
    fontSize: 16,
    lineHeight: "20.8px",
    letterSpacing: "-0.00833em",
    [breakpoints.sm]: {
      fontWeight: 400,
      fontSize: 15,
      lineHeight: "16.8px",
    },
  },
  searchResultSubTitle: {
    fontFamily: `"Manrope", "Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: 400,
    fontSize: 13,
    lineHeight: "20.8px",
    letterSpacing: "-0.00833em",

    [breakpoints.sm]: {
      fontSize: 10,
      lineHeight: "16.8px",
    },
  },
  tag: {
    fontFamily: `"Manrope", "Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: 400,
    fontSize: 13,
    lineHeight: "18.2px",
    [breakpoints.sm]: {
      fontSize: 12,
      lineHeight: "17.8px",
    },
  },
  tagBold: {
    fontFamily: `"Manrope", "Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: 700,
    fontSize: 13,
    lineHeight: "18.2px",
  },

  subHeaderBody: {
    fontFamily: `"Manrope", "Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: 700,
    fontSize: 20,
  },
  smallText: {
    fontFamily: `"Manrope", "Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: "normal",
    fontSize: 14,
  },
  bodyLargeRegular: {
    fontFamily: `"Manrope", "Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: "normal",
    fontSize: 18,
  },
  labelForm: {
    fontFamily: `"Manrope", "Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: "700",
    fontSize: 12,
    color: "#A0A0A0 !important",
  },
});

export default useStyles;
