import { createUseStyles } from "react-jss";
import { breakpoints } from "../../../constants/sizes";

const useStyles = createUseStyles((theme) => ({
  barContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    overflow: "hidden",
    "& .search-bar": {
      marginTop: 30,
      marginBottom: 20,
    },
    [breakpoints.md]: {
      "& .sidebar-root": {
        width: "100%",
        maxWidth: "none !important",
        display: (props) => (props.showMap ? "none" : null),
      },
      "& .sidebar-toogle-button": {
        display: "none",
      },
    },
  },
  content: {
    overflow: "auto",
    overflowX: "hidden",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    [breakpoints.md]: {
      paddingBottom: 60,
    },
  },
  mapButtonWrapper: {
    display: "none",
    [breakpoints.md]: {
      filter: `drop-shadow(0px 5px 4px ${theme.textPrimary})`,
      position: "absolute",
      bottom: 0,
      display: "flex",
      width: "100%",
      justifyContent: "center",
      backgroundColor: theme.background0,
    },
  },
  mapWrapper: {
    width: "100%",
    [breakpoints.md]: {
      display: (props) => (props.showMap ? null : "none"),
    },
  },
  "@global": {
    body: {
      overflow: "hidden",
    },
  },
}));

export default useStyles;
