import { createUseStyles } from "react-jss";
// import { breakpoints } from "../../../constants/sizes";

const useStyles = createUseStyles((theme) => ({
  container: {
    width: "100%",
    height: (props) => props.height,
  },
  divider: {
    height: 1,
    backgroundColor: theme.stroke,
  },
  legend: {
    marginTop: 10,
    fontSize: 12,
    color: theme.textPrimary,
  },
}));

export default useStyles;
