import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { months } from "../../Constants";
import SmSelectWrapper from "../SmSelectWrapper";

function MonthSelectSm({ currentMonth, onApply }) {
  const { t } = useTranslation();
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const selectedButtonRef = useRef(null);

  useEffect(() => {
    setSelectedMonth(currentMonth);
    selectedButtonRef.current.scrollIntoView();
  }, [currentMonth]);

  const handleMonthClick = (month) => {
    setSelectedMonth(month);
  };

  const handleApply = () => {
    if (onApply) onApply(selectedMonth);
  };

  return (
    <SmSelectWrapper
      buttonLabel={t("date.period.applyMonth")}
      onApplyClick={handleApply}
    >
      {months.map((month) => (
        <button
          ref={month === selectedMonth ? selectedButtonRef : null}
          key={month}
          type="button"
          className={month === selectedMonth ? "selected" : ""}
          onClick={() => handleMonthClick(month)}
        >
          {t(`date.month.${month}.long`)}
        </button>
      ))}
    </SmSelectWrapper>
  );
}

MonthSelectSm.propTypes = {
  onApply: PropTypes.func,
  currentMonth: PropTypes.string,
};

MonthSelectSm.defaultProps = {
  onApply: undefined,
  currentMonth: "",
};

export default MonthSelectSm;
