import React from "react";
import { useTheme } from "react-jss";
import PropTypes from "prop-types";
import classNames from "classnames";
import CustomTypography from "../CustomTypography";
import useStyles from "./styles";

const TitledSection = ({ icon, children, title, className }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <div
      className={classNames([
        classes.container,
        "titled-section-wrapper",
        className,
      ])}
    >
      <div className={classes.title}>
        {icon}
        <CustomTypography variant="body" color={theme.secondary0}>
          {title}
        </CustomTypography>
      </div>
      <div>{children}</div>
    </div>
  );
};

TitledSection.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
};
TitledSection.defaultProps = {
  icon: null,
  title: null,
  children: null,
  className: null,
};

export default TitledSection;
