import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "react-jss";
import Button from "@material-ui/core/Button";
import useStyles from "./styles";

const MoreButton = ({ open, moreItems, onClick }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <span className={classes.contentWrapper}>
      <Button className={classes.button} onClick={onClick}>
        {open ? `+${moreItems}` : "-"}
      </Button>
    </span>
  );
};

MoreButton.propTypes = {
  open: PropTypes.bool,
  onClick: PropTypes.func,
  moreItems: PropTypes.number,
};

MoreButton.defaultProps = {
  open: false,
  onClick: undefined,
  moreItems: 10,
};

export default MoreButton;
