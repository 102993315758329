import React, { useEffect, useState } from "react";
import {
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import classNames from "classnames";
import Button from "@material-ui/core/Button";
import { Route, Switch, useHistory } from "react-router-dom";
import { useTheme } from "react-jss";
import { useDispatch } from "react-redux";
import Header from "../../../components/Cooperatives/Header";
import Footer from "../../../components/V2/Footer";
import useStyles from "../styles";
// import Input from "../../../components/Form/Input";
import RegisterContainer from "../../../components/Form/RegisterContainer";
import CustomTextField from "../../../components/CustomTextField";
import api from "../../../services/api";
import AlertModal from "../../../components/Modal/SuccessModal";
import ErrorModal from "../../../components/Modal/ErrorModal";

function Create() {
  // const match = useRouteMatch();
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles(theme);
  const [modalSuccessOpen, setModalSuccessOpen] = useState(false);
  const [modalErrorOpen, setModalErrorOpen] = useState(false);
  const [bodyRequest, setBodyRequest] = useState({});
  const [formData, setFormData] = useState({
    name: {
      value: "",
      required: true,
      errorMessage: "Campo obrigatório",
      error: false,
    },
    responsible: {
      value: "",
      required: true,
      errorMessage: "Campo obrigatório",
      error: false,
    },
    products: {
      value: [],
      required: true,
      errorMessage: "Informe ao menos um produto",
      error: false,
    },
    email: {
      value: "",
      required: false,
      errorMessage: "",
      error: false,
    },
    phone: {
      value: "",
      required: false,
      errorMessage: "",
      error: false,
    },
    website: {
      value: "",
      required: false,
      errorMessage: "",
      error: false,
    },
  });

  const [step, setStep] = useState(1);

  const [demographicForm, setDemographicForm] = useState({
    contributors: "",
    age_contributors: "",
    contributors_man: "",
    contributors_woman: "",
  });

  const [address, setAddress] = useState({
    cep: {
      value: "",
      required: false,
      errorMessage: "Informe seu CEP",
      error: false,
    },
    logradouro: {
      value: "",
      required: false,
      errorMessage: "",
      error: false,
    },
    bairro: {
      value: "",
      required: false,
      errorMessage: "",
      error: false,
    },
    localidade: {
      value: "",
      required: true,
      errorMessage: "O campo Município é obrigatório",
      error: false,
    },
    uf: {
      value: "",
      required: true,
      errorMessage: "O campo Estado é obrigatório",
      error: false,
    },
    number: {
      value: "",
      required: false,
      errorMessage: "",
      error: false,
    },
    complemento: {
      value: "",
      required: false,
      errorMessage: "",
      error: false,
    },
  });

  const [disabledNextButton, setDisabledNextButton] = useState(true);

  function handleChangeFormData(key, value) {
    let error = false;
    if (value !== "") {
      if (key === "name" || key === "responsible") {
        const regex = /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/;
        const match = value.match(regex);
        error = !match;
      }

      if (key === "products") {
        const regex = /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ,]+$/;
        const match = value.match(regex);
        if (match) value = value.split(",");
        error = !match;
      }
    }

    if (error) return;
    setFormData((oldData) => ({
      ...oldData,
      [key]: { ...oldData[key], value, error: false },
    }));
  }

  function handleChangeAddress(key, value) {
    let error = false;
    if (value !== "") {
      if (key === "number") {
        value = value.replace(/\D/g, "");
      }
      if (key === "cep") {
        value = value.replace(/\D/g, "");
        value = value.replace(/(\d{5})(\d)/, "$1-$2");

        if (value.length > 9) error = true;
      }
    }

    if (error) return;

    setAddress((oldData) => ({ ...oldData, [key]: { value } }));
  }

  function handleChangeDemographicForm(key, value) {
    setDemographicForm((oldData) => ({ ...oldData, [key]: value }));
  }

  function setFormDataError(name) {
    if (formData[name].required) {
      setFormData((oldData) => ({
        ...oldData,
        [name]: { ...oldData[name], error: true },
      }));
    }
  }

  function setAddressError(name) {
    if (address[name].required) {
      setAddress((oldData) => ({
        ...oldData,
        [name]: { ...oldData[name], error: true },
      }));
    }
  }

  async function sendForm() {
    try {
      await api.post("/cooperatives", bodyRequest);
      setModalSuccessOpen(true);
    } catch (error) {
      setModalErrorOpen(true);
    }
  }

  async function handleFetchCep(cepToSearch) {
    try {
      const response = await fetch(
        `https://viacep.com.br/ws/${cepToSearch}/json/`
      );
      const { cep, logradouro, bairro, localidade, erro, uf } =
        await response.json();

      if (erro) window.alert("Erro ao buscar cep");

      handleChangeAddress("cep", cep);
      handleChangeAddress("logradouro", logradouro);
      handleChangeAddress("bairro", bairro);
      handleChangeAddress("localidade", localidade);
      handleChangeAddress("uf", uf);
    } catch (error) {
      // Tratar o erro
      console.warn(error);
    }
  }

  useEffect(() => {
    const formDataFormated = Object.entries(formData)
      .map(([key, value]) => [key, value.value])
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});

    setBodyRequest((oldData) => ({
      ...oldData,
      ...demographicForm,
      ...formDataFormated,
      state: address.uf.value,
      city: address.localidade.value,
      address: `${address.logradouro.value}, ${address.number.value}`,
      complement: address.complemento.value,
      cep: address.cep.value.replace("-", ""),
    }));

    const { name, products, responsible } = formData;
    const { localidade, uf } = address;
    if (
      name.value &&
      responsible.value &&
      products.value.length &&
      localidade.value.length &&
      uf.value.length
    ) {
      setDisabledNextButton(false);
    }

    console.log(Route, Switch, useDispatch);
  }, [address, demographicForm, formData]);

  useEffect(() => {}, [formData]);

  return (
    <>
      <Header />
      <div className={classes.containerCreate}>
        <RegisterContainer>
          <div className={classes.steps}>
            <h2 className={classes.title}>Cadastrar Cooperativa</h2>
            <div className={classes.buttonsContainer}>
              {step === 1 && (
                <>
                  <button
                    type="button"
                    className={classNames([classes.stepActive, classes.step])}
                  >
                    <p>Passo 1 </p>
                  </button>

                  <button type="button" className={classNames([classes.step])}>
                    <p>2</p>
                  </button>
                </>
              )}

              {step === 2 && (
                <>
                  <div className={classes.stepDone}>
                    <DoneIcon fontSize="small" />
                  </div>

                  <button
                    type="button"
                    className={classNames(classes.stepActive, [classes.step])}
                  >
                    <p>Passo 2</p>
                  </button>
                </>
              )}
            </div>
          </div>

          {step === 1 ? (
            <>
              <h3 className={classes.subtitle}>Dados gerais</h3>

              <CustomTextField
                error={formData.name.error}
                errorMessage={formData.name.errorMessage}
                required={formData.name.required}
                value={formData.name.value}
                label="NOME OU RAZÃO SOCIAL"
                placeholder="Ex: Cooperativa dos agricultores do Amazonas"
                variant="outlined"
                style={{ marginBottom: "24px", width: "100%" }}
                onChange={({ target }) => {
                  handleChangeFormData("name", target.value);
                }}
                onBlur={({ target }) => {
                  if (!target.value) setFormDataError("name");
                }}
              />
              <CustomTextField
                error={formData.responsible.error}
                errorMessage={formData.responsible.errorMessage}
                required={formData.responsible.required}
                label="PRESIDENTE OU RESPONSÁVEL"
                placeholder="Ex: João da Silva Lima"
                variant="outlined"
                style={{ marginBottom: "24px", width: "100%" }}
                value={formData.responsible.value}
                onChange={({ target }) => {
                  handleChangeFormData("responsible", target.value);
                }}
                onBlur={({ target }) => {
                  if (!target.value) setFormDataError("responsible");
                }}
              />

              <CustomTextField
                error={formData.products.error}
                errorMessage={formData.products.errorMessage}
                required={formData.products.required}
                label="PRODUTO(S)"
                placeholder="Ex: Café, Frutas, Aves"
                variant="outlined"
                style={{ marginBottom: "10px", width: "100%" }}
                value={formData.products.value}
                onChange={({ target }) => {
                  handleChangeFormData("products", target.value);
                }}
                onBlur={({ target }) => {
                  if (!target.value) setFormDataError("products");
                }}
              />

              <h3 className={classes.subtitle}>Contato</h3>

              <div className={classes.rowFieldsWrapper}>
                <CustomTextField
                  error={formData.email.error}
                  errorMessage={formData.email.errorMessage}
                  required={formData.email.required}
                  label="E-MAIL"
                  placeholder="Ex: cooperagro@email.com"
                  variant="outlined"
                  style={{ marginBottom: "10px", width: "100%" }}
                  value={formData.email.value}
                  onChange={({ target }) => {
                    handleChangeFormData("email", target.value);
                  }}
                  onBlur={({ target }) => {
                    if (!target.value) setFormDataError("email");
                  }}
                />

                <CustomTextField
                  error={formData.phone.error}
                  errorMessage={formData.phone.errorMessage}
                  required={formData.phone.required}
                  label="TELEFONE"
                  placeholder="Ex: (85) 91234-5678"
                  variant="outlined"
                  style={{ marginBottom: "10px", width: "100%" }}
                  value={formData.phone.value}
                  onChange={({ target }) => {
                    handleChangeFormData("phone", target.value);
                  }}
                  onBlur={({ target }) => {
                    if (!target.value) setFormDataError("phone");
                  }}
                />
              </div>

              <CustomTextField
                error={formData.website.error}
                errorMessage={formData.website.errorMessage}
                required={formData.website.required}
                label="WEBSITE"
                placeholder="Ex: cooperagro.com.br"
                variant="outlined"
                style={{ marginBottom: "24px", width: "100%" }}
                value={formData.website.value}
                onChange={({ target }) => {
                  handleChangeFormData("website", target.value);
                }}
                onBlur={({ target }) => {
                  if (!target.value) setFormDataError("website");
                }}
              />

              {/* <Button
                className={classes.buttonAccept}
                type="button"
                variant="contained"
              >
                <span style={{ marginRight: "8px", fontSize: "20px" }}>+</span>{" "}
                Outro contato
              </Button> */}

              <h3 className={classes.subtitle}>Endereço</h3>

              <div className={classes.rowFieldsWrapper}>
                <CustomTextField
                  error={address.cep.error}
                  errorMessage={address.cep.errorMessage}
                  required={address.cep.required}
                  label="CEP"
                  placeholder="Ex: 69923-000"
                  variant="outlined"
                  style={{ marginBottom: "10px", width: "100%" }}
                  value={address.cep.value}
                  onChange={({ target }) => {
                    handleChangeAddress("cep", target.value);
                    if (target.value.length === 9) {
                      handleFetchCep(target.value);
                    }
                  }}
                  onBlur={({ target }) => {
                    if (!target.value) setAddressError("cep");
                  }}
                />

                <CustomTextField
                  error={address.logradouro.error}
                  errorMessage={address.logradouro.errorMessage}
                  required={address.logradouro.required}
                  label="LOGRADOURO"
                  placeholder="Ex: Avenida Plácido de Castro"
                  variant="outlined"
                  style={{ marginBottom: "10px", width: "100%" }}
                  value={address.logradouro.value}
                  onBlur={({ target }) => {
                    if (!target.value) setAddressError("logradouro");
                  }}
                />
              </div>

              <div className={classes.rowFieldsWrapper}>
                <CustomTextField
                  error={address.number.error}
                  errorMessage={address.number.errorMessage}
                  required={address.number.required}
                  label="Número"
                  placeholder="Ex: 1732"
                  variant="outlined"
                  style={{ marginBottom: "10px", width: "100%" }}
                  value={address.number.value}
                  onChange={({ target }) => {
                    handleChangeAddress("number", target.value);
                  }}
                  onBlur={({ target }) => {
                    if (!target.value) setAddressError("number");
                  }}
                />

                <CustomTextField
                  error={address.complemento.error}
                  errorMessage={address.complemento.errorMessage}
                  required={address.complemento.required}
                  label="COMPLEMENTO"
                  placeholder="Ex: Zona Rural"
                  variant="outlined"
                  style={{ marginBottom: "10px", width: "100%" }}
                  onChange={({ target }) => {
                    handleChangeAddress("complemento", target.value);
                  }}
                  onBlur={({ target }) => {
                    if (!target.value) setAddressError("complemento");
                  }}
                />
              </div>

              <div className={classes.rowFieldsWrapper}>
                <CustomTextField
                  error={address.uf.error}
                  errorMessage={address.uf.errorMessage}
                  required={address.uf.required}
                  label="ESTADO (sigla)"
                  variant="outlined"
                  style={{ marginBottom: "10px", width: "100%" }}
                  value={address.uf.value}
                  placeholder="AM"
                  onChange={({ target }) => {
                    if (target.value.length <= 2) {
                      handleChangeAddress("uf", target.value);
                    }
                  }}
                  onBlur={({ target }) => {
                    if (!target.value) setAddressError("uf");
                  }}
                />
                <CustomTextField
                  error={address.localidade.error}
                  errorMessage={address.localidade.errorMessage}
                  required={address.localidade.required}
                  label="MUNICÍPIO"
                  placeholder="Amazonas"
                  variant="outlined"
                  style={{ marginBottom: "10px", width: "100%" }}
                  value={address.localidade.value}
                  onChange={({ target }) =>
                    handleChangeAddress("localidade", target.value)
                  }
                  onBlur={({ target }) => {
                    if (!target.value) setAddressError("localidade");
                  }}
                />
              </div>
            </>
          ) : (
            <>
              <h2 className={classes.secondStepTitle}>Dados demográficos</h2>

              <FormLabel
                id="demo-radio-buttons-group-label"
                className={classes.labelDemographic}
              >
                Número aproximado de colaboradores
              </FormLabel>
              <RadioGroup
                style={{ width: "100%", marginBottom: "24px" }}
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                onChange={({ target }) => {
                  handleChangeDemographicForm("contributors", target.value);
                }}
              >
                <FormControlLabel
                  className={classes.inputStyle}
                  value="BETWEEN_1_10"
                  control={<Radio />}
                  label="Entre 1 e 10"
                />
                <FormControlLabel
                  className={classes.inputStyle}
                  value="BETWEEN_11_30"
                  control={<Radio />}
                  label="Entre 11 e 30"
                />
                <FormControlLabel
                  className={classes.inputStyle}
                  value="BETWEEN_31_50"
                  control={<Radio />}
                  label="Entre 31 e 50"
                />
                <FormControlLabel
                  className={classes.inputStyle}
                  value="BETWEEN_51_100"
                  control={<Radio />}
                  label="Entre 50 e 100"
                />
                <FormControlLabel
                  className={classes.inputStyle}
                  value="MORE_100"
                  control={<Radio />}
                  label="Mais do que 100"
                />
              </RadioGroup>

              <FormLabel
                id="demo-radio-buttons-group-label"
                className={classes.labelDemographic}
              >
                Qual a média de idade entre os colaboradores?
              </FormLabel>
              <RadioGroup
                style={{ width: "100%", marginBottom: "24px" }}
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                onChange={({ target }) => {
                  handleChangeDemographicForm("age_contributors", target.value);
                }}
              >
                <FormControlLabel
                  className={classes.inputStyle}
                  value="BETWEEN_18_26"
                  control={<Radio />}
                  label="Entre 18 e 26 anos"
                />
                <FormControlLabel
                  className={classes.inputStyle}
                  value="BETWEEN_27_35"
                  control={<Radio />}
                  label="Entre 27 e 35 anos"
                />
                <FormControlLabel
                  className={classes.inputStyle}
                  value="BETWEEN_36_43"
                  control={<Radio />}
                  label="Entre 36 e 43 anos"
                />
                <FormControlLabel
                  className={classes.inputStyle}
                  value="BETWEEN_44_51"
                  control={<Radio />}
                  label="Entre 44 e 51 anos"
                />
                <FormControlLabel
                  className={classes.inputStyle}
                  value="MORE_52"
                  control={<Radio />}
                  label="Acima de 52 anos"
                />
              </RadioGroup>

              <FormLabel
                id="demo-radio-buttons-group-label"
                className={classes.labelDemographic}
              >
                Indique o número de colaboradores por Gênero. Quantos são
                Homens?
              </FormLabel>
              <RadioGroup
                style={{ width: "100%", marginBottom: "24px" }}
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                onChange={({ target }) => {
                  handleChangeDemographicForm("contributors_man", target.value);
                }}
              >
                <FormControlLabel
                  className={classes.inputStyle}
                  value="BETWEEN_1_10"
                  control={<Radio />}
                  label="Entre 1 e 10"
                />
                <FormControlLabel
                  className={classes.inputStyle}
                  value="BETWEEN_11_20"
                  control={<Radio />}
                  label="Entre 11 e 20"
                />
                <FormControlLabel
                  className={classes.inputStyle}
                  value="BETWEEN_21_30"
                  control={<Radio />}
                  label="Entre 21 e 30"
                />
                <FormControlLabel
                  className={classes.inputStyle}
                  value="BETWEEN_31_40"
                  control={<Radio />}
                  label="Entre 31 e 40"
                />
                <FormControlLabel
                  className={classes.inputStyle}
                  value="MORE_41"
                  control={<Radio />}
                  label="Mais de 41"
                />
              </RadioGroup>

              <FormLabel
                id="demo-radio-buttons-group-label"
                className={classes.labelDemographic}
              >
                Indique o número de colaboradores por Gênero. Quantos são
                Mulheres?
              </FormLabel>
              <RadioGroup
                style={{ width: "100%", marginBottom: "24px" }}
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                onChange={({ target }) => {
                  handleChangeDemographicForm(
                    "contributors_woman",
                    target.value
                  );
                }}
              >
                <FormControlLabel
                  className={classes.inputStyle}
                  value="BETWEEN_1_10"
                  control={<Radio />}
                  label="Entre 1 e 10"
                />
                <FormControlLabel
                  className={classes.inputStyle}
                  value="BETWEEN_11_20"
                  control={<Radio />}
                  label="Entre 11 e 20"
                />
                <FormControlLabel
                  className={classes.inputStyle}
                  value="BETWEEN_21_30"
                  control={<Radio />}
                  label="Entre 21 e 30"
                />
                <FormControlLabel
                  className={classes.inputStyle}
                  value="BETWEEN_31_40"
                  control={<Radio />}
                  label="Entre 31 e 40"
                />
                <FormControlLabel
                  className={classes.inputStyle}
                  value="MORE_41"
                  control={<Radio />}
                  label="Mais de 41"
                />
              </RadioGroup>
            </>
          )}

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              gap: "16px",
            }}
          >
            {step === 1 && (
              <>
                <Button
                  onClick={() => {
                    setStep(1);
                  }}
                  variant="outlined"
                  className={classes.buttonCancel}
                >
                  Cancelar
                </Button>
                {disabledNextButton ? (
                  <Button
                    className={classes.buttonDisabled}
                    type="button"
                    disabled
                    variant="contained"
                  >
                    Avançar
                  </Button>
                ) : (
                  <Button
                    className={classes.buttonAccept}
                    type="button"
                    onClick={() => {
                      setStep(2);
                    }}
                    variant="contained"
                  >
                    Avançar
                  </Button>
                )}
              </>
            )}
            {step === 2 && (
              <>
                <Button
                  onClick={() => {
                    setStep(1);
                  }}
                  variant="outlined"
                  className={classes.buttonCancel}
                >
                  Voltar
                </Button>
                <Button
                  onClick={() => {
                    sendForm();
                  }}
                  variant="outlined"
                  className={classes.buttonAccept}
                >
                  Confirmar
                </Button>
              </>
            )}
          </div>
        </RegisterContainer>

        <Footer />
      </div>

      <AlertModal
        isOpen={modalSuccessOpen}
        title="Solicitação em Análise"
        textCloseButton="Voltar para cooperativas"
        onRequestClose={() => {
          setModalSuccessOpen(false);
          history.push("/cooperativas");
        }}
      >
        <p>
          Sua solitiação foi enviada com sucesso e está em análise pela nossa
          equipe. Em breve a Cooperativa será disponibilizada na Rede RHISA.
        </p>
      </AlertModal>

      <ErrorModal
        isOpen={modalErrorOpen}
        textCloseButton="Voltar para o formulário"
        onRequestClose={() => {
          setModalErrorOpen(false);
        }}
      >
        <p>
          Ocorreu um erro ao enviar sua solicitação. Por favor, aguarde alguns
          instantes e tente novamente.
        </p>
      </ErrorModal>
    </>
  );
}

export default Create;
