import * as React from "react";

function SvgWriteMachine(props) {
  return (
    <svg
      width={26}
      height={22}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.513 0c-.572 0-1.036.464-1.036 1.036v6.471a2.466 2.466 0 00-2.04 1.523l-.26.627v-1.14a1.036 1.036 0 00-2.072 0V9.69c0 .607.226 1.188.635 1.635l.516.563-1.095 2.651a6.502 6.502 0 00-.494 2.493v2.369c0 1.117.908 2.025 2.025 2.025h20.616a2.028 2.028 0 002.025-2.025v-2.369a6.5 6.5 0 00-.494-2.493l-1.095-2.651.515-.563c.41-.447.636-1.028.636-1.635V8.518a1.036 1.036 0 10-2.073 0v1.14l-.259-.628a2.465 2.465 0 00-2.04-1.523v-6.47C20.523.463 20.059 0 19.486 0H6.513zM7.55 7.495V2.073h10.9v5.422c-.807.061-1.54.517-1.94 1.223A4.045 4.045 0 0113 10.763a4.045 4.045 0 01-3.51-2.045 2.442 2.442 0 00-1.94-1.223zM5.353 9.821a.418.418 0 01.388-.26h1.624c.14 0 .262.074.322.18A6.12 6.12 0 0013 12.837a6.12 6.12 0 005.312-3.094.37.37 0 01.323-.18h1.623c.172 0 .324.1.389.26l2.276 5.509c.354.856.35 1.357.341 3.016l-.004 1.006H2.74c0-.379-.003-.712-.004-1.009-.01-1.654-.013-2.156.341-3.014l2.276-5.509zm7.006 6.251a1.036 1.036 0 100 2.073h1.282a1.037 1.037 0 000-2.073h-1.282zm4.095 0a1.036 1.036 0 100 2.073h1.282a1.037 1.037 0 000-2.073h-1.282zm4.095 0a1.036 1.036 0 100 2.073h1.282a1.037 1.037 0 000-2.073h-1.282zm-12.285 0a1.036 1.036 0 100 2.073h1.282a1.036 1.036 0 000-2.073H8.264zm-4.095 0a1.036 1.036 0 100 2.073H5.45a1.037 1.037 0 000-2.073H4.17zm10.237-2.813a1.036 1.036 0 000 2.073h1.282a1.036 1.036 0 100-2.073h-1.282zm4.095 0a1.036 1.036 0 000 2.073h1.282a1.036 1.036 0 100-2.073h-1.282zm-8.19 0a1.037 1.037 0 000 2.073h1.283a1.036 1.036 0 100-2.073H10.31zm-4.095 0a1.037 1.037 0 000 2.073H7.5a1.036 1.036 0 100-2.073H6.216zm3.11-7.634a1.036 1.036 0 000 2.073h7.348a1.036 1.036 0 000-2.073H9.326zm0-2.813a1.036 1.036 0 000 2.073h5.004a1.037 1.037 0 000-2.072H9.326z"
        fill="#333"
      />
    </svg>
  );
}

export default SvgWriteMachine;
