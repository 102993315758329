import { createUseStyles } from "react-jss";
import { breakpoints } from "../../../../constants/sizes";

const useStyles = createUseStyles((theme) => ({
  title: {
    marginBottom: 15,
    fontWeight: "bold",
    fontSize: 14,
    [breakpoints.sm]: {
      fontWeight: 400,
    },
  },
  pickerWrapper: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: 10,
    "& button": {
      marginRight: 5,
      marginBottom: 5,
    },
    "& button:last-child": {
      margin: 0,
    },
    [breakpoints.sm]: {
      gridTemplateColumns: "1fr 1fr",
    },
  },

  monthsWrapper: {
    "& .selected, button:hover": {
      backgroundColor: theme.primary0,
      color: theme.background0,
      fontWeight: 700,
    },
  },

  optionSelector: {
    "&.MuiInputBase-root, button": {
      backgroundColor: theme.background0,
      width: "100%",
      height: 45,
      borderRadius: 4,
      border: `1px solid ${theme.stroke}`,
      "&:hover": {
        border: `1px solid ${theme.textPrimary}`,
      },
    },
    "& button": {
      fontFamily: `"Manrope", "Roboto", "Helvetica", "Arial", sans-serif`,
      fontSize: 16,
      textAlign: "left",
      border: `1px solid ${theme.stroke}`,
      color: theme.textPrimary,
      "&:hover": {
        cursor: "pointer",
        backgroundColor: theme.background0,
        color: theme.textPrimary,
        fontWeight: 400,
      },
      "& div:first-child": {
        display: "flex",
        justifyContent: "space-between",
      },
    },
    "& .MuiInputBase-input": {
      paddingLeft: 10,
      fontSize: 16,
      height: 30,
    },
    "& .MuiSelect-root": {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
  },
  arrowIcon: {
    position: "absolute",
    right: 10,
    pointerEvents: "none",
    transform: "scale(0.8)",
    "& path": {
      fill: theme.textPrimary,
    },
  },
}));

export default useStyles;
