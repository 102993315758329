import { createUseStyles } from "react-jss";
import { fadeOutDown } from "react-animations";

const useStyles = createUseStyles((/* theme */) => ({
  tabsRoot: {
    paddingBottom: 10,
  },
  "@keyframes fadeOutDown": { ...fadeOutDown },
  scrollButtons: {
    "&.MuiTabs-scrollButtonsDesktop": {
      "&.Mui-disabled": {
        animation: "1s $fadeOutDown",
        display: "none",
      },
    },
  },
}));

export default useStyles;
