import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { useTheme } from "react-jss";
import useStyles from "./styles";

const CustomLink = ({ component, children, outlined, ...props }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  switch (component) {
    case "span":
      return (
        <Link className={classes.link} {...props}>
          <span className={outlined ? classes.outlined : classes.linkWrapper}>
            {children}
          </span>
        </Link>
      );

    default:
      return (
        <Link className={classes.link} {...props}>
          <div className={outlined ? classes.outlined : classes.linkWrapper}>
            {children}
          </div>
        </Link>
      );
  }
};

CustomLink.propTypes = {
  children: PropTypes.node,
  outlined: PropTypes.bool,
  component: PropTypes.string,
};

CustomLink.defaultProps = {
  children: undefined,
  outlined: false,
  component: "div",
};

export default CustomLink;
