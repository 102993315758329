import React from "react";
import PropTypes from "prop-types";
import Checkbox from "@material-ui/core/Checkbox";
import CheckboxIconSvg from "../Icons/CheckboxIcon";
import CheckboxOutlineSvg from "../Icons/CheckboxOutline";

const CustomCheckbox = ({ children, ...props }) => (
  <Checkbox
    icon={<CheckboxOutlineSvg />}
    checkedIcon={<CheckboxIconSvg />}
    {...props}
  >
    {children}
  </Checkbox>
);

CustomCheckbox.propTypes = {
  children: PropTypes.node,
};
CustomCheckbox.defaultProps = {
  children: undefined,
};

export default CustomCheckbox;
