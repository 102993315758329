import React, { useState, useEffect } from "react";
import { useTheme } from "react-jss";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import queryString from "query-string";

import { fetchResults } from "../../../redux/result/resultActions";
import { resetFilters } from "../../../redux/resultFilter/resultFilterActions";
import { fetchStatistics, fetchCooperatives } from "../../../redux/cooperative/cooperativeActions";
import Header from "../../../components/Cooperatives/Header";
import Footer from "../../../components/V2/Footer";
import useStyles from "./styles";
import ResultView from "../../../components/Castanha/Landing/SearchContainer/ResultView";

import { breakpoints } from "../../../constants/sizes";
import categoryTypes from "../../../components/Castanha/Landing/SearchContainer/CategoryTabs/CategoryTypes";

function Search() {
  const theme = useTheme();
  const classes = useStyles(theme);
  const dispatch = useDispatch();
  const location = useLocation();
  const [filter, setFilter] = useState({});
  const [mounted, setMounted] = useState(false);
  const [showMapOnMd, setShowMapOnMd] = useState(false);
  const isMd = useMediaQuery(breakpoints.md);
  const { filters } = useSelector((state) => state.result);

  useEffect(() => {
    if (!isMd) {
      if (showMapOnMd) {
        setShowMapOnMd(false);
      }
    }
  }, [isMd]);

  const handleDispatch = (newFilters, category = categoryTypes.ALL) => {
    dispatch(
      fetchResults({
        ...newFilters,
        q: "castanha",
        category
      })
    );
    setFilter((oldValue) => ({ ...oldValue, ...newFilters }));
  };

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const { category } = parsed;
    handleDispatch(parsed, category);
    dispatch(fetchCooperatives({ q: "Castanha", limit: 1000, page: 1 }));
    setMounted(true);
  }, []);

  useEffect(() => {
    if (mounted) {
      const parsed = queryString.parse(location.search);
      handleDispatch(parsed, filters.category);
      dispatch(resetFilters());
    }
    return () => {
      dispatch(resetFilters());
    };
  }, [location]);

  const dispatchFetchStatistics = () => {
    dispatch(fetchStatistics());
  };

  useEffect(() => {
    dispatchFetchStatistics();
  }, []);

  return (
    <div className={classes.container}>
      <Header />
      <ResultView
      className={classes.resultView}
        query="castanha"
        currentPage={filter.page}
        initialCategory={queryString.parse(location.search).category}
      />

      <Footer />
    </div>
  );
}

export default Search;
