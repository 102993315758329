import * as React from "react";

function Star(props) {
  return (
    <svg
      width="30"
      height="29"
      viewBox="0 0 30 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M29.0304 10.0879L20.037 8.7809L16.0167 0.630509C15.9069 0.407356 15.7262 0.226708 15.5031 0.116903C14.9434 -0.159382 14.2633 0.0708553 13.9835 0.630509L9.96319 8.7809L0.969774 10.0879C0.721827 10.1234 0.495132 10.2403 0.321568 10.4174C0.11174 10.633 -0.00388537 10.9232 9.96959e-05 11.224C0.00408476 11.5249 0.127354 11.8119 0.342821 12.0219L6.84968 18.3659L5.3124 27.3239C5.27635 27.5322 5.29941 27.7465 5.37896 27.9425C5.45852 28.1384 5.59138 28.3082 5.76249 28.4324C5.9336 28.5567 6.13611 28.6306 6.34705 28.6456C6.55799 28.6606 6.76893 28.6163 6.95594 28.5175L15.0001 24.2883L23.0442 28.5175C23.2638 28.6344 23.5188 28.6734 23.7633 28.6309C24.3796 28.5246 24.794 27.9402 24.6877 27.3239L23.1505 18.3659L29.6573 12.0219C29.8344 11.8484 29.9513 11.6217 29.9867 11.3737C30.0824 10.7539 29.6502 10.18 29.0304 10.0879ZM20.4124 17.4732L21.6911 24.9223L15.0001 21.4085L8.30903 24.9258L9.58773 17.4768L4.17538 12.199L11.6563 11.1116L15.0001 4.33556L18.3438 11.1116L25.8248 12.199L20.4124 17.4732Z"
        fill="#00A5A8"
      />
    </svg>
  );
}

export default Star;
