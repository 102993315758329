import { createUseStyles } from "react-jss";
import { breakpoints } from "../../../../../constants/sizes";

const useStyles = createUseStyles((theme) => ({
  popperWrapper: {
    backgroundColor: theme.background0,
    zIndex: 10,
    padding: 16,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    position: "absolute",
    [breakpoints.md]: {
      width: 300,
      border: `1px solid ${theme.stroke}`,
      borderRadius: 4,
    },
    [breakpoints.sm]: {
      top: "60px !important",
      left: "0px !important",
      boxShadow: `inset  0 7px 9px -7px ${theme.innerShadow}`,
      width: "100%",
      height: "calc(100% - 60px)",
      border: "none",
    },
  },
  menuItem: {
    height: 40,
    display: "flex",
    alignItems: "center",
  },
  agencyAccordion: {
    "& button": {
      height: 40,
      display: "flex",
      alignItems: "center",
    },
  },
  agencyButton: {
    "& path": {
      fill: "red",
    },
  },
  whatsappGrid: {
    display: "flex",
    "& div:first-child": {
      marginRight: 10,
    },
  },
  whatsappButton: {
    backgroundColor: "transparent",
    padding: 0,
    border: "none",
    cursor: "pointer",
  },
  signUpButtonWrapper: {
    width: "100%",
    "& .MuiButton-root": {
      height: 48,
      fontWeight: "bold",
    },
  },
  authButton: {
    width: "100%",
  },
}));

export default useStyles;
