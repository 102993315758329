import { createUseStyles } from "react-jss";
import { fadeIn } from "react-animations";
import { breakpoints, defaultContainer } from "../../../constants/sizes";

const useStyles = createUseStyles((theme) => ({
  headerWrapper: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    zIndex: 5,
    position: "fixed",
    backgroundColor: theme.background0,
    height: 70,
    [breakpoints.md]: {
      position: "absolute",
      height: 58,
    },
  },
  content: {
    position: "relative",
    width: "100%",
    maxWidth: defaultContainer.Width + defaultContainer.paddingHorizontal * 2,
    padding: `0px ${defaultContainer.paddingHorizontal}px`,
  },

  "@keyframes slideOutUp": {
    from: { transform: "translateY(80px)" },
    to: { transform: "translateY(0px)" },
  },
  "@keyframes fadeIn": fadeIn,
  searchContainerWrapper: {
    marginTop: 80,
    width: "100%",
    maxWidth: defaultContainer.Width + defaultContainer.paddingHorizontal * 2,
    padding: `0px ${defaultContainer.paddingHorizontal}px`,

    [breakpoints.md]: {
      boxShadow: `inset  0 7px 9px -7px ${theme.innerShadow}`,
      marginTop: 60,
      minHeight: 0,
      padding: "20px 0px 0px 0px",
    },
  },
  searchContainer: {
    maxWidth: 600,
    minHeight: 800,
    position: "sticky",
    zIndex: 4,
    paddingBottom: 20,
    [breakpoints.lg]: {
      width: "52%",
    },
    [breakpoints.md]: {
      minHeight: 400,
      maxWidth: "100%",
      width: "100%",
      height: "100%",
      display: (props) => (props.showMapOnMd ? "none" : "block"),

      padding: (props) =>
        props.showMapOnMd ? 0 : `0px ${defaultContainer.paddingHorizontal}px`,
    },
  },
  footerWrapper: {
    position: "sticky",
    zIndex: 3,
    paddingBottom: 20,
    [breakpoints.lg]: {
      width: "52%",
    },
    [breakpoints.md]: {
      maxWidth: "100%",
      width: "100%",
      height: "100%",
      display: (props) => (props.showMapOnMd ? "none" : "block"),
      padding: (props) =>
        props.showMapOnMd ? 0 : `0px ${defaultContainer.paddingHorizontal}px`,
      marginTop: 20,
      marginBottom: 30,
    },
  },
  leftSection: {
    display: "block",
    [breakpoints.md]: {
      display: "none",
    },
  },
  mapWrapperWide: {
    width: "100%",
    position: "fixed",
    zIndex: 2,
    marginTop: 71,
    height: "calc(100% - 212px)",
    maxHeight: 1200,
    [breakpoints.md]: {
      display: (props) => (props.showMapOnMd ? "block" : "none"),
      marginTop: 0,
      width: "100%",
      height: "100%",
      position: "absolute",
      zIndex: -1,
    },
  },

  mapGrid: {
    height: "100%",
    display: "grid",
    gridTemplateColumns: "53% minmax(400px, 100%)",
    "@media (min-width: 1920px)": {
      gridTemplateColumns: "52% minmax(400px, 100%)",
    },
    "@media (min-width: 4000px)": {
      gridTemplateColumns: "51% minmax(400px, 100%)",
    },
    [breakpoints.md]: {
      gridTemplateColumns: "auto",
      minWidth: 250,
    },
  },
  mapGridItem: {
    width: "100%",
    height: "100%",
  },

  bottomButtonWrapper: {
    display: "none",
    animation: "1s $slideOutUp",
    position: "fixed",
    width: "100%",
    bottom: 0,
    zIndex: 4,
    backgroundColor: "white",
    filter: `drop-shadow(0px 5px 4px ${theme.textPrimary})`,
    [breakpoints.md]: {
      display: (props) => (!props.showMapOnMd ? "flex" : "none"),
      alignItems: "center",
      flexDirection: "column",
      minHeight: 40,
    },
  },
}));

export default useStyles;
