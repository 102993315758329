import React from "react";
import { useTheme } from "react-jss";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
// import { fetchResults } from "../../../../redux/result/resultActions";
import CustomTypography from "../../../../CustomTypography";
// import CustomButton from "../../../CustomButton";
// import NotificationIcon from "../../../Icons/Notification";
import { formatNumber } from "../../../../../util/formatNumber";
import useStyles from "./styles";

const Filter = ({ query, onAlertButtonClick, ...props }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  const { total, loading } = useSelector((state) => state.result);
  // const dispatch = useDispatch();

  return (
    <div {...props}>
      <div className={classes.gridContainer}>

        <div className={classes.titleSm}>
          <CustomTypography variant="h3" color={theme.textPrimary}>
            {!loading
              ? `${formatNumber(total)} resultados`
              : `${t("searchEngine.filters.loading")} "${query}"`}
          </CustomTypography>
        </div>
        <div>
          <div className={classes.buttonsContainer}>
            <div className={classes.alertButton}>
              {/* <CustomButton
                color={theme.background0}
                className={classes.button}
                startIcon={<NotificationIcon />}
                onClick={onAlertButtonClick}
              >
                <div>{t("searchEngine.alertPaper.title")}</div>
              </CustomButton> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Filter.propTypes = {
  query: PropTypes.string,
  onAlertButtonClick: PropTypes.func,
};

Filter.defaultProps = {
  query: "",
  onAlertButtonClick: undefined,
};

export default Filter;
