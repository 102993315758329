import resultTypes from "../../components/Landing/ResultTypes";
import { getDateFromYear } from "../../util/date";
import {
  FETCH_RESEARCHER_FAILURE,
  FETCH_RESEARCHER_REQUEST,
  FETCH_RESEARCHER_SUCCESS,
  FETCH_RESEARCHER_STATS_FAILURE,
  FETCH_RESEARCHER_STATS_REQUEST,
  FETCH_RESEARCHER_STATS_SUCCESS,
  FETCH_RESEARCHER_PROJECTS_REQUEST,
  FETCH_RESEARCHER_PROJECTS_SUCCESS,
  FETCH_RESEARCHER_PROJECTS_FAILURE,
  FETCH_RESEARCHER_PUBLICATIONS_REQUEST,
  FETCH_RESEARCHER_PUBLICATIONS_SUCCESS,
  FETCH_RESEARCHER_PUBLICATIONS_FAILURE,
  SET_STATS_START_DATE,
  SET_STATS_END_DATE,
  RESET_STATS_FILTERS,
  SET_DATE_PERIOD,
} from "./researcherTypes";

const initialState = {
  researcher: undefined,
  statistics: undefined,
  projects: undefined,
  publications: undefined,
  loadingResearcher: false,
  loadingStats: false,
  loadingProjects: false,
  loadingPublications: false,
  error: "",
  startDate: getDateFromYear(1980),
  endDate: new Date(),
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RESEARCHER_REQUEST:
      return {
        ...state,
        loadingResearcher: true,
        researcher: undefined,
      };
    case FETCH_RESEARCHER_SUCCESS:
      return {
        ...state,
        loadingResearcher: false,
        researcher: { ...action.payload, type: resultTypes.RESEARCHER },
      };
    case FETCH_RESEARCHER_FAILURE:
      return {
        ...state,
        loadingResearcher: false,
        error: action.payload,
      };
    case FETCH_RESEARCHER_STATS_REQUEST:
      return {
        ...state,
        loadingStats: true,
        statistics: undefined,
      };
    case FETCH_RESEARCHER_STATS_SUCCESS:
      return {
        ...state,
        loadingStats: false,
        statistics: {
          ...action.payload,
          collaborations: action.payload.collaborations.map((result) => ({
            ...result,
            type: resultTypes.RESEARCHER,
          })),
        },
      };
    case FETCH_RESEARCHER_STATS_FAILURE:
      return {
        ...state,
        loadingStats: false,
        error: action.payload,
      };
    case FETCH_RESEARCHER_PROJECTS_REQUEST:
      return {
        ...state,
        loadingProjects: true,
        projects: undefined,
      };
    case FETCH_RESEARCHER_PROJECTS_SUCCESS:
      return {
        ...state,
        loadingProjects: false,
        projects: action.payload,
      };
    case FETCH_RESEARCHER_PROJECTS_FAILURE:
      return {
        ...state,
        loadingProjects: false,
        error: action.payload,
      };
    case FETCH_RESEARCHER_PUBLICATIONS_REQUEST:
      return {
        ...state,
        loadingPublications: true,
        publications: undefined,
      };
    case FETCH_RESEARCHER_PUBLICATIONS_SUCCESS:
      return {
        ...state,
        loadingPublications: false,
        publications: action.payload,
      };
    case FETCH_RESEARCHER_PUBLICATIONS_FAILURE:
      return {
        ...state,
        loadingPublications: false,
        error: action.payload,
      };
    case SET_STATS_START_DATE:
      return {
        ...state,
        startDate: action.payload,
      };
    case SET_STATS_END_DATE:
      return {
        ...state,
        endDate: action.payload,
      };
    case RESET_STATS_FILTERS:
      return {
        ...state,
        startDate: getDateFromYear(1980),
        endDate: new Date(),
      };
    case SET_DATE_PERIOD:
      return {
        ...state,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      };

    default:
      return state;
  }
};
export default reducer;
