import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Timeline from "../Timeline";
import {
  setEndDate,
  setStartDate,
  setPeriod,
} from "../../redux/researcher/researcherActions";

const TimelineAnalysis = () => {
  const dispatch = useDispatch();
  const { startDate, endDate } = useSelector((state) => state.researcher);

  const handlePeriodChange = (newStartDate, newEndDate) => {
    dispatch(setPeriod(newStartDate, newEndDate));
  };

  const handleStartDateChange = (date) => {
    dispatch(setStartDate(date));
  };

  const handleEndDateChange = (date) => {
    dispatch(setEndDate(date));
  };

  return (
    <Timeline
      startDate={startDate}
      endDate={endDate}
      onStartDateChange={handleStartDateChange}
      onEndDateChange={handleEndDateChange}
      onPeriodChange={handlePeriodChange}
    />
  );
};

TimelineAnalysis.propTypes = {};

TimelineAnalysis.defaultProps = {};

export default TimelineAnalysis;
