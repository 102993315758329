import { createUseStyles } from "react-jss";
import { breakpoints } from "../../../constants/sizes";

const useStyles = createUseStyles((theme) => ({
  viewMapButton: {
    "&.MuiButton-root": {
      "&:hover": {
        backgroundColor: theme.background1,
      },
    },
    "& path": {
      fill: theme.primary0,
    },
    [breakpoints.md]: {
      display: "block",
    },
  },
}));

export default useStyles;
