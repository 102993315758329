import * as React from "react";

function BookChapter(props) {
  return (
    <svg
      width="22"
      height="18"
      viewBox="0 0 26 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.05827 1.77509C2.7462 0.65366 4.97546 0 7.375 0C9.45962 0 11.4157 0.493341 13 1.35814C14.5843 0.493341 16.5404 0 18.625 0C21.0259 0 23.2539 0.653699 24.9417 1.77509C25.2904 2.00676 25.5 2.39761 25.5 2.81625V19.0662C25.5 19.5269 25.2467 19.9502 24.8407 20.1679C24.4347 20.3856 23.942 20.3623 23.5583 20.1074C22.3261 19.2888 20.5916 18.75 18.625 18.75C16.6595 18.75 14.9238 19.2888 13.6917 20.1074C13.2726 20.3859 12.7274 20.3859 12.3083 20.1074C11.0762 19.2888 9.34046 18.75 7.375 18.75C5.40954 18.75 3.6738 19.2888 2.44173 20.1074C2.05805 20.3623 1.56527 20.3856 1.15931 20.1679C0.753344 19.9502 0.5 19.5269 0.5 19.0662V2.81625C0.5 2.39761 0.709573 2.00676 1.05827 1.77509ZM11.75 3.52437C10.5754 2.89584 9.05958 2.5 7.375 2.5C5.69042 2.5 4.17459 2.89584 3 3.52437V17.0327C4.31725 16.5281 5.8099 16.25 7.375 16.25C8.9401 16.25 10.4328 16.5281 11.75 17.0327V3.52437ZM14.25 17.0327C15.5672 16.5281 17.0599 16.25 18.625 16.25C20.1908 16.25 21.6831 16.5281 23 17.0326V3.52433C21.8256 2.8958 20.3106 2.5 18.625 2.5C16.9404 2.5 15.4246 2.89584 14.25 3.52437V17.0327Z"
        fill="white"
      />
    </svg>
  );
}

export default BookChapter;
