import React from "react";
import { useTheme } from "react-jss";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import CustomTypography from "../CustomTypography";
import CustomButton from "../CustomButton";
import AmazonasImg from "../../assets/images/amazonas_1.png";
import SedectiImg from "../../assets/images/sedecti_02.png";
import IcsImg from "../../assets/images/ics.png";
import UfamImg from "../../assets/images/marcaufam.png";
import AcariquaraImg from "../../assets/images/acariquara.png";
import GeodatinImg from "../../assets/images/geodatin.png";
import useStyles from "./styles";

const Partners = ({ buttonTo }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { t } = useTranslation();

  return (
    <div id="partners" className={classes.fold}>
      <div className={classes.fifthFoldWrapper}>
        <CustomTypography variant="h2" className={classes.fifthFoldTitle}>
          {t("landing.fifthFold.title")}
        </CustomTypography>

        <div className={classes.partnersSection}>
          <span
            style={{
              gridArea: "a",
            }}
            className={classes.footerImage}
          >
            <img alt="Amazonas" src={AmazonasImg} />
            <img alt="Sedecti" src={SedectiImg} style={{ marginLeft: 8 }} />
          </span>
          <img
            alt="ICS"
            src={IcsImg}
            style={{
              gridArea: "b",
            }}
            className={classes.footerImage}
          />
          <img
            alt="UFAM"
            src={UfamImg}
            style={{
              gridArea: "c",
            }}
            className={classes.footerImage}
          />
          <img
            alt="Acariquara"
            src={AcariquaraImg}
            style={{
              gridArea: "d",
            }}
            className={classes.footerImage}
          />
          <img
            alt="Geodatin"
            src={GeodatinImg}
            style={{
              gridArea: "e",
            }}
            className={classes.footerImage}
          />
        </div>

        <div className={classes.fifthFoldButton}>
          <a href={buttonTo} className={classes.href}>
            <CustomButton
              variant="contained"
              backgroundColor={theme.primary0}
              style={{ width: 214 }}
            >
              <CustomTypography color={theme.background0} weight="bold">
                {t("landing.fifthFold.button")}
              </CustomTypography>
            </CustomButton>
          </a>
        </div>
      </div>
    </div>
  );
};

Partners.propTypes = {
  buttonTo: PropTypes.string,
};
Partners.defaultProps = {
  buttonTo: "#cta",
};

export default Partners;
