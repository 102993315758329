import React from "react";
import { useTheme } from "react-jss";
import PropTypes from "prop-types";
import useStyles from "./styles";
import VideoInfo from "../../VideoInfo";
import HighlightSkeleton from "../../../Skeleton/HighlightSkeleton";

/**
 * This component represents an Article in Highlight.
 */
export default function Highlight({ data }) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <div className={classes.wrapper}>
      {!data ? <HighlightSkeleton /> : <VideoInfo data={data} />}
    </div>
  );
}
Highlight.propTypes = {
  data: PropTypes.shape(),
};

Highlight.defaultProps = {
  data: undefined,
};
