import React, { useEffect } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { useTheme } from "react-jss";
import { useDispatch } from "react-redux";
import { fetchStatistics } from "../../redux/cooperative/cooperativeActions";
import Header from "../../components/Cooperatives/Header";
import Footer from "../../components/V2/Footer";
import MapViewer from "../../components/Cooperatives/MapViewer";
import Home from "./Home";
import Cooperative from "./Cooperative";
import Result from "./Result";
import useStyles from "./styles";

function Cooperatives() {
  const match = useRouteMatch();
  const theme = useTheme();
  const classes = useStyles(theme);
  const dispatch = useDispatch();

  const dispatchFetchStatistics = () => {
    dispatch(fetchStatistics());
  };

  useEffect(() => {
    dispatchFetchStatistics();
  }, []);

  return (
    <div className={classes.container}>
      <Header />
      <MapViewer>
        <div className={classes.contentWrapper}>
          <Switch>
            <Route exact path={match.path} component={Home} />
            <Route
              exact
              path={`${match.path}/resultados/:stateName`}
              component={Result}
            />
            <Route path={`${match.path}/:id`} component={Cooperative} />
          </Switch>
        </div>
      </MapViewer>

      <Footer />
    </div>
  );
}

export default Cooperatives;
