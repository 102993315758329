import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((/* theme */) => ({
  link: {
    display: "flex",
    textDecoration: "none",
    "&:hover": {},
  },
}));

export default useStyles;
