import React from "react";
import { useTheme } from "react-jss";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CustomTypography from "../../../CustomTypography";
import { months } from "../../Constants";
import ArrowDown from "../../../Icons/ArrowDownThin";
import useStyles from "./styles";

const MonthYearSelect = ({
  selectedMonth,
  onMonthChanged,
  selectedYear,
  onYearChanged,
  title,
  years,
  onClickChangeMonthSm,
  onClickChangeYearSm,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const isSm = useMediaQuery("(max-width: 600px)");
  const { t } = useTranslation();

  function arrowIcon() {
    return (
      <div className={classes.arrowIcon}>
        <ArrowDown />
      </div>
    );
  }

  return (
    <>
      <CustomTypography variant="tag" className={classes.title}>
        {title}
      </CustomTypography>
      <div className={classes.pickerWrapper}>
        <div className={classes.monthsWrapper}>
          {isSm ? (
            <div className={classes.optionSelector}>
              <button type="button" onClick={onClickChangeMonthSm}>
                <div>
                  <div> {t(`date.month.${selectedMonth}.long`)}</div>
                  <div>
                    <ArrowDown />
                  </div>
                </div>
              </button>
            </div>
          ) : (
            <Select
              className={classes.optionSelector}
              disableUnderline
              value={selectedMonth}
              onChange={(event) => onMonthChanged(event.target.value)}
              IconComponent={() => arrowIcon()}
              MenuProps={{ disableScrollLock: true }}
            >
              {months.map((month) => (
                <MenuItem key={month} value={month}>
                  {t(`date.month.${month}.long`)}
                </MenuItem>
              ))}
            </Select>
          )}
        </div>
        <div className={classes.yearWrapper}>
          {isSm ? (
            <div className={classes.optionSelector}>
              <button type="button" onClick={onClickChangeYearSm}>
                <div>
                  <div> {selectedYear}</div>
                  <div>
                    <ArrowDown />
                  </div>
                </div>
              </button>
            </div>
          ) : (
            <Select
              className={classes.optionSelector}
              disableUnderline
              value={selectedYear}
              onChange={(event) => onYearChanged(event.target.value)}
              IconComponent={() => arrowIcon()}
              MenuProps={{ disableScrollLock: true }}
            >
              {years.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          )}
        </div>
      </div>
    </>
  );
};

MonthYearSelect.propTypes = {
  selectedMonth: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  selectedYear: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  onMonthChanged: PropTypes.func.isRequired,
  onYearChanged: PropTypes.func.isRequired,
  years: PropTypes.arrayOf(PropTypes.number).isRequired,
  title: PropTypes.string,
  onClickChangeMonthSm: PropTypes.func.isRequired,
  onClickChangeYearSm: PropTypes.func.isRequired,
};

MonthYearSelect.defaultProps = {
  title: "Select a date",
};

export default MonthYearSelect;
