import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => ({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    rowGap: 20,
    "& .titled-section-wrapper:nth-child(2n -1)": {
      backgroundColor: theme.background1,
    },
    "& .titled-section-wrapper:nth-child(2n)": {
      border: `1px solid ${theme.stroke}`,
      borderRadius: 4,
    },
  },
  contactSection: {
    "& .buttons-wrapper": {
      marginTop: 15,
      display: "flex",
      columnGap: 10,
    },
    "& .message-button": {
      "& path": {
        fill: theme.background0,
      },
      "& .MuiBox-root": {
        color: `${theme.background0} !important`,
      },
    },
    "& .contact-button": {
      "& path": {
        fill: theme.primary0,
      },
      "& .MuiBox-root": {
        color: `${theme.primary0} !important`,
      },
    },
    "& .MuiButtonBase-root": {
      padding: 10,
      height: 32,
      borderRadius: 4,
      "& svg": {
        marginRight: 10,
      },
    },
    "& .MuiButton-outlined": {
      "&:hover": {
        "& path": {
          fill: theme.background0,
        },
        "& .MuiBox-root": {
          color: `${theme.background0} !important`,
        },
      },
    },
  },
}));

export default useStyles;
