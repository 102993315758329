import React, { useState, useEffect, useRef } from "react";
import { useTheme } from "react-jss";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SvgMenu from "../../Icons/Menu";
import SvgClose from "../../Icons/Close";
import { breakpoints } from "../../../constants/sizes";
import useStyles from "./styles";
import MenuPopper from "./MenuPopper";

const MobileMenu = ({ selectedMenuButton, ...props }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const location = useLocation();
  const menuButtonRef = useRef(null);
  const [popupPosition, setPopupPosition] = useState({});
  const isMd = useMediaQuery(breakpoints.md);
  const [isOpen, setIsOpen] = useState(false);

  /**
   * Open popup
   * @param {*} event
   */
  const handleClick = (event) => {
    setIsOpen(Boolean(event.currentTarget));
  };

  /**
   * Closes popup
   */
  const handleClickAway = () => {
    setIsOpen(false);
  };

  /**
   * Closes popup if location changes
   */
  useEffect(() => {
    handleClickAway();
  }, [location]);

  /**
   * Calculate right margin to fit popup at parent bottom-right position
   */
  useEffect(() => {
    const { top, height, right } =
      menuButtonRef.current.getBoundingClientRect();
    setPopupPosition({
      top: top + height,
      right: document.body.clientWidth - right,
    });
  }, [menuButtonRef, document.body.clientWidth]);

  /**
   * Close popup if scren size exceed md size
   */
  useEffect(() => {
    handleClickAway();
  }, [isMd]);

  return (
    <ClickAwayListener onClickAway={handleClickAway} mouseEvent="onMouseUp">
      <div {...props}>
        <div className={classes.wrapper}>
          <button
            ref={menuButtonRef}
            type="button"
            onClick={isOpen ? handleClickAway : handleClick}
            className={classes.menuButton}
          >
            {isOpen ? (
              <div className={classes.closeButton}>
                <SvgClose />
              </div>
            ) : (
              <SvgMenu />
            )}
          </button>
          {isOpen && (
            <MenuPopper
              selectedMenuButton={selectedMenuButton}
              style={popupPosition}
            />
          )}
        </div>
      </div>
    </ClickAwayListener>
  );
};

MobileMenu.propTypes = {
  selectedMenuButton: PropTypes.shape().isRequired,
};
MobileMenu.defaultProps = {};

export default MobileMenu;
