import React, { useState, useEffect, useRef, memo } from "react";
import { useTheme } from "react-jss";
import PropTypes from "prop-types";
// import { useTranslation } from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress";
import CustomTabs from "../../../../CustomTabs";
import CustomTab from "../../../../CustomTab";
import InstituteHeader from "../InstituteHeader";
import About from "../About";
import { tabsTypes } from "../../tabTypes";
import GeneralContent from "../TabsContent/General";
import PaginatedListWrapper from "../../TabsContent/PaginatedListWrapper";
import InstitutionalCollaborationsPaginatedList from "../TabsContent/InstitutionalCollaborationsPaginatedList";
import MainResearchersPaginatedList from "../TabsContent/MainResearchersPaginatedList";
import useStyles from "./styles";

const InstituteContainer = ({
  institute,
  statistics,
  loading,
  isLoadingAnalysis,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  // const { t } = useTranslation();
  const [tabActive, setTabActive] = useState(tabsTypes.GENERAL);
  const tabsRef = useRef(null);
  // const { loadingStats } = useSelector((state) => state.researcher);

  useEffect(() => {
    setTabActive(tabsTypes.GENERAL);
  }, [institute]);

  if (!institute) {
    if (loading)
      return (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      );
    return null;
  }

  const instituteAddress = () => {
    const { publicPlace, number, district, city, state } = institute;
    if (!!publicPlace && !!number && !!district) {
      if (!state || !city) {
        return `${publicPlace} ${number}, ${district}`;
      }
      return `${publicPlace} ${number}, ${district} - ${city}/${state}`;
    }

    return "";
  };

  const handleTabChange = (_, newTabActive) => {
    setTabActive(newTabActive);
  };

  const handleViewAllClick = (sectionName) => {
    tabsRef.current.scrollIntoView();
    setTabActive(sectionName);
  };

  function TabSwicher() {
    switch (tabActive) {
      case tabsTypes.GENERAL:
        return (
          <GeneralContent
            institute={institute}
            statistics={statistics}
            loading={loading}
            isLoadingAnalysis={isLoadingAnalysis}
            onViewAllClick={handleViewAllClick}
          />
        );
      case tabsTypes.RESEARCHERS:
        return (
          <MainResearchersPaginatedList
            items={statistics ? statistics.main_researchers : []}
          />
        );
      case tabsTypes.COLABORATIONS:
        return (
          <InstitutionalCollaborationsPaginatedList
            items={statistics ? statistics.institutions_collaboration : []}
          />
        );
      default:
        return null;
    }
  }

  return (
    <div className={classes.content}>
      <InstituteHeader {...institute} />
      <About
        {...institute}
        address={instituteAddress()}
        about={institute.description}
      />
      <>
        <div ref={tabsRef} className={classes.section}>
          <CustomTabs value={tabActive} onChange={handleTabChange}>
            <CustomTab label="Visão geral" value={tabsTypes.GENERAL} />
            <CustomTab label="Pesquisadores" value={tabsTypes.RESEARCHERS} />
            <CustomTab label="Colaborações" value={tabsTypes.COLABORATIONS} />
          </CustomTabs>
        </div>
        <PaginatedListWrapper>
          <TabSwicher />
        </PaginatedListWrapper>
      </>
    </div>
  );
};

InstituteContainer.propTypes = {
  institute: PropTypes.shape(),
  statistics: PropTypes.oneOfType([PropTypes.shape(), PropTypes.bool]),
  loading: PropTypes.bool,
  isLoadingAnalysis: PropTypes.bool,
};

InstituteContainer.defaultProps = {
  institute: undefined,
  statistics: undefined,
  loading: true,
  isLoadingAnalysis: false,
};

export default memo(InstituteContainer);
