import React, { memo } from "react";
import { useTheme } from "react-jss";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useSelector, useDispatch } from "react-redux";
import {
  addFilter,
  removeFilter,
  toogleEnabled,
} from "../../../../redux/resultFilter/resultFilterActions";
import CustomButton from "../../../CustomButton";
import Checkbox from "../../../CustomCheckbox";
import FilterSvg from "../../../Icons/Filter";
import categoryTypes from "../../SearchContainer/CategoryTabs/CategoryTypes";
import ButtonWithPopup from "../../../ButtonWithPopup";

import useStyles from "./styles";

function CategoryFilter({ className }) {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { categoryFilter, enabled, appliedCategoriesCount } = useSelector(
    (state) => state.resultFilter
  );

  const handleChange = (event) => {
    if (!event.target.checked) {
      dispatch(removeFilter(event.target.name));
    } else {
      dispatch(addFilter(event.target.name));
    }
  };

  const handleApplyButton = () => {
    dispatch(toogleEnabled());
  };

  return (
    <>
      <ButtonWithPopup
        endIcon={!enabled && <FilterSvg />}
        title={t("searchEngine.filters.viewOnMap")}
        filterButton={
          <>
            {t("searchEngine.filters.filterButton")}
            {enabled && (
              <div className={classes.filterAplied}>
                {appliedCategoriesCount}
              </div>
            )}
          </>
        }
        className={classNames(classes.container, className)}
        bottomButton={
          <CustomButton
            className={classes.applyButton}
            onClick={handleApplyButton}
          >
            {!enabled
              ? t("searchEngine.filters.apply")
              : t("searchEngine.filters.remove")}
          </CustomButton>
        }
      >
        <>
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleChange}
                name={categoryTypes.ALL}
                checked={categoryFilter[categoryTypes.ALL]}
              />
            }
            label={t("searchEngine.filters.selectAll")}
          />
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleChange}
                name={categoryTypes.PUBLICATIONS}
                checked={categoryFilter[categoryTypes.PUBLICATIONS]}
              />
            }
            label={t("searchEngine.filters.publications")}
          />
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleChange}
                name={categoryTypes.RESEARCHER}
                checked={categoryFilter[categoryTypes.RESEARCHER]}
              />
            }
            label={t("searchEngine.filters.authors")}
          />
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleChange}
                name={categoryTypes.INSTITUTIONS}
                checked={categoryFilter[categoryTypes.INSTITUTIONS]}
              />
            }
            label={t("searchEngine.filters.institutions")}
          />
        </>
      </ButtonWithPopup>
    </>
  );
}

CategoryFilter.propTypes = {
  className: PropTypes.string,
};

CategoryFilter.defaultProps = {
  className: "",
};

export default memo(CategoryFilter);
