import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    "& .MuiButton-root": {
      padding: 0,
      minHeight: 0,
      height: "40px",
      fontWeight: "bold",
      backgroundColor: theme.secondary0,
      minWidth: 120,
      borderRadius: "0px 8px 8px 0px",
    },
    "& .MuiInputBase-root ": {
      fontSize: 16,
    },
  },
  searchBarWrapper: {
    width: "100%",
    height: 40,
    border: `1px solid ${theme.stroke}`,
    borderRadius: "8px 0px 0px 8px",
    paddingLeft: 10,
    display: "flex",
    alignItems: "center",
    "& .icon-button-base": {
      height: 25,
      width: 25,
      padding: 0,
      marginRight: 10,
    },
  },
  input: {
    width: "100%",
  },
}));

export default useStyles;
