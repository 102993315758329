import { getStrapiURL } from "./api";

/**
 * This function returns image url from Strapi.
 * @param {*} media
 */
export function getStrapiMedia(media) {
  const imageUrl = media.url.startsWith("/")
    ? getStrapiURL(media.url)
    : media.url;
  return imageUrl;
}

export function getMediaUrl(relativeUrl) {
  const imageUrl = relativeUrl.startsWith("/")
    ? getStrapiURL(relativeUrl)
    : relativeUrl;
  return imageUrl;
}

export function getYoutubeId(link = "") {
  const regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = link.match(regExp);
  return match && match[7].length === 11 ? match[7] : false;
}

export function isPodcastUrl(url = "") {
  const regExp = new RegExp("soundcloud", "gi");
  const match = url.match(regExp);
  return match && match.length > 0;
}
export function isVideoUrl(url = "") {
  const regExp = new RegExp("youtube", "gi");
  const match = url.match(regExp);
  return match && match.length > 0;
}
