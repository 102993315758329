import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTheme } from "react-jss";
import { useTranslation } from "react-i18next";
import CustomTypography from "../../../CustomTypography";
import RelatedInfo from "../RelatedInfo";
import useStyles from "./styles";
import ResultLabel from "../../ResultLabel";
import resultTypes from "../../ResultTypes";
import MoreButton from "../../MoreButton";
import ProductionsInfo from "./ProductionsInfo";
import InsituteInfo from "./InsituteInfo";

const ResultMoreInfo = ({
  result,
  type,
  hideImageOnSm,
  hideLabel,
  variant,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  const [hideColaborators, setHideColaborators] = useState(true);

  const isPopup = variant === "popup";

  const formatDoi = (text) => {
    if (!text) {
      return t("notFound.name");
    }
    const splitedDoi = text.split("/");
    return `${splitedDoi[3]} ${splitedDoi[4]}`;
  };

  const isArticle = resultTypes.ARTICLE_JOURNALS === type;

  const collaboratorIndex = (showMore) => {
    if (!showMore) {
      return result.collaborators.length;
    }
    return 2;
  };

  return (
    <>
      <div className={classes.resultContent}>
        {(!hideLabel || isArticle) && (
          <div className={classes.info}>
            <CustomTypography
              variant={isPopup ? "subtitle" : "searchResultSubTitle"}
              color={theme.textSecondary}
            >
              {!hideLabel && <ResultLabel type={type} />}
              {isArticle && <span>DOI: {formatDoi(result.doi)}</span>}
            </CustomTypography>
          </div>
        )}
      </div>
      <div className={classes.resultContent}>
        {result.collaborators &&
          result.collaborators
            .slice(0, collaboratorIndex(hideColaborators))
            .map((relatedResult) => (
              <div key={`${relatedResult.id}-${relatedResult.name}`}>
                <RelatedInfo
                  result={relatedResult}
                  hideImageOnSm={hideImageOnSm}
                  isPopup={isPopup}
                />
              </div>
            ))}
        {result.collaborators && result.collaborators.length > 2 && (
          <MoreButton
            open={hideColaborators}
            onClick={() => setHideColaborators(!hideColaborators)}
            moreItems={result.collaborators.length - 2}
          />
        )}
        <div>
          <CustomTypography
            variant={isPopup ? "subtitle" : "searchResultSubTitle"}
            color={theme.textSecondary}
            className={classes.date}
          >
            {result.year
              ? t("searchEngine.result.date", {
                  date: new Date(result.year),
                })
              : type === resultTypes.RESEARCHER && (
                  <>
                    <ProductionsInfo
                      totalPublications={parseFloat(result.total_publications)}
                      lastYearPublication={parseFloat(
                        result.last_year_publication
                      )}
                      isPopup={isPopup}
                    />
                  </>
                )}
          </CustomTypography>
        </div>
        {type === resultTypes.RESEARCHER && (
          <div className={classes.instituteInfoWrapper}>
            <InsituteInfo institute={result.institute} isPopup={isPopup} />
          </div>
        )}
      </div>
    </>
  );
};

ResultMoreInfo.propTypes = {
  result: PropTypes.shape().isRequired,
  type: PropTypes.string,
  hideImageOnSm: PropTypes.bool,
  hideLabel: PropTypes.bool,
  variant: PropTypes.string,
};

ResultMoreInfo.defaultProps = {
  type: "",
  hideImageOnSm: false,
  hideLabel: false,
  variant: undefined,
};

export default ResultMoreInfo;
