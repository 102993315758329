import { createUseStyles } from "react-jss";
import { breakpoints } from "../../../constants/sizes";

const useStyles = createUseStyles(() => ({
  container: {
    width: "60%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflowX: "hidden",
    boxShadow: "0px 3px 5px 2px #e3e3e3",
    padding: "2rem",
    marginBottom: "20px",
    [breakpoints.md]: {
      "& .footer-root": {
        display: "none",
      },
      width: "100%",
      paddingBottom: 70,
      padding: "1rem",
      boxShadow: "none",
      marginBottom: "0px",
    },
  },
}));

export default useStyles;
