import React, { memo } from "react";
import PropTypes from "prop-types";
import { useTheme } from "react-jss";
import CustomPaper from "../../../CustomPaper";
import useStyles from "./styles";
import AnalysisCardSkeleton from "../../../Skeleton/AnalysisCardSkeleton";

const AnalysisCard = ({
  title,
  children,
  bottom,
  loading,
  hideTopSkeleton,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <CustomPaper>
      {loading ? (
        <AnalysisCardSkeleton hideTop={hideTopSkeleton} />
      ) : (
        <div className={classes.contentWrapper}>
          {title && <div className={classes.title}>{title}</div>}
          {children}
          {bottom && (
            <div className={classes.bottomWrapper}>
              <div className={classes.bottom}> {bottom}</div>
            </div>
          )}
        </div>
      )}
    </CustomPaper>
  );
};

AnalysisCard.propTypes = {
  title: PropTypes.node,
  children: PropTypes.node,
  bottom: PropTypes.node,
  loading: PropTypes.bool,
  hideTopSkeleton: PropTypes.bool,
};

AnalysisCard.defaultProps = {
  title: undefined,
  children: undefined,
  bottom: undefined,
  loading: true,
  hideTopSkeleton: false,
};

export default memo(AnalysisCard);
