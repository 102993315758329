import { createUseStyles } from "react-jss";
import { defaultContainer } from "../../constants/sizes";

const useStyles = createUseStyles((/* theme */) => ({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  content: {
    position: "relative",
    width: "100%",
    maxWidth: defaultContainer.Width + defaultContainer.paddingHorizontal * 2,
    padding: `0px ${defaultContainer.paddingHorizontal}px`,
  },
  paddingBottom: {
    paddingBottom: 30,
  },
}));

export default useStyles;
