import * as React from "react";

function CooperativeCorn(props) {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99995 6.10352e-05C6.7245 6.10352e-05 4.87988 1.84468 4.87988 4.12013C4.87988 6.39559 6.7245 8.24021 8.99995 8.24021C11.2754 8.24021 13.12 6.39559 13.12 4.12013C13.12 1.84468 11.2754 6.10352e-05 8.99995 6.10352e-05ZM6.61346 4.12013C6.61346 2.80211 7.68192 1.73364 8.99995 1.73364C10.318 1.73364 11.3864 2.80211 11.3864 4.12013C11.3864 5.43816 10.318 6.50663 8.99995 6.50663C7.68192 6.50663 6.61346 5.43816 6.61346 4.12013ZM4.12007 8.4586C1.84462 8.4586 0 10.3032 0 12.5787C0 14.8541 1.84462 16.6987 4.12007 16.6987C6.39553 16.6987 8.24015 14.8541 8.24015 12.5787C8.24015 10.3032 6.39553 8.4586 4.12007 8.4586ZM13.8799 8.4586C11.6045 8.4586 9.75985 10.3032 9.75985 12.5787C9.75985 14.8541 11.6045 16.6987 13.8799 16.6987C16.1554 16.6987 18 14.8541 18 12.5787C18 10.3032 16.1554 8.4586 13.8799 8.4586ZM1.73358 12.5787C1.73358 11.2607 2.80205 10.1922 4.12007 10.1922C5.4381 10.1922 6.50657 11.2607 6.50657 12.5787C6.50657 13.8967 5.4381 14.9652 4.12007 14.9652C2.80205 14.9652 1.73358 13.8967 1.73358 12.5787ZM11.4934 12.5787C11.4934 11.2607 12.5619 10.1922 13.8799 10.1922C15.198 10.1922 16.2664 11.2607 16.2664 12.5787C16.2664 13.8967 15.198 14.9652 13.8799 14.9652C12.5619 14.9652 11.4934 13.8967 11.4934 12.5787Z"
        fill="white"
      />
    </svg>
  );
}

export default CooperativeCorn;
