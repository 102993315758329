import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "react-jss";
import { useTranslation } from "react-i18next";
import CustomTypography from "../../../../../CustomTypography";
import useStyles from "./styles";

const ProductionsInfo = ({
  totalPublications,
  lastYearPublication,
  isPopup,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();

  const hasProductions = totalPublications > 0;

  return (
    <div>
      <CustomTypography
        variant={isPopup ? "subtitle" : "searchResultSubTitle"}
        color={theme.textSecondary}
        className={classes.date}
      >
        {hasProductions ? (
          <div style={{ textTransform: "none" }}>
            <div className={classes.productionsWrapper}>
              <div>
                {t("searchEngine.result.productionsWithCount", {
                  count: totalPublications,
                })}
              </div>
              <div>
                {t("searchEngine.result.moreRecently", {
                  date: lastYearPublication,
                })}
              </div>
            </div>
          </div>
        ) : (
          <div style={{ textTransform: "none" }}>
            {t("researcher.withoutAcademicProduction")}
          </div>
        )}
      </CustomTypography>
    </div>
  );
};

ProductionsInfo.propTypes = {
  totalPublications: PropTypes.number,
  lastYearPublication: PropTypes.number,
  isPopup: PropTypes.bool,
};

ProductionsInfo.defaultProps = {
  totalPublications: undefined,
  lastYearPublication: undefined,
  isPopup: false,
};

export default ProductionsInfo;
