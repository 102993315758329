import React, { useState, useEffect } from "react";
import { useTheme } from "react-jss";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import queryString from "query-string";
import {
  setSelectedResult,
  fetchResults,
} from "../../../redux/result/resultActions";
import { resetFilters } from "../../../redux/resultFilter/resultFilterActions";
import { breakpoints } from "../../../constants/sizes";
import MapView from "../../../components/Landing/MapView";
import SearchContainer from "../../../components/Landing/SearchContainer";
import categoryTypes from "../../../components/Landing/SearchContainer/CategoryTabs/CategoryTypes";
import useStyles from "./styles";

/**
 *
 */
function SearchPage() {
  const location = useLocation();
  const [filter, setFilter] = useState({});
  const [mounted, setMounted] = useState(false);
  const [showMapOnMd, setShowMapOnMd] = useState(false);
  const isMd = useMediaQuery(breakpoints.md);
  const theme = useTheme();
  const classes = useStyles({ theme });
  const dispatch = useDispatch();
  const { results, selectedResult, filters, loading } = useSelector(
    (state) => state.result
  );

  useEffect(() => {
    if (!isMd) {
      if (showMapOnMd) {
        setShowMapOnMd(false);
      }
    }
  }, [isMd]);

  const handleDispatch = (newFilters, category) => {
    dispatch(
      fetchResults({
        ...newFilters,
        category: category || categoryTypes.ALL,
      })
    );

    setFilter((oldValue) => ({ ...oldValue, ...newFilters }));
  };

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const { category } = parsed;
    handleDispatch(parsed, category);
    setMounted(true);
  }, []);

  useEffect(() => {
    if (mounted) {
      const parsed = queryString.parse(location.search);
      handleDispatch(parsed, filters.category);
      dispatch(resetFilters());
    }
    return () => {
      dispatch(resetFilters());
    };
  }, [location]);

  const handleCloseResult = () => {
    dispatch(setSelectedResult(null));
  };

  const handleClickResult = (result) => {
    dispatch(setSelectedResult(result));
  };

  return (
    <MapView
      className={classes.mapView}
      filter={filter}
      handleClickResult={handleClickResult}
      handleCloseResult={handleCloseResult}
      results={results}
      selectedResult={selectedResult}
      showCategoryFilter
      loading={loading}
    >
      <SearchContainer
        query={filter.q}
        currentPage={filter.page}
        initialCategory={queryString.parse(location.search).category}
      />
    </MapView>
  );
}

export default SearchPage;
