import React, { memo } from "react";
import { useTheme } from "react-jss";
import PropTypes from "prop-types";
import CustomTypography from "../../../CustomTypography";
import useStyles from "./styles";

const ModalSection = ({ children, title }) => {
  ModalSection.propTypes = {
    children: PropTypes.arrayOf(PropTypes.shape()),
    title: PropTypes.string,
  };

  ModalSection.defaultProps = {
    children: undefined,
    title: "",
  };

  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <>
      <div className={classes.modalSection}>
        <div className={classes.title}>
          <CustomTypography
            component="span"
            variant="searchModalTitle"
            weight="regular"
            className={classes.unselected}
            color={theme.textSecondary}
          >
            {title}
          </CustomTypography>
        </div>
        <div className={classes.titleLine} />
        <div>{children}</div>
      </div>
    </>
  );
};

export default memo(ModalSection);
