import { createUseStyles } from "react-jss";
import { breakpoints } from "../../../constants/sizes";

const useStyles = createUseStyles((/* theme */) => ({
  wrapper: {},
  videoThumb: {
    width: "100%",
    maxHeight: (props) => (props.miniThumb ? 170 : 250),
    [breakpoints.smd]: {
      maxHeight: 160,
    },
  },
  bodyPreview: {
    display: "-webkit-box",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    height: "4.8rem;",
    marginBottom: 10,
    marginTop: 10,
    [breakpoints.sm]: {
      maxHeight: "3.75rem;",
    },
  },
  titlePreview: {
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  link: {
    display: "flex",
    textDecoration: "none",
    "&:hover": {},
  },
}));

export default useStyles;
