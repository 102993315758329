export const queryParams = (query) => {
  let queryParam = "";

  if (query?.q) {
    queryParam = `?q=${query?.q || ""}`;
  }

  if (query?.page) {
    queryParam = `${queryParam}&page=${query.page}`;
  }

  if (query?.limit) {
    queryParam = `${queryParam}&limit=${query.page}`;
  }

  return queryParam;
};

export const buildCooperativeUrl = (state, query) =>
  `/cooperativas/resultados/${state ? `${state}` : "Todos"}${queryParams(
    query
  )}`;
