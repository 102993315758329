import * as React from "react";

function SvgArrowDownThin(props) {
  return (
    <svg
      width="12"
      height="7"
      viewBox="0 0 12 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.5476 0.165734C10.2209 0.00239706 9.82639 0.066422 9.56813 0.32468L6.00059 3.89223L2.43304 0.324681C2.17478 0.0664229 1.78024 0.00239706 1.45357 0.165734C0.935545 0.424747 0.823508 1.11515 1.23304 1.52468L5.52918 5.82082C5.78953 6.08117 6.21164 6.08117 6.47199 5.82082L10.7681 1.52468C11.1777 1.11515 11.0656 0.424747 10.5476 0.165734Z"
        fill="#333333"
      />
    </svg>
  );
}

export default SvgArrowDownThin;
