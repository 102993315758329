import { createUseStyles } from "react-jss";
import { breakpoints } from "../../constants/sizes";

const useStyles = createUseStyles((theme) => ({
  header: {
    display: "flex",
    flexFlow: "row nowrap",
    height: 72,
    userSelect: "none",
    width: "100%",
    padding: "0px 30px 0px 30px",
    borderBottom: `1px solid ${theme.stroke}`,

    [breakpoints.md]: {
      height: 50,
      marginTop: 10,
    },
  },
  headerSmWithBackButton: {
    [breakpoints.md]: {
      marginTop: 8,
      marginBottom: 8,
    },
  },
  headerItens: {
    width: "100%",
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    justifyContent: "space-between",

    [breakpoints.md]: {
      gap: 0,
      justifyContent: "flex-end",
    },
  },
  buttonsWrapper: {
    display: "flex",
    gap: 30,
    alignItems: "center",
    [breakpoints.md]: {
      width: "100%",
      display: (props) => (props.hideLogo ? "none" : "flex"),
    },
  },
  headerItem: {
    display: "flex",
    [breakpoints.md]: {
      display: "none",
    },
  },
  actionsWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: 542,
    gap: 20,
    [breakpoints.md]: {
      gap: 0,
      width: "100%",
    },
  },
  headerSearch: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    width: "100%",
    [breakpoints.md]: {
      maxWidth: "none",
      width: "100%",
    },
  },
  headerMobile: {
    display: "none",
    [breakpoints.md]: {
      display: "flex",
      alignItems: "center",
      height: "100%",
    },
  },
  logoWrapper: {
    display: "flex",
    cursor: "pointer",
    userSelect: "none",

    "&:focus": {
      outline: "none",
    },
  },
  logo: {
    width: 104,
    [breakpoints.md]: {
      height: 50,
      width: 40,
      objectFit: "cover",
      objectPosition: 0,
    },
  },
  logoSearchEngineVariant: {
    width: 104,
    [breakpoints.md]: {
      width: 104,
      height: 32,
      objectPosition: 0,
      objectFit: "fit",
    },
  },
  hideOnSm: {
    [breakpoints.md]: {
      display: "none",
    },
  },
  link: {
    textDecoration: "none",
    position: "relative",
    whiteSpace: "nowrap",
    width: 150,
    color: theme.textSecondary,
    fontSize: 16,
  },
  arrowMenu: {
    marginLeft: 10,
  },
  menuSearchEngineVariant: {
    "& path": {
      fill: theme.textPrimary,
    },
    "& rect": {
      display: "none",
    },
  },
  headerSearchSearchEngineVariant: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    "& path": {
      fill: theme.textPrimary,
    },
    [breakpoints.md]: {
      width: "100%",
    },
  },
  buttonWrapper: {
    display: "flex",
    gap: 10,
    [breakpoints.md]: {
      display: "none",
    },
  },
  signInButton: {
    "& .MuiButton-root": {
      "&:hover": {
        backgroundColor: theme.background0,
      },
    },
  },
  signUpButtonWrapper: {
    minWidth: 155,
    "& .MuiButton-root": {
      height: 40,
      fontWeight: "bold",
    },
  },
  contactUsWrapper: {
    display: "flex",
    alignItems: "center",
    "& svg": {
      marginLeft: 15,
      marginTop: 6,
    },
  },
  whatsappWrapper: {
    "& button": {
      backgroundColor: "transparent",
      border: "none",
      cursor: "pointer",
      "&:hover": {
        "& path": {
          fill: theme.primary0,
        },
      },
    },
  },
  aboutUsButton: {
    "& .button-root": {
      filter: "none",
    },
  },
  aboutUsButtonLabel: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    minWidth: 95,
    color: theme.textSecondary,
  },
  aboutUsContent: {
    width: 600,
    borderRadius: "0px 0px 8px 8px",
    border: "none",
    marginTop: 16,
  },
  singePopperContent: {
    width: 360,
    borderRadius: "0px 0px 8px 8px",
    border: "none",
    marginTop: 16,
  },
  aboutUsButtons: {
    display: "flex",
    flexWrap: "wrap",
    rowGap: 15,
    "& .popup-button": {
      minWidth: "50%",
      display: "flex",
      flexDirection: "column",
      rowGap: 5,
    },
  },
}));

export default useStyles;
