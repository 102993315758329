import { createUseStyles } from "react-jss";
import { breakpoints } from "../../../../constants/sizes";

const useStyles = createUseStyles((theme) => ({
  customSelector: {
    "& .button-root.MuiButtonBase-root": {
      filter: "none",
      height: 62,
      borderRadius: "8px 0px 0px 8px",
      width: 200,
      [breakpoints.sm]: {
        height: 54,
      },
    },
  },
  selectPopup: {
    padding: "0px !important",
    width: "230px !important",
    "& .popup-children": {
      margin: 0,
    },
    [breakpoints.sm]: {
      width: "100% !important",
      padding: "24px !important",
      position: "fixed !important",
      top: "unset !important",
      bottom: "0px !important",
      height: "457px !important",
    },
  },
  selectOptionsWrapper: {
    [breakpoints.sm]: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      paddingTop: 15,
    },
  },
  selectorLabel: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    fontSize: 16,
    fontWeight: "bold",
    color: theme.lowDark,
    "& svg": {
      transformOrigin: "center",
    },
  },
  selectOption: {
    "&.MuiButtonBase-root": {
      fontSize: 16,
      fontWeight: 400,
      width: "100%",
      backgroundColor: "transparent",
      borderRadius: 0,
      justifyContent: "space-between",
      "&:hover": {
        background: theme.hoverBackground,
      },
    },
    "&.active": {
      backgroundColor: theme.hoverBackground,
    },
  },
  applyButton: {
    "&.MuiButtonBase-root": {
      color: theme.textTertiary,
    },
  },
}));

export default useStyles;
