import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Skeleton from "@material-ui/lab/Skeleton";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import useStyles from "./styles";

const AnalysisCardSkeleton = ({
  hideBottom,
  hideTop,
  hideCenter,
  variant,
  className,
}) => {
  const classes = useStyles();

  function CenterSkeleton() {
    switch (variant) {
      case "piechart":
        return (
          <Skeleton variant="circle" height={110} width={110}>
            <Avatar />
          </Skeleton>
        );
      case "wordcloud":
        return (
          <div className={classes.wordcloudWrapper}>
            <Skeleton width="60%">
              <Typography variant="body2">.</Typography>
            </Skeleton>
            <Skeleton width="30%">
              <Typography variant="body2">.</Typography>
            </Skeleton>
            <Skeleton width="40%">
              <Typography variant="body2">.</Typography>
            </Skeleton>
          </div>
        );
      case "totalPublication":
        return (
          <div className={classes.totalPublication}>
            <Skeleton width="40%">
              <Typography variant="h4">.</Typography>
            </Skeleton>
          </div>
        );
      default:
        return (
          <Skeleton width="40%">
            <Typography variant="h4">.</Typography>
          </Skeleton>
        );
    }
  }

  return (
    <div className={classNames([classes.content, className])}>
      {hideTop ? (
        <div />
      ) : (
        <Skeleton width="100%">
          <Typography variant="body2">.</Typography>
        </Skeleton>
      )}
      {hideCenter ? <div className={classes.hide} /> : <CenterSkeleton />}
      {hideBottom ? (
        <div className={classes.hide} />
      ) : (
        <Skeleton width="100%">
          <Typography variant="body2">.</Typography>
        </Skeleton>
      )}
    </div>
  );
};

AnalysisCardSkeleton.propTypes = {
  hideBottom: PropTypes.bool,
  hideTop: PropTypes.bool,
  hideCenter: PropTypes.bool,
  variant: PropTypes.string,
  className: PropTypes.string,
};

AnalysisCardSkeleton.defaultProps = {
  hideBottom: false,
  hideTop: false,
  hideCenter: false,
  variant: undefined,
  className: undefined,
};

export default AnalysisCardSkeleton;
