import React from "react";
import { useTheme } from "react-jss";
import PropTypes from "prop-types";
import CustomTypography from "../../CustomTypography";
import Timeline from "../../Timeline";
import useStyles from "./styles";

const TimelineCooperatives = ({ contracted }) => {
  const theme = useTheme();
  const classes = useStyles({ theme, contracted });

  return (
    <div className={classes.container}>
      <CustomTypography
        variant="smallText"
        color={theme.secondary0}
        className={classes.description}
      >
        Linha do tempo de fundação:
      </CustomTypography>
      <Timeline />
    </div>
  );
};

TimelineCooperatives.propTypes = {
  contracted: PropTypes.bool,
};
TimelineCooperatives.defaultProps = {
  contracted: false,
};

export default TimelineCooperatives;
