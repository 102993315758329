import React, { memo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useTheme } from "react-jss";
import CustomTypography from "../../CustomTypography";
import CustomTextField from "../../CustomTextField";
import useStyles from "./styles";

function Input({ label, className, ...props }) {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <>
      <CustomTypography variant="tag" color={theme.textPrimary}>
        {label}*
      </CustomTypography>
      <CustomTextField
        className={classNames([classes.textField, className])}
        {...props}
      />
    </>
  );
}

Input.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
};

Input.defaultProps = {
  label: "",
  className: undefined,
};

export default memo(Input);
