import { createUseStyles } from "react-jss";
// import { breakpoints } from "../../../constants/sizes";

const useStyles = createUseStyles((/* theme */) => ({
  marker: {
    zIndex: 0,
  },
  markerFeatured: {
    zIndex: 1,
  },
  markerDestak: {
    zIndex: 2,
  },
}));

export default useStyles;
