import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import Grid from "@material-ui/core/Grid";
import SkeletonTypography from "../SkeletonTypography";

export default function RecentlySkeleton(props) {
  return (
    <>
      <Grid container spacing={2} {...props}>
        <Grid item xs={4}>
          <Skeleton variant="rect" width="100%">
            <div style={{ paddingTop: 140 }} />
          </Skeleton>
        </Grid>
        <Grid item xs={8}>
          <SkeletonTypography variant="h3" />
          <SkeletonTypography variant="body1" />
        </Grid>
      </Grid>
    </>
  );
}
