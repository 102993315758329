import { createUseStyles } from "react-jss";
import { defaultContainer } from "../../../constants/sizes";

const useStyles = createUseStyles(() => ({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "hidden",
  },
  section: {
    position: "relative",
    width: "100%",
    maxWidth: defaultContainer.Width + defaultContainer.paddingHorizontal * 2,
    padding: `0px ${defaultContainer.paddingHorizontal}px`,
  },
}));

export default useStyles;
