import { createUseStyles } from "react-jss";
import { breakpoints, defaultContainer } from "../../constants/sizes";

const useStyles = createUseStyles((/* theme */) => ({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "hidden",
  },
  section: {
    position: "relative",
    width: "100%",
    maxWidth: defaultContainer.Width + defaultContainer.paddingHorizontal * 2,
    padding: `0px ${defaultContainer.paddingHorizontal}px`,
  },
  micorriza: {
    position: "absolute",
    height: 600,
    right: 100,
    zIndex: -1,
    userSelect: "none",
  },
  center404: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexFlow: "column",
    height: 350,
  },
  codeTypography: {
    fontSize: 80,
    marginBottom: 40,

    [breakpoints.sm]: {
      fontSize: 40,
      marginBottom: 20,
    },
  },
  landingButton: {
    width: 160,
    [breakpoints.sm]: {
      width: 80,
      marginBottom: 20,
    },
  },
}));

export default useStyles;
