import { createUseStyles } from "react-jss";
import {
  breakpoints,
  defaultContainer,
  defaultContentContainer,
} from "../../constants/sizes";

const marginTitleSubtitle = 25;

const useStyles = createUseStyles((theme) => ({
  section: {
    position: "relative",
    width: "100%",
    padding: `${defaultContainer.paddingHorizontal}px ${defaultContainer.paddingHorizontal}px`,
    paddingRight: defaultContentContainer.paddingLeft,
    paddingLeft: defaultContentContainer.paddingLeft,
    [breakpoints.md]: {
      padding: defaultContentContainer.paddingSm,
    },
  },
  ctaBackground: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    paddingTop: 100,
    paddingBottom: 80,
    background:
      "linear-gradient(rgba(232, 242, 243, 0.15), rgba(232, 242, 243, 0.82))",
    [breakpoints.md]: {
      paddingTop: 10,
      paddingBottom: 10,
    },
  },
  sectionTitle: {
    display: "flex",
    columnGap: 8,
    alignItems: "center",
    marginBottom: marginTitleSubtitle,
  },
  ctaWrapper: {
    display: "flex",

    [breakpoints.md]: {
      flexDirection: "column-reverse",
    },
  },
  ctaTexts: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",

    [breakpoints.md]: {
      width: "100%",
    },
  },
  ctaTextsTopic: {
    minHeight: 160,
  },
  ctaTextFields: {
    backgroundColor: theme.background0,
    borderRadius: "4px",
  },
  ctaTitle: {
    marginBottom: 32,
  },
  ctaButtom: {
    "&:hover": {
      "& .MuiBox-root ": {
        color: `${theme.textTertiary} !important`,
      },
    },
    "& .MuiBox-root ": {
      color: `${theme.textSecondary} !important`,
    },
  },
  ctaNewsletterBox: {
    width: "50%",
    paddingRight: 100,

    [breakpoints.md]: {
      width: "100%",
      paddingRight: 0,
    },
  },
  ctaNewsletterInputs: {
    display: "flex",
    flexDirection: "column",
    marginTop: 40,
    marginBottom: 48,

    [breakpoints.sm]: {
      marginTop: 24,
      marginBottom: 32,
    },
  },
  ctaNewsletterBottom: {
    display: "flex",
    justifyContent: "center",
  },
  icon: {
    marginBottom: 5,
  },
}));

export default useStyles;
