import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useTheme } from "react-jss";
import ReactECharts from "echarts-for-react";
import useStyles from "./styles";

/**
 *
 * @param {*} data: Array<{value: number, name: string}>
 * @returns
 */
const BarChart = ({ data, valueName, width, height, applyOpacity }) => {
  if (!data) {
    console.log("Error: data must be given to PieChart functional component.");
    return null;
  }

  const theme = useTheme();
  const classes = useStyles({ theme, height, width });

  const values = data.map((d) => d.value);
  const maxValue = Math.max(...values);
  const minValue = Math.min(...values);

  const normalizeValue = (value) => {
    if (applyOpacity)
      return (parseFloat(value) - minValue) / (maxValue - minValue);
    return 1;
  };

  const option = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    grid: {
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
    },
    yAxis: [
      {
        type: "value",
        show: false,
        splitLine: {
          // remove grid lines
          show: false,
        },
        splitArea: { show: false }, // remove the grid area
      },
    ],
    xAxis: {
      scale: true,
      show: false,
      splitLine: {
        // remove grid lines
        show: false,
      },
      splitArea: { show: false }, // remove the grid area
      data: data.map((d) => d.name),
    },
    series: [
      {
        name: valueName,
        type: "bar",
        data: data.map((d) => ({
          value: d.value,
          itemStyle: {
            color: "#5758BB",
            opacity: 0.5 + 0.5 * normalizeValue(d.value),
          },
        })),
      },
    ],
  };

  return useMemo(
    () => (
      <ReactECharts
        className={classes.barChart}
        option={option}
        style={{ height, width: "100%" }}
      />
    ),
    [data]
  );
};

BarChart.propTypes = {
  applyOpacity: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.shape()),
  width: PropTypes.number,
  height: PropTypes.number,
  valueName: PropTypes.string,
};

BarChart.defaultProps = {
  applyOpacity: false,
  data: undefined,
  width: 270,
  height: 150,
  valueName: "Value",
};

export default BarChart;
