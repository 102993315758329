import { createUseStyles } from "react-jss";
// import { breakpoints, defaultContainer } from "../../../constants/sizes";
import { hex2rgba } from "../../../../../../util/hooks";

const useStyles = createUseStyles((theme) => ({
  contentItem: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    flexDirection: "column",
    paddingBottom: 35,
  },
  checkedWraper: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: 30,
  },
  checked: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
    borderRadius: "50%",
    backgroundColor: hex2rgba(theme.success, 0.2),
    width: 124,
    height: 124,
  },
  checkIconWrapper: {
    marginTop: 10,
    "& path": {
      fill: theme.success,
    },
  },
  closeButtonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
  ctaTextFields: {
    backgroundColor: theme.background0,
    borderRadius: "4px",
    width: "100%",
  },
  buttonWrapper: {
    paddingTop: 20,
    paddingBottom: 20,
    display: "flex",
    justifyContent: "center",
  },
  alertButton: {
    width: 204,
    "& path": {
      fill: theme.background0,
    },
  },
}));

export default useStyles;
