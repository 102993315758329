import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "react-jss";
import CircularProgress from "@material-ui/core/CircularProgress";
import useStyles from "./styles";

const Spinner = ({ spinning, children }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  if (spinning) {
    return (
      <div className={classes.container}>
        <CircularProgress />
      </div>
    );
  }

  return children;
};

Spinner.propTypes = {
  spinning: PropTypes.bool,
  children: PropTypes.node,
};
Spinner.defaultProps = {
  spinning: true,
  children: undefined,
};

export default Spinner;
