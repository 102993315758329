import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useTheme } from "react-jss";
import { useTranslation } from "react-i18next";
import useStyles from "./styles";
import CustomPagination from "../../../../../CustomPagination";
import Section from "../../../Section";
import ProjectDescription from "../../../ProjectDescription";
import resultTypes from "../../../../ResultTypes";
import CustomMarker from "../../../../CustomMarker";
import SectionRow from "../../../SectionRow";
import { isEmpty } from "../../../../../../util/hooks";

const ProjectsPaginatedList = ({ items }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const itemsPerPage = 10;

  if (!items) {
    console.error("items cannot be null");
    return null;
  }

  const numberOfPages = useMemo(
    () => Math.ceil(items.length / itemsPerPage),
    [itemsPerPage]
  );

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  return (
    <>
      <div>
        <Section
          title={t("resultPage.researchProjects.title")}
          name="researchProjects"
          isEmpty={isEmpty(items)}
          emptyMessage={t("resultPage.emptyProjects")}
          loading={false}
          showViewMore={false}
        >
          {items
            .slice((page - 1) * itemsPerPage, page * itemsPerPage)
            .map((project) => (
              <SectionRow
                key={project.id}
                title={project.name}
                subtitle={
                  <ProjectDescription
                    yearStart={project.start_year}
                    yearEnd={project.end_year}
                  />
                }
                imageCell={<CustomMarker type={resultTypes.RESEARCH} />}
                placeholder={t("resultPage.infoUnavailable")}
              />
            ))}
        </Section>
      </div>

      <div className={classes.paginationWrapper}>
        <CustomPagination count={numberOfPages} onChange={handlePageChange} />
      </div>
    </>
  );
};

ProjectsPaginatedList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default ProjectsPaginatedList;
