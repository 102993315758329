import * as React from "react";

function SvgShare(props) {
  return (
    <svg
      width={24}
      height={18}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.125 4.434v-3.34c0-.217.066-.428.19-.608.123-.18.299-.32.504-.403a1.155 1.155 0 011.226.237l7.875 7.656a1.092 1.092 0 01.33.773 1.066 1.066 0 01-.33.773l-7.875 7.656a1.154 1.154 0 01-1.226.239 1.117 1.117 0 01-.505-.404 1.07 1.07 0 01-.19-.608v-3.386a13.86 13.86 0 00-.947-.032c-6.105 0-9.27 3.907-9.408 4.08a1.121 1.121 0 01-.563.384 1.151 1.151 0 01-.689-.01 1.124 1.124 0 01-.555-.397 1.073 1.073 0 01-.212-.639c0-9.979 9.097-11.682 12.375-11.971zm-.948 6.366c.727 0 1.476.05 2.221.145.27.036.519.165.698.364.18.2.279.456.279.721v1.735l5.16-5.016-5.16-5.015v1.734c0 .604-.53 1.094-1.15 1.094-1.024 0-9.128.22-10.889 7.027 1.861-1.332 4.816-2.79 8.84-2.79z"
        fill="#333"
      />
    </svg>
  );
}

export default SvgShare;
