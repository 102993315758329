import React from "react";
import { useTheme } from "react-jss";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import CustomTypography from "../../CustomTypography";
import UfamImg from "../../../assets/images/marcaufam.png";
import ueaImg from "../../../assets/images/uea.png";
import fapeamImg from "../../../assets/images/fapeam.png";
import incImg from "../../../assets/images/inc.png";

import useStyles from "./styles";

const Partners = ({ buttonTo }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { t } = useTranslation();

  return (
    <div id="partners" className={classes.fold}>
      <div className={classes.fifthFoldWrapper}>
        <CustomTypography variant="h2" className={classes.fifthFoldTitle}>
          {t("landing.fifthFold.title")}
        </CustomTypography>

        <div className={classes.partnersSection}>
          <img
            alt="ICS"
            src={UfamImg}
            style={{ gridArea: "b" }}
            className={classes.footerImage}
          />
          <img
            alt="UFAM"
            src={ueaImg}
            style={{ gridArea: "c" }}
            className={classes.footerImage}
          />
          <img
            alt="Acariquara"
            src={incImg}
            style={{ gridArea: "d" }}
            className={classes.footerImage}
          />
          <img
            alt="Geodatin"
            src={fapeamImg}
            style={{ gridArea: "e" }}
            className={classes.footerImage}
          />
        </div>

        <div className={classes.fifthFoldButton}>
          <Link to={buttonTo} className={classes.link}>
           
              <CustomTypography variant="h4" color="aliceblue">
                {t("landing.fifthFold.button")}
              </CustomTypography>
            
          </Link>
        </div>
      </div>
    </div>
  );
};

Partners.propTypes = {
  buttonTo: PropTypes.string,
};

Partners.defaultProps = {
  buttonTo: "/castanhas/resultados",
};

export default Partners;
