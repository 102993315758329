/* eslint-disable no-unused-vars */
import { createUseStyles } from "react-jss";
import { breakpoints, defaultContainer } from "../../../constants/sizes";

const useStyles = createUseStyles((theme) => ({
  articleImg: {
    width: "100%",
    marginBottom: 30,
    maxHeight: 400,
    objectFit: "cover",
    objectPosition: "center center",
  },
  articleInfoWrapper: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    marginTop: 16,
    marginBottom: 24,
  },
  articleInfo: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  moreArticleInfo: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  link: {
    display: "flex",
    textDecoration: "none",
    "&:hover": {},
  },
  section: {
    position: "relative",
    width: "100%",
    maxWidth: defaultContainer.Width + defaultContainer.paddingHorizontal * 2,
    padding: `0px ${defaultContainer.paddingHorizontal}px`,
  },
  moreArticlesBackground: {
    backgroundColor: theme.background1,
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  moreArticlesWrapper: {
    margin: "40px 0px 40px 0px",
  },
  moreArticles: {
    marginTop: 24,
    display: "grid",
    width: "100%",

    gridTemplateColumns: "auto auto auto",
    gridColumnGap: 32,
    gridRowGap: 40,
    alignItems: "stretch",

    [breakpoints.smd]: {
      gridColumnGap: 16,
    },

    [breakpoints.sm]: {
      gridTemplateColumns: "repeat(auto-fill, 100%)",
      marginTop: 16,
    },
  },
  moreArticle: {
    height: "auto",
  },

  modalImage: {
    width: "100%",
    height: 300,
    marginBottom: 10,
    marginTop: 10,
    objectFit: "cover",
    objectPosition: "center center",
    [breakpoints.smd]: {
      height: 200,
    },
    [breakpoints.sm]: {
      height: 200,
    },
  },

  videoThumb: {
    width: "100%",
    maxHeight: (props) => (props.miniThumb ? 170 : 250),
    [breakpoints.smd]: {
      maxHeight: 160,
    },
  },

  podcastWrapper: {
    "& iframe": {
      borderWidth: 0,
    },
  },
}));

export default useStyles;
