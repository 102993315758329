import { createUseStyles } from "react-jss";
import { defaultContentContainer, breakpoints } from "../../constants/sizes";

const useStyles = createUseStyles((theme) => ({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "hidden",
  },
  firstFold: {
    width: "100%",
    display: "flex",
    columnGap: 30,
    alignItems: "center",
    background:
      "linear-gradient(rgba(232, 242, 243, 0.82), rgba(232, 242, 243, 0.15))",
    paddingTop: defaultContentContainer.paddingTop,
    paddingBottom: defaultContentContainer.paddingTop,
    paddingLeft: defaultContentContainer.paddingLeft,
    paddingRight: defaultContentContainer.paddingLeft,
    "& img": {
      width: "100%",
    },
    [breakpoints.md]: {
      flexDirection: "column",
      padding: defaultContentContainer.paddingSm,
      width: "100%",
    },
  },
  secondFold: {
    background: theme.background0,
    flexDirection: "row-reverse",
    [breakpoints.md]: {
      flexDirection: "column",
      padding: defaultContentContainer.paddingSm,
      width: "100%",
    },
  },
  thirdFold: {
    width: "100%",
    background:
      "linear-gradient(rgba(232, 242, 243, 0.15), rgba(232, 242, 243, 0.82))",
  },
  sentenceWithIcon: {
    display: "flex",
    columnGap: 15,
    alignItems: "center",
    "& svg": {
      width: 30,
      height: 30,
    },
  },
  thirdFoldRow: {
    display: "grid",
    width: "100%",
    gridTemplateColumns: "50% 50%",
    [breakpoints.md]: {
      display: "flex",
      flexDirection: "column",
      rowGap: 10,
    },
  },
  sideSectionWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    rowGap: 90,
    maxWidth: 600,
    [breakpoints.md]: {
      maxWidth: "none",
      paddingTop: 20,
      paddingBottom: 20,
      rowGap: 20,
    },
  },
  sideSection: {
    width: "100%",
  },
  section: {
    width: "50%",
    [breakpoints.md]: {
      width: "100%",
      marginTop: 10,
    },
  },
}));

export default useStyles;
