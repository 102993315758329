const categoryTypes = {
  ALL: "ALL",
  RESEARCHER: "RESEARCHER",
  PUBLICATIONS: "PUBLICATIONS",
  TOPICS: "TOPICS",
  INSTITUTIONS: "INSTITUTIONS",
  COAUTORS: "COAUTORS",
  list: ["RESEARCHER", "PUBLICATIONS", "TOPICS", "INSTITUTIONS"],
};

export default categoryTypes;
