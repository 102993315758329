import React, { useEffect, useContext } from "react";
import { useTheme } from "react-jss";
import { Redirect, useLocation, useParams } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedResult } from "../../../redux/result/resultActions";
import {
  fetchInstitute,
  fetchStatistics,
} from "../../../redux/institute/instituteActions";
// import { useTranslation } from "react-i18next";
import InstituteContainer from "../../../components/Landing/ResultArea/Institute/InstituteContainer";
import useStyles from "./styles";
import MapView from "../../../components/Landing/MapView";
import AuthContext from "../../../contexts/auth";

/**
 *
 */
function InstitutePage() {
  const location = useLocation();
  const theme = useTheme();
  const classes = useStyles(theme);
  // const { t } = useTranslation();
  const { id } = useParams();
  const { selectedResult } = useSelector((state) => state.result);
  const { institute, statistics, loadingInstitute, loadingStats, error } =
    useSelector((state) => state.institute);
  const { authCxtMounted } = useContext(AuthContext);
  const dispatch = useDispatch();

  const dispatchFetchStatistics = () => {
    dispatch(fetchStatistics(id));
  };
  useEffect(() => {
    if (authCxtMounted) {
      dispatch(setSelectedResult(null));
      dispatch(fetchInstitute(id));
      dispatchFetchStatistics();
    }
  }, [location, authCxtMounted]);

  const handleCloseResult = () => {
    dispatch(setSelectedResult(null));
  };

  const handleClickResult = (result) => {
    dispatch(setSelectedResult(result));
  };

  if (error) {
    return <Redirect to="/404" />;
  }

  return (
    <>
      <MapView
        className={classes.mapView}
        handleClickResult={handleClickResult}
        handleCloseResult={handleCloseResult}
        results={statistics ? statistics.main_researchers : undefined}
        selectedResult={selectedResult}
        filter={{ q: institute ? institute.name : "" }}
        loading={loadingInstitute || loadingStats}
        hideCategoryFilter
      >
        <InstituteContainer
          institute={institute}
          statistics={statistics}
          loading={loadingInstitute}
          isLoadingAnalysis={loadingStats}
        />
      </MapView>
    </>
  );
}

export default InstitutePage;
