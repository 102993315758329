import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "react-jss";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useStyles from "./styles";
import CustomTypography from "../../CustomTypography";
import MoreArticle from "../Article/MoreArticle";
import ContentList from "../ContentList";
import ColumnistBadge from "../Columnist/ColumnistBadge";
import WriteMachine from "../../Icons/WriteMachine";
import ArticlesListSkeleton from "../../Skeleton/ArticlesListSkeleton";
import ColumnistListSkeleton from "../../Skeleton/ColumnistListSkeleton";
import { isEmpty } from "../../../util/hooks";
/**
 * This component a viewer for articles  .
 * @returns Articles viewer component
 */
export default function ArticlesViewer({
  columnists,
  title,
  breadcrumb,
  articles,
  currentColumnist,
  isLoadingArticles,
  isLoadingColumnists,
  articlesBottom,
}) {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { t } = useTranslation();

  return (
    <>
      {breadcrumb && <div>{breadcrumb}</div>}
      <div className={classes.container}>
        <div className={classes.item}>
          <CustomTypography className={classes.itemChild} variant="h3">
            {title}
          </CustomTypography>
          {articles && articles.length > 0 ? (
            <div>
              {articles.map((article) => (
                <div key={article.id} className={classes.itemChild}>
                  <MoreArticle
                    article={article}
                    columnist={
                      article.columnist.id
                        ? article.columnist
                        : currentColumnist
                    }
                  />
                </div>
              ))}
            </div>
          ) : (
            <ArticlesListSkeleton />
          )}
          <div className={classes.bottomWrapper}>{articlesBottom}</div>
          {!isLoadingArticles && isEmpty(articles) ? (
            <CustomTypography variant="body">
              {t("agency.noArticlesAvailable")}
            </CustomTypography>
          ) : null}
        </div>
        <div className={classes.item}>
          <ContentList
            leftTitleIcon={<WriteMachine />}
            grayBackground
            title={
              <CustomTypography weight="bold" variant="titleVerticalList">
                {t("agency.columnist.columnists")}
              </CustomTypography>
            }
            rightTitle={
              <Link to="/agencia/colunistas" className={classes.link}>
                <div className={classes.rightTitle}>
                  <CustomTypography variant="h4" color={theme.primary0}>
                    {t("agency.columnist.viewAll")}
                  </CustomTypography>
                </div>
              </Link>
            }
            skeleton={<ColumnistListSkeleton />}
            isLoading={isLoadingColumnists}
            isEmptyMessage={t("agency.noColumnistsFound")}
          >
            {columnists && columnists.length > 0
              ? columnists.map((columnist) => (
                  <ColumnistBadge key={columnist.id} columnist={columnist} />
                ))
              : null}
          </ContentList>
        </div>
      </div>
    </>
  );
}

ArticlesViewer.propTypes = {
  columnists: PropTypes.arrayOf(PropTypes.shape()),
  title: PropTypes.string,
  breadcrumb: PropTypes.shape(),
  currentColumnist: PropTypes.shape(),
  articles: PropTypes.arrayOf(PropTypes.shape()),
  isLoadingArticles: PropTypes.bool,
  isLoadingColumnists: PropTypes.bool,
  articlesBottom: PropTypes.shape(),
};

ArticlesViewer.defaultProps = {
  title: "",
  breadcrumb: undefined,
  currentColumnist: undefined,
  columnists: undefined,
  articles: undefined,
  isLoadingArticles: true,
  isLoadingColumnists: false,
  articlesBottom: undefined,
};
