import React, { useState, useRef, useMemo, useEffect, memo } from "react";
import { useTheme } from "react-jss";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import ReactMapGL, {
  NavigationControl,
  Source,
  Layer,
  Popup,
} from "react-map-gl";
import Location from "../../Mapbox/Location";
import { heatmapLayer } from "./mapStyle";
import { circleLayer } from "./circleLayer";
import customMarker from "../../../assets/images/custom_marker.png";
import useStyles from "./styles";

const MAP_CONFIG = {
  mapboxApiAccessToken: process.env.REACT_APP_MAPBOX_TOKEN,
};
const cooperativesMarkersId = "cooperatives-markers";

const Map = ({ bottom }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const mapRef = useRef();
  const mapContainerRef = useRef(null);
  const { cooperatives } = useSelector((state) => state.cooperative);

  const [viewport, setViewport] = useState({
    width: "100%",
    height: "100%",
    latitude: -14.235,
    longitude: -51.9253,
    zoom: 3,
  });
  const map = mapRef?.current?.getMap();
  const [selectedCooperative, setSelecetedCooperative] = useState();

  useEffect(() => {
    if (map) {
      map.loadImage(customMarker, (error, image) => {
        if (error) {
          throw error;
        }
        map.addImage("custom-marker", image);
      });
    }
  }, [map]);

  const onViewportChange = (nextViewport) => setViewport(nextViewport);

  const setLocation = (newViewport) => {
    setViewport((oldViewport) => ({ ...oldViewport, ...newViewport }));
  };

  const handleMapClick = ({ features }) => {
    const cooperative = features.find(
      (feature) => feature?.layer?.id === cooperativesMarkersId
    )?.properties;

    setSelecetedCooperative(cooperative);
  };

  const handleClosePopup = () => {
    setSelecetedCooperative(null);
  };

  const data = useMemo(
    () => ({
      type: "FeatureCollection",
      crs: {
        type: "name",
        properties: {
          name: "urn:ogc:def:crs:OGC:1.3:CRS84",
        },
      },
      features:
        cooperatives?.map((cooperative) => ({
          properties: cooperative,
          geometry: {
            type: "Point",
            coordinates: [
              parseFloat(cooperative.longitude),
              parseFloat(cooperative.latitude),
            ],
          },
        })) || [],
    }),
    [cooperatives]
  );

  return useMemo(
    () => (
      <div ref={mapContainerRef} className={classes.map}>
        <ReactMapGL
          ref={mapRef}
          {...viewport}
          {...MAP_CONFIG}
          onViewportChange={onViewportChange}
          width="100%"
          height="100%"
          onClick={handleMapClick}
        >
          <NavigationControl className={classes.navigationControl} />

          {data && (
            <Source type="geojson" data={data}>
              <Layer {...heatmapLayer} />
              <Layer {...circleLayer} id={cooperativesMarkersId} />
            </Source>
          )}

          {selectedCooperative && (
            <Popup
              latitude={parseFloat(selectedCooperative.latitude)}
              longitude={parseFloat(selectedCooperative.longitude)}
              onClose={handleClosePopup}
            >
              {selectedCooperative.name}
            </Popup>
          )}
        </ReactMapGL>
        <div className={classes.mapContent}>
          <div className={classes.bottom}>
            <Location
              lat={viewport.latitude}
              lng={viewport.longitude}
              setValues={setLocation}
            />
            <> {bottom}</>
          </div>
        </div>
      </div>
    ),
    [viewport, cooperatives, selectedCooperative]
  );
};
Map.propTypes = {
  bottom: PropTypes.node,
};
Map.defaultProps = {
  bottom: null,
};

export default memo(Map);
