import React, { useEffect } from "react";
import { useTheme } from "react-jss";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchCooperatives,
  setCooperativeProduct,
} from "../../../redux/cooperative/cooperativeActions";
import ProductsNavigator from "../../../components/Cooperatives/ProductsNavigator";
import GeneralStatistics from "../../../components/Cooperatives/GeneralStatistics";
import StateNavigator from "../../../components/Cooperatives/StateNavigator";
import SectionHeader from "../../../components/Cooperatives/Home/SectionHeader";
import SearchBar from "../../../components/Cooperatives/SearchBar";
import Spinner from "../../../components/Spinner";
import useStyles from "./styles";

function State() {
  const theme = useTheme();
  const classes = useStyles(theme);
  const dispatch = useDispatch();
  const { loadingStatistics, loadingCooperatives, statistics } = useSelector(
    (state) => state.cooperative
  );

  const handleClearSelectedProducts = () => {
    dispatch(setCooperativeProduct(null));
  };

  const handleFetchAllCooperatives = (query, itemsPerPage) => {
    dispatch(fetchCooperatives(query, itemsPerPage));
  };

  useEffect(() => {
    handleClearSelectedProducts();
  }, []);

  useEffect(() => {
    if (statistics) {
      const itemsPerPage = 50;
      handleFetchAllCooperatives({ q: "" }, itemsPerPage);
    }
  }, [statistics]);

  return (
    <div className={classes.container}>
      <SectionHeader />
      <SearchBar />
      <Spinner spinning={loadingStatistics || loadingCooperatives}>
        <ProductsNavigator />
        <GeneralStatistics />
        <StateNavigator />
      </Spinner>
    </div>
  );
}

export default State;
