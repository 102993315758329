import * as React from "react";

function SvgFacebook(props) {
  return (
    <svg
      width={22}
      height={22}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.66.5H1.34a.84.84 0 00-.84.84v19.32c0 .465.375.84.84.84h19.32a.84.84 0 00.84-.84V1.34a.84.84 0 00-.84-.84zm-2.425 6.13h-1.678c-1.315 0-1.57.624-1.57 1.543v2.024h3.14l-.41 3.168h-2.73V21.5h-3.273v-8.132H8.976v-3.171h2.738V7.86c0-2.712 1.656-4.19 4.077-4.19 1.16 0 2.155.087 2.446.126v2.832h-.002z"
        fill="#333"
      />
    </svg>
  );
}

export default SvgFacebook;
