import React from "react";
import { useTheme } from "react-jss";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import ArrowLeft from "../../../Icons/ArrowLeft";
import CustomTypography from "../../../CustomTypography";
import useStyles from "./styles";

const SectionHeader = ({ cooperativeName }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className={classes.container}>
      <IconButton onClick={history.goBack}>
        <ArrowLeft />
      </IconButton>
      <CustomTypography variant="subHeaderBody" color={theme.textSecondary}>
        {cooperativeName || t("loading")}
      </CustomTypography>
    </div>
  );
};

SectionHeader.propTypes = {
  cooperativeName: PropTypes.string,
};
SectionHeader.defaultProps = {
  cooperativeName: null,
};

export default SectionHeader;
