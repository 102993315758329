import * as React from "react";

function SvgTwitter(props) {
  return (
    <svg
      width={26}
      height={22}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M25.932 2.985c-.947.42-1.965.703-3.035.831A5.3 5.3 0 0025.22.891a10.552 10.552 0 01-3.357 1.282 5.288 5.288 0 00-9.008 4.823A15.011 15.011 0 011.961 1.472a5.284 5.284 0 00-.715 2.658 5.288 5.288 0 002.35 4.4 5.273 5.273 0 01-2.393-.662v.069a5.288 5.288 0 004.24 5.183 5.325 5.325 0 01-2.387.09 5.289 5.289 0 004.938 3.67 10.607 10.607 0 01-6.566 2.264c-.421 0-.843-.024-1.261-.073a14.95 14.95 0 008.102 2.375c9.724 0 15.04-8.055 15.04-15.04 0-.227-.005-.456-.015-.683a10.746 10.746 0 002.636-2.735l.002-.003z"
        fill="#333"
      />
    </svg>
  );
}

export default SvgTwitter;
