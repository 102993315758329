import { createUseStyles } from "react-jss";
// import { breakpoints, defaultContainer } from "../../../constants/sizes";

const useStyles = createUseStyles((theme) => ({
  root: {
    margin: "0 auto",
    padding: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: 5,
    "&.MuiPaper-root": {
      backgroundColor: (props) =>
        props.backgroundColor ? props.backgroundColor : theme.background0,
      "&.MuiPaper-outlined": {
        border: `1px solid ${theme.stroke}`,
      },
    },
  },
}));

export default useStyles;
