import { createUseStyles } from "react-jss";
import { breakpoints } from "../../../../constants/sizes";

const useStyles = createUseStyles(() => ({
  container: {
    display: "grid",
    gridTemplateColumns: "auto auto",
    gridColumnGap: 5,
    marginRight: 10,
    marginBottom: 5,
  },
  ResultArea: {
    paddingBottom: 10,
  },
  resultContent: {
    marginBottom: 5,
  },
  articleInfo: {
    wordSpacing: 12,
  },
  imageWrapper: {
    [breakpoints.sm]: {
      display: (props) => (props.hideImageOnSm ? "none" : "block"),
    },
  },
  nameWrapper: {
    display: "flex",
    [breakpoints.sm]: {
      paddingTop: 3,
    },
  },
}));

export default useStyles;
