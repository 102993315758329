import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useTheme } from "react-jss";
import CustomButton from "../../CustomButton";
import useStyles from "./styles";

const TagButton = ({ onClick, selected, children }) => {
  const theme = useTheme();
  const classes = useStyles({ theme, selected });

  return (
    <div className={classNames([classes.tagButton, "tag-button-base"])}>
      <CustomButton onClick={onClick}>{children}</CustomButton>
    </div>
  );
};

TagButton.propTypes = {
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  children: PropTypes.node,
};

TagButton.defaultProps = {
  onClick: undefined,
  selected: true,
  children: undefined,
};

export default TagButton;
