import { createUseStyles } from "react-jss";
// import { breakpoints } from "../../../constants/sizes";

const useStyles = createUseStyles((/* theme */) => ({
  wordCloudWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: (props) => props.height,
  },
  wordCloud: {
    width: "100%",
    height: "100%",
    paddingTop: 10,
  },
}));

export default useStyles;
