import { createUseStyles } from "react-jss";
// import { breakpoints } from "../../../constants/sizes";

const useStyles = createUseStyles((/* theme */) => ({
  container: {
    minWidth: 280,
  },

  closeButtonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

export default useStyles;
