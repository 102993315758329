import React from "react";
import { useTheme } from "react-jss";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import Modal from "react-modal";
import useStyles from "./styles";

Modal.setAppElement("#root");

const LoadingModal = ({ isLoading, onLoadingEnd }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Modal
      isOpen={isLoading}
      onRequestClose={onLoadingEnd}
      contentLabel="Alert Modal"
      className={classes.modal}
      overlayClassName={classes.overlay}
    >
      <div className={classes.contentWrapper}>
        <div className={classes.content}>
          <CircularProgress />
        </div>
      </div>
    </Modal>
  );
};

LoadingModal.propTypes = {
  isLoading: PropTypes.bool,
  onLoadingEnd: PropTypes.func,
};
LoadingModal.defaultProps = {
  isLoading: false,
  onLoadingEnd: undefined,
};

export default LoadingModal;
