import React from "react";
import PropTypes from "prop-types";
import resultTypes from "../ResultTypes";
import ArticleIcon from "../../../Icons/Article";
import StarIcon from "../../../Icons/Star";
import BookIcon from "../../../Icons/Book";
import InsituteIcon from "../../../Icons/Institute";
import BookChapterIcon from "../../../Icons/BookChapter";
import MicroscopeIcon from "../../../Icons/Microscope";

const ResultIcon = ({ type, ...props }) => {
  switch (type) {
    case resultTypes.RESEARCHER:
      return <StarIcon {...props} />;
    case resultTypes.ARTICLE_JOURNALS:
      return <ArticleIcon {...props} />;
    case resultTypes.BOOK_CHAPTER:
      return <BookChapterIcon {...props} />;
    case resultTypes.BOOK:
      return <BookIcon {...props} />;
    case resultTypes.INSTITUTE:
      return <InsituteIcon {...props} />;
    case resultTypes.RESEARCH:
      return <MicroscopeIcon />;
    default:
      return null;
  }
};

ResultIcon.propTypes = {
  type: PropTypes.string.isRequired,
};

export default ResultIcon;
