import React, { useState, memo } from "react";
import PropTypes from "prop-types";
import { useTheme } from "react-jss";
import Skeleton from "@material-ui/lab/Skeleton";
import CustomMarker from "../Landing/CustomMarker";
import useStyles from "./styles";

const InstituteImg = ({ url, size, smSize, type }) => {
  const theme = useTheme();
  const classes = useStyles({
    theme,
    size,
    smSize: smSize < size ? smSize : size,
  });
  const [imageLoaded, setImageLoaded] = useState(false);
  // const { t } = useTranslation();

  const onLoadImage = () => {
    setImageLoaded(true);
  };

  if (!url) {
    return <CustomMarker size={size} type={type} />;
  }

  return (
    <>
      <div className={classes.wrapper}>
        <img
          alt="foto"
          style={imageLoaded ? {} : { display: "none" }}
          src={url}
          className={classes.image}
          onLoad={onLoadImage}
        />
        {!imageLoaded && (
          <div className={classes.imageSkeleton}>
            <Skeleton variant="circle" width="100%" height="100%" />
          </div>
        )}
      </div>
    </>
  );
};

InstituteImg.propTypes = {
  url: PropTypes.string,
  size: PropTypes.number,
  smSize: PropTypes.number,
  type: PropTypes.string,
};

InstituteImg.defaultProps = {
  url: undefined,
  size: 40,
  smSize: 40,
  type: "",
};

export default memo(InstituteImg);
