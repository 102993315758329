import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import SkeletonTypography from "../SkeletonTypography";

export default function ArticletSkeleton(props) {
  return (
    <div {...props}>
      <Skeleton variant="rect" width="100%">
        <div style={{ paddingTop: 190, marginBottom: 10 }} />
      </Skeleton>
      <SkeletonTypography variant="h3" />
      <SkeletonTypography variant="body1" />
    </div>
  );
}
