export const FETCH_COOPERATIVES_STATISTICS_REQUEST =
  "FETCH_COOPERATIVES_STATISTICS_REQUEST";
export const FETCH_COOPERATIVES_STATISTICS_SUCCESS =
  "FETCH_COOPERATIVES_STATISTICS_SUCCESS";
export const FETCH_COOPERATIVES_STATISTICS_FAILURE =
  "FETCH_COOPERATIVES_STATISTICS_FAILURE";
export const FETCH_COOPERATIVES_REQUEST = "FETCH_COOPERATIVES_REQUEST";
export const FETCH_COOPERATIVES_SUCCESS = "FETCH_COOPERATIVES_SUCCESS";
export const FETCH_COOPERATIVES_FAILURE = "FETCH_COOPERATIVES_FAILURE";
export const SET_COOPERATIVE_PRODUCT = "SET_COOPERATIVE_PRODUCT";
export const FETCH_COOPERATIVE_REQUEST = "FETCH_COOPERATIVE_REQUEST";
export const FETCH_COOPERATIVE_SUCCESS = "FETCH_COOPERATIVE_SUCCESS";
export const FETCH_COOPERATIVE_FAILURE = "FETCH_COOPERATIVE_FAILURE";
export const SET_SHOW_MAP = "SET_SHOW_MAP";
