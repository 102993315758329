import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "react-jss";
import Tabs from "@material-ui/core/Tabs";
import useStyles from "./styles";

const CustomTabs = ({ value, onChange, children, ...props }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <Tabs
      value={value}
      onChange={onChange}
      variant="scrollable"
      scrollButtons="auto"
      aria-label="ant example"
      classes={{
        root: classes.tabsRoot,
        scrollButtons: classes.scrollButtons,
      }}
      {...props}
    >
      {children}
    </Tabs>
  );
};

CustomTabs.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  children: PropTypes.node,
};

CustomTabs.defaultProps = {
  value: undefined,
  onChange: undefined,
  children: undefined,
};

export default CustomTabs;
