import { createUseStyles } from "react-jss";
// import { breakpoints, defaultContainer } from "../../../constants/sizes";

const useStyles = createUseStyles((theme) => ({
  contentItem: {
    paddingBottom: 15,
  },
  closeButtonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
  ctaTextFields: {
    backgroundColor: theme.background0,
    borderRadius: "4px",
    width: "100%",
  },
  buttonWrapper: {
    paddingTop: 20,
    paddingBottom: 20,
    display: "flex",
    justifyContent: "center",
  },
  alertButton: {
    width: 204,
    "& path": {
      fill: theme.background0,
    },
  },
}));

export default useStyles;
