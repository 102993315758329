import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { easeCubic } from "d3-ease";
import { FlyToInterpolator } from "react-map-gl";
import SvgSearch from "../../Icons/Search";
import SvgTarget from "../../Icons/Target";
import useStyles from "./styles";

const Location = ({ lat, lng, setValues }) => {
  const classes = useStyles();
  const collapseRef = useRef();
  const wrapperRef = useRef();
  const [open, setOpen] = useState(true);

  const handleOpenBar = () => {
    setOpen(!open);
  };

  const handleLocation = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        setValues({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          transitionDuration: 1500,
          transitionInterpolator: new FlyToInterpolator(),
          transitionEasing: easeCubic,
          zoom: 9,
        });
      });
    }
  };

  const formatCoordinate = (coordinate) => coordinate.toFixed(2);

  return (
    <div className={classNames([classes.container, "location-wrapper"])}>
      <div
        role="button"
        className={classes.locationButton}
        onClick={handleOpenBar}
        onKeyDown={() => {}}
        style={{ borderRadius: open ? "8px 0px 0px 8px" : 8 }}
        tabIndex={-1}
      >
        <SvgSearch />
      </div>
      <div
        ref={collapseRef}
        style={{ width: open ? 150 : 0 }}
        className={classes.collapse}
      >
        <div ref={wrapperRef} className={classes.wrapper}>
          <div className={classes.values}>{`${formatCoordinate(
            lat
          )}, ${formatCoordinate(lng)}`}</div>
          <div
            role="button"
            className={classNames(classes.locationButton, classes.targetButton)}
            onClick={handleLocation}
            onKeyDown={handleLocation}
            style={{ borderRadius: open ? "0px 8px 8px 0px" : 4 }}
            tabIndex={-1}
          >
            <SvgTarget style={{ transform: "scale(1.1)" }} />
          </div>
        </div>
      </div>
    </div>
  );
};

Location.propTypes = {
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  setValues: PropTypes.func.isRequired,
};

export default Location;
