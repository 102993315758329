import React from "react";
import { useTheme } from "react-jss";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import CustomTypography from "../../CustomTypography";
import ArrowRight from "../../Icons/ArrowRight";
import useStyles from "./styles";

const StateNavigator = () => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { statistics } = useSelector((state) => state.cooperative);

  const states = statistics?.cooperatives_states;

  return (
    <div className={classes.container}>
      <CustomTypography color={theme.textSecondary} variant="bodyLargeRegular">
        Cooperativas Por Estado
      </CustomTypography>
      <div className={classes.statesListWrapper}>
        {states?.map(({ state, count }) => (
          <div key={state} className="list-item">
            <div className={classes.itemContent}>
              <div>
                <CustomTypography
                  variant="body"
                  weight="bold"
                  color={theme.primary0}
                >
                  {state}
                </CustomTypography>
                <CustomTypography
                  variant="smallText"
                  color={theme.secondary0}
                >{`${count} cooperativas`}</CustomTypography>
              </div>
              <Link to={`/cooperativas/resultados/${state}`}>
                <IconButton className={classes.button}>
                  <ArrowRight />
                </IconButton>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StateNavigator;
