import { createUseStyles } from "react-jss";
import { breakpoints } from "../../constants/sizes";

const useStyles = createUseStyles((theme) => ({
  container: {},
  filterButton: {
    "&.MuiButton-root": {
      minWidth: 110,
      minHeight: 40,
      height: "100%",
      backgroundColor: theme.background0,
      display: "flex",
      alignItems: "center",
      borderRadius: 10,
      filter: `drop-shadow(2px 5px 3px ${theme.shadow3})`,
      padding: "0px 8px 0px 8px",
      "&:hover": {
        backgroundColor: theme.background0,
      },
      "& path": {
        fill: theme.textPrimary,
      },
      [breakpoints.sm]: {},
    },
  },
  paper: {
    position: "relative",
    marginTop: 8,
    backgroundColor: theme.background0,
    zIndex: 5,
    width: 276,
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 19,
    paddingBottom: 19,
    border: `1px solid ${theme.stroke}`,
    borderRadius: 8,
    [breakpoints.sm]: {
      position: "absolute",
      transform: "none !important",
      width: "100%",
      top: (props) => (props.unsetTop ? "unset" : "120px !important"),
      height: "calc(100% - 127px)",
      bottom: -1,
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  },
  titleWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    "&.MuiButton-root": {
      minWidth: 0,
      padding: 0,
    },
  },
  formGroup: {
    marginTop: 10,
    marginBottom: 10,
    height: "100%",
    "& .MuiTypography-body1": {
      color: theme.textPrimary,
      fontSize: 16,
    },
  },
}));

export default useStyles;
