import React from "react";
import { useTheme } from "react-jss";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import useStyles from "./styles";
import Header from "../../components/Header";
import micorriza404 from "../../assets/images/404rhisa.svg";
import CustomTypography from "../../components/CustomTypography";
import CustomButton from "../../components/CustomButton";

export default function NotFound() {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const history = useHistory();
  const { t } = useTranslation();

  const handleButtonClick = () => {
    history.push("/");
  };

  return (
    <div className={classes.container}>
      <Header />

      <div className={classes.section}>
        <img alt="micorriza" src={micorriza404} className={classes.micorriza} />
      </div>
      <div className={classes.section}>
        <div className={classes.center404}>
          <CustomTypography className={classes.codeTypography} variant="h1">
            404
          </CustomTypography>
          <CustomTypography
            weight="regular"
            variant="body"
            color={theme.textSecondary}
            style={{ marginBottom: 40 }}
          >
            {t("notFound.message")}
          </CustomTypography>
          <CustomButton
            variant="contained"
            backgroundColor={theme.primary2}
            className={classes.landingButton}
            onClick={() => handleButtonClick()}
          >
            <CustomTypography color={theme.background0} weight="bold">
              {t("notFound.redirectButton")}
            </CustomTypography>
          </CustomButton>
        </div>
      </div>
    </div>
  );
}
