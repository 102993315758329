import React from "react";
import classNames from "classnames";
import { useTheme } from "react-jss";
import { useTranslation } from "react-i18next";
import CustomLink from "../../CustomLink";
import CustomTypography from "../../CustomTypography";
import useStyles from "./styles";

const Footer = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();

  return (
    <div className={classNames([classes.footer, "footer-root"])}>
      <CustomTypography color={theme.secondary0} variant="body" weight="bold">
        {t("header.rhisaNet")}
      </CustomTypography>
      <CustomLink to="/">
        <CustomTypography color={theme.primary0} variant="body" weight="bold">
          {t("footer.privacy")}
        </CustomTypography>
      </CustomLink>
      <CustomLink to="/">
        <CustomTypography color={theme.primary0} variant="body" weight="bold">
          {t("footer.useTerms")}
        </CustomTypography>
      </CustomLink>
    </div>
  );
};

export default Footer;
