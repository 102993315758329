import React, { useEffect } from "react";
import { useTheme } from "react-jss";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router";
import { fetchCooperative } from "../../../redux/cooperative/cooperativeActions";
import SectionHeader from "../../../components/Cooperatives/Cooperative/SectionHeader";
import Description from "../../../components/Cooperatives/Cooperative/Description";
import Spinner from "../../../components/Spinner";
import useStyles from "./styles";

const Cooperative = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const dispatch = useDispatch();
  const { id } = useParams();
  const { loadingCooperative, cooperative } = useSelector(
    (state) => state.cooperative
  );
  const handleFetchCooperative = () => {
    dispatch(fetchCooperative(id));
  };

  useEffect(() => {
    handleFetchCooperative();
  }, [id]);

  return (
    <div className={classes.container}>
      <SectionHeader cooperativeName={cooperative?.name} />
      <Spinner spinning={loadingCooperative}>
        <Description cooperative={cooperative} />
      </Spinner>
    </div>
  );
};

export default Cooperative;
