import { createUseStyles } from "react-jss";
import { breakpoints, sideBar } from "../../constants/sizes";

const useStyles = createUseStyles((theme) => ({
  container: {
    height: "100%",
    width: sideBar.Width,
    display: "flex",
    transition: "max-width 0.15s ease-out",
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.25)",
    [breakpoints.sm]: {
      width: "100%",
    },
  },
  toogleButton: {
    marginTop: 20,
    marginRight: -15,
    marginLeft: -21,
    "& .MuiButton-root": {
      zIndex: 2,
      padding: 0,
      minWidth: 0,
      width: 36,
      height: 36,
      borderRadius: 24,
      border: `1px solid ${theme.stroke}`,
      backgroundColor: theme.background0,

      "& svg": {
        transform: (props) =>
          props.isHiding ? "rotate(-180deg)" : "rotate(0deg)",

        transition: "transform 0.15s ease-out",
      },

      "&:hover": {
        "& path": {
          fill: theme.background0,
        },
      },
    },
  },
  content: {
    width: "100%",
    "& div": {
      display: (props) => (props.isHiding ? "none" : null),
    },
  },
}));

export default useStyles;
