import resultTypes from "../../components/Landing/ResultTypes";
import {
  FETCH_INSTITUTE_REQUEST,
  FETCH_INSTITUTE_SUCCESS,
  FETCH_INSTITUTE_FAILURE,
  FETCH_INSTITUTE_STATS_REQUEST,
  FETCH_INSTITUTE_STATS_SUCCESS,
  FETCH_INSTITUTE_STATS_FAILURE,
} from "./instituteTypes";

const initialState = {
  institute: undefined,
  statistics: undefined,
  loadingInstitute: false,
  loadingStats: false,
  error: undefined,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_INSTITUTE_REQUEST:
      return {
        ...state,
        institute: undefined,
        loadingInstitute: true,
        error: undefined,
      };
    case FETCH_INSTITUTE_SUCCESS:
      return {
        ...state,
        institute: action.payload,
        loadingInstitute: false,
      };
    case FETCH_INSTITUTE_FAILURE:
      return {
        ...state,
        loadingInstitute: false,
        error: action.payload,
      };
    case FETCH_INSTITUTE_STATS_REQUEST:
      return {
        ...state,
        statistics: undefined,
        loadingStats: true,
        error: undefined,
      };
    case FETCH_INSTITUTE_STATS_SUCCESS:
      return {
        ...state,
        statistics: {
          ...action.payload,
          main_researchers: action.payload.main_researchers.map(
            (researcher) => ({ ...researcher, type: resultTypes.RESEARCHER })
          ),
        },
        loadingStats: false,
      };
    case FETCH_INSTITUTE_STATS_FAILURE:
      return {
        ...state,
        loadingStats: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
export default reducer;
