import { createUseStyles } from "react-jss";

const useStyles = createUseStyles(() => ({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "hidden",
  },
}));

export default useStyles;
