import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { useTheme } from "react-jss";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import PropTypes from "prop-types";
import DateSvg from "../Icons/Date";
import CustomDatePicker from "../CustomDatePicker";
import DateRangePickerPopper from "./DateRangePickerPopper";
import useStyles from "./styles";

function DateRangeFilter({
  startDate,
  endDate,
  onStartChange,
  onEndChange,
  className,
}) {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { t } = useTranslation();
  const isSm = useMediaQuery("(max-width: 600px)");
  const [anchorEl, setAnchorEl] = useState(null);
  const modalIsOpen = Boolean(anchorEl);

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!isSm) handleClickAway();
  }, [isSm]);

  const onStartDateChange = (date) => {
    if (onStartChange) onStartChange(date);
  };

  const onEndDateChange = (date) => {
    if (onEndChange) onEndChange(date);
  };

  // Set anchor elent opening the portal
  const handleDateClick = (currentTag) => {
    setAnchorEl(currentTag);
  };

  return (
    <>
      <>
        <div className={classNames(classes.container, className)}>
          <div style={{ display: "flex", minWidth: 100 }}>
            <div
              className={classNames([classes.dateIcon, "date-icon-wrapper"])}
            >
              <DateSvg />
            </div>
            <div className={classes.buttonWrapper}>
              <CustomDatePicker
                onApplyClick={onStartDateChange}
                date={startDate}
                maxYear={endDate.getFullYear()}
                title={`${t("date.period.selectStart")}`}
                hideDefaultPopper={isSm} // Hide default popup to show a custom popup in small screens
                onLabelClick={handleDateClick} // Only disptach if hideDefaultPopper is true
              />
            </div>
          </div>

          <div className={classes.separator} />
          <div className={classes.buttonWrapper}>
            <CustomDatePicker
              onApplyClick={onEndDateChange}
              date={endDate}
              minYear={startDate.getFullYear()}
              title={`${t("date.period.selectEnd")}`}
              hideDefaultPopper={isSm} // Hide default popup to show a custom popup in small screens
              onLabelClick={handleDateClick} // Only disptach if hideDefaultPopper is true
            />
          </div>
        </div>
      </>
      <DateRangePickerPopper
        anchorEl={anchorEl}
        startDate={startDate}
        endDate={endDate}
        onClickAway={handleClickAway}
        onEndChange={onEndChange}
        onStartChange={onStartChange}
        open={modalIsOpen}
      />
    </>
  );
}

DateRangeFilter.propTypes = {
  className: PropTypes.string,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  onStartChange: PropTypes.func,
  onEndChange: PropTypes.func,
};

DateRangeFilter.defaultProps = {
  className: "",
  startDate: new Date("1980/02/01"),
  endDate: new Date(),
  onStartChange: undefined,
  onEndChange: undefined,
};

export default DateRangeFilter;
