import React from "react";
import { useTheme } from "react-jss";
import { Link } from "react-router-dom";
import useStyles from "./styles";
import CustomTypography from "../CustomTypography";
import SvgFacebook from "../Icons/Facebook";
import SvgInstagram from "../Icons/Instagram";
import SvgLinkedin from "../Icons/Linkedin";
import SvgTwitter from "../Icons/Twitter";
import CustomLink from "../CustomLink";

/**
 * This component represents a Footer.
 */
export default function Footer() {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <div className={classes.footerBackground}>
      <div className={classes.footer}>
        <div className={classes.bottomButtons}>
          <CustomTypography
            variant="bodyLargeRegular"
            color={theme.textSecondary}
          >
            Todos os Direitos Reservados
          </CustomTypography>
          <CustomLink to="/termos-uso">
            <CustomTypography variant="noticeTitle" color={theme.textSecondary}>
              Termos de Uso e
            </CustomTypography>
          </CustomLink>
          <CustomLink to="/termos-uso">
            <CustomTypography variant="noticeTitle" color={theme.textSecondary}>
              Política de Privacidade
            </CustomTypography>
          </CustomLink>
        </div>
        <div className={classes.sn}>
          <span className={classes.snIcons}>
            <Link
              to={{ pathname: "https://facebook.com/pg/rederhisa" }}
              target="_blank"
            >
              <SvgFacebook className={classes.icon} />
            </Link>
            <Link
              to={{ pathname: "https://instagram.com/rederhisa/" }}
              target="_blank"
            >
              <SvgInstagram
                className={`${classes.icon} ${classes.instagram}`}
              />
            </Link>
            {false && (
              <Link
                to={{ pathname: "https://facebook.com/pg/rederhisa" }}
                target="_blank"
              >
                <SvgLinkedin className={classes.icon} />
              </Link>
            )}
            <Link
              to={{ pathname: "https://twitter.com/rederhisa" }}
              target="_blank"
            >
              <SvgTwitter className={classes.icon} />
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
}
