import { createUseStyles } from "react-jss";
import { breakpoints } from "../../../constants/sizes";

const useStyles = createUseStyles((theme) => ({
  wrapper: {
    display: "flex",
    alignItems: "center",

    "& :focus-visible": {
      outline: "none",
    },

    [breakpoints.md]: {
      width: "100%",
      flexDirection: "row-reverse",
    },
  },
  wrapperSearchEngine: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    border: `1px solid ${theme.stroke}`,
    borderRadius: 4,
    height: 40,
    paddingLeft: 10,
    paddingRight: 10,

    "& :focus-visible": {
      outline: "none",
    },

    [breakpoints.md]: {
      width: "100%",
      border: "none",
      padding: 0,
    },
  },
  wrapperOnSearching: {
    display: "flex",
    alignItems: "center",

    "& :focus-visible": {
      outline: "none",
    },

    [breakpoints.md]: {
      width: "100%",
    },
  },
  searchButton: {
    width: 25,
    height: 25,
    display: "flex",
    marginRight: 12,
    cursor: "pointer",
    [breakpoints.md]: {
      marginLeft: 12,
    },
  },
  closeButton: {
    display: "none",
    [breakpoints.md]: {
      marginLeft: 12,
      display: "flex",
      marginRight: 12,
      cursor: "pointer",
    },
  },
  hideOnSm: {
    [breakpoints.md]: {
      display: "none",
    },
  },
  svgSearch: {
    "& path": {
      fill: theme.textPrimary,
    },
  },
  svgSearchSearchEngine: {
    "& path": {
      transformOrigin: "center",
      transform: "scale(0.8)",
    },
  },
  searchTextField: {
    width: "100%",

    "& .MuiInput-underline::before": { display: "none" },
    "& .MuiInput-underline::after": { display: "none" },
  },
  modal: {
    backgroundColor: "white",
    width: 478,
    marginLeft: -35,
    maxHeight: "75%",
    overflow: "auto",
    overflowX: "hidden",
    [breakpoints.sm]: {
      width: "98%",
      marginLeft: 0,
    },
    border: `solid 0.5px ${theme.stroke}`,
    borderRadius: 5,
    padding: 24,
    zIndex: 10,
  },
  arrowRight: {
    width: 20,
    height: 20,
    "& svg": {
      transform: "rotate(180deg) scale(1.3)",
    },
  },
}));

export default useStyles;
