import { createUseStyles } from "react-jss";
import { breakpoints } from "../../../constants/sizes";

const useStyles = createUseStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 15,
  },
  paper: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    backgroundColor: theme.background0,
    border: `0.5px solid ${theme.stroke}`,
    borderRadius: 8,
    boxShadow: theme.background0,
    maxWidth: 500,
    outline: 0,
    padding: "48px 32px 32px 40px",

    [breakpoints.sm]: {
      padding: "48px 24px 24px 40px",
    },
  },
  title: {
    marginBottom: 16,
    marginTop: 40,
  },
  doneImage: {
    height: 88,
    userSelect: "none",
  },
  description: {
    marginBottom: 48,

    [breakpoints.sm]: {
      marginBottom: 27,
    },
  },
  closeImage: {
    position: "absolute",
    height: 24,
    userSelect: "none",
    right: 16,
    top: 16,
    zIndex: 0,
    cursor: "pointer",

    "&::before": {
      content: "''",
      position: "absolute",
      left: "50%",
      right: "50%",
      transform: "translateX(-50%) scale(1.4)",
      zIndex: -1,
      height: "100%",
      width: "100%",
      borderRadius: "100%",
      transition: "all 250ms ease",
    },
    "&:focus": {
      outline: 0,
    },
    "&:active": {
      "&::before": {
        backgroundColor: theme.shadow0,
      },
    },
    "&:hover": {
      "&::before": {
        backgroundColor: `${theme.textSecondary}3f`,
      },
    },
  },
  href: {
    textDecoration: "none",
  },
}));

export default useStyles;
