import React from "react";
import { useTheme } from "react-jss";
import PropTypes from "prop-types";
import ResultIcon from "../ResultIcon";
import useStyles from "./styles";
import { typeColor } from "../ResultTypes";

const CustomMarker = ({ type, onClick, size, isFeatured, ...props }) => {
  const theme = useTheme();
  const color = typeColor(type, isFeatured);
  const classes = useStyles({ theme, color, size });

  return (
    <button
      type="button"
      className={onClick ? classes.button : classes.icon}
      onClick={onClick}
      {...props}
    >
      <div className={classes.iconWrapper}>
        <ResultIcon className={classes.resultIcon} type={type} />
      </div>
    </button>
  );
};

CustomMarker.propTypes = {
  onClick: PropTypes.func,
  type: PropTypes.string,
  size: PropTypes.number,
  isFeatured: PropTypes.bool,
};

CustomMarker.defaultProps = {
  onClick: undefined,
  type: "",
  size: 42,
  isFeatured: false,
};

export default CustomMarker;
