import React, { useMemo, useRef, memo } from "react";
import PropTypes from "prop-types";
import { useTheme } from "react-jss";
import Highcharts from "highcharts";
import wordcloud from "highcharts/modules/wordcloud";
import HighchartsReact from "highcharts-react-official";
import { useSize } from "react-hook-size";
import { isEmpty } from "../../../util/hooks";
import EmptyMessage from "../EmptyMessage";
import useStyles from "./styles";

wordcloud(Highcharts);
Highcharts.setOptions({
  colors: ["#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd", "#8c564b"],
});

/**
 *
 * @param {*} word: Array<{value: number, text: string}>
 * @returns
 */
const WordCloud = ({ words, height, seriesName, emptyMessage }) => {
  if (!words) {
    console.warn("Words must be given to WordCloud functional component.");
    return null;
  }

  const theme = useTheme();
  const classes = useStyles({ theme, height });
  const wordcloudWrapperRef = useRef(null);
  const { width, height: h } = useSize(wordcloudWrapperRef);

  const options = {
    title: {
      text: null,
    },
    chart: {
      height,
      width,
    },
    series: [
      {
        type: "wordcloud",
        spiral: "archimedean",
        name: seriesName,
        data: words,
        minFontSize: 13,
        maxFontSize: 30,
      },
    ],
  };

  return useMemo(
    () => (
      <div className={classes.wordCloudWrapper}>
        <div ref={wordcloudWrapperRef} className={classes.wordCloud}>
          {isEmpty(words) ? (
            <EmptyMessage emptyMessage={emptyMessage} />
          ) : (
            <HighchartsReact highcharts={Highcharts} options={options} />
          )}
        </div>
      </div>
    ),
    [words, width, h]
  );
};

WordCloud.propTypes = {
  words: PropTypes.arrayOf(PropTypes.shape()),
  height: PropTypes.number,
  seriesName: PropTypes.string,
  emptyMessage: PropTypes.string,
};

WordCloud.defaultProps = {
  words: undefined,
  height: 225,
  seriesName: "Mentions",
  emptyMessage: "Empty content",
};

export default memo(WordCloud);
