/* eslint-disable no-unused-vars */
import { createUseStyles } from "react-jss";
import { breakpoints } from "../../../../constants/sizes";

const useStyles = createUseStyles((/* theme */) => ({
  wrapper: {},
  highlightImg: {
    width: "100%",
    maxHeight: 250,
    objectFit: "cover",
    objectPosition: "center center",
  },
  articleInfo: {
    display: "flex",
    alignItems: "center",
    marginTop: 24,
    marginBottom: 16,
  },
  bodyPreview: {
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    height: "2.9rem;",
    marginBottom: 10,
  },
  link: {
    display: "flex",
    textDecoration: "none",
    "&:hover": {},
  },
}));

export default useStyles;
