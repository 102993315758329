import React, { createContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import api from "../services/api";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [token, setToken] = useState(undefined);
  /**
   * State created to prevent requests before bearer token set
   * in api
   */
  const [authCxtMounted, setAuthCxtMounted] = useState(false);
  const [interceptor, setInterceptor] = useState(undefined);
  const [successModalIsOpen, setSuccessModalIsOpen] = useState(false);
  const location = useLocation();

  function storeToken(newToken) {
    localStorage.setItem("@rhisa/token", newToken);
  }

  function storeUser(newUser) {
    localStorage.setItem("@rhisa/user", JSON.stringify(newUser));
  }

  function setApiToken(apiToken) {
    const apiInterceptor = api.interceptors.request.use(
      (config) => {
        config.headers.Authorization = `bearer ${apiToken}`;
        return config;
      },
      (e) => {
        Promise.reject(e);
      }
    );

    setInterceptor(apiInterceptor);
    setAuthCxtMounted(true);
  }

  function storeData(newToken, newUser) {
    if (!newUser || !newToken) return;
    storeUser(newUser);
    storeToken(newToken);
    setApiToken(newToken);
    setUser(newUser);
    setToken(newToken);
  }

  function clearStorage() {
    localStorage.removeItem("@rhisa/token");
    localStorage.removeItem("@rhisa/user");
    api.interceptors.request.eject(interceptor);
  }

  useEffect(() => {
    const storagedToken = localStorage.getItem("@rhisa/token");
    const retrievedUser = localStorage.getItem("@rhisa/user");

    if (!storagedToken || !retrievedUser) {
      clearStorage();
      setAuthCxtMounted(true);
      return;
    }

    setToken(storagedToken);

    setApiToken(storagedToken);

    const storagedUser = JSON.parse(retrievedUser);
    setUser(storagedUser);
  }, []);

  useEffect(() => {
    if (location.pathname === "/cadastro") {
      setError(undefined);
    }
  }, [location]);

  async function signIn(userCredentials) {
    const { email, password } = userCredentials;
    setLoading(true);
    setError(undefined);
    try {
      const response = await api.post("/login", { email, password });
      setSuccessModalIsOpen(true);
      storeData(response.data.token, response.data.user);
    } catch ({ response }) {
      setError(response.data.error);
    }
    setLoading(false);
  }

  async function signUp(userCredentials) {
    const { name, email, password, phone, lattesId } = userCredentials;
    setLoading(true);
    setError(undefined);
    try {
      const response = await api.post("/register", {
        name,
        email,
        password,
        phone,
        lattesId,
      });
      setSuccessModalIsOpen(true);
      storeData(response.data.token, response.data.user);
    } catch ({ response }) {
      setError(response.data.error || response.data);
    }
    setLoading(false);
  }

  async function signOut() {
    setUser(undefined);
    setError(undefined);
    clearStorage();
  }

  function closeSuccesModal() {
    setSuccessModalIsOpen(false);
  }

  function clearErrors() {
    setError(undefined);
  }

  const isAuthenticated = user && token;

  const contextValues = {
    user,
    token,
    signIn,
    signOut,
    error,
    loading,
    isAuthenticated,
    signUp,
    authCxtMounted,
    successModalIsOpen,
    closeSuccesModal,
    clearErrors,
  };

  return (
    <AuthContext.Provider value={contextValues}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node,
};

AuthProvider.defaultProps = {
  children: undefined,
};

export default AuthContext;
