import React from "react";
import { useTheme } from "react-jss";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { setCooperativeProduct } from "../../../redux/cooperative/cooperativeActions";
import TagButton from "../../Buttons/TagButton";
import HorizontalScrollbar from "../../HorizontalScrollbar";
import { buildCooperativeUrl } from "../../../util/cooperative";
import useStyles from "./styles";

const ProductsNavigator = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const history = useHistory();
  const { stateName } = useParams();
  const dispatch = useDispatch();
  const { statistics, selectedProduct } = useSelector(
    (state) => state.cooperative
  );

  const products = statistics?.cooperatives_products;

  const handleClickProduct = (product) => {
    dispatch(setCooperativeProduct(product));
    history.push(buildCooperativeUrl(stateName || "Todos"));
  };

  return (
    <div className={classes.container}>
      <HorizontalScrollbar>
        {products?.map(({ product, count }) => (
          <TagButton
            key={product}
            selected={product === selectedProduct}
            onClick={() => handleClickProduct(product)}
          >
            {`${product} (${count})`}
          </TagButton>
        ))}
      </HorizontalScrollbar>
    </div>
  );
};

export default ProductsNavigator;
