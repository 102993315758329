import { createUseStyles } from "react-jss";
import { defaultContainer } from "../../../constants/sizes";

const useStyles = createUseStyles((/* theme */) => ({
  section: {
    position: "relative",
    width: "100%",
    maxWidth: defaultContainer.Width + defaultContainer.paddingHorizontal * 2,
    padding: `0px ${defaultContainer.paddingHorizontal}px`,
  },
  container: {
    width: "100%",
  },
  bannerImg: {
    marginTop: 20,
    marginBottom: 20,
    width: "100%",
    maxHeight: 200,
    objectFit: "cover",
    objectPosition: "center center",
  },
  emptyBanner: {
    marginTop: 20,
    marginBottom: 20,
    backgroundColor: "#4385F6",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default useStyles;
