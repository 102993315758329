import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useTheme } from "react-jss";
import ReactECharts from "echarts-for-react";
import { isEmpty } from "../../../util/hooks";
import useStyles from "./styles";
import EmptyMessage from "../EmptyMessage";

/**
 *
 * @param {*} data: Array<{value: number, name: string}>
 * @returns
 */
const PieChart = ({ title, valueName, data, width, height, emptyMessage }) => {
  if (!data) {
    console.log("Error: data must be given to PieChart functional component.");
    return null;
  }

  const singleLine = data.length < 5;
  const theme = useTheme();
  const classes = useStyles({ theme, height, width, singleLine });

  const option = {
    title: {
      text: title,
      x: "left",
      textStyle: {
        fontSize: 12,
        color: theme.textPrimary,
      },
    },
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b} : {c} ({d}%)",
    },
    legend: {
      bottom: 0,
      left: "center",
      icon: "circle",
      data: data.map((d) => d.name),
    },

    series: [
      {
        name: valueName,
        type: "pie",
        radius: "45%",
        center: ["50%", `${singleLine ? "50%" : "38%"}`],
        label: {
          show: false,
        },
        data,
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };
  return useMemo(
    () => (
      <div className={classes.container}>
        {isEmpty(data) ? (
          <EmptyMessage emptyMessage={emptyMessage} />
        ) : (
          <ReactECharts
            option={option}
            style={{ height: "100%", width: "100%" }}
          />
        )}
      </div>
    ),
    [data]
  );
};

PieChart.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape()),
  width: PropTypes.number,
  height: PropTypes.number,
  valueName: PropTypes.string,
  emptyMessage: PropTypes.string,
};

PieChart.defaultProps = {
  title: "Chart title",
  data: undefined,
  width: 270,
  height: 241,
  valueName: "Value",
  emptyMessage: "Empty content",
};

export default PieChart;
