import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTheme } from "react-jss";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useSelector, useDispatch } from "react-redux";
import { setShowMap } from "../../../redux/cooperative/cooperativeActions";
import Sidebar from "../../Sidebar";
import ViewOnMapButton from "../../Mapbox/ViewOnMapButton";
import Map from "../Map";
import TimelineCooperatives from "../TimelineCooperatives";
import { breakpoints } from "../../../constants/sizes";
import useStyles from "./styles";

const MapViewer = ({ children }) => {
  const theme = useTheme();
  const { showMap } = useSelector((state) => state.cooperative);
  const classes = useStyles({ theme, showMap });
  const [isHidingSidebar, setisHidingSidebar] = useState(true);
  const isMd = useMediaQuery(breakpoints.md);
  const dispatch = useDispatch();

  const handleToogleVisibility = (isHiding) => {
    setisHidingSidebar(isHiding);
  };

  const dispatchSetShowMap = (show) => {
    dispatch(setShowMap(show));
  };

  const handleShowMap = () => {
    dispatchSetShowMap(true);
  };

  useEffect(() => {
    if (!isMd) {
      dispatchSetShowMap(false);
    }
  }, [isMd]);

  return (
    <div className={classes.barContainer}>
      <Sidebar onToggleVisibility={handleToogleVisibility}>
        <div className={classes.content}>{children}</div>
        <div className={classes.mapButtonWrapper}>
          <ViewOnMapButton onClick={handleShowMap} />
        </div>
      </Sidebar>
      <div className={classes.mapWrapper}>
        <Map bottom={<TimelineCooperatives contracted={!isHidingSidebar} />} />
      </div>
    </div>
  );
};

MapViewer.propTypes = {
  children: PropTypes.node,
};

MapViewer.defaultProps = {
  children: undefined,
};

export default MapViewer;
