import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => ({
  buttonWrapper: {
    "& button ": {
      backgroundColor: theme.background0,
      border: "none",
      cursor: "pointer",
      "&:hover": {},
    },
    cursor: "pointer",
    "&:hover": {
      cursor: "pointer",
    },
    "& :focus-visible": {
      outline: "none",
    },
  },
  headerButton: {
    textDecoration: "none",
    position: "relative",
    whiteSpace: "nowrap",
    "& :focus-visible": {
      outline: "none",
    },
    "& path": {
      fill: (props) =>
        props.isSelected ? props.theme.primary0 : props.theme.textSecondary,
    },
    display: "flex",
    alignItems: "center",
    columnGap: 10,
    height: 40,
    "& .leftIcon": {
      height: "100%",
      display: "flex",
      alignItems: "center",
    },
  },
}));

export default useStyles;
