import { createUseStyles } from "react-jss";
import { defaultContainer, breakpoints } from "../../constants/sizes";
import micorrizaLarge from "../../assets/images/micorriza_large.svg";

const useStyles = createUseStyles((/* theme */) => ({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  content: {
    width: "100%",
    maxWidth: defaultContainer.Width + defaultContainer.paddingHorizontal * 2,
    minHeight: "100vh",
    padding: `10px ${defaultContainer.paddingHorizontal * 4}px`,
    backgroundImage: `url(${micorrizaLarge})`,
    [breakpoints.sm]: {
      padding: `10px 5px`,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },

  contentBox: {
    background: "#fff",
    width: "100%",
    height: "auto",
    border: "0.5px solid #BFD4D7",
    borderRadius: "6px",
    padding: "32px",
    [breakpoints.sm]: {
      width: "95%",
      padding: "16px",
    },
  },

  title: {
    marginBottom: 40,
    marginLeft: 0,
    [breakpoints.sm]: {
      marginBottom: 20,
    },
  },

  border: {
    border: "1px solid black",
    borderCollapse: "collapse",
    padding: "10px",
  },
}));

export default useStyles;
