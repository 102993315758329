import { createUseStyles } from "react-jss";
import { sideBar } from "../../../constants/sizes";

const useStyles = createUseStyles(() => ({
  container: {
    paddingLeft: sideBar.paddingHorizontal,
    paddingRight: sideBar.paddingHorizontal,
  },
}));

export default useStyles;
