import * as React from "react";

function Book(props) {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.75 0.5H4.5C2.9925 0.5 0.75 1.44881 0.75 4.0625V20.6875C0.75 23.3012 2.9925 24.25 4.5 24.25H22.0625C22.7183 24.25 23.25 23.7183 23.25 23.0625C23.25 22.4067 22.7183 21.875 22.0625 21.875H4.515C3.9375 21.8608 3.25 21.6446 3.25 20.6875C3.25 19.7304 3.9375 19.5142 4.515 19.5H22C22.6904 19.5 23.25 18.9404 23.25 18.25V2.875C23.25 1.56519 22.1287 0.5 20.75 0.5ZM20.75 16.5C20.75 16.8452 20.4702 17.125 20.125 17.125H3.875C3.52982 17.125 3.25 16.8452 3.25 16.5V4.0625C3.25 3.10537 3.9375 2.88925 4.5 2.875H20.125C20.4702 2.875 20.75 3.15482 20.75 3.5V16.5Z"
        fill="white"
      />
    </svg>
  );
}

export default Book;
