import React, { memo, useMemo } from "react";
import PropTypes from "prop-types";
import { useTheme } from "react-jss";
import { useTranslation } from "react-i18next";
import CustomTypography from "../../../../CustomTypography";
import TotalPublicationsCard from "../../TotalPublicationsCard";
import CustomPaper from "../../../../CustomPaper";
import PieChart from "../../../../Charts/PieChart";
import WordCloud from "../../../../Charts/WordCloud";
import AnalysisCardSkeleton from "../../../../Skeleton/AnalysisCardSkeleton";
import useStyles from "./styles";

const Analysis = ({ statistics, loading }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();

  const articleForQualisFormated = useMemo(
    () =>
      statistics && !!statistics.article_for_qualis
        ? Object.keys(statistics.article_for_qualis).map((key) => ({
            value: statistics.article_for_qualis[key],
            name: key,
          }))
        : [],
    [statistics]
  );

  return (
    <div className={classes.container}>
      <div className={classes.title}>
        <CustomTypography variant="columnistName" color={theme.textPrimary}>
          {t("resultPage.analysis.title")}
        </CustomTypography>
      </div>

      <>
        <div className={classes.sectionWrapper}>
          <TotalPublicationsCard
            publicationsForYear={
              statistics && statistics.researcher_for_year
                ? statistics.researcher_for_year
                : []
            }
            totalArticles={
              statistics && statistics.total_researcher
                ? parseFloat(statistics.total_researcher)
                : 0
            }
            loading={loading}
            hideLastPublicationIndicator
          />
        </div>
        <div className={classes.chartsSection}>
          <div className={classes.pieChartWrapper}>
            <CustomPaper>
              {loading ? (
                <AnalysisCardSkeleton
                  variant="piechart"
                  className={classes.pieChartWrapper}
                />
              ) : (
                <>
                  <CustomTypography
                    variant="description"
                    className={classes.wordCloudTitle}
                  >
                    {t("resultPage.analysis.classificationOfPublishedArticles")}
                  </CustomTypography>
                  <PieChart
                    valueName={t("resultPage.analysis.classification")}
                    data={articleForQualisFormated}
                    title=""
                    legend={t("resultPage.analysis.legend")}
                    emptyMessage={t("resultPage.analysis.emptyMessage.chart")}
                  />
                </>
              )}
            </CustomPaper>
          </div>
          <div className={classes.wordCloudWrapper}>
            <CustomPaper>
              {loading ? (
                <AnalysisCardSkeleton
                  variant="wordcloud"
                  className={classes.pieChartWrapper}
                />
              ) : (
                <>
                  <CustomTypography
                    variant="description"
                    className={classes.wordCloudTitle}
                  >
                    {t("resultPage.analysis.mainWorkTopics")}
                  </CustomTypography>

                  <WordCloud
                    words={statistics.cloud_words}
                    height={241}
                    seriesName={t("resultPage.analysis.mentions")}
                    emptyMessage={t("resultPage.analysis.emptyMessage.chart")}
                  />
                </>
              )}
            </CustomPaper>
          </div>
        </div>
      </>
    </div>
  );
};

Analysis.propTypes = {
  statistics: PropTypes.shape(),
  loading: PropTypes.bool,
};

Analysis.defaultProps = {
  statistics: undefined,
  loading: true,
};

export default memo(Analysis);
