import React from "react";
import { useTheme } from "react-jss";
import PropTypes from "prop-types";
import useStyles from "./styles";
import CustomTypography from "../../CustomTypography";
import PodcastViewer from "../PodcastViewer";
import PodcastListSkeleton from "../../Skeleton/PodcastListSkeleton";
import { isEmpty } from "../../../util/hooks";

/**
 * This component represents a grid displayer fro multimedia section.
 * @returns GridDisplayer component
 */
export default function PodcastGridDisplayer({ title, podcasts }) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <>
      <div className={classes.section}>
        <CustomTypography style={{ marginBottom: 16 }} variant="h3">
          {title}
        </CustomTypography>
        {isEmpty(podcasts) ? (
          <PodcastListSkeleton />
        ) : (
          <div className={classes.container}>
            {podcasts.map((podcast) => (
              <div key={podcast.id} className={classes.item}>
                <PodcastViewer data={podcast} />
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
}

PodcastGridDisplayer.propTypes = {
  title: PropTypes.string,
  podcasts: PropTypes.arrayOf(PropTypes.shape()),
};

PodcastGridDisplayer.defaultProps = {
  title: "",
  podcasts: undefined,
};
