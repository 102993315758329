import React from "react";
import { useTheme } from "react-jss";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import CustomTypography from "../../../CustomTypography";
import CustomButton from "../../../CustomButton";
import CheckSvg from "../../../Icons/Check";
import useStyles from "./styles";

const SuccessContainer = ({
  onClickClose,
  children,
  title,
  textCloseButton,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();

  return (
    <>
      <div className={classes.contentItem}>
        <div className={classes.checkedWraper}>
          <div className={classes.checked}>
            <div className={classes.checkIconWrapper}>
              <CheckSvg />
            </div>
          </div>
        </div>

        <CustomTypography variant="h3" color={theme.textPrimary}>
          {!title ? t("searchEngine.alertModal.success.completed") : title}
        </CustomTypography>
      </div>
      <div className={classes.contentItem}>{children}</div>

      <div className={classes.buttonWrapper}>
        <CustomButton
          color={theme.background0}
          className={classes.alertButton}
          onClick={onClickClose}
        >
          {!textCloseButton
            ? t("searchEngine.alertModal.success.close")
            : textCloseButton}
        </CustomButton>
      </div>
    </>
  );
};

SuccessContainer.propTypes = {
  children: PropTypes.node,
  onClickClose: PropTypes.func,
  title: PropTypes.string,
  textCloseButton: PropTypes.string,
};

SuccessContainer.defaultProps = {
  children: undefined,
  onClickClose: undefined,
  title: "",
  textCloseButton: "",
};

export default SuccessContainer;
