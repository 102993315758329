/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, memo } from "react";
import PropTypes from "prop-types";
import { useTheme } from "react-jss";
import { useTranslation } from "react-i18next";
import Link from "@material-ui/core/Link";
import ReactMarkdown from "react-markdown";
import CustomTypography from "../../../../CustomTypography";
import CustomLink from "../../../../CustomLink";
import CustomPaper from "../../../../CustomPaper";
import resultTypes from "../../../ResultTypes";
import trimText from "../../../../../util/trimText";
import InstitutionalBond from "./InstitutionalBond";
import useStyles from "./styles";

const About = ({ lattesId, type, about, institutionalBond, ...props }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  const [viewMore, setViewMore] = useState(false);
  const trimmedText = trimText(about, 200);

  const shouldTrim = about.length > 200;

  const handleViewMore = () => {
    setViewMore((oldState) => !oldState);
  };

  return (
    <div {...props}>
      <div className={classes.content}>
        <div className={classes.title}>
          <CustomTypography variant="noticeTitle" color={theme.textPrimary}>
            {type === resultTypes.RESEARCHER
              ? t("resultPage.aboutResearcher")
              : ""}
          </CustomTypography>
        </div>

        <div>
          <CustomTypography variant="body1" color={theme.textPrimary}>
            <ReactMarkdown>
              {viewMore || !shouldTrim ? about : `${trimmedText[0]}...`}
            </ReactMarkdown>
          </CustomTypography>

          {shouldTrim && (
            <CustomTypography color={theme.primary1} variant="body">
              <Link
                onClick={handleViewMore}
                style={{ textDecoration: "none" }}
                className={classes.link}
              >
                {viewMore ? t("resultPage.hide") : t("resultPage.viewMore")}
              </Link>
            </CustomTypography>
          )}
        </div>
      </div>
      {institutionalBond && (
        <InstitutionalBond
          institute={institutionalBond.name}
          yearEndBond={institutionalBond.year_end_bond}
          yearStartBond={institutionalBond.year_start_bond}
          timeBond={institutionalBond.time_bond}
          image={institutionalBond.image}
        />
      )}
      <CustomPaper
        backgroundColor={theme.background1}
        className={classes.bottomPaperWrapper}
      >
        <div className={classes.bottomPaper}>
          <span> {t("resultPage.infoSource")}</span>&nbsp;
          <span>
            <CustomLink
              component="span"
              to={{ pathname: `http://lattes.cnpq.br/${lattesId}` }}
              target="_blank"
              className={classes.link}
            >
              {t("resultPage.access")}
            </CustomLink>
          </span>
        </div>
      </CustomPaper>
    </div>
  );
};

About.propTypes = {
  type: PropTypes.string,
  about: PropTypes.string,
  lattesId: PropTypes.string,
  institutionalBond: PropTypes.shape(),
};

About.defaultProps = {
  type: "",
  about: "",
  lattesId: undefined,
  institutionalBond: undefined,
};

export default memo(About);
