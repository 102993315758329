import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => ({
  footer: {
    width: "100%",
    height: 60,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderTop: `1px solid ${theme.stroke}`,
    columnGap: 30,
  },
}));

export default useStyles;
