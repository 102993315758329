import React from "react";
import SkeletonTypography from "../SkeletonTypography";

export default function ArticletSkeleton(props) {
  return (
    <div {...props}>
      <SkeletonTypography variant="h3" />
      <SkeletonTypography variant="body1" />
    </div>
  );
}
