import { createUseStyles } from "react-jss";

const useStyles = createUseStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minWidth: 180,
    height: 85,
    padding: 10,
    borderRadius: 4,
    "@media (max-width: 768px)": {
      minWidth: 140,
      height: 70, // diminui a altura em telas menores
    },
  },
  number: {
    fontSize: "4rem",
    fontWeight: 700,
    "@media (max-width: 768px)": {
      fontSize: "3rem", // reduz o tamanho da fonte para dispositivos menores
    },
  },
  subtitle: {
    fontSize: "1.2rem",
    "@media (max-width: 768px)": {
      fontSize: "1rem", // reduz o tamanho da fonte para dispositivos menores
    },
  },
}));

export default useStyles;
