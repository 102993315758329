import React from "react";
import { useTheme } from "react-jss";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Analysis from "../../Analysis";
import resultTypes from "../../../../ResultTypes";
import Section from "../../../Section";
import ProfileImg from "../../../../../ProfileImg";
import InstituteImg from "../../../../../InstituteImg";
import SectionRow from "../../../SectionRow";
import CustomMarker from "../../../../CustomMarker";
import ProjectDescription from "../../../ProjectDescription";
import { isEmpty, lengthIsGreaterThan } from "../../../../../../util/hooks";
import PublicationDescription from "../../../PublicationDescription";
import { tabsTypes } from "../../../tabTypes";
import useStyles from "./styles";

const GeneralContent = ({
  statistics,
  projects,
  publications,
  isLoadingAnalysis,
  isLoadingProjects,
  isLoadingPublications,
  onViewAllClick,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  // const { loadingStats } = useSelector((state) => state.researcher);

  const handleViewMore = (sectionName) => {
    if (onViewAllClick) onViewAllClick(sectionName);
  };

  return (
    <div className={classes.content}>
      <div className={classes.section}>
        <Analysis statistics={statistics} loading={isLoadingAnalysis} />
      </div>
      <div className={classes.section}>
        <Section
          title={t("resultPage.publications.title")}
          name={tabsTypes.PUBLICATIONS}
          showViewMore={lengthIsGreaterThan(publications, 5)}
          onViewMoreClick={handleViewMore}
          loading={isLoadingPublications}
          isEmpty={isEmpty(publications)}
          emptyMessage={t("resultPage.emptyPublications")}
        >
          {!isEmpty(publications) &&
            publications
              .slice(0, 5)
              .map((publication) => (
                <SectionRow
                  key={publication.id}
                  title={publication.title}
                  subtitle={<PublicationDescription year={publication.year} />}
                  imageCell={<CustomMarker type={publication.type} />}
                  placeholder={t("resultPage.infoUnavailable")}
                />
              ))}
        </Section>
      </div>
      <div className={classes.section}>
        <Section
          title={t("resultPage.researchProjects.title")}
          name={tabsTypes.PROJECTS}
          showViewMore={!isEmpty(projects)}
          onViewMoreClick={handleViewMore}
          loading={isLoadingProjects}
          isEmpty={isEmpty(projects)}
          emptyMessage={t("resultPage.emptyProjects")}
        >
          {!isEmpty(projects) &&
            projects
              .slice(0, 5)
              .map((project) => (
                <SectionRow
                  key={project.id}
                  title={project.name}
                  subtitle={
                    <ProjectDescription
                      yearStart={project.yearStart}
                      yearEnd={project.yearEnd}
                    />
                  }
                  imageCell={<CustomMarker type={resultTypes.RESEARCH} />}
                  placeholder={t("resultPage.infoUnavailable")}
                />
              ))}
        </Section>
      </div>
      <div className={classes.section}>
        <Section
          title={t("resultPage.mainCoAuthors")}
          name={tabsTypes.COAUTHORS}
          showViewMore={
            statistics && lengthIsGreaterThan(statistics.collaborations, 5)
          }
          onViewMoreClick={handleViewMore}
          loading={isLoadingAnalysis}
          isEmpty={statistics && isEmpty(statistics.collaborations)}
          emptyMessage={t("resultPage.emptyCoAuthors")}
        >
          {statistics &&
            !isEmpty(statistics.collaborations) &&
            statistics.collaborations.slice(0, 5).map((colaborator) => (
              <SectionRow
                key={colaborator.lattes_id}
                title={colaborator.name}
                linkTo={
                  colaborator.name
                    ? `/buscador/pesquisador/${colaborator.lattes_id}`
                    : undefined
                }
                subtitle={
                  <span>
                    {colaborator.institute || t("researcher.notAffiliated")}
                  </span>
                }
                imageCell={
                  <ProfileImg
                    url={colaborator.image}
                    name={colaborator.name}
                    size={40}
                  />
                }
                stats={t("resultPage.collaborationsWithCount", {
                  count: parseFloat(colaborator.coauthorship),
                })}
                placeholder={t("resultPage.infoUnavailable")}
              />
            ))}
        </Section>
      </div>
      <div className={classes.section}>
        <Section
          title={t("resultPage.institutionalCollab")}
          name={tabsTypes.COLABORATIONS}
          showViewMore={
            statistics &&
            lengthIsGreaterThan(statistics.institutions_collaboration, 5)
          }
          onViewMoreClick={handleViewMore}
          loading={isLoadingAnalysis}
          isEmpty={statistics && isEmpty(statistics.institutions_collaboration)}
          emptyMessage={t("resultPage.emptyInstitutionalCollab")}
        >
          {statistics &&
            !isEmpty(statistics.institutions_collaboration) &&
            statistics.institutions_collaboration
              .slice(0, 5)
              .map((institute) => (
                <SectionRow
                  key={institute.name}
                  title={institute.name}
                  imageCell={
                    <InstituteImg
                      url={institute.image}
                      type={resultTypes.INSTITUTE}
                    />
                  }
                  stats={t("resultPage.collaborationsWithCount", {
                    count: parseFloat(institute.total_collaborations),
                  })}
                  placeholder={t("resultPage.infoUnavailable")}
                  linkTo={`/buscador/instituto/${institute.id}`}
                />
              ))}
        </Section>
      </div>
    </div>
  );
};

GeneralContent.propTypes = {
  statistics: PropTypes.shape(),
  projects: PropTypes.arrayOf(PropTypes.shape()),
  publications: PropTypes.arrayOf(PropTypes.shape()),
  isLoadingAnalysis: PropTypes.bool,
  isLoadingProjects: PropTypes.bool,
  isLoadingPublications: PropTypes.bool,
  onViewAllClick: PropTypes.func,
};

GeneralContent.defaultProps = {
  statistics: undefined,
  publications: undefined,
  projects: undefined,
  isLoadingAnalysis: false,
  isLoadingProjects: false,
  isLoadingPublications: false,
  onViewAllClick: undefined,
};

export default GeneralContent;
