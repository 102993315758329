import React, { useState } from "react";
import { useTheme } from "react-jss";
import { useTranslation } from "react-i18next";
import { isEmail } from "validator";
import PropTypes from "prop-types";
import CustomTextField from "../CustomTextField";
import CustomTypography from "../CustomTypography";
import CustomButton from "../CustomButton";
import api from "../../services/api";
import ShareModal from "../Landing/ShareModal";
import Email from "../Icons/Landing/Email";
import Search from "../Icons/Landing/Search";
import Dialog from "../Icons/Landing/Dialog";
import Star from "../Icons/Landing/Star";
import useStyles from "./styles";

/**
 * This component represents a Cta.
 */
export default function Cta({ id }) {
  Cta.propTypes = {
    id: PropTypes.string,
  };

  Cta.defaultProps = {
    id: "",
  };

  const theme = useTheme();
  const classes = useStyles({ theme });
  const { t } = useTranslation();

  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [registeringToNewsletter, setRegisteringToNewsletter] = useState(false);
  const [ctaBoxValues, setCtaBoxValues] = useState({
    name: "",
    email: "",
    lattes: "",
  });

  const [ctaBoxErrors, setCtaBoxErrors] = useState({
    name: false,
    email: false,
    lattes: false,
  });

  const handleCtaBoxValues = (key, event) => {
    setCtaBoxValues((errors) => ({ ...errors, [key]: event.target.value }));
  };

  const handleDataSending = () => {
    if (registeringToNewsletter) return;

    const errors = {
      name: ctaBoxValues.name.length === 0,
      email: !isEmail(ctaBoxValues.email),
      lattes: ctaBoxErrors.lattes,
    };
    setCtaBoxErrors(errors);

    if (!errors.name && !errors.email) {
      setRegisteringToNewsletter(true);
      api
        .post("/visitors/", ctaBoxValues)
        .then(() => {
          setRegisteringToNewsletter(false);
          setShareModalOpen(true);
        })
        .catch(() => {
          setRegisteringToNewsletter(false);
        });
    }
  };

  const handleShareModalClose = () => {
    setShareModalOpen(false);
  };

  return (
    <div id={id} className={classes.ctaBackground}>
      <ShareModal open={shareModalOpen} onClose={handleShareModalClose} />
      <div className={classes.section}>
        <div className={classes.ctaWrapper}>
          <div className={classes.ctaNewsletterBox}>
            <div className={classes.sectionTitle}>
              <Email />
              <CustomTypography
                variant="body"
                weight="bold"
                color={theme.research}
              >
                {t("landing.cta.box.subscribeNewsletter")}
              </CustomTypography>
            </div>
            <CustomTypography variant="h2" weight="bold" color={theme.lowDark}>
              {t("landing.cta.title")}
            </CustomTypography>

            <div className={classes.ctaNewsletterInputs}>
              <CustomTextField
                error={ctaBoxErrors.name}
                placeholder={t("landing.cta.box.namePlaceholder")}
                variant="outlined"
                style={{ marginBottom: 16, width: "100%" }}
                className={classes.ctaTextFields}
                onChange={(event) => handleCtaBoxValues("name", event)}
              />
              <CustomTextField
                error={ctaBoxErrors.email}
                placeholder={t("landing.cta.box.emailPlaceholder")}
                variant="outlined"
                style={{ marginBottom: 16, width: "100%" }}
                className={classes.ctaTextFields}
                onChange={(event) => handleCtaBoxValues("email", event)}
              />
              <CustomTextField
                error={ctaBoxErrors.lattes}
                placeholder={t("landing.cta.box.lattesPlaceholder")}
                variant="outlined"
                style={{ marginBottom: 16, width: "100%" }}
                className={classes.ctaTextFields}
                onChange={(event) => handleCtaBoxValues("lattes", event)}
              />
            </div>
            <div className={classes.ctaNewsletterBottom}>
              <CustomButton
                variant="contained"
                backgroundColor={theme.forestShade}
                style={{ width: 280 }}
                className={classes.ctaButtom}
                onClick={() => handleDataSending()}
              >
                <CustomTypography color={theme.lowDark} weight="bold">
                  {t("landing.cta.box.button")}
                </CustomTypography>
              </CustomButton>
            </div>
          </div>
          <div className={classes.ctaTexts}>
            <div className={classes.ctaTextsTopic}>
              <Search className={classes.icon} />
              <CustomTypography variant="h2" color={theme.lowDark}>
                {t("landing.cta.searchPromotion")}
              </CustomTypography>
            </div>

            <div className={classes.ctaTextsTopic}>
              <Dialog className={classes.icon} />
              <CustomTypography variant="h2" color={theme.lowDark}>
                {t("landing.cta.agencyUpdates")}
              </CustomTypography>
            </div>

            <div className={classes.ctaTextsTopic}>
              <Star className={classes.icon} />
              <CustomTypography variant="h2" color={theme.lowDark}>
                {t("landing.cta.newFeatures")}
              </CustomTypography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
