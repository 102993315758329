import React from "react";
import { useTheme } from "react-jss";
import PropTypes from "prop-types";
import CustomTypography from "../../../CustomTypography";
import useStyles from "./styles";

const Card = ({ title, caption }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <div className={classes.container}>
      <CustomTypography variant="h2" color={theme.textSecondary}>
        {title}
      </CustomTypography>
      <CustomTypography
        variant="smallText"
        color={theme.secondary0}
        weight="bold"
      >
        {caption}
      </CustomTypography>
    </div>
  );
};

Card.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  caption: PropTypes.string,
};

Card.defaultProps = {
  title: "",
  caption: "",
};

export default Card;
