import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "100%",
  },
  contentWrapper: {
    display: "flex",
    maxWidth: "100%",
    overflow: "auto",
    scrollBehavior: "smooth",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      background: "transparent",
      "-webkit-appearance": "none",
      width: 0,
      height: 0,
    },
    columnGap: 10,
  },
  navigatorsWrapper: {
    marginTop: -35,
    width: "100%",
    display: "flex",
    justifyContent: (props) => (props.scrolled ? "space-between" : "flex-end"),
  },
  button: {
    "&.MuiIconButton-root": {
      padding: 0,
      width: 30,
      height: 35,
      background: theme.background0,
      "&:hover": {
        background: theme.background0,
      },
    },
  },
  buttonLeft: {
    "&.MuiIconButton-root": {
      borderRadius: "0px 15px 15px 0px",
    },
  },
  buttonRight: {
    "&.MuiIconButton-root": {
      borderRadius: "15px 0px 0px 15px",
    },
  },
}));

export default useStyles;
