import React from "react";
import { Route, Switch, useRouteMatch, Redirect } from "react-router-dom";
import Home from "./Home";
import Videos from "./Videos";
import useStyles from "./styles";

/**
 * This page wraps ever single page that Columnists contains.
 */
export default function Multimedia() {
  const match = useRouteMatch();
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Switch>
        <Route exact path={`${match.path}/videos`} component={Videos} />
        <Route exact path={match.path} component={Home} />
        <Route path={`${match.path}/*`}>
          <Redirect to="/404" />
        </Route>
      </Switch>
    </div>
  );
}
