import { createUseStyles } from "react-jss";
import { breakpoints } from "../../constants/sizes";

const useStyles = createUseStyles((theme) => ({
  titleWrapper: {
    display: "flex",
    width: "100%",
    flexFlow: "row wrap",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
  },
  link: {
    display: "flex",
    textDecoration: "none",
    [breakpoints.xsm]: {
      width: "100%",
    },
  },
  arrow: {
    marginLeft: 15,
    "& path": {
      fill: theme.primary0,
    },
  },
}));

export default useStyles;
