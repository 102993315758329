import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import PropTypes from "prop-types";
import CustomTypography from "../../../CustomTypography";
import { getStrapiMedia } from "../../../../services/hcms/media";
import ArticleInfo from "../../ArticleInfo";
import SectionTitle from "../../../SectionTitle";
import RecentlyListSkeleton from "../../../Skeleton/RecentlyListSkeleton";
import NextLink from "../../../NextLink";
import useStyles from "./styles";

export default function Recently({ articles }) {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { t } = useTranslation();

  return (
    <div className={classes.wrapper}>
      <SectionTitle
        title={t("agency.recently.title")}
        righLink={{
          pathName: "/agencia/artigos-recentes",
          text: t("agency.recently.viewAll"),
        }}
      />

      {!articles ? (
        <>
          <div className={classes.recentlyContainer}>
            <RecentlyListSkeleton />
          </div>
        </>
      ) : (
        <div className={classes.recentlyContainer}>
          {articles.map((article) => (
            <div key={article.id} className={classes.recentlyArticle}>
              <NextLink
                to={`/agencia/artigo/${article.slug}`}
                className={classes.link}
              >
                <img
                  alt="recentlyImg"
                  src={getStrapiMedia(article.thumbnail)}
                  className={classes.recentlyArticleImg}
                />
              </NextLink>

              <div className={classes.recentlyArticleInfo}>
                <div className={classes.recentlyArticleTitle}>
                  <NextLink
                    className={classes.link}
                    to={`/agencia/artigo/${article.slug}`}
                  >
                    <CustomTypography
                      className={classes.titleTypography}
                      variant="noticeTitle"
                    >
                      {article.title}
                    </CustomTypography>
                  </NextLink>
                </div>
                <ArticleInfo
                  columnist={article.columnist}
                  publishedAt={article.published}
                  spaceBetween
                  hideAvatar
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

Recently.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.shape()),
};

Recently.defaultProps = {
  articles: undefined,
};
