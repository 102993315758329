// const MAX_ZOOM_LEVEL = 9;

export const circleLayer = {
  type: "symbol",
  source: "trees",
  minzoom: 8,
  layout: {
    "icon-image": "custom-marker",
    // get the title name from the source's "title" property
    "text-field": ["get", "title"],
    "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
    "text-offset": [0, 1.25],
    "text-anchor": "top",
  },
};
