import { createUseStyles } from "react-jss";
// import { breakpoints, defaultContainer } from "../../../constants/sizes";

const useStyles = createUseStyles((theme) => ({
  content: {
    paddingTop: 10,
  },
  divider: {
    marginTop: 16,
  },
  title: {},
  bottomPaperWrapper: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  bottomPaper: {
    fontSize: 14,
    width: "100%",
    textAlign: "center",
    color: theme.textSecondary,
  },
  link: {
    textDecoration: "none",
    cursor: "pointer",
    color: theme.primary0,
  },
  contactWrapper: {
    paddingTop: 25,
    paddingBottom: 10,
  },
  contactContent: {
    marginTop: 20,
    display: "grid",
    gridTemplateColumns: "20px auto",
    gap: 15,
    alignItems: "center",
  },
  infoPlaceholder: {
    fontSize: 13,
    fontStyle: "italic",
  },
}));

export default useStyles;
