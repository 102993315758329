import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => ({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: 15,
    "& path": {
      fill: theme.secondary0,
    },
  },
  title: {
    display: "flex",
    columnGap: 10,
    alignItems: "center",
    paddingBottom: 5,
  },
}));

export default useStyles;
