/* eslint-disable no-unused-vars */
import React, { memo } from "react";
import { useTheme } from "react-jss";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useSelector, useDispatch } from "react-redux";
import Radio from "@material-ui/core/Radio";
import {
  addFilter,
  removeFilter,
  toogleEnabled,
} from "../../../../redux/resultFilter/resultFilterActions";
import CustomButton from "../../../CustomButton";
import ArrowDownSvg from "../../../Icons/ArrowDown";
import categoryTypes from "../../SearchContainer/CategoryTabs/CategoryTypes";
import ButtonWithPopup from "../../../ButtonWithPopup";

import useStyles from "./styles";

function RealtionalFilter({ className }) {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { categoryFilter, enabled, appliedCategoriesCount } = useSelector(
    (state) => state.resultFilter
  );

  const handleChange = (event) => {
    /* if (!event.target.checked) {
      dispatch(removeFilter(event.target.name));
    } else {
      dispatch(addFilter(event.target.name));
    } */
  };

  const handleApplyButton = () => {
    // dispatch(toogleEnabled());
  };

  return (
    <>
      <ButtonWithPopup
        endIcon={
          <span className={classes.arrowDownIcon}>
            <ArrowDownSvg />
          </span>
        }
        title={t("searchEngine.filters.viewOnMap")}
        filterButton={
          <div>
            {`${t("searchEngine.filters.show")}:`}
            <span className={classes.filterApliedName}>Co-autores</span>
          </div>
        }
        className={classNames(classes.container, className)}
        bottomButton={
          <CustomButton
            className={classes.applyButton}
            onClick={handleApplyButton}
          >
            {!enabled
              ? t("searchEngine.filters.apply")
              : t("searchEngine.filters.remove")}
          </CustomButton>
        }
        transparentOnSm
      >
        <>
          <FormControlLabel
            control={
              <Radio
                onChange={handleChange}
                name={categoryTypes.COAUTORS}
                checked
              />
            }
            label={t("searchEngine.filters.coAuthors")}
          />
          <FormControlLabel
            disabled
            control={
              <Radio
                onChange={handleChange}
                name={categoryTypes.PUBLICATIONS}
                checked={false}
              />
            }
            label={t("searchEngine.filters.publications")}
          />
        </>
      </ButtonWithPopup>
    </>
  );
}

RealtionalFilter.propTypes = {
  className: PropTypes.string,
};

RealtionalFilter.defaultProps = {
  className: "",
};

export default memo(RealtionalFilter);
