import React, { useState, useEffect } from "react";
import { useTheme } from "react-jss";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import queryString from "query-string";
import { fetchResults } from "../../../redux/result/resultActions";
import { resetFilters } from "../../../redux/resultFilter/resultFilterActions";
import { breakpoints } from "../../../constants/sizes";
import SearchContainer from "../../../components/Castanha/Landing/SearchContainer";
import categoryTypes from "../../../components/Castanha/Landing/SearchContainer/CategoryTabs/CategoryTypes";
import useStyles from "./styles";

function Result() {
  const location = useLocation();
  const [filter, setFilter] = useState({});
  const [mounted, setMounted] = useState(false);
  const [showMapOnMd, setShowMapOnMd] = useState(false);
  const isMd = useMediaQuery(breakpoints.md);
  const theme = useTheme();
  const classes = useStyles({ theme });
  const dispatch = useDispatch();
  const { filters } = useSelector((state) => state.result);

  useEffect(() => {
    if (!isMd) {
      if (showMapOnMd) {
        setShowMapOnMd(false);
      }
    }
  }, [isMd]);

  const handleDispatch = (newFilters, category = categoryTypes.ALL) => {
    dispatch(
      fetchResults({
        ...newFilters,
        q: "castanha",
        category
      })
    );
    setFilter((oldValue) => ({ ...oldValue, ...newFilters }));
  };

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const { category } = parsed;
    handleDispatch(parsed, category);
    setMounted(true);
  }, []);

  useEffect(() => {
    if (mounted) {
      const parsed = queryString.parse(location.search);
      handleDispatch(parsed, filters.category);
      dispatch(resetFilters());
    }
    return () => {
      dispatch(resetFilters());
    };
  }, [location]);

  return (
    <div className={classes.container}>
      <SearchContainer
        query="castanha"
        currentPage={filter.page}
        initialCategory={queryString.parse(location.search).category}
      />
    </div>
  );
}

export default Result;
