import {
  FETCH_RESULTS_REQUEST,
  FETCH_RESULTS_SUCCESS,
  FETCH_RESULTS_FAILURE,
  FETCH_MORE_RESULTS_SUCCESS,
  SET_SELECTED_RESULT,
  FETCH_MORE_RESULTS_REQUEST,
  SET_FILTERS,
  SET_TOTAL,
} from "./resultTypes";
import resultTypes from "../../components/Landing/ResultTypes";
import categoryTypes from "../../components/Landing/SearchContainer/CategoryTabs/CategoryTypes";

const initialState = {
  loading: false,
  results: [],
  resultContent: undefined,
  error: "",
  selectedResult: undefined,
  total: 0,
  loadingMore: false,
  filters: { page: 1, category: "" },
  totalPublications: 0,
  totalResearchers: 0,
  totalInstitutes: 0,
  loadingEnd: false,
};

const mergePayload = (payload) => {
  let response = [];
  if (payload.publications) {
    response = [
      ...payload.publications.data.map((element) => ({
        ...element,
        category: categoryTypes.PUBLICATIONS,
      })),
    ];
  }
  if (payload.researchers) {
    response = [
      ...response,
      ...payload.researchers.data.map((element, index) => ({
        ...element,
        type: resultTypes.RESEARCHER,
        id: `${element.id}-${index}-researcher`,
        category: categoryTypes.RESEARCHER,
      })),
    ];
  }
  if (payload.institutions) {
    response = [
      ...response,
      ...payload.institutions.data.map((element, index) => ({
        ...element,
        type: resultTypes.INSTITUTE,
        id: `${element.id}-${index}-institute`,
        category: categoryTypes.INSTITUTIONS,
      })),
    ];
  }
  return response;
};

const sumTotal = (payload) => {
  let total = 0;
  if (payload.publications) total += parseFloat(payload.publications.count);
  if (payload.researchers) total += parseFloat(payload.researchers.count);
  if (payload.institutions) total += parseFloat(payload.institutions.count);
  return total;
};

const buildResults = (payload) => {
  let results = {
    loading: false,
    results: mergePayload(payload).sort((firstEl, secondEl) =>
      firstEl.name
        ? firstEl.name.localeCompare(secondEl.name || secondEl.title)
        : firstEl.title.localeCompare(secondEl.name || secondEl.title)
    ),
  };

  const total = sumTotal(payload);

  results = {
    ...results,
    loadingEnd: results.results.length === 0 || total < 5,
  };

  return results;
};
const buildTotal = (payload) => {
  let total = { total: sumTotal(payload) };

  if (payload.publications)
    total = { ...total, totalPublications: payload.publications.count };
  if (payload.researchers)
    total = { ...total, totalResearchers: payload.researchers.count };
  if (payload.institutions)
    total = { ...total, totalInstitutes: payload.institutions.count };

  return total;
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RESULTS_REQUEST:
      return {
        ...state,
        loading: true,
        results: [],
        selectedResult: undefined,
        loadingEnd: false,
      };
    case FETCH_RESULTS_SUCCESS:
      return {
        ...state,
        ...buildResults(action.payload),
      };
    case FETCH_RESULTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        loadingMore: false,
      };
    case FETCH_MORE_RESULTS_REQUEST:
      return {
        ...state,
        loadingMore: true,
      };
    case FETCH_MORE_RESULTS_SUCCESS:
      return {
        ...state,
        loadingMore: false,
        results: [...state.results, ...mergePayload(action.payload)],
        loadingEnd: mergePayload(action.payload).length === 0,
      };
    case SET_SELECTED_RESULT:
      return {
        ...state,
        loading: false,
        selectedResult: action.payload,
      };
    case SET_FILTERS:
      return {
        ...state,
        filters: { ...state.filters, ...action.payload },
      };
    case SET_TOTAL:
      return {
        ...state,
        ...buildTotal(action.payload),
      };
    default:
      return state;
  }
};
export default reducer;
