import React, { useEffect, useState, memo } from "react";
import { useTheme } from "react-jss";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import useSWR from "swr";
import { useTranslation } from "react-i18next";
import api from "../../../../services/hcms/api";
import CustomTypography from "../../../CustomTypography";
import MoreArticle from "../../../Agency/Article/MoreArticle";
import ColumnistBadge from "../../../Agency/Columnist/ColumnistBadge";
import ModalSection from "../ModalSection";
import SummaryContent from "../../../Agency/SummaryContent";
import { isEmpty } from "../../../../util/hooks";
import SearchSkeleton from "../../../Skeleton/SearchSkeleton";
import useStyles from "./styles";

const SearchModal = ({ searchText, onClick }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
  }, [searchText]);

  const { data: { columnists, articles, videos, podcasts } = {} } = useSWR(
    `search?query=${searchText}&limit=2`,
    async (swrRoute) => {
      const response = await api.get(swrRoute);
      setLoading(false);
      return response.data;
    }
  );

  const hasResults =
    !isEmpty(articles) ||
    !isEmpty(columnists) ||
    !isEmpty(videos) ||
    !isEmpty(podcasts);

  return (
    <>
      {loading ? (
        <SearchSkeleton />
      ) : (
        <div>
          {hasResults ? (
            <>
              {!isEmpty(articles) && (
                <ModalSection title={t("agency.article.title")}>
                  {articles.map((article) => (
                    <MoreArticle
                      key={article.id}
                      article={article}
                      columnist={article.columnist}
                      onClick={onClick}
                      hideImage
                    />
                  ))}
                </ModalSection>
              )}

              {!isEmpty(columnists) && (
                <ModalSection title={t("agency.columnist.columnists")}>
                  {columnists.map((columnist) => (
                    <ColumnistBadge
                      key={columnist.id}
                      columnist={columnist}
                      onClick={onClick}
                    />
                  ))}
                </ModalSection>
              )}
              {!isEmpty(videos) && (
                <ModalSection title={t("multimedia.videos.title")}>
                  {videos.map((video) => (
                    <SummaryContent
                      key={video.id}
                      data={video}
                      onClick={onClick}
                    />
                  ))}
                </ModalSection>
              )}
              {!isEmpty(podcasts) && (
                <ModalSection title={t("multimedia.podcasts.title")}>
                  {podcasts.map((podcast) => (
                    <SummaryContent
                      key={podcast.id}
                      data={podcast}
                      onClick={onClick}
                    />
                  ))}
                </ModalSection>
              )}
              <Link
                to={`/busca/${searchText}`}
                className={classes.link}
                onClick={onClick}
              >
                <div className={classes.viewMoreWrapper}>
                  <CustomTypography
                    variant="searchModalTitle"
                    weight="bold"
                    color={theme.primary0}
                  >
                    {t("header.searchBar.seeAllResults")}
                  </CustomTypography>
                </div>
              </Link>
            </>
          ) : (
            <CustomTypography
              component="span"
              variant="searchModalTitle"
              weight="regular"
            >
              {t("header.searchBar.noResultsFound")}
            </CustomTypography>
          )}
        </div>
      )}
    </>
  );
};

SearchModal.propTypes = {
  searchText: PropTypes.string,
  onClick: PropTypes.func,
};

SearchModal.defaultProps = {
  searchText: "",
  onClick: null,
};

export default memo(SearchModal);
