import { createUseStyles } from "react-jss";
import { breakpoints } from "../../../constants/sizes";

const useStyles = createUseStyles((theme) => ({
  wrapper: {
    width: "100%",
    padding: 20,
    minHeight: 200,
  },
  grayBackground: {
    backgroundColor: theme.background1,
  },
  titleWrapper: {
    display: "flex",
    width: "100%",
    flexFlow: "row wrap",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
  },
  title: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    marginTop: 5,
    marginRight: 10,
  },

  titleTypography: {
    marginTop: 10,
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  gridContent: {
    marginTop: 24,
    display: "grid",
    width: "100%",

    gridTemplateColumns: "minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)",
    gridColumnGap: 32,
    gridRowGap: 20,
    alignItems: "stretch",

    [breakpoints.smd]: {
      gridTemplateColumns: "repeat(auto-fill, 100%)",
      gridColumnGap: 16,
      marginTop: 16,
      display: "grid",
    },
  },
  twoGrids: {
    marginTop: 24,
    display: "grid",
    width: "100%",

    gridTemplateColumns: "minmax(0, 1fr) minmax(0, 1fr)",
    gridColumnGap: 32,
    gridRowGap: 40,
    alignItems: "stretch",

    [breakpoints.smd]: {
      gridColumnGap: 16,
    },

    [breakpoints.sm]: {
      gridTemplateColumns: "repeat(auto-fill, 100%)",
      marginTop: 16,
      display: "grid",
    },
  },
  item: {
    height: "auto",
    [breakpoints.sm]: {
      width: "100%",
    },
  },
}));

export default useStyles;
