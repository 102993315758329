import * as React from "react";

function SvgLanguage(props) {
  return (
    <svg
      width={16}
      height={14}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.064 5.448a.5.5 0 00-.926 0l-3.195 7.864a.5.5 0 00.463.688h.516a.5.5 0 00.46-.306l.696-1.646h3.046l.696 1.646a.5.5 0 00.46.306h.516a.5.5 0 00.463-.688l-3.195-7.864zm-1.361 5.12l.898-2.765.898 2.764h-1.796zM8.395 9.508a.529.529 0 00-.152-.597A14.858 14.858 0 017.01 7.718C8.342 5.913 9.096 3.86 9.404 2.894h1.327a.5.5 0 00.5-.5v-.48a.5.5 0 00-.5-.5H6.587V.5a.5.5 0 00-.5-.5h-.481a.5.5 0 00-.5.5v.913H.962a.5.5 0 00-.5.5v.481a.5.5 0 00.5.5H7.84c-.32.907-.91 2.34-1.81 3.647-1.1-1.462-1.587-2.554-1.593-2.565a.503.503 0 00-.698-.21l-.415.24a.5.5 0 00-.198.654l.023.047c.03.052.579 1.275 1.842 2.913.03.041.062.08.093.12-1.673 1.914-3 2.662-3.017 2.675l-.122.082a.5.5 0 00-.143.684l.25.392a.5.5 0 00.677.16l.233-.138c.074-.056 1.39-.808 3.096-2.719.825.885 1.455 1.374 1.491 1.403.214.148.51.06.608-.182l.238-.589z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgLanguage;
