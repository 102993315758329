import { createUseStyles } from "react-jss";
import { breakpoints } from "../../../constants/sizes";

const useStyles = createUseStyles((theme) => ({
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 10,
    backgroundColor: theme.background2,
    [breakpoints.sm]: {
      top: 10,
      left: 10,
      right: 10,
      bottom: 10,
    },
    "& :focus-visible": {
      outline: "none !important",
    },
  },
  contentWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  content: {
    width: 596,
    backgroundColor: theme.background0,
    borderRadius: 5,
    padding: 32,
    borderWidth: 1,
    borderColor: theme.stroke,
    borderStyle: "solid",
    [breakpoints.sm]: {
      width: "100%",
      padding: 20,
    },
  },
  contentItem: {
    paddingBottom: 15,
  },
  button: {
    "&.MuiButton-root": {
      minWidth: 0,
    },
  },
  closeButtonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
  ctaTextFields: {
    backgroundColor: theme.background0,
    borderRadius: "4px",
    width: "100%",
  },
  buttonWrapper: {
    paddingTop: 20,
    paddingBottom: 20,
    display: "flex",
    justifyContent: "center",
  },
  alertButton: {
    width: 204,
    "& path": {
      fill: theme.background0,
    },
  },
}));

export default useStyles;
