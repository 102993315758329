import React from "react";
import { useTheme } from "react-jss";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Portal } from "react-portal";
import ReactWhatsapp from "react-whatsapp";
import CustomTypography from "../../../../CustomTypography";
import CustomButton from "../../../../CustomButton";
import Whatsapp from "../../../../Icons/Whatsapp";
import CustomLink from "../../../../CustomLink";
import AccordionItem from "../../../../AccordionItem";
import useStyles from "./styles";

const MenuPopper = ({ selectedMenuButton, ...props }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { t } = useTranslation();

  function getFontWeight(condition) {
    if (condition) return "bold";
    return "regular";
  }

  function getFontColor(condition) {
    if (condition) return theme.primary0;
    return theme.textSecondary;
  }

  return (
    <Portal>
      <div className={classes.popperWrapper} {...props}>
        <div>
          <div className={classes.menuItem}>
            <CustomLink to="/">
              <CustomTypography
                variant="paragraph1"
                weight={getFontWeight(selectedMenuButton.landing)}
                color={getFontColor(selectedMenuButton.landing)}
              >
                {t("header.start")}
              </CustomTypography>
            </CustomLink>
          </div>
          <div className={classes.menuItem}>
            <CustomLink to="/cooperativas">
              <CustomTypography
                variant="paragraph1"
                weight={getFontWeight(selectedMenuButton.cooperatives)}
                color={getFontColor(selectedMenuButton.cooperatives)}
              >
                {t("header.cooperatives")}
              </CustomTypography>
            </CustomLink>
          </div>
          <div className={classes.menuItem}>
            <CustomLink to="/missao">
              <CustomTypography
                variant="paragraph1"
                weight={getFontWeight(selectedMenuButton.duty)}
                color={getFontColor(selectedMenuButton.duty)}
              >
                Missão
              </CustomTypography>
            </CustomLink>
          </div>
          <div className={classes.agencyAccordion}>
            <AccordionItem
              open={selectedMenuButton.agency}
              buttonChildren={
                <CustomTypography
                  variant="paragraph1"
                  color={getFontColor(selectedMenuButton.agency)}
                  className={classes.agencyButton}
                >
                  {t("header.agency")}
                </CustomTypography>
              }
            >
              <div className={classes.menuItem}>
                <CustomLink to="/agencia">
                  <CustomTypography
                    variant="paragraph1"
                    weight={getFontWeight(
                      selectedMenuButton.agency &&
                        !selectedMenuButton.multimidia
                    )}
                    color={getFontColor(
                      selectedMenuButton.agency &&
                        !selectedMenuButton.multimidia
                    )}
                  >
                    {t("agency.news")}
                  </CustomTypography>
                </CustomLink>
              </div>
              <div className={classes.menuItem}>
                <CustomLink to="/agencia/multimidia">
                  <CustomTypography
                    variant="paragraph1"
                    weight={getFontWeight(selectedMenuButton.multimidia)}
                    color={getFontColor(selectedMenuButton.multimidia)}
                  >
                    {t("multimedia.title")}
                  </CustomTypography>
                </CustomLink>
              </div>
            </AccordionItem>
          </div>
          <div className={classes.menuItem}>
            <CustomLink to="/contato">
              <CustomTypography
                variant="paragraph1"
                weight={getFontWeight(selectedMenuButton.contactUs)}
                color={getFontColor(selectedMenuButton.contactUs)}
              >
                {t("header.contactUs")}
              </CustomTypography>
            </CustomLink>
          </div>
          <div className={classes.menuItem}>
            <ReactWhatsapp
              number={process.env.REACT_APP_WHATSAPP_CONTACT_NUMBER}
              message={t("header.contactUsMessage")}
              className={classes.whatsappButton}
            >
              <CustomTypography
                variant="paragraph1"
                weight="regular"
                color={theme.textSecondary}
              >
                <div className={classes.whatsappGrid}>
                  <div>
                    <Whatsapp />
                  </div>
                  <div>WhatsApp</div>
                </div>
              </CustomTypography>
            </ReactWhatsapp>
          </div>
        </div>
        <div>
          <div className={classes.signUpButtonWrapper}>
            <CustomButton
              color={theme.background0}
              href="/cadastro"
              className={classes.authButton}
            >
              {t("forms.signUp")}
            </CustomButton>
          </div>
        </div>
      </div>
    </Portal>
  );
};

MenuPopper.propTypes = {
  selectedMenuButton: PropTypes.shape().isRequired,
};

export default MenuPopper;
