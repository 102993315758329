import React from "react";
import { useTheme } from "react-jss";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import CustomTypography from "../../CustomTypography";
import Card from "./Card";
import useStyles from "./styles";

const GeneralStatistics = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  const { statistics } = useSelector((state) => state.cooperative);

  return (
    <div className={classes.generalWrapper}>
      <CustomTypography color={theme.textSecondary} variant="bodyLargeRegular">
        {t("cooperatives.statistics.general")}
      </CustomTypography>

      <div className={classes.cardsWrapper}>
        <Card
          caption={t("cooperatives.statistics.persistedCooperatives")}
          title={statistics?.total_cooperatives}
        />
        <Card
          caption={t("cooperatives.statistics.memberCities")}
          title={statistics?.total_cities}
        />
        <Card caption="produzem café (mocked)" title="1.262" />
      </div>
    </div>
  );
};

export default GeneralStatistics;
