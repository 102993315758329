import React from "react";
import { useTheme } from "react-jss";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import CustomButton from "../CustomButton";
import CustomTypography from "../CustomTypography";
import useStyles from "./styles";

export default function LoadMoreButton({
  isLoadEnded,
  loadEndedMessage,
  onClick,
  visible,
  isLoading,
}) {
  const theme = useTheme();
  const classes = useStyles({
    theme,
    isLoadEnded: isLoadEnded && loadEndedMessage === "",
  });
  const { t } = useTranslation();

  if (!visible) {
    return isLoading ? <CircularProgress style={{ marginBottom: 40 }} /> : null;
  }

  return (
    <div className={classes.buttonWrapper}>
      {isLoadEnded ? (
        <div>{loadEndedMessage}</div>
      ) : (
        <CustomButton
          variant="contained"
          backgroundColor={theme.primary2}
          style={{ width: 214 }}
          className={classes.viewMoreButton}
          onClick={onClick}
        >
          <CustomTypography color={theme.background0} weight="bold">
            {t("components.viewMoreButton")}
          </CustomTypography>
        </CustomButton>
      )}
    </div>
  );
}

LoadMoreButton.propTypes = {
  isLoadEnded: PropTypes.bool,
  onClick: PropTypes.func,
  loadEndedMessage: PropTypes.string,
  visible: PropTypes.bool,
  isLoading: PropTypes.bool,
};

LoadMoreButton.defaultProps = {
  isLoadEnded: false,
  loadEndedMessage: "",
  onClick: undefined,
  visible: true,
  isLoading: false,
};
