import React, { memo } from "react";
import PropTypes from "prop-types";
import { useTheme } from "react-jss";
import { Marker } from "react-map-gl";

import useStyles from "./styles";

const ClusterMarker = ({
  latitude,
  longitude,
  points,
  pointCount,
  id,
  onClick,
}) => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <Marker
      key={`cluster-${id}`}
      latitude={latitude}
      longitude={longitude}
      offsetLeft={-15}
    >
      <button
        type="button"
        className={classes.clusterMark}
        style={{
          width: `${40 + (pointCount / points.length) * 50}px`,
          height: `${40 + (pointCount / points.length) * 50}px`,
        }}
        onClick={onClick}
      >
        {pointCount}
      </button>
    </Marker>
  );
};

ClusterMarker.propTypes = {
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
  points: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  pointCount: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

ClusterMarker.defaultProps = {
  onClick: undefined,
};

export default memo(ClusterMarker);
