import React, { memo } from "react";
import PropTypes from "prop-types";
import { useTheme } from "react-jss";
// import { useTranslation } from "react-i18next";import {
import { useMediaQuery } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import CustomTypography from "../../../../CustomTypography";
import CustomButton from "../../../../CustomButton";
import ProfileImg from "../../../../ProfileImg";
import NotificationIcon from "../../../../Icons/Notification";
import EditIcon from "../../../../Icons/Edit";
import CustomLink from "../../../../CustomLink";
import useStyles from "./styles";

const InstituteHeader = ({ image, name, id }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  // const { t } = useTranslation();
  const isSm = useMediaQuery("(max-width: 600px)");

  return (
    <>
      <div className={classes.container}>
        <div className={classes.centralizeHorizontal}>
          <ProfileImg url={image} name={name} size={64} smSize={46} />
        </div>
        <div className={classes.centralizeHorizontal}>
          <div className={classes.content}>
            <div>
              <CustomTypography
                variant="columnistName"
                color={theme.textPrimary}
              >
                {name}
              </CustomTypography>
            </div>
            <div>
              <CustomLink to={`/buscador/instituto/${id}`}>
                <CustomTypography
                  variant="searchResultSubTitle"
                  color={theme.textPrimary}
                >
                  {`rhisa.org/buscador/instituto/${id}`}
                </CustomTypography>
              </CustomLink>
            </div>
          </div>
        </div>
        <div className={classes.buttonsWrapper}>
          <div className={classes.buttonsGrid}>
            <CustomButton
              color={theme.background0}
              className={classes.button}
              startIcon={<NotificationIcon />}
            >
              <div className={classes.buttonText}>Monitorar</div>
            </CustomButton>

            <CustomButton
              variant="outlined"
              color="primary"
              outlineColor={theme.primary0}
              className={classes.button}
              startIcon={isSm ? <EditIcon /> : null}
            >
              <div className={classes.buttonText}>Editar</div>
            </CustomButton>
          </div>
        </div>
      </div>

      <div className={classes.divider}>
        <Divider />
      </div>
    </>
  );
};

InstituteHeader.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

InstituteHeader.defaultProps = {
  image: "",
  name: "",
  id: "",
};

export default memo(InstituteHeader);
