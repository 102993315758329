import * as React from "react";

function Edit(props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4917 0.217197C13.8379 0.0738033 14.2089 0 14.5836 0C14.9583 0 15.3294 0.0738033 15.6755 0.217197C16.0217 0.360589 16.3363 0.570764 16.6012 0.83572C16.8662 1.10068 17.0764 1.41522 17.2198 1.76141C17.3631 2.10759 17.4369 2.47863 17.4369 2.85333C17.4369 3.22803 17.3631 3.59906 17.2198 3.94525C17.0764 4.29143 16.8662 4.60598 16.6012 4.87093L5.43713 16.035C5.00141 16.4707 4.44761 16.7668 3.84429 16.8875L1.19629 17.4175C0.868448 17.4831 0.529506 17.3805 0.293049 17.1442C0.0565919 16.9078 -0.0460938 16.5689 0.0194043 16.241L0.548405 13.593C0.669526 12.9894 0.96679 12.4343 1.40242 11.9993L12.566 0.83572C12.831 0.570766 13.1455 0.360591 13.4917 0.217197ZM14.5836 2C14.4716 2 14.3606 2.02207 14.2571 2.06496C14.1535 2.10784 14.0595 2.17069 13.9802 2.24993L13.0739 3.15622L14.281 4.36272L15.187 3.45672C15.2663 3.37748 15.3291 3.28341 15.372 3.17988C15.4149 3.07635 15.4369 2.96539 15.4369 2.85333C15.4369 2.74126 15.4149 2.6303 15.372 2.52677C15.3291 2.42324 15.2663 2.32917 15.187 2.24993C15.1078 2.1707 15.0137 2.10784 14.9102 2.06496C14.8066 2.02207 14.6957 2 14.5836 2ZM12.8668 5.77693L11.6597 4.57043L2.81613 13.414C2.66003 13.5699 2.55304 13.769 2.50956 13.9853L2.27448 15.162L3.45177 14.9264C3.66839 14.883 3.86668 14.7771 4.02292 14.6208L12.8668 5.77693Z"
        fill="black"
      />
    </svg>
  );
}

export default Edit;
