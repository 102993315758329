import { createUseStyles } from "react-jss";
import { defaultContainer, breakpoints } from "../../constants/sizes";

const useStyles = createUseStyles((/* theme */) => ({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  content: {
    paddingTop: 10,
    position: "relative",
    width: "100%",
    maxWidth: defaultContainer.Width + defaultContainer.paddingHorizontal * 2,
    padding: `0px ${defaultContainer.paddingHorizontal}px`,
  },
  formWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: 600,
    [breakpoints.md]: {
      width: "100%",
    },
  },

  title: {
    marginBottom: 40,
    [breakpoints.sm]: {
      marginBottom: 20,
    },
  },
}));

export default useStyles;
