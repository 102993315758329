import * as React from "react";

function SvgDate(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.90002 0.75C6.90002 0.335786 6.56424 0 6.15002 0C5.73581 0 5.40002 0.335786 5.40002 0.75V2H4.46154C1.9975 2 0 3.98057 0 6.42373V7.95V15.5763C0 18.0194 1.9975 20 4.46154 20H15.5385C18.0025 20 20 18.0194 20 15.5763V6.42373C20 3.98057 18.0025 2 15.5385 2H14.1V0.75C14.1 0.335786 13.7642 0 13.35 0C12.9358 0 12.6 0.335786 12.6 0.75V2H6.90002V0.75ZM1.53846 8.7V15.5763C1.53846 17.177 2.84717 18.4746 4.46154 18.4746H15.5385C17.1528 18.4746 18.4615 17.177 18.4615 15.5763V8.7H1.53846ZM18.4615 7.2H1.53846V6.42373C1.53846 4.82304 2.84717 3.52542 4.46154 3.52542H5.40002V4.35C5.40002 4.76421 5.73581 5.1 6.15002 5.1C6.56424 5.1 6.90002 4.76421 6.90002 4.35V3.52542H12.6V4.35C12.6 4.76421 12.9358 5.1 13.35 5.1C13.7642 5.1 14.1 4.76421 14.1 4.35V3.52542H15.5385C17.1528 3.52542 18.4615 4.82304 18.4615 6.42373V7.2Z"
        fill="#333333"
      />
    </svg>
  );
}

export default SvgDate;
