import { createUseStyles } from "react-jss";
// import { breakpoints, defaultContainer } from "../../../constants/sizes";

const useStyles = createUseStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    height: 40,
    borderRadius: 4,
    backgroundColor: theme.background1,
    border: `1px solid ${theme.stroke}`,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
  },
  dateRangeWrapper: {
    minWidth: 230,
  },
  divider: {
    height: "100%",
    width: 1,
    backgroundColor: theme.stroke,
  },
  sliderWrapper: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: 10,
    "& .MuiSlider-root": {
      padding: 0,
    },
    "& .MuiSlider-rail": {
      height: 8,
      backgroundColor: theme.secondary0Opacity,
      borderRadius: 8,
    },
    "& .MuiSlider-track": {
      height: 8,
      backgroundColor: theme.primary0,
      borderRadius: 8,
    },
    "& .MuiSlider-thumb": {
      backgroundColor: "transparent",
      "& .arrowWrapper": {
        paddingBottom: 15,
      },

      boxShadow: "none !important",
    },
  },
}));

export default useStyles;
