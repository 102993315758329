/**
 * This file defines contants to be used on the code.
 */

export const defaultContainer = { Width: 1160, paddingHorizontal: 16 };
export const defaultContentContainer = {
  paddingLeft: 40,
  paddingTop: 60,
  paddingSm: 15,
};
export const sideBar = { Width: 600, paddingHorizontal: 20 };

export const breakpoints = {
  xsm: "@media (max-width: 400px)",
  sm: "@media (max-width: 600px)",
  smd: "@media (max-width: 800px)",
  md: "@media (max-width: 960px)",
  lg: "@media (max-width: 1190px)",
};

export const breakpointsHeight = {
  xsm: "@media (max-height: 400px)",
  sm: "@media (max-height: 640px)",
  smd: "@media (max-height: 800px)",
  md: "@media (max-height: 960px)",
  lg: "@media (max-height: 1190px)",
};
