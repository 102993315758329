import { createUseStyles } from "react-jss";
// import { breakpoints } from "../../constants/sizes";

const useStyles = createUseStyles((theme) => ({
  iconWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: (props) => props.size,
    height: (props) => props.size,
    backgroundColor: (props) => props.color,
    borderRadius: "100%",
    border: `2px solid ${theme.background0}`,
    "& path": {
      fill: theme.background0,
      transformOrigin: "center",
      transform: (props) => `scale(${props.size * 0.024})`,
    },
  },
  button: {
    padding: 0,
    borderRadius: "100%",
    backgroundColor: theme.background0,
    border: "none",
    cursor: "pointer",
  },
  icon: {
    padding: 0,
    borderRadius: "100%",
    backgroundColor: theme.background0,
    border: "none",
  },
  resultIcon: { marginLeft: (props) => (props.size < 24 ? -2 : 0) },
}));

export default useStyles;
