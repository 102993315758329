import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "react-jss";
import CustomTypography from "../CustomTypography";

import useStyles from "./styles";

/**
 * This component renders a share button styled for project.
 * @returns A share button
 */
const SectionContent = ({ children, grayBackground, className, title }) => {
  SectionContent.propTypes = {
    children: PropTypes.shape(),
    grayBackground: PropTypes.bool,
    className: PropTypes.string,
    title: PropTypes.string,
  };
  SectionContent.defaultProps = {
    children: undefined,
    grayBackground: false,
    className: "",
    title: "",
  };

  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <div className={`${classes.section} ${className}`}>
      <div
        className={`${
          grayBackground ? classes.contentWrapperGray : classes.contentWrapper
        } ${classes.centralize}`}
      >
        <div className={classes.content}>
          <CustomTypography className={classes.itemChild} variant="h3">
            {title}
          </CustomTypography>
          {children}
        </div>
      </div>
    </div>
  );
};

export default SectionContent;
