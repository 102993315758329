import { createUseStyles } from "react-jss";
import { breakpoints } from "../../../../../../constants/sizes";

const useStyles = createUseStyles(() => ({
  date: {
    textTransform: "capitalize",
  },
  productionsWrapper: {
    display: "flex",
    flexWrap: "wrap",
    gap: 20,
    [breakpoints.sm]: {
      display: "grid",
      gridTemplateColumns: "auto auto",
      gridColumnGap: 20,
    },
  },
}));

export default useStyles;
