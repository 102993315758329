import { createUseStyles } from "react-jss";
import { breakpoints, defaultContainer } from "../../../constants/sizes";

const useStyles = createUseStyles((theme) => ({
  fold: {
    position: "relative",
    padding: `0px ${defaultContainer.paddingHorizontal}px`
  },
  fifthFoldTitle: {
    marginBottom: 62
  },
  fifthFoldWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: 70,
    paddingBottom: 70,

    [breakpoints.md]: {
      flexDirection: "column",
      marginTop: 25,
      marginBottom: 40
    }
  },
  link: {
    textDecoration: "none"
  },
  fifthFoldButton: {
    marginTop: 60,
    backgroundColor: theme.primary0,
    color: "aliceblue",
    borderRadius: "4px",
    textTransform: "none",
    padding: 20.5,
    "& h4": {
      margin: 0
    },

    "&:hover": {
      backgroundColor: theme.primary1
    },
    [breakpoints.md]: {
      display: "flex",
      flexDirection: "horizontal",
      padding: 10,
      fontSize: 14,
      justifyContent: "center"
    }
  },
  href: {
    textDecoration: "none"
  },
  footerImage: {
    justifySelf: "center",
    alignSelf: "center",
    userSelect: "none"
  },
  partnersSection: {
    position: "relative",
    width: "100%",
    maxWidth: defaultContainer.Width + defaultContainer.paddingHorizontal * 2,
    padding: `0px ${defaultContainer.paddingHorizontal}px`,
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    marginBottom: 40,
  }
}));

export default useStyles;
