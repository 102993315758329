import * as React from "react";

function SvgHeadphone(props) {
  return (
    <svg
      width={32}
      height={32}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M26.667 17.573v-2.906a10.667 10.667 0 10-21.334 0v2.906a4 4 0 00-2.666 3.76V24a4 4 0 004 4h4A1.334 1.334 0 0012 26.667v-8a1.333 1.333 0 00-1.333-1.334H8v-2.666a8 8 0 1116 0v2.666h-2.667A1.333 1.333 0 0020 18.667v8A1.334 1.334 0 0021.333 28h4a4 4 0 004-4v-2.667a4 4 0 00-2.666-3.76zM9.333 20v5.333H6.667A1.333 1.333 0 015.333 24v-2.667A1.333 1.333 0 016.667 20h2.666zm17.334 4a1.333 1.333 0 01-1.334 1.333h-2.666V20h2.666a1.334 1.334 0 011.334 1.333V24z"
        fill="#333"
      />
    </svg>
  );
}

export default SvgHeadphone;
