import { createUseStyles } from "react-jss";
// import { breakpoints } from "../../../constants/sizes";

const useStyles = createUseStyles((/* theme */) => ({
  content: {
    width: "100%",
    "& button": {
      padding: 0,
      backgroundColor: "transparent",
      border: "none",
      cursor: "pointer",
      "& svg": {
        marginLeft: 10,
        transform: (props) =>
          props.active ? "rotate(0deg)" : "rotate(-90deg)",

        transition: "transform 0.15s ease-out",
      },
    },
  },
  children: {
    overflow: "hidden",
    transition: "max-height 0.15s ease-out",
    paddingLeft: 5,
  },
}));

export default useStyles;
