import { createUseStyles } from "react-jss";
import { breakpoints } from "../../../../../constants/sizes";

const useStyles = createUseStyles((theme) => ({
  root: {
    margin: "0 auto",
    padding: 24,
    display: "flex",
    alignItems: "center",
    borderRadius: 5,
    "&.MuiPaper-root": {
      backgroundColor: theme.background1,
      "&.MuiPaper-outlined": {
        border: `1px solid ${theme.stroke}`,
      },
    },
  },
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "auto auto",
    [breakpoints.sm]: {
      gridTemplateColumns: "auto auto",
    },
  },
  buttonsContainer: {
    [breakpoints.sm]: {
      display: "flex",
      justifyContent: "flex-end",
    },
  },
  button: {
    width: "100%",
    "& path": {
      fill: theme.background0,
    },
    [breakpoints.sm]: {
      "& div": {
        display: "none",
      },
    },
    "&.MuiButton-root": {
      minWidth: 50,
    },

    "& .MuiButton-startIcon": {
      [breakpoints.sm]: {
        margin: 0,
      },
    },
  },
  alertButton: {
    display: "none",
    [breakpoints.sm]: {
      display: "block",
    },
  },
  titleWide: {
    [breakpoints.sm]: {
      display: "none",
    },
  },
  titleSm: {
    display: "none",
    [breakpoints.sm]: {
      display: "block",
    },
  },
}));

export default useStyles;
