import React from "react";
import { useTheme } from "react-jss";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import useStyles from "./styles";
import Content from "./Content";

/**
 * This component represents a header button.
 */
const HeaderButton = ({
  text,
  component,
  url,
  isSelected,
  onClick,
  leftIcon,
}) => {
  HeaderButton.propTypes = {
    text: PropTypes.string,
    url: PropTypes.string,
    isSelected: PropTypes.bool,
    onClick: PropTypes.func,
    component: PropTypes.shape(),
    leftIcon: PropTypes.shape(),
  };
  HeaderButton.defaultProps = {
    text: "",
    url: "/",
    isSelected: false,
    onClick: undefined,
    component: undefined,
    leftIcon: undefined,
  };

  const theme = useTheme();
  const classes = useStyles({ theme, isSelected });

  return (
    <Link to={url} onClick={onClick} className={classes.headerButton}>
      {leftIcon && <div className="leftIcon">{leftIcon}</div>}
      <Content isSelected={isSelected} text={text} component={component} />
    </Link>
  );
};

export default HeaderButton;
